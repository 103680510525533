import React from "react";
import { Box, Typography, useTheme } from "@mui/material";
import LogoOnly from "../assets/logo/logoOnly.png";
import LogoTextOnly from "../assets/logo/textOnly.png";

const Error = () => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100vh",
        flexDirection: "column",
        backgroundColor: theme.palette.primary.litSurface,
      }}
    >
      <img
        alt="ifr-logo"
        src={LogoOnly}
        width="60px"
        style={{ marginBottom: "10px" }}
      />
      <img alt="ifr-logo-text" src={LogoTextOnly} width="260px" height="40px" />

      <Typography
        variant="h3"
        gutterBottom
        sx={{
          fontWeight: 600,
          marginTop: "20px",
          textAlign: "center",
          color: theme.palette.secondary.main,
        }}
      >
        Page Not Found
      </Typography>
    </Box>
  );
};

export default Error;
