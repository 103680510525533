import React, { useMemo, useRef, useState } from "react";
import { Box, Button, InputAdornment, styled, useTheme } from "@mui/material";
import { CustomTextField, Loader } from "../../index";
import {
  PayPalButtons,
  PayPalScriptProvider,
  usePayPalScriptReducer,
} from "@paypal/react-paypal-js";
import { debounce } from "lodash";

const PaymentContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "150px",
  border: `1px solid ${theme.palette.secondary.main}`,
  borderRadius: theme.borderRadius[10],
  padding: "25px",
  boxShadow: theme.boxShadow.shadowTwo,
}));

const PaypalCheckout = () => {
  const theme = useTheme();
  const [{ isPending }] = usePayPalScriptReducer();

  const [amount, setAmount] = useState();
  const [toggleButton, setToggleButton] = useState(false);

  const onAmountChange = (value) => setAmount(value);

  const onCreateOrder = (data, actions) => {
    return actions.order.create({
      purchase_units: [
        {
          amount: {
            currency_code: "USD",
            value: `${amount}`,
          },
        },
      ],
    });
  };

  const onApproveOrder = (data, actions) => {
    return actions.order.capture();
  };

  return (
    <PaymentContainer>
      {!toggleButton ? (
        <CustomTextField
          label="Amount"
          variant="outlined"
          name="paypal-amount"
          value={amount}
          onChange={(e) => onAmountChange(e.target.value)}
          size="small"
          type="number"
          fullWidth
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
          sx={{
            marginBottom: "20px",
          }}
          onBlur={(e) => {
            const inputValue = e.target.value;
            if (inputValue) {
              setAmount(Number(inputValue).toFixed(2));
            }
          }}
        />
      ) : isPending ? (
        <Loader />
      ) : (
        <PayPalButtons
          style={{ layout: "horizontal", height: 35, label: "pay" }}
          createOrder={(data, actions) => onCreateOrder(data, actions, amount)}
          onApprove={(data, actions) => onApproveOrder(data, actions)}
        />
      )}

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Button
          variant="contained"
          color="primary"
          sx={{
            "&.MuiButton-root": {
              color: theme.palette.neutral[90],
              marginTop: "10px",
              height: "35px",
              paddingLeft: "40px",
              paddingRight: "40px",
              textTransform: "none",
              "&:hover": {
                backgroundColor: theme.palette.primary.main,
              },
            },
          }}
          size="small"
          startIcon={
            !toggleButton ? (
              <i class="ri-checkbox-circle-fill"></i>
            ) : (
              <i class="ri-arrow-left-circle-fill"></i>
            )
          }
          onClick={() => setToggleButton((prev) => !prev)}
          disabled={!amount}
        >
          {!toggleButton ? "Confirm amount" : "Back"}
        </Button>
      </Box>
    </PaymentContainer>
  );
};

export default PaypalCheckout;
