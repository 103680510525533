import axiosInstance from "../../axiosInstance";

/**
 * Upload journal cover image
 * @param {*} file
 * @returns
 */
const uploadArticleImage = async (file) => {
  try {
    const res = await axiosInstance.post(
      `/api/document/upload/articleImages`,
      file
    );

    if (res.status === 200) {
      return res.data;
    } else {
    }
  } catch (error) {}
};

export default uploadArticleImage;
