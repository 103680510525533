import axiosInstance from "../../axiosInstance";

const getIssueById = async ({ issueId }) => {
  try {
    const res = await axiosInstance.get(`/api/issue?issueId=${issueId}`);

    if (res.status === 200) {
      return res.data;
    } else {
    }
  } catch (error) {}
};

export default getIssueById;
