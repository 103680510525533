import { Box, styled } from "@mui/material";
import React from "react";

const StyledLoaderContainer = styled(Box)(({ theme }) => ({
  "& .dot": {
    display: "inline-block",
    width: "15px",
    height: "15px",
    borderRadius: "15px",
    backgroundColor: theme.palette.secondary.main,
  },

  "& .dot:nth-last-of-type(1)": {
    animation: "bounce 1.2s .1s ease-in-out infinite",
  },
  "& .dot:nth-last-of-type(2)": {
    animation: "bounce 1.2s .2s ease-in-out infinite",
  },
  "& .dot:nth-last-of-type(3)": {
    animation: "bounce 1.2s .3s ease-in-out infinite",
  },

  "@keyframes bounce": {
    0: { transform: "translate(0,0)" },
    "50%": { transform: "translate(0,15px)" },
    "100%": { transform: "translate(0,0)" },
  },
}));

const Loader = ({ sx }) => {
  return (
    <StyledLoaderContainer sx={sx}>
      <Box className="dot"></Box>
      <Box className="dot"></Box>
      <Box className="dot"></Box>
    </StyledLoaderContainer>
  );
};

export default Loader;
