import { ThemeProvider } from "@mui/material";
import MainRouter from "./router";
import theme from "./styles/theme";
import { ToastContainer } from "react-toastify";
import { Provider } from "react-redux";
import store from "./redux/store";

import "./styles/global.css";
import "remixicon/fonts/remixicon.css";
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <MainRouter />
        </ThemeProvider>
      </Provider>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        draggable
        closeOnClick
        pauseOnHover
        theme="colored"
        limit={3}
      />
    </>
  );
}

export default App;
