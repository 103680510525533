import {
  Box,
  Button,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import React, { useEffect, useState } from "react";
import editorAdminServices from "../../../services/editor/admin";
import { editorListTableColumn } from "../../../helpers/constant";
import { Navigate, useNavigate } from "react-router-dom";
import moment from "moment";
import { CustomDialog, CustomIconMenu, Loader } from "../../../components";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.surface,
    color: theme.palette.neutral[90],
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const StyledTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.info.main,
  cursor: "pointer",
}));

const WarningDescText = styled(Typography)(({ theme }) => ({
  color: theme.palette.neutral[90],
}));

const JournalEditorList = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const [deleteEditor, setDeleteEditor] = useState({
    id: null,
    warningOpen: false,
    loading: false,
  });

  const [editorList, setEditorList] = useState({
    data: [],
    loading: false,
    filter: {
      sortBy: "",
      sortDirection: "",
      page: 0,
      rowsPerPage: 10,
      totalDocs: 0,
    },
    refresh: false,
  });

  useEffect(() => {
    const fetchAllEditors = async () => {
      setEditorList((prev) => ({
        ...prev,
        loading: true,
      }));

      const reqAllEditors = {
        searchKey: "",
        sortBy: editorList.filter.sortBy,
        sortDirection: editorList.filter.sortDirection,
        page: editorList.filter.page + 1,
        limit: editorList.filter.rowsPerPage,
      };

      const resAllEditors = await editorAdminServices.getEditorList(
        reqAllEditors
      );

      if (resAllEditors?.data?.docs?.length > 0) {
        setEditorList((prev) => ({
          ...prev,
          data: resAllEditors.data.docs,
          loading: false,
        }));
      } else {
        setEditorList((prev) => ({
          ...prev,
          data: [],
          loading: false,
        }));
      }
    };
    fetchAllEditors();
  }, [
    editorList.filter.page,
    editorList.filter.rowsPerPage,
    editorList.refresh,
    editorList.filter.sortBy,
    editorList.filter.sortDirection,
  ]);

  const handleChangePage = (event, newPage) => {
    setEditorList((prev) => ({
      ...prev,
      filter: {
        ...prev.filter,
        page: newPage,
      },
    }));
  };

  const handleChangeRowsPerPage = (event) => {
    setEditorList((prev) => ({
      ...prev,
      filter: {
        ...prev.filter,
        rowsPerPage: event.target.value,
        page: 0,
      },
    }));
  };

  const onClickDeleteEditor = async () => {
    if (!deleteEditor?.id) {
      return;
    }
    setDeleteEditor((prev) => ({
      ...prev,
      loading: true,
    }));

    const deleteRes = await editorAdminServices.deleteEditor({
      editorId: deleteEditor?.id,
    });

    if (deleteRes?.data) {
      setEditorList((prev) => ({
        ...prev,
        refresh: !prev.refresh,
      }));
      setDeleteEditor((prev) => ({
        ...prev,
        id: null,
        warningOpen: false,
        loading: false,
      }));
    } else {
      setDeleteEditor((prev) => ({
        ...prev,
        loading: false,
      }));
    }
  };

  const rowActionOptions = (rowData) => [
    {
      name: "Delete",
      handleOption: () => {
        setDeleteEditor((prev) => ({
          ...prev,
          id: rowData._id,
          warningOpen: true,
        }));
      },
    },
  ];

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          marginBottom: "10px",
        }}
      >
        <Button
          sx={{
            "&.MuiButton-root": {
              color: theme.palette.neutral[0],
              backgroundColor: theme.palette.secondary.main,
              textTransform: "none",

              "&:hover": {
                backgroundColor: theme.palette.secondary.hover,
              },
            },
          }}
          size="small"
          onClick={() => navigate(`/admin/editor/add`)}
        >
          Create Editor
        </Button>
      </Box>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              {editorListTableColumn?.map(
                (editorListColumn, editorListColumnIndex) => (
                  <StyledTableCell
                    size="small"
                    key={`${editorListColumnIndex}_${editorListColumn}`}
                  >
                    {editorListColumn}
                  </StyledTableCell>
                )
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {editorList?.loading ? (
              <StyledTableRow
                key={`subject_not_found`}
                sx={{ height: 30 * editorList?.filter?.rowsPerPage }}
              >
                <StyledTableCell
                  scope="row"
                  colSpan={7}
                  sx={{ textAlign: "center" }}
                >
                  <Loader />
                </StyledTableCell>
              </StyledTableRow>
            ) : editorList?.data?.length > 0 ? (
              editorList?.data?.map((editorRow) => (
                <StyledTableRow key={`${editorRow._id}${editorRow.name}`}>
                  <StyledTableCell size="small" component="th" scope="row">
                    <StyledTitle
                      onClick={() =>
                        navigate(`/admin/editor/edit?editorId=${editorRow._id}`)
                      }
                    >
                      {editorRow.name}
                    </StyledTitle>
                  </StyledTableCell>
                  <StyledTableCell size="small">
                    {editorRow?.journal?.name}
                  </StyledTableCell>
                  <StyledTableCell size="small">
                    {editorRow?.designation}
                  </StyledTableCell>
                  <StyledTableCell size="small">
                    {`${editorRow?.email}, ${editorRow?.phone}`}
                  </StyledTableCell>
                  <StyledTableCell size="small">
                    {editorRow?.updatedBy}
                  </StyledTableCell>
                  <StyledTableCell size="small">
                    {moment(editorRow?.updatedAt).format("DD-MM-YYYY")}
                  </StyledTableCell>
                  <StyledTableCell size="small">
                    <CustomIconMenu options={rowActionOptions(editorRow)} />
                  </StyledTableCell>
                </StyledTableRow>
              ))
            ) : (
              <StyledTableRow key={`subject_not_found`}>
                <StyledTableCell
                  size="small"
                  scope="row"
                  colSpan={7}
                  sx={{ textAlign: "center" }}
                >
                  No available data
                </StyledTableCell>
              </StyledTableRow>
            )}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={editorList?.data?.length}
          rowsPerPage={editorList?.filter?.rowsPerPage}
          page={editorList?.filter?.page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            "& .MuiToolbar-root": {
              minHeight: "48px",
            },
          }}
        />
      </TableContainer>
      <CustomDialog
        open={deleteEditor.warningOpen}
        title={"Are you sure ?"}
        agreeButtonText={"Delete"}
        agreeButtonProp={{
          variant: "contained",
          color: "secondary",
          type: "button",
          endIcon: deleteEditor.loading && <CircularProgress size={20} />,
          disabled: deleteEditor.loading,
          onClick: () => onClickDeleteEditor(),
          size: "small",
          sx: {
            "&.MuiButton-root": {
              color: theme.palette.neutral[0],
              // width: "100%",
              borderRadius: theme.borderRadius[10],
              // backgroundColor: theme.palette.secondary.main,
              textTransform: "none",
              "&:hover": {
                backgroundColor: theme.palette.secondary.hover,
              },
            },
          },
        }}
        cancelButtonText={"Cancel"}
        cancelButtonProp={{
          variant: "contained",
          color: "primary",
          type: "button",
          disabled: deleteEditor.loading,
          onClick: () =>
            setDeleteEditor((prev) => ({
              ...prev,
              id: null,
              warningOpen: false,
              loading: false,
            })),
          size: "small",
          sx: {
            "&.MuiButton-root": {
              color: theme.palette.neutral[0],
              // width: "100%",
              borderRadius: theme.borderRadius[10],
              // backgroundColor: theme.palette.primary.main,
              textTransform: "none",
              "&:hover": {
                backgroundColor: theme.palette.primary.hover,
              },
            },
          },
        }}
      >
        <WarningDescText>
          Do you really want to delete this record? This process cannot be
          undone.
        </WarningDescText>
      </CustomDialog>
    </Box>
  );
};

export default JournalEditorList;
