import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { ListSubheader, styled } from "@mui/material";

const CustomListSubheader = styled(ListSubheader)(({ theme }) => ({
  backgroundColor: theme.palette.primary.surface,
}));

export default function CustomMenu({
  menuButtonText,
  buttonProps,
  menuProps,
  options,
  showMenuArrow,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        endIcon={
          showMenuArrow ? open ? <ExpandLessIcon /> : <ExpandMoreIcon /> : null
        }
        {...buttonProps}
      >
        {menuButtonText}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        {...menuProps}
      >
        {options?.map((option, optionIndex) => (
          <>
            {option.categoryName && (
              <CustomListSubheader>{option.categoryName}</CustomListSubheader>
            )}
            {!option.categoryName && (
              <MenuItem
                key={`${option.name}_${optionIndex}`}
                onClick={() => {
                  option?.onSelect();
                  handleClose();
                }}
                selected={option?.selected || false}
              >
                {option.name}
              </MenuItem>
            )}
          </>
        ))}
      </Menu>
    </div>
  );
}
