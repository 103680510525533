import axiosInstance from "../../axiosInstance";

const deleteArchive = async ({ archiveId }) => {
  try {
    const res = await axiosInstance.delete(
      `/api/archive/delete?archiveId=${archiveId}`
    );

    if (res.status === 200) {
      return res.data;
    } else {
    }
  } catch (error) {}
};

export default deleteArchive;
