import { Box, Button, Typography, styled, useTheme } from "@mui/material";
import React from "react";
import CustomTextField from "../../../CustomTextField";

const StyledBox = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "100%",
  // background: theme.palette.primary.main,
  borderRadius: theme.borderRadius[10],
  padding: "25px",
  boxShadow: theme.boxShadow.shadowThree,
  marginBottom: "10px",
  // textAlign: "center",
}));

function PublishWithCardWithInput({
  title,
  buttonText,
  buttonProps,
  children,
}) {
  const theme = useTheme();
  return (
    <StyledBox>
      <Typography
        variant="body1"
        gutterBottom
        sx={{
          fontWeight: 600,
          textAlign: "center",
        }}
      >
        {title}
      </Typography>
      {children}
      {/* s */}
      <Button
        sx={{
          "&.MuiButton-root": {
            color: theme.palette.neutral[90],
            backgroundColor: theme.palette.primary.main,
            textTransform: "none",
            width: "100%",
            borderRadius: "30px",
            padding: "10px 20px",
            "&:hover": {
              backgroundColor: theme.palette.primary.hover,
            },
          },
        }}
        // size="small"
        // onClick={() => setCreateToggle((prev) => !prev)}
        {...buttonProps}
      >
        {buttonText}
      </Button>
    </StyledBox>
  );
}

export default PublishWithCardWithInput;
