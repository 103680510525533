import axios from "axios";
import authServices from "./auth";
import { Navigate } from "react-router-dom";

let axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_BASE_URL,
  withCredentials: true,
});

axiosInstance.interceptors.request.use(
  (config) => {
    /**
     * Get the access token from localStorage
     */
    const getAccessToken = localStorage.getItem("ifrAccessToken");

    /**
     * return the config with access token
     */
    config.headers = {
      Authorization: `Bearer ${getAccessToken}`,
      // "Content-Type": `application/json`,
    };
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.response.config;
    /**
     * Check 401 status for access token expire, then
     * get the new access token from refresh token and re-try the api fetch
     */
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      const res = await authServices.refreshToken();
      const { accessToken } = res;
      localStorage.setItem("ifrAccessToken", accessToken);
      axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;

      return axiosInstance(originalRequest);
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
