import React, { useEffect, useState } from "react";
import { Box, Grid, Pagination, Typography, useTheme } from "@mui/material";
import OpenAccessLogoBlack from "../../../assets/images/OpenAccessLogoBlack.png";
import articleWebsiteServices from "../../../services/article/website";
import { useParams } from "react-router-dom";
import issueWebsiteServices from "../../../services/issue/website";
import { ArticleCard } from "../../../components/website/articlePage";
import { Loader } from "../../../components";
import { setReduxJournalDetails } from "../../../redux/slices/webJournal";
import { useDispatch, useSelector } from "react-redux";

const ArticleList = () => {
  const { issueId } = useParams();
  const theme = useTheme();
  const dispatch = useDispatch();

  const { journalDetails } = useSelector((state) => state.webJournalReducer);

  const [articleList, setArticleList] = useState({
    data: [],
    page: 1,
    limit: 10,
    loading: false,
  });

  const [issue, setIssue] = useState({
    data: {},
    loading: false,
  });

  useEffect(() => {
    const fetchIssue = async () => {
      setIssue((prev) => ({
        ...prev,
        loading: true,
      }));
      const resIssue = await issueWebsiteServices.getIssueByIdWebsite({
        issueId: issueId,
      });

      if (resIssue) {
        setIssue((prev) => ({
          ...prev,
          data: resIssue.data,
          loading: false,
        }));
        if (!journalDetails) {
          dispatch(setReduxJournalDetails(resIssue.data.journal));
        }
      } else {
        setIssue((prev) => ({
          ...prev,
          loading: false,
        }));
      }
    };

    fetchIssue();
  }, []);

  useEffect(() => {
    const fetchArticles = async () => {
      setArticleList((prev) => ({
        ...prev,
        loading: true,
      }));
      const resArticles = await articleWebsiteServices.getArticleListWebsite({
        issue: issueId,
        page: articleList.page,
        limit: articleList.limit,
      });
      if (resArticles) {
        setArticleList((prev) => ({
          ...prev,
          data: resArticles.data,
          loading: false,
        }));
      } else {
        setArticleList((prev) => ({
          ...prev,
          loading: false,
        }));
      }
    };

    fetchArticles();
  }, [issueId, articleList.page, articleList.limit]);

  return (
    <Box
      sx={{
        padding: "20px",
        height: "100%",
        background: theme.palette.primary.litSurface,
      }}
    >
      {issue?.loading ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "80vh",
          }}
        >
          <Loader />
        </Box>
      ) : (
        <>
          <Typography
            variant="h5"
            gutterBottom
            sx={{
              fontWeight: 600,
              marginBottom: "20px",
            }}
          >
            {`${issue?.data?.archive?.name}, ${issue?.data?.name}, ${issue?.data?.journal?.name}`}
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={3} md={3} lg={3}>
              <Box
                sx={{
                  maxWidth: "100%",
                  borderRadius: theme.borderRadius[10],
                  overflow: "hidden",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-start",
                  flexDirection: "column",
                }}
              >
                <img
                  src={OpenAccessLogoBlack}
                  alt="open access"
                  style={{
                    width: "120px",
                    marginBottom: "20px",
                  }}
                />
                <img
                  src={`${process.env.REACT_APP_FILE_BASE_URL}${issue?.data?.journal?.coverImgUrl}`}
                  alt="journal cover"
                  style={{
                    maxWidth: "80%",
                  }}
                />
                <Typography
                  variant="body1"
                  gutterBottom
                  sx={{
                    fontWeight: 600,
                    marginTop: "20px",
                  }}
                >
                  Publisher: International Federation of Research and Discovery
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={9} md={9} lg={9}>
              {articleList?.loading ? (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "80vh",
                  }}
                >
                  <Loader />
                </Box>
              ) : articleList?.data?.docs?.length > 0 ? (
                <Box
                  sx={{
                    height: "100%",
                  }}
                >
                  {articleList?.data?.docs?.map((articleData) => (
                    <ArticleCard
                      key={`${articleData._id}`}
                      articleDetails={articleData}
                      journalDetails={issue?.data?.journal}
                    />
                  ))}
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: "20px",
                    }}
                  >
                    <Pagination
                      count={articleList?.data?.totalPages || 1}
                      page={articleList?.page}
                      color="primary"
                      onChange={(event, value) =>
                        setArticleList((prev) => ({
                          ...prev,
                          page: value,
                        }))
                      }
                    />
                  </Box>
                </Box>
              ) : (
                <Box
                  sx={{
                    height: "100vh",
                  }}
                >
                  <Typography variant="body1"> No article available</Typography>
                </Box>
              )}
            </Grid>
          </Grid>
        </>
      )}
    </Box>
  );
};

export default ArticleList;
