import React from "react";
import { Box, Button, IconButton, Typography, useTheme } from "@mui/material";

const CustomFileUploadButton = ({
  fileName,
  fileTitleText,
  buttonText,
  fileNameProps,
  buttonProps,
  deleteButtonProps,
  inputProps,
}) => {
  const theme = useTheme();
  return fileName ? (
    <Box
      sx={{
        border: `1px solid ${theme.palette.neutral[50]}`,
        borderRadius: theme.borderRadius[8],
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "10px",
        position: "relative",
      }}
    >
      {fileTitleText && (
        <Typography
          variant="caption"
          sx={{
            backgroundColor: theme.palette.neutral[0],
            color: `${theme.palette.neutral[80]}`,
            position: "absolute",
            top: "-11px",
            left: "10px",
            padding: "0 3px",
          }}
          noWrap
        >
          {fileTitleText}
        </Typography>
      )}
      <Typography variant="body1" noWrap {...fileNameProps}>
        {fileName}
      </Typography>
      <IconButton
        edge="end"
        sx={{
          background: theme.palette.neutral[0],
          color: theme.palette.secondary.main,
        }}
        size="small"
        {...deleteButtonProps}
      >
        <i class="ri-delete-bin-2-fill ri-sm"></i>
      </IconButton>
    </Box>
  ) : (
    <Button {...buttonProps}>
      {buttonText ? buttonText : "Upload file"}
      <input type="file" hidden {...inputProps} />
    </Button>
  );
};

export default CustomFileUploadButton;
