import axiosInstance from "../axiosInstance";

/**
 * Delete journal cover image
 * @param {*} deleteFilePaths
 * @returns
 */
const deleteJournalCoverImage = async ({ deleteFilePaths }) => {
  try {
    const res = await axiosInstance.post(`/api/document/delete`, {
      deleteFilePaths,
    });

    if (res.status === 200) {
      return res.data;
    } else {
    }
  } catch (error) {
    console.log(error);
  }
};

export default deleteJournalCoverImage;
