import React, { useState } from "react";
import { Box, useTheme, Button, CircularProgress } from "@mui/material";
import { CustomTextField } from "../../components";
import LogoOnly from "../../assets/logo/logoOnly.png";
import LogoTextOnly from "../../assets/logo/textOnly.png";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import authServices from "../../services/auth";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

function AdminLogin() {
  const theme = useTheme();
  const navigate = useNavigate();
  const [loginLoader, setLoginLoader] = useState(false);

  const loginValidationSchema = yup.object().shape({
    email: yup.string().email().required("email is required"),
    password: yup.string().required("password is required"),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",
      password: "",
    },
    resolver: yupResolver(loginValidationSchema),
  });

  const onSubmit = async (data) => {
    setLoginLoader(true);
    const resLogin = await authServices.login(data);

    if (resLogin) {
      let { accessToken, userId } = resLogin;
      localStorage.setItem("ifrAccessToken", accessToken);
      localStorage.setItem("userId", userId);
      toast.success("Login successful");
      navigate("/admin/home");
      setLoginLoader(false);
    } else {
      setLoginLoader(false);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: "100vh",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justify: "center",
          flexDirection: "column",
          background: theme.palette.neutral[0],
          width: "30%",
          boxShadow: `0px 0px 5px ${theme.palette.neutral[50]}`,
          borderRadius: theme.borderRadius[10],
          padding: "30px",
        }}
      >
        <Box
          sx={{ display: "flex", alignItems: "center", marginBottom: "30px" }}
        >
          <img alt="ifr-logo" src={LogoOnly} width="40px" />
          <img
            alt="ifr-logo-text"
            src={LogoTextOnly}
            width="130px"
            height="20px"
          />
        </Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <CustomTextField
                label="Email"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                sx={{
                  marginBottom: "20px",
                }}
                helperText={errors?.email?.message}
                error={errors?.email?.message}
                {...field}
              />
            )}
          />

          <Controller
            name="password"
            control={control}
            render={({ field }) => (
              <CustomTextField
                id="outlined-basic"
                label="Password"
                variant="outlined"
                size="small"
                type="password"
                fullWidth
                sx={{
                  marginBottom: "30px",
                }}
                helperText={errors?.password?.message}
                error={errors?.password?.message}
                {...field}
              />
            )}
          />

          <Button
            variant="contained"
            color="secondary"
            sx={{
              "&.MuiButton-root": {
                color: theme.palette.neutral[0],
                height: "35px",
                width: "100%",
                paddingLeft: "40px",
                paddingRight: "40px",
                borderRadius: theme.borderRadius[10],
                textTransform: "none",
                "&:hover": {
                  backgroundColor: theme.palette.secondary.hover,
                },
              },
            }}
            size="small"
            type="submit"
            endIcon={loginLoader && <CircularProgress size={20} />}
            disabled={loginLoader}
          >
            Login
          </Button>
        </form>
      </Box>
    </Box>
  );
}

export default AdminLogin;
