import axiosInstance from "../axiosInstance";

const refreshToken = async () => {
  try {
    const res = await axiosInstance.get("/api/auth/refreshToken");

    if (res.status === 200) {
      return res.data;
    } else {
    }
  } catch (error) {}
};

export default refreshToken;
