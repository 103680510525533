import axiosInstance from "../../axiosInstance";

const createArticleSubmission = async (data) => {
  try {
    const res = await axiosInstance.post(
      `/api/articleSubmission/websiteCreateArticleSubmission`,
      data
    );

    if (res.status === 201) {
      return res.data;
    } else {
    }
  } catch (error) {}
};

export default createArticleSubmission;
