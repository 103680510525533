export const adminSideBarMenu = [
  {
    name: "Home",
    navigate: "/admin/home",
    icon: <i className="ri-home-2-fill ri-lg"></i>,
  },
  {
    name: "Subject",
    navigate: "/admin/subject",
    icon: <i className="ri-book-fill ri-lg"></i>,
  },
  {
    name: "Journal",
    navigate: "/admin/journal",
    icon: <i className="ri-booklet-fill ri-lg"></i>,
  },
  {
    name: "Archives",
    navigate: "/admin/archive",
    icon: <i className="ri-archive-fill ri-lg"></i>,
  },
  {
    name: "Editors",
    navigate: "/admin/editor",
    icon: <i className="ri-user-2-fill ri-lg"></i>,
  },
  {
    name: "Article Submissions",
    navigate: "/admin/article-submission",
    icon: <i className="ri-file-list-3-fill ri-lg"></i>,
  },
];

export const subjectListTableColumn = ["Name", "Updated By", "Updated At", ""];

export const journalListTableColumn = [
  "Name",
  "Language",
  "Origin",
  "Updated By",
  "Updated At",
  "",
];

export const issueListTableColumn = [
  "Name",
  "Archive",
  "Journal",
  "Updated By",
  "Updated At",
  "",
];

export const articleListTableColumn = [
  "Name",
  "Issue",
  "Archive",
  "Page No.",
  "Status",
  "Updated By",
  "Updated At",
  "",
];

export const articleSubmissionListTableColumn = [
  "Tracking ID",
  "Manuscript Title",
  "Author name",
  "Contacts",
  "Journal",
  "Article Type",
  // "Updated By",
  "Submitted At",
  "",
];

export const editorListTableColumn = [
  "Name",
  "Journal",
  "Designation",
  "Contacts",
  "updated by",
  "updated at",
  "",
];

/**
 * Website constants
 */
export const navbarMenu = (
  {
    journalId = "",
    journalCustomId = "",
    issueId = "",
    articleId = "",
    pathname,
  },
  navigate
) => {
  let includesJournalPath = [
    "/journal/:journalId/author-guidelines",
    "/journal/:journalId/reviewer-guidelines",
  ].includes(pathname);

  if (journalId || issueId || articleId || includesJournalPath) {
    return [
      {
        name: "IFRD Home",
        route: "/",
        type: "button",
      },
      {
        name: "About Journal",
        route: `/journal/${journalId || journalCustomId}`,
        type: "button",
      },
      {
        name: "Editorial Board",
        route: `/editor/${journalId || journalCustomId}`,
        type: "button",
      },
      {
        name: "Instructions",
        type: "menu",
        menuOptions: [
          // {
          //   name: "Advisory Board",
          //   onSelect: () => navigate("/membership"),
          // },
          {
            name: "Author Guidelines",
            onSelect: () =>
              navigate(
                `/journal/${journalId || journalCustomId}/author-guidelines`
              ),
          },
          {
            name: "Editorial Guidelines",
            onSelect: () =>
              navigate(
                `/journal/${journalId || journalCustomId}/editorial-guidelines`
              ),
          },
          {
            name: "Reviewer's Guidelines",
            onSelect: () =>
              navigate(
                `/journal/${journalId || journalCustomId}/reviewer-guidelines`
              ),
          },
        ],
      },
      {
        name: "Ethics & Policies",
        route: `/journal/${journalId || journalCustomId}/ethics-and-policies`,
        type: "button",
      },
      {
        name: "Archive",
        route: `/archive/${journalId || journalCustomId}`,
        type: "button",
      },
      {
        name: "APC",
        route: `/journal/${journalId || journalCustomId}/apc`,
        type: "button",
      },
      {
        name: "Reproducing Form",
        route: "",
        type: "button",
      },
    ];
  }

  return [
    {
      name: "Home",
      route: "/",
      type: "button",
    },
    {
      name: "Journals",
      route: "/journal",
      type: "button",
    },
    {
      name: "Membership",
      // route: "/",
      type: "menu",
      menuOptions: [
        {
          name: "About Membership",
          onSelect: () => navigate("/membership"),
        },
        {
          name: "Apply Membership",
          onSelect: () => navigate("/"),
        },
        {
          name: "Student Insider Membership",
          onSelect: () => navigate("/membership/student"),
        },
        {
          name: "Privileged Membership",
          onSelect: () => navigate("/membership/privilege"),
        },
        {
          name: "Luminary Membership",
          onSelect: () => navigate("/membership/luminary"),
        },
        {
          name: "Delegate Membership",
          onSelect: () => navigate("/membership/delegate"),
        },
      ],
    },
    {
      name: "Conference",
      route: "#",
      type: "button",
    },
    {
      name: "Payments",
      route: "/payment",
      type: "button",
    },
    {
      name: "About us",
      route: "/about-us",
      type: "button",
    },
  ];
};

export const publishWithIFRDiscoveryCards = [
  {
    title: "Open Access Journal",
    buttonText: "Submit Article",
    inputPlaceholder: "Choose Journal",
    isDropDown: false,
    tag: "journal",
  },
  {
    title: "Track Manuscript",
    buttonText: "Get Details",
    inputPlaceholder: "Type Manuscript No",
    isDropDown: false,
    tag: "track",
  },
  {
    title: "Browse Subjects",
    buttonText: "Submit",
    inputPlaceholder: "choose Subjects",
    isDropDown: false,
    tag: "browse",
  },
];

export const publishWithIFRDiscoveryDisplayCards = [
  {
    title: "Membership",
    imageTag: "membership",
    description: "Be a part of our family",
  },
  {
    title: "Upcoming Conference",
    imageTag: "conference",
    description: "Empowering scholars for extra miles",
  },
  {
    title: "Journal & Publication",
    imageTag: "article",
    description: "Discover Globally Peer-Reviewed Journals",
  },
];

export const supportHubCards = [
  {
    title: "Journal Assistance Center",
    imageTag: "creative",
    description:
      "Simplify Your Publication Workflow and Enhance Your Research Influence",
  },
  {
    title: "Editorial Board",
    imageTag: "article",
    description: "",
    isButton: true,
    buttonText: "Join as Editor",
  },
  {
    title: "Publication Assistance Center",
    imageTag: "assistance",
    description:
      "Taking Your Research to New Pinnacles with Professional Publishing Assistance",
  },
  {
    title: "Reviewer Board",
    imageTag: "review",
    description: "",
    isButton: true,
    buttonText: "Join as Reviewer",
  },
];

export const socialMediaArray = [
  {
    imageTag: "Facebook",
  },
  {
    imageTag: "Instagram",
  },
  {
    imageTag: "Twitter",
  },
  {
    imageTag: "Linkedin",
  },
  {
    imageTag: "Youtube",
  },
];

export const footerQuickLinksArray = [
  {
    name: "Terms & Conditions",
    route: "/term-conditions",
  },
  {
    name: "Privacy Policy",
    route: "/",
  },
  {
    name: "Refund Policy",
    route: "/refund-policy",
  },
  {
    name: "Hard Copy",
    route: "/",
  },
  {
    name: "Payments",
    route: "/payment",
  },
];

export const footerOthersArray = [
  {
    name: "About Us",
    route: "/about-us",
  },
  {
    name: "Contact Us",
    route: "/contact-us",
  },
  {
    name: "Join as Editor",
    route: "/",
  },
  {
    name: "Join as Reviewer",
    route: "/",
  },
  {
    name: "Careers",
    route: "/careers",
  },
  {
    name: "FAQ",
    route: "/faq",
  },
];

export const aboutMembershipPoints = [
  {
    text: "Priority access to submit your research articles across our diverse range of journals.",
  },
  {
    text: "Expedited peer review process to fast-track the publication of your work.",
  },
  {
    text: "Access to specialized resources and tools to support your research endeavors.",
  },
  {
    text: "Networking opportunities and collaboration initiatives to connect with peers in your field.",
  },
];

export const aboutMembershipSectionLinks = [
  {
    name: "Student Insider Membership",
    link: "/membership/student",
  },
  {
    name: "Privileged Membership",
    link: "/membership/privilege",
  },
  {
    name: "Luminary Membership",
    link: "/membership/luminary",
  },
  {
    name: "Delegate Membership",
    link: "/membership/delegate",
  },
];

export const studentMembershipAdvantagePoints = [
  {
    text: "Unlock the Gateway to your professional future with our prestigious ‘Student Insider E-Membership (SMIFRD)’ Certificate.",
  },
  {
    text: "Membership offers the benefit of publishing your first 2 research articles free of cost.",
  },
  {
    text: "Membership grants you a 50% discount on publication fees for all our journals till the membership validity.",
  },
  {
    text: "Members have the privilege of participating in all National and International Seminars/Conferences with a 50% discount on registration fees.",
  },
  {
    text: "Membership Validity up to 3 years.",
  },
  {
    text: "Members will be promoted to Privileged Membership (PMIFRD) after the expiry of Student Insider Membership (SMIFRD). Check (T&C Apply)",
  },
];

export const privilegeMembershipAdvantagePoints = [
  {
    text: "Members will be presented with an exclusive IFRD specially designed Silver Medal of membership, accompanied by a framed certificate, and delivered to their residential address.",
  },
  {
    text: "Gain access to a prestigious E-Membership Certificate, a valuable tool for advancing both academic and professional endeavours.",
  },
  {
    text: "Experience the advantage of expedited article publication through our Fast-Track Mode, ensuring publication within just 15 business days.",
  },
  {
    text: "Membership offers the privilege of publishing your first four research articles free of charge.",
  },
  {
    text: "Members will enjoy a substantial 50% discount on publication fees across all our journals.",
  },
  {
    text: "Benefit from a 50% waiver on registration fees for all National and International Seminars/Conferences scheduled for upcoming events.",
  },
  {
    text: "Participate in Academic and Research Award applications without any registration fees, an opportunity exclusively available to members",
  },
  {
    text: "Enjoy the enduring benefits of Lifetime Membership Validity, ensuring a lasting connection to our resources and opportunities.",
  },
];

export const privilegeMembershipEligibleCriteriaPoints = [
  {
    text: "Bachelor's Degree with minimum 5 year's experience in Academic, Teaching, Social Services or Research field in any discipline.",
  },
  {
    text: "Master's Degree with minimum 5 year's experience in Academic, Teaching, Social Services or Research field in any discipline.",
  },
  {
    text: "PhD Degree with minimum 5 year's experience in Academic, Teaching, Social Services or Research field in any discipline",
  },
  {
    text: "Applicants whose Student Insider Membership(SMIFRD) has expired and has completed minimum Bachelor's Degree with a history of at least 5 research articles published.",
  },
];

export const luminaryMembershipAdvantagePoints = [
  {
    text: "Luminary members can proudly display the prestigious Luminary designation after their name (Ex: Dr. Sarah Johnson, LMIFRD), signifying their exceptional status.",
  },
  {
    text: "Upon joining, Luminary members will be honoured with an exquisite IFRD specially designed Golden Medal, complemented by a framed membership certificate, delivered to their designated address.",
  },
  {
    text: "Luminary members will receive a coveted E-Membership Certificate, a valuable asset for enhancing their academic and professional journey.",
  },
  {
    text: "Experience the advantage of expedited article publication through our Fast-Track Mode, ensuring publication within just 5 business days.",
  },
  {
    text: "Luminary membership entitles you to publish your initial 5 research articles at Free of Cost.",
  },
  {
    text: "Enjoy a generous 50% discount on publication fees across all our prestigious journals.",
  },
  {
    text: "Luminary members are eligible for a remarkable 50% waiver on registration fees for all National and International Seminars/Conferences slated for upcoming events.",
  },
  {
    text: "Participate in Academic and Research Award applications without any registration fees, exclusively available to Luminary members.",
  },
  {
    text: "Luminary members have the privilege of assuming editorial roles in our esteemed International Journals and are given priority invitations as Highly Regarded Orators at our forthcoming National/International events.",
  },
  {
    text: "Luminary Membership grants you lifetime access to all these exclusive benefits, ensuring your continued academic and professional advancement.",
  },
];

export const luminaryMembershipEligibleCriteriaPoints = [
  {
    text: "Holding Master’s Degree with minimum 10 years experience in Academic, Teaching, Social Services or Research field in any discipline and has published minimum 10 research articles in any national / international journals.",
  },
  {
    text: "Holding PhD Degree with minimum 8 years experience in Academic, Teaching, Social Services or Research field in any discipline and has published minimum 8 research articles in any national / international Journals.",
  },
  {
    text: "Any member who is a part of our Privileged Membership (PMIFRD) and has a history of 25 research articles published in any national / international journals.",
  },
];

export const submitArticleTypeOptions = [
  {
    name: "Original Research Articles",
  },
  {
    name: "Research Article",
  },
  {
    name: "Review Articles",
  },
  {
    name: "Meta-analysis",
  },
  {
    name: "Case Reports",
  },
  {
    name: "Editorial",
  },
  {
    name: "Letter to the Editor",
  },
  {
    name: "Perspective Articles",
  },
  {
    name: "Short Communications",
  },
  {
    name: "Commentary",
  },
  {
    name: "Systematic Review",
  },
  {
    name: "Methodology Articles",
  },
  {
    name: "Protocol Articles",
  },
  {
    name: "Book Reviews",
  },
  {
    name: "Conference Proceedings",
  },
  {
    name: "Thematic Issue Articles",
  },
  {
    name: "Technical Notes",
  },
  {
    name: "Data Papers",
  },
];

export const copyrightFormTC = [
  {
    text: "The copyright transfer includes the exclusive right to reproduce, distribute, display, and publish the Work in print and electronic formats, including but not limited to the Publisher's website, databases, and third-party repositories.",
  },
  {
    text: "The Publisher has the right to edit, revise, and adapt the Work as necessary for publication, including changes to format, style, and content, while maintaining the integrity and original intent of the Work.",
  },
  {
    text: "The transfer of copyright is effective upon acceptance of the Work for publication in the Journal that is only released by IFR Discovery.",
  },
  {
    text: "I warrant that the Work is original, has not been previously published, and is not currently under consideration for publication elsewhere. I further warrant that the Work does not infringe upon any copyright, trademark, or other intellectual property rights of any third party. However, if any conflict arises in the future and found that the paper does not comply with the copyright standards, then the publisher shall have full rights to withdraw or take any action over the paper, to solve the conflict for peaceful settlement.",
  },
  {
    text: " I understand that I retain certain rights to the Work, including the right to use the Work for noncommercial purposes, such as teaching, research, and personal use, provided that proper attribution is given to the Publisher and the original publication in its Journal is acknowledged.",
  },
  {
    text: "I agree to indemnify and hold harmless the Publisher, its officers, employees, and agents from any claims or damages arising out of any breach of the warranties and representations made in this Agreement.",
  },
  {
    text: "This Agreement constitutes the entire understanding between the parties regarding the copyright in the Work and supersedes all prior agreements and understandings, whether written or oral, relating to the same.",
  },
];

export const articleSubmissionTrackingTags = [
  { name: "Acknowledgement", tag: "acknowledgement" },
  { name: "Plagiarisms Check", tag: "plagiarismsCheck" },
  { name: "Editor Assigned", tag: "editorAssigned" },
  { name: "Reviewer Assigned", tag: "reviewerAssigned" },
  { name: "Approved/Rejected", tag: "approvedRejected" },
  { name: "Payment", tag: "payment" },
  { name: "Published", tag: "published" },
];

export const authGuidelinesArticlePoints = {
  article: [
    {
      text: "Abstract: Limited to 400 words without key words.",
    },
    {
      text: "Page Limit: Limited to 20 pages including abstract, references, tables, figures, and their legends.",
    },
    {
      text: "Tables and figures: The total number of tables and figures for the main text is limited to 8.",
    },
    {
      text: "References guideline: Limited to 30 references. Please use the most important and relevant references where possible.",
    },
    {
      text: "Supplemental information: Supplemental information for less important materials, methods, data (tables and figures) is allowed.",
    },
  ],
  review: [
    {
      text: "Abstract: Limited to 400 words.",
    },
    {
      text: "Page Limit: Limited to 20 pages, including abstract, references, figures and tables.",
    },
    {
      text: "References guideline: Limited to 60 references.",
    },
    {
      text: "Supplemental information: Optional.",
    },
  ],
  method: [
    {
      text: "Abstract: Limited to 350 words.",
    },
    {
      text: "Page Limit: Limited to 8 pages, including abstract, references, figures and tables.",
    },
    {
      text: "References guideline: Limited to 20 references.",
    },
    {
      text: "Supplemental information: Supplemental information for less important materials, methods, data (tables and figures) are allowed.",
    },
  ],
  editorial: [
    {
      text: "Abstract: Not relevant.",
    },
    {
      text: "Page Limit: Limited to 8 pages, including references.",
    },
    {
      text: "References guideline: Limited to 10 references.",
    },
    {
      text: "Supplemental information: Optional.",
    },
  ],
  perspective: [
    {
      text: "Abstract: Not relevant.",
    },
    {
      text: "Page limit: Limited to 10 pages includig abstract, references and figure legends.",
    },
    {
      text: "References guideline: Limited to 20 references.",
    },
    {
      text: "Supplemental information: Optional.",
    },
  ],
  manuscriptContent: [
    {
      text: "Title page",
    },
    {
      text: "Abstract",
    },
    {
      text: "Introduction",
    },
    {
      text: "Materials and methods",
    },
    {
      text: "Results",
    },
    {
      text: "Discussion",
    },
    {
      text: "Conclusions",
    },
    {
      text: "Author Contributions",
    },
    {
      text: "Acknowledgments",
    },
    {
      text: "Conflict of interest",
    },
    {
      text: "Data availability",
    },
    {
      text: "References",
    },
  ],
  manuscriptCoverLetter: [
    {
      text: "The title of the manuscript and whether it is an original submission or a re-submission.",
    },
    {
      text: "A highlight that contains 3 to 5 short sentences to state the core findings of the research described in the paper.",
    },
    {
      text: "All authors agree with the submission.",
    },
    {
      text: "The work has not been published or submitted for publication elsewhere, including in another form or language.",
    },
    {
      text: "Please declare all figures, tables and data that are reproduced from other publications; the authors have to obtain authorization from the copyright holders (usually the publisher) for their use, and should include this authorization form with their submission;",
    },
    {
      text: "Please state if the word counts conform to the required word limits;",
    },
    {
      text: "Statement of Conflict of Interests.",
    },
  ],
  manuscriptOrganizationSubmission: [
    {
      text: "Cover letter;",
    },
    {
      text: "Manuscript;",
    },
    {
      text: "Supplementary materials (if applicable).",
    },
  ],
  manuscriptOrganizationSubRevisions: [
    {
      text: "A rebuttal letter;",
    },
    {
      text: "Marked-up version of the manuscript (Microsoft Word) with no figures;",
    },
    {
      text: "Clean (non-highlighted) version of the manuscript;",
    },
    {
      text: "Figures with a resolution of 300 dpi or above are expected;",
    },
    {
      text: "Supplementary files (Microsoft Word or Excel) are expected.",
    },
  ],
  manuscriptOrganizationRequirements: [
    {
      text: "The addition of all authors into the submission system",
      description:
        "The full names of all authors should be entered into the submission system in the same order as shown in the manuscript. Contact information including institution, address and official e-mail addresses must be provided. Please use organizational email accounts as the official author email addresses where possible, such as abc@mtu.edu.",
    },
    {
      text: "Title Page",
      description:
        "A title page should include a succinct title (less than 300 characters), a very concise running title (which should be less than 50 characters, for example, Plant Immune Mechanisms), the full names of all authors including their given names, the affiliations and location (including city, state, country and zip/post code), the full name, and official email address(es) of the corresponding author(s).",
    },
    {
      text: "Abstract",
      description:
        "A brief abstract up to 400 words should state the background and purpose of the research, methods (or rationale), main results and findings, and brief conclusions of the study. The abstract should not contain abbreviations or references and should not be structured.",
    },
    {
      text: "Introduction",
      description:
        "The Introduction should provide a background on the research topic, and a focused literature review that includes known and controversial findings, challenging issues, and the hypothesis of the research. Finally, briefly summarize the materials and methods as well as the research rationale. Note that the Introduction should not contain results or conclusions.",
    },
    {
      text: "Materials and Methods",
      description:
        'Materials and Methods should be described in sufficient detail to allow the research work to be reliably reproduced in another laboratory, and to leave the readers in no doubt as to how the results were derived. Please also remember to include a section on "Statistical Analysis" where the statistical methods, with enough details including threshold cutoff, are provided to enable an appraisal of the work and an analysis of the data to reproduce the results. When the total word number exceeds the limit, it is suggested to check for redundancy in the Introduction, Results, and Discussion for removal rather than to briefly describe the methods without providing sufficient details. In a circumstance where it is difficult to compress other sections, some detailed methods, especially those that are routinely used in most laboratories, can be placed into the Electronic Supplementary Information section.',
    },
    {
      text: "Results",
      description:
        "Results should be presented in a logical sequence in the text, tables and figures, usually matching the order as described in the Materials and Methods. Repeated presentation of the same data in different forms should be avoided. The Results should not include a lengthy discussion.",
    },
    {
      text: "Discussion",
      description:
        "The results should not be restated in the Discussion, but can be recapitulated to support or rebut existing hypotheses, conceived assumptions, or true facts stated in the Introduction or literature. The Discussion should compare and relate new or major findings in the Results to the existing body of knowledge in the field, in terms of improvement or further advance of current knowledge and technologies (Methods paper), and overall significance and contribution to the field as well as the future research focuses that stemmed from this study.",
    },
    {
      text: "Conclusions",
      description:
        "A short conclusion of the study may be presented in a short Conclusions section, or at the end of the Discussion section.",
    },
    {
      text: "Electronic Supplementary Information",
      description: "",
      olType: "a",
      listType: "ol",
      points: [
        {
          text: " All lesser significant figures, tables, and procedures that support the main body of key results and conclusions in the text should be included as Electronic Supplementary Information and uploaded as a separate file (PDF) at the time of manuscript submission.",
        },
        {
          text: 'The availability of Electronic Supplementary Information should be mentioned in a separate paragraph in the manuscript, placed immediately before the References, as: "Electronic Supplementary Information".',
        },
      ],
    },
    {
      text: "Author Contributions",
      description:
        "IFR Discovery requires the independent contributions of each author be provided during online submission and every author must assume public accountability for the content of the submitted work under review. If there are individuals whose contributions do not meet the criteria for authorship, make sure to include appropriate acknowledgments within the manuscript.",
    },
    {
      text: "Acknowledgments",
      description:
        "Acknowledgments should include the funding agency and grant number which provided other resources. Individuals who have contributed to make the research possible, but not sufficiently quantified to be authors should also be included in this section.",
    },
    {
      text: "Conflict of Interest",
      description:
        'A statement must be included for all contributing authors who are involved in various kinds of conflicts. Examples of potential conflicts of interest include employment, consultancies, stock ownership, honoraria, paid expert testimony, patent applications/registrations, and grants or other resources which may potentially influence the decision derived from this research. If no conflict of interest is declared, the following statement should be declared in the manuscript: "The authors declare that they have no conflict of interest".',
    },
    {
      text: "Citations in Text",
      description: "Examples:",
      olType: "1",
      points: [
        {
          text: "Finally, forests play a leading role in the global cycling of energy, carbon, water and nutrients [4-7].",
        },
        {
          text: "For the past millennia, the livelihood of humans has largely depended on forest resources, but these resources are not inexhaustible [2,3].",
        },
      ],
    },
    {
      text: "References",
      description:
        "Please ensure that every reference cited in the text is also present in the reference list. Authors should ensure the accuracy of references, and are encouraged to use EndNote or other reference management tools when writing the manuscript. Only one citation should appear within each reference number. If more than one reference is cited in one place, please separate the numbers by commas with a space.***List numbered references in the Literature Cited with numerals and periods, without parentheses.***Include the following information (in the following order):",
      listType: "ul",
      points: [
        {
          text: "Name(s) of author(s), last name first, followed by initials without periods. Include both (or all)",
        },
        {
          text: 'Initials for each author whenever they were included in the original article or book. Do not leave spaces between initials. Do not use a comma between surnames and initials—use commas only to separate different authors\' names. If a given reference has six or more authors, list the first five, then type "et al." in the bibliography. If a reference has five or fewer authors, list them all.',
        },
        {
          text: "Year of publication of the article or book, followed by a period, with no parentheses. If the article has recently been accepted for publication and is in press, list it in the Literature Cited section. Provide the journal title and expected year of publication, plus volume and pages where known.",
        },
        {
          text: "Title of article or chapter (see above for the policy of individual Annual Reviews journals regarding whether to include titles of articles or chapters).",
        },
        {
          text: "Title of journal (full spelling) or book (not abbreviated unless part of a periodical series).",
        },
        {
          text: "For a book reference, name(s) of editor(s).",
        },
        {
          text: 'Volume number, then a colon and inclusive page numbers; if there is no volume number, inclusive page numbers preceded by a comma and "pp." Do not repeat hundred digits unless required, e.g., 3-10, 71-77, 100-9, 331-35, 1002-3, 1198-202, 1536-38. The issue number can be included in parentheses immediately following the volume if necessary, e.g., 10(4):123-30.',
        },
        {
          text: "For a book reference, place of publication, name of publisher, and edition, should be included if necessary.",
        },
      ],
      egText: "Examples:",
      examples: [
        {
          text: "Journals",
          description: "Author. Year. Title. Journal Volume|:Pages",
          points: [
            {
              text: "West GB, Brown JH, Enquist BJ. 2001. A general model for ontogenetic growth. Nature 413:628-63",
            },
            {
              text: "Zeng Y, Ye S, Yu W, Wu S, Hou W, et al. 2014. Genetic linkage map construction and QTL identification of juvenile growth traits in Torreya grandis. BMC Genetics 15:S2",
            },
          ],
        },
        {
          text: "Books:",
          description:
            "Author. Year. Title|. pp Pages|. Place Published|: Publisher|. Number of Pages pp.",
          points: [
            {
              text: "Boddy L, Hiscox J, Gilmartin EC et al. 2017. Wood decay communities in angiosperm wood. In The Fungal Community: Its Organization and Role in the Ecosystem, Fourth Edition, eds. Dighton J, White JF, 32:619. Boca Raton: CRC Press. pp. 169-89",
            },
          ],
        },
        {
          text: "Book Section:",
          description:
            "Author. Year. Title. In Book Title|, ed. Editor|, Volume|:Pages|. Place Published|: Publisher|. Number of Pages pp|.",
          points: [
            {
              text: "Myles S, Liu D, 2004. The apple genome sheds light on the evolution of Rosaceae, ed. A Smith, Vol. 15, Atlanta: Maximum Academic Press. pp. 66-78.",
            },
          ],
        },
        {
          text: "Patents:",
          description: "Inventor. Year. Country| Patent No. Patent Number",
          points: [
            {
              text: "Denecker J, Hoeberichts F, Muhlenbock P, Van Breusegem F, Van Der Kelen K. 2013. U.S. Means and methods for the reduction of photorespiration in crops. WO Patent No.2014147249A1.",
            },
          ],
        },
        {
          text: "Theses and Dissertations:",
          description:
            "Author. Year. Title|. Thesis Type|. University|, Place Published|. Number of Pages pp.",
          points: [
            {
              text: "Daniell D, 2005. Alternative oxidase is involved in the pathogenicity, development, and oxygen stress response of Botrytis cinerea, Thesis, University of Washington. U.S. pp.55-78.",
            },
          ],
        },
        {
          text: "Conference Proceedings:",
          description:
            "Author. Year Published. Title|. Proc. Conference Name|, Edition, Conference Location, Year of Conference|, Volume|:Pages|. Place Published|: Publisher",
          points: [
            {
              text: "Mohan Jain S. 2013. Mutation-assisted breeding for improving ornamental plants. Proc. XXII International Eucarpia Symposium, Section Ornamentals, Breeding for Beauty, Thuringia, 2013, 714:85-98. Thuringia: Tuorix Press",
            },
          ],
        },
        {
          text: "Magazine Article:",
          description: "Author. Year. Title. Magazine Volume|:Pages",
          points: [
            {
              text: "Hong Y. 2008. Phospholipas Is Involved in the Hyperosmotic Response in Arabidopsis. Agronomy Magazine 20:14-16",
            },
          ],
        },
        {
          text: "Report:",
          description:
            "Author. Year. Title|. Type|. Rep. Report Number|, Institution|, Place Published",
          points: [
            {
              text: "du Jardin P. 2012. The Science of Plant Biostimulants − a bibliographic analysis. Technical Report. Ad. Hoc. Study on Bio-stimulants Products 30-CE0455515/00-96, University of Liège, Liège, Belgium. https://orbi.uliege.be/bitstream/2268/169257/1/Plant_Biostimulants_final_report_bio_2012_en.pdf",
            },
          ],
        },
        {
          text: "Web page:",
          description: "Author/Organization. Year. Title. URL",
          points: [
            {
              text: "Biostimulant Coalition. 2013. What are the biostimulants? www.biostimulantcoalition.org",
            },
          ],
        },
      ],
    },
    {
      text: "Tables and Figures",
      description:
        "Authors should submit tables and figures with clear content. Tables and figures should be numbered consecutively in Arabic numerals as Table 1, 2, 3 and Figure 1, 2, 3.",
      listType: "ul",
      points: [
        {
          text: "Each figure/table should be supplied as an individual file;",
        },
        {
          text: "Letterings on figures should be in Times New Roman (non-bold), and the font size should be 8 ~10 pt. Any letterings/data in bold should be explained in figure caption.",
        },
        {
          text: "The minimum line width for figures should be 0.4 pt;",
        },
        {
          text: "Composite figures should be labeled as Figure 1a, 1b, 1c, etc. with a, b, and c clearly labeled in each panel.",
        },
        {
          text: "Any color images should be saved in RGB color mode at 300 dpi or higher;",
        },
        {
          text: "Any mono line art should be saved in gray mode at 600 dpi;",
        },
        {
          text: "TIFF, EPS, JPG and PDF files are the preferred format for figures. It is recommended that you generate your figures in JPEG format before converting them to PDFs or uploading individual files. This will reduce the file sizes and enable you to obtain figures close to the requirements;",
        },
        {
          text: "Tables should be in editable format. Please avoid using vertical rules and shading in table cells;",
        },
        {
          text: "Table and figure legends should be placed immediately above or below each, table/figure respectively. All designations in the tables or figures should use the lower case alphabet, in the order of a, b, c.",
        },
      ],
    },
    {
      text: "Supplementary Files",
      description: "",
      listType: "ul",
      points: [
        {
          text: "Supplementary figures/tables should be cited/numbered consecutively in the main text;",
        },
        {
          text: "Each supplementary figure/table should be supplied as an individual file;",
        },
        {
          text: "The content in supplementary figures/tables should be clear enough to read;",
        },
        {
          text: "TIFF, EPS, JPG and PDF are the preferred format for supplementary figures;",
        },
        {
          text: "PDF is the preferred format for supplementary tables, but it is acceptable if large tables are supplied as Microsoft Excel files.",
        },
      ],
    },
    {
      text: "Sharing of Research Data, Software and Protocols",
      description:
        "The journal encourages authors to share data that supports their research publication wherever it is appropriate, by interlinking the data with their published articles. Research data refer to the results of observations or experimentation that validate research findings.",
      listType: "ul",
      examples: [
        {
          text: "Data availability",
          description:
            'A statement on data availability is required for all original articles which informs readers about the accessibility of research data linked to a paper and outlines the terms under which the data can be obtained. The statement should contain details about the location of the data that underpins the outcomes presented in the article. This may involve links to publicly archived datasets analyzed or produced during the research, where relevant. The term "data" refers to the fundamental dataset required to comprehend, reproduce, and expand upon the conclusions put forth in the article. We acknowledge that there are circumstances were making research data openly accessible might not be feasible, especially when it could jeopardize individual privacy. In such cases, the manuscript should still include a data availability statement, along with any prerequisites for accessing the data.',
        },
        {
          text: "Software and Protocols",
          description:
            "To facilitate reproducibility and data reuse, the journal also encourages authors to share their software, code, models, algorithms, protocols, methods and other useful materials related to the project. Genome and single cell sequence data, transcriptome, metabolome and other big data sets must be deposited to a reliable and permanent repository before the paper will be published. Unavailability may result in a retraction of the published paper.",
        },
      ],
    },
    {
      text: " Language Editing",
      description:
        "Authors need to ensure the English language is of sufficient quality to be understood so that editors and reviewers can accurately assess the work presented in the manuscript. Language Editing Services can assist authors to improve and polish the language of the manuscript, but authors need to cover the cost of this themselves.***If you are an author whose native language is not English—or you have any concerns regarding the language quality of your manuscript—we recommend having your manuscript professionally edited by a qualified English-speaking researcher in your field prior to submission.",
      listType: "ul",

      egText: "Production",
      examples: [
        {
          points: [
            {
              text: "Proof Correction",
              description:
                "The corresponding author will receive an e-mail containing a PDF proofing along with a Correction format in MS-Word file in which then can manually mention the mistakes and suggest correction edits. To ensure a fast publication process of manuscripts, proof reading and corrections must be returned within 3 days.",
            },
            {
              text: "Article Processing Charges (APCs)",
              description:
                'IFR Discovery levies an Article Processing Charge (APC) per article. Only accepted papers will be charged. Authors will receive a payment email alongside the "License to Publish" form.',
            },
            {
              text: " License to Publish (LTP)",
              description:
                "Publishing Open Access will mean the paper is freely accessible online immediately upon publication. By signing the License to Publish, authors are permitted to post the final, published PDF of their article on a website, institutional repository, or other free public server, immediately on publication. Open access articles are published under a CC BY-NCND License (Creative Commons Attribution-NonCommercial-NoDerivatives 4.0 International License). This license allows readers to copy, distribute, and transmit the contribution as long as it is attributed back to the author, and they do not alter, transform, or build upon the work, nor use it for commercial purposes. Please read the full license for further details at https://creativecommons.org/licenses/by-nc-nd/4.0/",
            },
            {
              text: "Permission for Photographic/Illustration Use",
              description:
                "When re-using figures from a third-party (i.e., another journal) or those which are copyrighted, the authors must submit the documents of permission provided by the original publisher or the copyright holder. The copyright holder, who could be an individual, corporation, or, most frequently, a publisher, must complete the Permission for Photographic/Illustration Use",
            },
          ],
        },
      ],
    },
  ],
};

export const reviewerGuidelinesPoints = [
  {
    text: "Manuscripts undergo a quality check by the editorial office to ensure completeness and prevent plagiarism using iThenticate. Manuscripts with a similarity index over 15% (excluding references) will be unaccepted.",
  },
  {
    text: "The Editor-in-Chief assigns each manuscript to an Associate Editor based on expertise and workload considerations.",
  },
  {
    text: "The handling editor selects at least two reviewers based on expertise, publication history, and past reviews, and invites them to provide feedback on the manuscript.",
  },
  {
    text: "Reviewers have a 2-week deadline to submit their feedback. After receiving review reports, the Associate Editor makes a recommendation to the Editor-in-Chief, who then makes a decision. Recommendations can be: (a) Acceptance, (b) Acceptance with minor revisions, (c) Major revisions required, (d) Rejection with encouragement to revise and resubmit, or (e) Rejection without the possibility of resubmission.",
  },
  {
    text: "The Editor-in-Chief makes the final decision, and the editorial office sends the decision letter to the corresponding authors and all co-authors.",
  },
  {
    text: "Final Decision",
    nestedPoints: [
      {
        text: "Major Revision: The revised manuscript and rebuttal letter are re-reviewed by the original reviewers. Multiple rounds of review may occur if necessary.",
      },
      {
        text: "Minor Revision: Assessed by the Associate Editor without additional review.",
      },
      {
        text: "Rejection with Encouragement to Revise and Resubmit: May or may not be reviewed by the same reviewers or Associate Editor.",
      },
      {
        text: "Acceptance: Manuscript progresses to copy editing, figure editing, typesetting, and proof delivery to corresponding author(s).",
      },
    ],
  },
];

export const editorialGuidelinesPoints = [
  {
    title: "WHAT TO EXPECT FROM OUR COLLABORATIVE PEER REVIEW",
    description:
      "Our peer review process is designed in such a process where our reviewers and the editor interact within the publisher's provided email with the authors, enabling quick iterations and facilitating consensus. Editors and reviewers work with the authors to improve their manuscript which includes two main phases and a final decision by the Publication Manager:",
    listStyle: "none",
    points: [
      {
        title: "Independent Review Phase:",
        description:
          "During this phase, each manuscript is reviewed by experts in the field who remain anonymous to each other and to the authors. Reviewers assess various aspects of the manuscript, including its originality, methodology, significance, and overall quality. They provide detailed feedback and recommendations, which may include suggestions for revisions or notes on potential issues. This phase ensures that each manuscript is evaluated rigorously and objectively, free from any potential biases introduced by knowledge of the authors' identities or other reviewers' opinions.",
      },
      {
        title: "Interactive Review Phase:",
        description:
          "Following the initial independent reviews, the manuscript enters the Interactive Review Phase. In this phase, the authors receive feedback from the reviewers and are given the opportunity to revise their manuscript based on this feedback. Reviewers may request additional clarifications or data, and authors are expected to address these comments and resubmit the revised manuscript. This iterative process facilitates a collaborative approach to improving the research and ensures that the manuscript adheres to the journal's standards before final acceptance.",
      },
      {
        title: "Final Decision Process:",
        description:
          "After the Independent and Interactive Review Phases, the Publication Manager reviews all feedback from editors, reviewers and assesses how well the authors have addressed their comments. Based on this evaluation, the Publication Manager makes the final decision, which may be acceptance, further revisions, or rejection of the manuscript. This decision, along with a summary of reviewer feedback and any remaining issues, is communicated to the authors to ensure clarity and transparency in the publication process.",
      },
    ],
  },
  {
    title: "INDEPENDENT REVIEW PHASE",
    description:
      "In the Independent Review Phase, each reviewer conducts a thorough evaluation of the manuscript based on several key criteria:",
    listStyle: "none",
    points: [
      {
        title: "Originality:",
        description:
          "Reviewers assess whether the research presents novel ideas, findings, or approaches that advance the field. They evaluate the manuscript's contribution to existing knowledge and whether it offers new insights or perspectives that are not covered in previous research.",
      },
      {
        title: "Methodology:",
        description:
          "Reviewers examine the research design and methods used in the study. They consider whether the research approach is appropriate for the research questions posed and whether the methods are applied rigorously and accurately. This includes assessing the validity of the experimental design, data collection procedures, and analysis techniques.",
      },
      {
        title: "Significance:",
        description:
          "Reviewers evaluate the importance and impact of the research findings. They consider whether the results have significant implications for the field, contribute to theoretical understanding, or offer practical applications. The significance of the research should be clear and well-supported by the data presented.",
      },
      {
        title: "Quality:",
        description:
          "Reviewers assess the overall quality of the manuscript, including the clarity of writing, logical organization, and coherence of arguments. They ensure that the manuscript is well-structured, with a clear presentation of results and conclusions that are logically derived from the data.",
      },
    ],
    conclusion:
      "Reviewers submit a detailed report with their findings and recommendations. This report helps the editorial team make informed decisions about whether to accept, reject, or request revisions to the manuscript.",
  },
  {
    title: "INTERACTIVE REVIEW PHASE",
    description:
      "The Interactive Review Phase involves direct engagement between reviewers and authors to address any issues identified in the initial review. This phase includes several key components:",
    listStyle: "none",
    points: [
      {
        title: "Clarifications:",
        description:
          "Reviewers may request additional information or seek clarifications on specific aspects of the manuscript. Authors are expected to respond to these requests by providing further explanations, data, or revisions as needed. This dialogue helps resolve any ambiguities or concerns that arose during the initial review.",
      },
      {
        title: "Revisions:",
        description:
          "Authors revise their manuscript based on the feedback received from reviewers. Revisions may involve making changes to the research design, data presentation, or manuscript text. Authors should address all comments and suggestions provided by the reviewers to improve the manuscript's quality.",
      },
      {
        title: "Further Review:",
        description:
          "After revisions are made, the manuscript may be sent back to the reviewers for further evaluation. Reviewers assess whether the revisions adequately address their comments and whether the manuscript has improved as a result. This ensures that all issues are resolved and that the manuscript meets the journal's standards before final acceptance.",
      },
    ],
    conclusion:
      "The goal of the Interactive Review Phase is to refine the manuscript through constructive feedback and collaboration, ensuring that it is of high quality and ready for publication.",
  },
  {
    title: "HOW TO PEER REVIEW",
    description: "Before Accepting the Invitation:",

    points: [
      {
        title: "Is the manuscript within my expertise?",
        description:
          "Review the topic and content of the manuscript to determine if it aligns with your area of expertise. It is essential that you have the necessary background knowledge and experience to provide a thorough and informed review. If the manuscript falls outside your area of expertise, it may be better to decline the invitation.",
      },
      {
        title: "Do I have the time?",
        description:
          "Assess your current workload and availability to ensure you can dedicate the required time to conduct a detailed and thoughtful review. Peer reviewing involves reading the manuscript thoroughly, evaluating its content, and providing constructive feedback, all of which require a significant time investment.",
      },
      {
        title: "Do I have a conflict of interest?",
        description:
          "Identify any personal, professional, or financial conflicts of interest that could affect your impartiality. A conflict of interest may include personal relationships with the authors, financial ties to competing research, or any other factors that might bias your review. Disclose any potential conflicts to the editorial team to maintain the integrity of the review process.",
      },
      {
        title: "Respond to the invitation",
        description:
          "Promptly confirm your availability and willingness to review the manuscript or decline if you are unable to participate. Timely responses help the editorial team manage the review process efficiently and find alternative reviewers if necessary.",
      },
    ],
    subTitle: "During Peer Review:",
    subDescription:
      "Before a manuscript is sent for peer review, our research integrity team and handling editor conduct initial quality checks to ensure the manuscript meets basic standards. As a reviewer, your role includes:",
    subPoints: [
      {
        title: "Focus on the quality of the science objectively:",
        description:
          "Evaluate the manuscript based on its scientific merit. Your review should be focused solely on the content and quality of the research, without influence from personal biases or external factors. Ensure your assessment is objective and based on the validity and rigor of the research.",
      },
      {
        title: "Collaborate towards improvement and think constructively:",
        description:
          "Engage in a collaborative approach to enhance the manuscript. Provide feedback that helps the authors improve their work, and think constructively about how to address any issues or weaknesses identified. Your goal is to support the authors in refining their research and improving the manuscript's quality.",
      },
      {
        title:
          "Help the author and editor understand what is needed with clear comments:",
        description:
          "Provide detailed, actionable feedback that clearly communicates what changes or improvements are needed. Your comments should guide the authors in making revisions and assist the editor in making an informed decision about the manuscript. Ensure that your feedback is specific and helps clarify any issues or concerns.",
      },
    ],
  },
  {
    title: "WHAT TO DO",
    listStyle: "none",
    points: [
      {
        title: "Respect the scope:",
        description:
          "Adhere to the scope and objectives of the manuscript. Focus your review on the specific content and aims of the research, and avoid introducing comments that are not relevant to the manuscript’s topic. Ensure that your feedback is aligned with the manuscript's goals and research questions.",
      },
      {
        title: "Focus on science:",
        description:
          "Concentrate on the scientific aspects of the manuscript, including the research design, methodology, data analysis, and conclusions. Assess whether the research is conducted rigorously and if the results are presented clearly and accurately. Your review should be grounded in established scientific principles and standards.",
      },
      {
        title: "Provide constructive feedback:",
        description:
          "Offer detailed, actionable feedback that helps the authors improve their manuscript. Highlight both the strengths and weaknesses of the research, and provide specific suggestions for revisions. Constructive feedback should guide the authors in making meaningful improvements and enhancing the overall quality of the manuscript.",
      },
      {
        title: "Consider field specifics:",
        description:
          "Take into account the norms, standards, and conventions of the research field when evaluating the manuscript. Ensure that your feedback reflects current practices and expectations within the discipline. Consider how the manuscript contributes to the field and whether it adheres to relevant research standards.",
      },
    ],
  },
  {
    title: "WHAT NOT TO DO",
    listStyle: "none",
    points: [
      {
        title: "Don't be vague or too brief:",
        description:
          "Avoid providing general or cursory comments. Detailed and specific feedback is essential for guiding the authors in making meaningful revisions. Vague comments may not provide enough information for authors to understand what changes are needed, leading to further delays in the review process.",
      },

      {
        title: "Don't leave out key points in your initial report:",
        description:
          "Ensure that your initial review covers all significant aspects of the manuscript. Omitting important points can lead to additional rounds of feedback and delay the review process. Address all relevant issues and provide a comprehensive evaluation of the manuscript.",
      },

      {
        title: "Don't drop out of the peer review: ",
        description:
          "Once you commit to reviewing a manuscript, complete the process as agreed. If unforeseen circumstances arise, inform the editorial team as soon as possible to facilitate finding a replacement reviewer. Dropping out of the review process can cause delays and impact the timely handling of manuscripts.",
      },
    ],
  },
];

export const APCPoints = [
  {
    title: "Explanation of APC",
    description:
      "Article Processing Charges (APCs) are fees charged to authors to cover the costs associated with the publication process. The APCs are structured to cover the work force of the publisher including the operational costs associated with the publication process, peer review, editorial handling, copyediting, typesetting, formatting, indexing, and hosting the article on our platform.",
  },
  {
    title: "Transparency",
    description:
      "At IFR Discovery, we are committed to transparency in our APC policy. We strive to provide authors with a clear understanding of what services their APC covers and how it contributes to maintaining the quality and integrity of our journal.",
  },
  {
    title: "Breakdown of Charges",
    description:
      "The base fee mentioned on top of the page is for a standard 20-page article size. However, additional charges may apply if the article exceeds 25 pages. Our APC is calculated based on the services provided during the publication depending on factors such as article length, complexity, and the extent of editorial and production services required. Please contact our editorial office for a personalized quote for your manuscript.",
  },
  {
    title: "Discounts or Waivers",
    description:
      "We offer discounts or waivers on APCs for authors from highly poor countries that are below index level, students, or members of specific organizations with respect to a valid reason and proof. Authors may apply for a waiver by contacting our official mail and providing appropriate documentation.",
  },
  {
    title: "Payment Process",
    description:
      "APC payment is typically requested upon acceptance of the manuscript for publication. Accepted methods of payment include UPI Transfer, Bank Transfer, PayPal and Western Union. Detailed payment instructions will be provided upon acceptance of your manuscript.",
  },
  {
    title: "Contact Information",
    description: `For inquiries or assistance regarding APC payment, please contact our editorial office at ${process.env.REACT_APP_IFRD_EMAIL}. Our team will be happy to assist you with any questions you may have regarding APCs or the publication process.`,
  },
  {
    title: "Financial Disclosure",
    description: `IFR Discovery maintains transparency in its financial operations. APCs are used solely for the purpose of maintaining the quality and sustainability of our journal and the publisher.`,
  },
];

export const ethicsAndPoliciesPoints = [
  {
    heading: "ALLEGATIONS OF MISCONDUCT",
    points: [
      {
        title: "Our Approach",
        description:
          "When allegations of misconduct such as data fabrication, falsification, or plagiarism arise, we approach them with seriousness and thoroughness. Ensuring the integrity of our publications is paramount.",
      },
      {
        title: "Guidelines",
        subPoints: [
          {
            title: "Reporting Misconduct",
            description:
              "We encourage anyone who suspects misconduct to report it in writing to the IFRD Ethics Committee or the editorial office of the relevant journal. We offer a confidential reporting mechanism to protect the identity of the complainant.",
          },
          {
            title: "Initial Assessment",
            description:
              "Upon receiving a report, we conduct an initial review to determine if the allegation has sufficient grounds for further investigation. This preliminary assessment is performed by an impartial officer or committee.",
          },
          {
            title: "Formal Investigation",
            description:
              "If the initial review indicates a potential breach of ethical standards, a formal investigation will be initiated. An independent committee or external experts will be appointed to gather evidence and evaluate the situation.",
          },
          {
            title: "Resolution and Actions",
            description:
              "Based on the investigation's findings, possible actions include issuing a correction, retracting the publication, or taking other appropriate measures. We ensure that both the complainant and the respondent are informed of the outcomes and any corrective actions.",
          },
          {
            title: "Confidentiality and Retaliation Protection",
            description:
              "We maintain strict confidentiality throughout the investigation process to safeguard all involved parties. Additionally, we protect those who report misconduct in good faith from any form of retaliation.",
          },
        ],
      },
    ],
  },
  {
    heading: "AUTHORSHIP AND CONTRIBUTORSHIP",
    points: [
      {
        title: "Our Philosophy",
        description:
          "We are committed to ensuring fair and accurate attribution of authorship and contributorship. Recognizing the contributions of all involved in the research process is essential for maintaining academic integrity.",
      },
      {
        title: "Guidelines",
        subPoints: [
          {
            title: "Criteria for Authorship",
            description:
              "Authorship should be based on substantial contributions to the study's conception, design, data acquisition, analysis, or manuscript writing. All individuals who meet these criteria should be included as authors.",
          },
          {
            title: "Order of Authors",
            description:
              "The order of authors should reflect their level of contribution. This order should be agreed upon by all authors before submission. Any changes in authorship order must be documented and agreed upon by all parties.",
          },
          {
            title: "Acknowledgments",
            description:
              "Individuals who made significant contributions but do not qualify for authorship should be acknowledged in the manuscript. This includes those providing technical support or other essential input.",
          },
          {
            title: "Authorship Dispute Resolution",
            description:
              "Disputes regarding authorship should be resolved before submission. If disputes arise after publication, they will be addressed through our established procedures, which may involve mediation by the editorial board or ethics committee.",
          },
        ],
      },
    ],
  },
  {
    heading: "COMPLAINTS AND APPEALS",
    points: [
      {
        title: "Our Approach",
        description:
          "We are committed to ensuring fair and accurate attribution of authorship and contributorship. Recognizing the contributions of all involved in the research process is essential for maintaining academic integrity.",
      },
      {
        title: "Guidelines",
        subPoints: [
          {
            title: "Submitting Complaints",
            description:
              "Complaints about editorial decisions or ethical issues should be submitted in writing to the editorial office of the relevant journal. Your complaint should include a detailed description of the issue and any supporting evidence.",
          },
          {
            title: "Review and Response",
            description:
              "We review all complaints impartially and promptly. An initial review determines if further action is required. Our editorial board or a designated committee will provide a response and outline any actions taken.",
          },
          {
            title: "Appeals Procedure",
            description:
              "Authors may appeal editorial decisions by submitting a formal appeal letter that includes a clear rationale for reconsideration. Appeals will be reviewed by senior editorial members or an independent committee.",
          },
          {
            title: "Resolution and Follow-up",
            description:
              "We will provide a detailed explanation of the resolution following a complaint or appeal. All parties involved will be informed of the outcome and any corrective actions taken.",
          },
        ],
      },
    ],
  },
  {
    heading: "CONFLICTS OF INTEREST / COMPETING INTERESTS",
    points: [
      {
        title: "Our Commitment",
        description:
          "We are dedicated to managing conflicts of interest to ensure that the research and editorial processes remain objective and unbiased. Transparency in these matters is crucial for maintaining research integrity.",
      },
      {
        title: "Guidelines",
        subPoints: [
          {
            title: "Disclosure of Conflicts",
            description:
              "Authors, reviewers, and editors are required to disclose any potential conflicts of interest that could influence their work. This includes financial, personal, or professional interests that may affect objectivity.",
          },
          {
            title: "Management of Conflicts",
            description:
              "We manage conflicts by excluding individuals with conflicts of interest from specific decision-making roles or by seeking independent reviews where necessary. Our goal is to ensure unbiased and fair evaluations.",
          },
          {
            title: "Transparency in Reporting",
            description:
              "All disclosed conflicts of interest will be published alongside the article to provide transparency. This allows readers to assess any potential influences on the research findings and conclusions.",
          },
        ],
      },
    ],
  },
  {
    heading: "DATA AND REPRODUCIBILITY",
    points: [
      {
        title: "Our Philosophy",
        description:
          "We prioritize transparency and accessibility of research data to support reproducibility and the advancement of scientific knowledge. Ensuring that research can be replicated is a fundamental aspect of scientific integrity.",
      },
      {
        title: "Guidelines",
        subPoints: [
          {
            title: "Data Sharing",
            description:
              "We encourage authors to share their data and research materials to support reproducibility. Data should be made available in public repositories or provided upon request, in line with our data sharing policies.",
          },
          {
            title: "Reproducibility Requirements",
            description:
              "Research should be reported with sufficient detail to allow replication by other researchers. Authors should provide comprehensive methodologies, protocols, and data to facilitate reproducibility.",
          },
          {
            title: "Data Management and Integrity",
            description:
              "Researchers are responsible for securely managing and accurately reporting their data. Data should be stored in accordance with relevant regulations and institutional guidelines to ensure its integrity.",
          },
        ],
      },
    ],
  },
  {
    heading: "ETHICAL OVERSIGHT",
    points: [
      {
        title: "Our Standards",
        description:
          "We are committed to rigorous ethical oversight to ensure that research involving human or animal subjects adheres to ethical standards and legal requirements, thereby protecting participants and maintaining research integrity.",
      },
      {
        title: "Guidelines",
        subPoints: [
          {
            title: "Ethics Approval",
            description:
              "Research involving human or animal subjects must obtain approval from an appropriate ethics review board or institutional review board prior to commencement. Documentation of this approval should be submitted with the manuscript.",
          },
          {
            title: "Compliance with Ethical Standards",
            description:
              "Researchers must comply with ethical guidelines, laws, and regulations throughout their study. Research that fails to meet these standards may be subject to retraction or other corrective measures.",
          },
          {
            title: "Monitoring and Re-evaluation",
            description:
              "Research may be subject to periodic reviews to ensure ongoing compliance with ethical standards. We monitor adherence to ethical practices and address any issues that arise during the research process.",
          },
        ],
      },
    ],
  },
  {
    heading: "INTELLECTUAL PROPERTY",
    points: [
      {
        title: "Our Philosophy",
        description:
          "Respecting and managing intellectual property is essential for ethical research. We ensure that intellectual property rights are properly handled and that all contributions are appropriately acknowledged.",
      },
      {
        title: "Guidelines",
        subPoints: [
          {
            title: "Ownership and Rights",
            description:
              "Intellectual property rights, including patents and copyrights, should be clearly defined and respected. Authors should ensure proper attribution for all contributions and obtain necessary permissions for third-party materials.",
          },
          {
            title: "Permissions and Agreements",
            description:
              "Authors must secure permissions for the use of third-party materials and disclose any intellectual property agreements or arrangements during submission. This includes permissions for previously published works or proprietary data.",
          },
          {
            title: "Disclosure of Intellectual Property Conflicts",
            description:
              "Any potential conflicts related to intellectual property must be disclosed to the journal. Authors should adhere to relevant intellectual property laws and institutional policies.",
          },
        ],
      },
    ],
  },
  {
    heading: "JOURNAL MANAGEMENT",
    points: [
      {
        title: "Our Approach",
        description:
          "Effective journal management is key to maintaining the integrity and quality of our publication process. We are committed to ensuring that all aspects of journal management are handled with transparency and adherence to ethical standards.",
      },
      {
        title: "Guidelines",
        subPoints: [
          {
            title: "Editorial Independence",
            description:
              "Our editorial decisions are made independently of commercial interests or external pressures. We base decisions solely on the merit of the research and adherence to ethical standards.",
          },
          {
            title: "Confidentiality",
            description:
              "We uphold confidentiality for all parties involved in the editorial process, including authors, reviewers, and editors. This protects the integrity of the review process and prevents bias.",
          },
          {
            title: "Integrity in Operations",
            description:
              "We are committed to operating with integrity, including fair and unbiased handling of submissions, adherence to ethical guidelines, and transparency in decision-making processes.",
          },
        ],
      },
    ],
  },
  {
    heading: "PEER REVIEW PROCESSES",
    points: [
      {
        title: "Our Approach",
        description:
          "Peer review is essential for validating the quality and credibility of research. We strive to ensure that our peer review process is thorough, objective, and transparent.",
      },
      {
        title: "Guidelines",
        subPoints: [
          {
            title: "Reviewer Selection",
            description:
              "Reviewers are selected based on their expertise in the relevant field. We require disclosure of any potential conflicts of interest to ensure unbiased and objective reviews.",
          },
          {
            title: "Review Guidelines and Conduct",
            description:
              "Reviewers are expected to provide constructive feedback based on scientific merit, originality, and relevance. Reviews should be conducted in a timely manner to facilitate the publication process.",
          },
          {
            title: "Confidentiality",
            description:
              "Reviewers must maintain the confidentiality of manuscripts and not use them for personal gain or disclose them to others. Reviews should be based solely on the manuscript's content.",
          },
          {
            title: "Handling Revisions",
            description:
              "Authors should address reviewer comments and revise their manuscripts accordingly. Revised manuscripts may undergo additional rounds of review to ensure that all concerns are addressed.",
          },
        ],
      },
    ],
  },
  {
    heading: "POST-PUBLICATION DISCUSSIONS AND CORRECTIONS",
    points: [
      {
        title: "Our Commitment",
        description:
          "We are dedicated to maintaining the accuracy of the scientific record through post-publication corrections and engaging in scholarly discussions. This ensures that our published research remains reliable and up-to-date.",
      },
      {
        title: "Guidelines",
        subPoints: [
          {
            title: "Issuing Corrections and Retractions",
            description:
              "We will promptly correct any errors or inaccuracies identified post-publication. If a study’s validity is significantly compromised, a retraction may be issued. Corrections and retractions will be clearly documented and communicated to readers.",
          },
          {
            title: "Responses to Criticisms",
            description:
              "Authors are encouraged to respond to post-publication criticisms or discussions in a respectful and constructive manner. Responses should address the issues raised and contribute to scholarly dialogue.",
          },
          {
            title: "Updating Published Content",
            description:
              "The journal may issue updates or clarifications to published articles to ensure the scientific record is accurate. Updates will be published alongside the original article to provide context and maintain transparency.",
          },
        ],
      },
    ],
  },
];

export const TCPoints = [
  {
    title: "Introduction",
    descriptions: [
      {
        text: "Welcome to IFRD INTERNATIONAL LLP. As the parent company overseeing the INTERNATIONAL FEDERATION OF RESEARCH AND DISCOVERY (IFR Discovery), we are responsible for managing a diverse array of academic and professional journals. By accessing and utilizing our services, including those offered by IFR Discovery, you hereby agree to comply with and be bound by these Terms and Conditions. These terms are intended to establish a clear understanding of the mutual responsibilities and rights of all parties involved. We strongly encourage you to read them carefully.",
      },
    ],
  },
  {
    title: "Definitions",
    descriptions: [
      {
        text: "In these Terms and Conditions, the following terms shall have the following meanings:",
      },
    ],
    points: [
      {
        text: '"IFRD International LLP" refers to the parent company managing the operations of the International Federation of Research and Discovery.',
      },
      {
        text: '"International Federation of Research and Discovery" (IFR Discovery) refers to the organization under IFRD International LLP responsible for overseeing various academic and professional journals.',
      },
      {
        text: '"User" refers to any individual or entity that accesses our services and publications.',
      },
      {
        text: '"Journals" refers to the various academic and professional publications managed by IFR Discovery.',
      },
    ],
  },
  {
    title: "Use of Services",
    descriptions: [
      {
        text: "Our services are intended for users who are at least 18 years old. By accessing and using our services, you agree to do so solely for lawful purposes and in a manner that does not infringe upon the rights of, or restrict or inhibit the use and enjoyment of the services by, any third party. This includes, but is not limited to, conduct that is unlawful, or that may harass or cause distress or inconvenience to any person.",
      },
    ],
  },
  {
    title: "Intellectual Property Rights",
    descriptions: [
      {
        text: "The content published in our journals, including but not limited to articles, reviews, and graphics, is the intellectual property of IFRD International LLP and/or its respective authors. All journals under the International Federation of Research and Discovery are fully open access and licensed under the Creative Commons Attribution-NonCommercial-NoDerivs 4.0 International (CC BY-NC-ND 4.0) license. Users are permitted to share, copy, and redistribute the material in any medium or format, provided that proper attribution is given, the material is not used for commercial purposes, and it is not modified or used to create derivative works.",
      },
      {
        text: "In certain cases, we may grant explicit written permission for reproduction, distribution, or use of our content beyond the scope of this license. Requests for such permissions can be submitted using the form available on each journal's webpage of our IFR Discovery Publisher and will be reviewed on a case-by-case basis.",
      },
    ],
  },
  {
    title: "Submission of Manuscripts",
    descriptions: [
      {
        text: "Authors who wish to submit manuscripts to our journals must ensure that their work is original and not under consideration elsewhere. By submitting a manuscript, authors agree to comply with our submission guidelines and ethical standards. We reserve the right to reject any submissions that do not adhere to these standards or that we deem inappropriate for any reason.",
      },
    ],
  },
  {
    title: "Review Process",
    descriptions: [
      {
        text: "All submitted manuscripts undergo a rigorous peer-review process to assess the quality, originality, and relevance of the research. The peer-review process is conducted confidentially by experts in the relevant field. We reserve the right to accept or reject any manuscript based on the feedback from reviewers and our editorial standards. Detailed Guidelines on the Review Process is available on our every Journal's site.",
      },
    ],
  },
  {
    title: "Privacy Policy",
    descriptions: [
      {
        text: "We are committed to protecting your privacy and personal information. Any personal data collected from users is processed in accordance with our Privacy Policy. By using our services, you consent to such processing and warrant that all data provided by you is accurate.",
      },
      {
        text: "We collect personal data such as your name, email address, institutional affiliation, and any other information you provide during the manuscript submission process or while accessing our services. This data is used to manage your account, process submissions, conduct peer reviews, communicate with you regarding our services, and enhance your experience.",
      },
      {
        text: "We implement appropriate technical and organizational measures to ensure the security of your personal data and protect it against unauthorized or unlawful processing, accidental loss, destruction, or damage.",
      },
      {
        text: 'We do not share your personal data with third parties without your explicit consent, except where required by law or necessary to provide our services. You have the right to access, rectify, or request the deletion of your personal data. To exercise these rights or if you have any questions regarding our privacy practices, please contact us at <a href="mailto:office@ifrdiscovery.org">office@ifrdiscovery.org</a>.',
      },
    ],
  },
  {
    title: "Limitation of Liability",
    descriptions: [
      {
        text: "To the fullest extent permitted by law, IFRD International LLP shall not be held liable for any direct, indirect, incidental, consequential, or punitive damages arising from, or in connection with, your use of our services or any content provided therein. This includes, but is not limited to, damages for loss of profits, goodwill, data, or other intangible losses, even if IFRD International LLP has been advised of the possibility of such damages.",
      },
      {
        text: 'We make no warranties, expressed or implied, regarding the accuracy, reliability, or completeness of the content published in our journals. The content is provided on an "as is" basis and is intended for informational purposes only. While every effort is made to ensure the accuracy of the content, errors and omissions can occur. Therefore, IFRD International LLP disclaims any liability for any inaccuracies or omissions in the content provided.',
      },
      {
        text: "Furthermore, IFRD International LLP shall not be responsible for any damages resulting from: ",
      },
    ],
    points: [
      {
        text: "The use or inability to use our services",
      },
      {
        text: "Unauthorized access to or alteration of your data or transmissions",
      },
      {
        text: "Statements or conduct of any third party on our services",
      },
      {
        text: "Any other matter relating to the services",
      },
    ],
    conclusion: [
      {
        text: "It is also important to note that some jurisdictions do not allow the exclusion of certain warranties or the limitation or exclusion of liability for incidental or consequential damages. Accordingly, some of the above limitations may not apply to you. In such jurisdictions, our liability is limited to the greatest extent permitted by law.",
      },
      {
        text: "In summary, while we strive to provide high-quality and accurate content, we cannot guarantee the completeness or reliability of the information. Users are encouraged to exercise their own judgment and seek independent advice where necessary.",
      },
    ],
  },
  {
    title: "Changes to Terms and Conditions",
    descriptions: [
      {
        text: "We reserve the right to amend these Terms and Conditions at any time without prior notice. Any changes will be posted on our website and will become effective immediately upon posting. Your continued use of our services constitutes acceptance of the revised terms.",
      },
    ],
  },
  {
    title: "Governing Law ",
    descriptions: [
      {
        text: "These Terms and Conditions are governed by and construed in accordance with the laws of India. Any disputes arising from these terms shall be subject to the exclusive jurisdiction of the courts of Assam, India.",
      },
    ],
  },
  {
    title: "Contact Information",
    descriptions: [
      {
        text: 'For any inquiries or concerns regarding these Terms and Conditions, please contact us at <a href="mailto:office@ifrdiscovery.org">office@ifrdiscovery.org</a>.',
      },
    ],
  },
];

export const FaqPoints = [
  {
    question: "What is IFRD INTERNATIONAL LLP?",
    ans: "IFRD International LLP is the parent company that oversees the International Federation of Research and Discovery (IFR Discovery). IFR Discovery manages a diverse range of academic and professional journals that are fully open access and licensed under the Creative Commons Attribution-NonCommercial-NoDerivs 4.0 International (CC BY-NC-ND 4.0) license.",
  },
  {
    question: "How do I access the journals published by IFRD?",
    ans: "You can access our journals online through the IFR Discovery website. All our journals are fully open access, allowing you to read and download articles without any subscription fees.",
  },
  {
    question: "What are open access journals?",
    ans: "Open access journals provide unrestricted online access to their content, meaning anyone can read, download, and share the articles without subscription fees. Our journals are licensed under CC BY-NC-ND 4.0, which allows sharing with proper attribution, but not for commercial use or modification.",
  },
  {
    question: "How do I submit a manuscript to an IFR Discovery journal?",
    ans: "To submit a manuscript, visit the specific journal's webpage on the IFR Discovery website and follow the submission guidelines provided. You will need to create an account and complete the online submission process, including uploading your manuscript and any supplementary materials.",
  },
  {
    question: "What is the peer-review process?",
    ans: "The peer-review process involves evaluating the manuscript's quality, originality, and relevance by experts in the field. Reviewers provide feedback and recommendations, which the editors consider when making a decision to accept, revise, or reject the manuscript.",
  },
  {
    question: "How long does the review process take?",
    ans: "The review process duration varies depending on the journal and the availability of reviewers. Typically, it takes between 7 to 14 Days for the initial review. Authors will be notified of the decision and any required revisions.",
  },
  {
    question: "What are the publication fees?",
    ans: "IFR Discovery journals may have publication fees to cover the costs of open access publishing, peer review, and editing. Specific fees vary by journal and are detailed on the respective journal's webpage.",
  },
  {
    question: "Can I request a waiver for publication fees?",
    ans: "Yes, IFRD offers fee waivers or discounts for authors from low-income countries or those facing financial hardship. To request a waiver, please contact the editorial office of the respective journal with your request and justification.",
  },
  {
    question: "How can I become a reviewer for IFR Discovery journals?",
    ans: "If you are interested in becoming a reviewer, you can apply by visiting the reviewer section on the IFR Discovery website. Provide your credentials, areas of expertise, and previous reviewing experience. The editorial team will review your application and contact you if you are selected.",
  },
  {
    question: "What are the responsibilities of a reviewer?",
    ans: "Reviewers are responsible for providing an unbiased, constructive, and timely evaluation of submitted manuscripts. This includes assessing the originality, quality, relevance, and scientific rigor of the work, as well as offering feedback for improvement.",
  },
  {
    question: "Will I receive recognition for my reviewing work?",
    ans: "Yes, reviewers play a crucial role in maintaining the quality of our journals. We acknowledge the contributions of reviewers through certificates, recognition on the journal's website, and the possibility of being nominated for outstanding reviewer awards.",
  },
  {
    question: "How can I stay updated on new publications?",
    ans: "You can subscribe to email alerts, LinkedIn or RSS feeds from the IFR Discovery website to receive notifications about new publications, special issues, and upcoming events. Additionally, you can follow us on social media platforms for the latest updates.",
  },
  {
    question:
      "Can I use the content from IFR Discovery journals for my research or presentations?",
    ans: "Yes, you can use the content from our journals for your research or presentations, provided you give proper attribution to the original authors and the journal. Please note that the content cannot be used for commercial purposes or modified without permission.",
  },
  {
    question: "How do I contact the editorial office for a specific journal?",
    ans: "Contact information for the editorial office of each journal is available on the respective journal's webpage on the IFR Discovery website. You can reach out to them with any specific questions or concerns.",
  },
];

export const refundPolicyPoints = [
  {
    title: "Introduction",
    description:
      "At IFRD INTERNATIONAL LLP, we strive to ensure that our users and customers have a satisfying experience with our services and publications. However, we understand that there may be circumstances where a refund is necessary. This Refund Policy outlines the conditions under which refunds are granted and the process for requesting a refund. By using our services, you agree to the terms outlined in this policy.",
  },
  {
    title: "Eligibility for Refunds",
    description:
      "Refunds are granted under specific circumstances, including but not limited to:",
    listType: "ul",
    points: [
      {
        text: "<strong>Duplicate Payments:</strong> If you have been charged more than once for the same service or publication.",
      },
      {
        text: "<strong>Non-Delivery of Service:</strong> If the service you paid for was not provided due to our fault.",
      },
      {
        text: "<strong>Technical Issues:</strong> If you encounter technical problems that prevent you from accessing our services, and we are unable to resolve the issue.",
      },
      {
        text: "<strong>Errors in Billing:</strong> If there are errors in billing, such as incorrect charges.",
      },
    ],
  },
  {
    title: "Refund Process",
    description: "To request a refund, please follow these steps:",
    listType: "ol",
    points: [
      {
        text: "<strong>Contact Us:</strong> Reach out to our customer support team at office@ifrdiscovery.org within 30 days of the original payment date. Provide your Full Name, Contact Information, and details of the payment, including the date and amount.",
      },
      {
        text: "<strong>Review and Verification:</strong> Our team will review your request and verify the details of the payment and the reason for the refund. We may request additional information or documentation to support your claim.",
      },
      {
        text: "<strong>Processing the Refund:</strong> If your refund request is approved, we will process the refund within 10 days and usually it may take up to next 10 business days to appear in your account, depending on the payment method.",
      },
    ],
  },
  {
    title: "Refund Amount",
    description:
      "The amount of the refund will be calculated by the Publisher based on the specific circumstances and transaction charges. This will be fully discussed with the authors or customers, and a decision will be made regarding the amount that can be refunded. This ensures transparency and fairness in the refund process.",
  },
  {
    title: "Payment Methods",
    description: "Refunds will be processed as follows:",
    points: [
      {
        text: "For international authors and customers, refunds will be made only through a PayPal account. No other medium will be used.",
      },
      {
        text: "For Indian authors, refunds will be processed through bank transfer to the account details provided during the refund request.",
      },
    ],
  },
  {
    title: "Non-Refundable Cases",
    description: "Refunds are generally not provided in the following cases:",
    points: [
      {
        text: "<strong>Change of Mind:</strong> If you change your mind after purchasing a service or publication.",
      },
      {
        text: "<strong>Completed Services:</strong> If the service has already been completed or the publication has been delivered and accessed.",
      },
      {
        text: "<strong>Unauthorized Transactions:</strong> If the payment was made without the account holder's authorization and the issue is not reported within a reasonable timeframe.",
      },
      {
        text: "<strong>Violation of Terms:</strong> If your use of our services violates our Terms and Conditions.",
      },
    ],
  },
  {
    title: "Partial Refunds",
    description:
      "In certain circumstances, partial refunds may be issued at our discretion. This may occur when a portion of the service has been delivered or accessed but there is a valid reason for a partial refund.",
  },
  {
    title: "Contact Information",
    description:
      "For any questions or concerns regarding our Refund Policy or to request a refund, please contact our customer support team at:",
    points: [
      {
        text: "<strong>Email:</strong> office@ifrdiscovery.org",
      },
      {
        text: "<strong>Phone:</strong> +91 6000605461",
      },
      {
        text: "<strong>Address:</strong> IFRD International LLP, Sing gaon, Udali, Lanka, Hojai, Assam, India, 782446",
      },
    ],
  },
  {
    title: "Amendments to the Refund Policy",
    description:
      "We reserve the right to modify this Refund Policy at any time. Any changes will be posted on our website, and the revised policy will become effective immediately upon posting. Your continued use of our services constitutes acceptance of the revised policy.",
  },
];

export const careersPoints = [
  {
    title: "JOIN OUR TEAM",
    description:
      "At IFRD INTERNATIONAL LLP, we are dedicated to advancing knowledge and fostering innovation through our diverse range of academic and professional journals. Our team is composed of passionate individuals who are committed to excellence, integrity, and making a positive impact in the field of research and discovery. If you are looking to contribute to a dynamic and forward-thinking organization, we invite you to explore career opportunities with us.",
  },
  {
    title: "WHY WORK WITH US?",
    points: [
      {
        textTitle: "Innovative Environment",
        text: "We are at the forefront of academic publishing, continually exploring new ways to disseminate knowledge and support the research community. By joining IFRD INTERNATIONAL LLP, you will be part of a team that values creativity, embraces innovation, and encourages continuous learning.",
      },
      {
        textTitle: "Professional Growth",
        text: "We believe in investing in our employees' professional development. We offer opportunities for career advancement, training programs, and mentorship to help you achieve your career goals. Whether you are just starting out or looking to take the next step in your career, IFRD INTERNATIONAL LLP provides a supportive environment for growth.",
      },
      {
        textTitle: "Collaborative Culture",
        text: "Our collaborative and inclusive culture fosters teamwork and respects diverse perspectives. We believe that great ideas come from diverse minds working together, and we are committed to creating a workplace where everyone feels valued and heard.",
      },
      {
        textTitle: "Global Impact",
        text: "As part of a leading international organization, your work will have a global impact. Our journals reach a worldwide audience, and our mission is to make high-quality research accessible to all. By joining us, you will contribute to advancing knowledge and making a difference in the academic community.",
      },
      {
        textTitle: "Professional Growth",
        text: "We believe in investing in our employees' professional development. We offer opportunities for career advancement, training programs, and mentorship to help you achieve your career goals. Whether you are just starting out or looking to take the next step in your career, IFRD INTERNATIONAL LLP provides a supportive environment for growth.",
      },
    ],
  },
  {
    title: "CAREER OPPORTUNITIES",
    description:
      "We offer a variety of career opportunities across different functions, including:",
    points: [
      {
        text: "<strong>Editorial:</strong> Editors, peer reviewers, and editorial assistants who ensure the quality and integrity of our publications.",
      },
      {
        text: "<strong>Production:</strong> Specialists who manage the publication process, from manuscript submission to final publication.",
      },
      {
        text: "<strong>Marketing and Communications:</strong> Professionals who promote our journals, engage with the research community, and build our brand.",
      },
      {
        text: "<strong>Customer Support:</strong> Team members who provide exceptional service to our authors, reviewers, and readers.",
      },
      {
        text: "<strong>IT and Development:</strong> Experts who develop and maintain our digital platforms and ensure seamless user experiences.",
      },
    ],
  },
  {
    title: "HOW TO APPLY",
    description:
      "If you are interested in joining IFRD INTERNATIONAL LLP, please visit our careers page on the company website to view current job openings or email us your Resume by mentioning Careers at the Subject Line of the email. To apply, submit your resume, cover letter, and any other required documents through our online application system. Be sure to highlight your relevant experience, skills, and why you are passionate about working with us.",
  },
  {
    title: "INTERNSHIPS AND GRADUATE PROGRAMS",
    description:
      "We also offer internships and graduate programs for students and recent graduates looking to gain valuable experience in the academic publishing industry. Our programs provide hands-on learning opportunities, mentorship from industry professionals, and a chance to contribute to impactful projects.",
  },
  {
    title: "CONTACT US",
    description:
      'For any inquiries about career opportunities at IFRD INTERNATIONAL LLP, please contact our HR department at:<br/>Email: <a href="mailto:hr@ifrdiscovery.org">hr@ifrdiscovery.org</a> or <a href="mailto:office@ifrdiscovery.org">office@ifrdiscovery.org</a><br/>Join us in our mission to advance knowledge and make a lasting impact in the world of research and discovery. We look forward to welcoming you to our team.',
  },
];

/**
 * Common constants
 */

export const editorTypes = {
  CHIEF_EDITOR: {
    name: "Editor-in-chief",
    tag: "CHIEF_EDITOR",
  },
  ASSOCIATE_EDITOR: {
    name: "Associate Editor",
    tag: "ASSOCIATE_EDITOR",
  },
};

export const editorOptions = [
  {
    name: editorTypes.CHIEF_EDITOR.name,
    value: editorTypes.CHIEF_EDITOR.tag,
  },
  {
    name: editorTypes.ASSOCIATE_EDITOR.name,
    value: editorTypes.ASSOCIATE_EDITOR.tag,
  },
];

export const articlePublishStatus = {
  draft: "DRAFT",
  published: "PUBLISHED",
};
