import React, { useState } from "react";

const Test = () => {
  return (
    <div
      style={{
        margin: "0 auto",
        height: "100vh",
        width: "100vw",
      }}
    >
      <div
        style={{
          padding: "23px",
        }}
      >
      </div>
    </div>
  );
};

export default Test;
