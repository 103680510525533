import { useEffect } from "react";
import { Box, Button, Grid, Typography, styled, useTheme } from "@mui/material";
import { ReactComponent as Fee } from "../../../assets/images/Fee.svg";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchJournalById } from "../../../redux/slices/webJournal";
import { APCPoints } from "../../../helpers/constant";

const StyledBox = styled(Grid)(({ theme }) => ({
  width: "100%",
  height: "100%",
  // background: theme.palette.primary.main,
  borderRadius: theme.borderRadius[10],
  padding: "25px",
  boxShadow: theme.boxShadow.shadowThree,
  marginBottom: "10px",
  textAlign: "center",
}));

const JournalAPC = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { journalId } = useParams();

  const { journalDetails, journalLoading } = useSelector(
    (state) => state.webJournalReducer
  );

  useEffect(() => {
    if (journalId) {
      dispatch(fetchJournalById({ journalCustomId: journalId }));
    }
  }, [journalId, dispatch]);

  return (
    <Box
      sx={{
        padding: "20px 40px",
        height: "100%",
        textAlign: "justify",
        // background: theme.palette.primary.litSurface,
      }}
    >
      <Grid container justifyContent={"center"} alignItems={"center"}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Typography
            variant="h5"
            gutterBottom
            sx={{
              fontWeight: 600,
              marginBottom: "20px",
            }}
          >
            Article Processing Charge (APC)
          </Typography>
          <Typography
            variant="h6"
            gutterBottom
            sx={{
              fontWeight: 600,
              marginBottom: "20px",
              "& span": {
                color: theme.palette.info.main,
              },
            }}
          >
            {journalDetails?.name}: <span>35 USD</span>
          </Typography>
          <Typography
            variant="body1"
            gutterBottom
            sx={{
              marginBottom: "20px",
            }}
          >
            Please find below the information regarding Article Processing
            Charges (APC) for publication in IFR Discovery. At IFR Discovery, we
            believe in transparency and accessibility, and as such, we strive to
            provide authors with clear and comprehensive details regarding APCs.
            We understand the importance of ensuring that authors are fully
            informed about the costs associated with publishing their research.
            Below, you will find a breakdown of our APC policy, including
            pricing, discounts, payment methods, and additional information to
            assist you in understanding the process. We are committed to
            fostering equitable access to publication opportunities while
            maintaining the quality and integrity of our journal.
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Fee style={{ maxWidth: "400px" }} />
          </Box>
        </Grid>
      </Grid>
      <ol>
        {APCPoints?.map((apc) => (
          <li
            style={{
              marginBottom: `20px`,
            }}
          >
            <Typography variant="body1" gutterBottom>
              {apc.title}:
            </Typography>
            <Typography variant="body1" gutterBottom>
              {apc.description}:
            </Typography>
          </li>
        ))}
      </ol>
      <Typography variant="body1" gutterBottom>
        At IFR Discovery, we are committed to facilitating a transparent and
        efficient APC process to ensure equitable access to publication for
        researchers worldwide. Thank you for considering IFR Discovery for the
        dissemination of your research findings.
      </Typography>
    </Box>
  );
};

export default JournalAPC;
