import React, { useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { CustomTextField } from "../../index";
import subjectAdminServices from "../../../services/subject/admin";
import { toast } from "react-toastify";

const StyledBox = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "100%",
  borderRadius: theme.borderRadius[10],
  padding: "25px",
  boxShadow: theme.boxShadow.shadowThree,
  marginBottom: "10px",
}));

function CreateSubject({ setCreateToggle, setSubjectList }) {
  const theme = useTheme();
  const [saveLoader, setSaveLoader] = useState(false);

  const journalValidationSchema = yup.object().shape({
    name: yup.string().required("name is required"),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm({
    defaultValues: {
      name: "",
    },
    resolver: yupResolver(journalValidationSchema),
  });

  const onSubmit = async (data) => {
    setSaveLoader(true);
    const resSaveJournal = await subjectAdminServices.createSubject(data);
    if (resSaveJournal) {
      toast.success("Subject save successful");
      setSaveLoader(false);
      setSubjectList((prev) => ({ ...prev, refresh: !prev.refresh }));
      setCreateToggle((prev) => !prev);
    } else {
      setSaveLoader(false);
      setError("name", { type: "custom", message: "Subject already exist" });
    }
  };

  return (
    <StyledBox>
      <Typography variant="p" gutterBottom>
        Create subject
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2} sx={{ marginTop: "10px" }}>
          <Grid item xs={12}>
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <CustomTextField
                  label="Name *"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  helperText={errors?.name?.message}
                  error={errors?.name?.message}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item container xs={12} spacing={2}>
            <Grid item xs={2}>
              <Button
                sx={{
                  "&.MuiButton-root": {
                    color: theme.palette.neutral[0],
                    width: "100%",
                    borderRadius: theme.borderRadius[10],
                    backgroundColor: theme.palette.secondary.main,
                    textTransform: "none",
                    "&:hover": {
                      backgroundColor: theme.palette.secondary.hover,
                    },
                  },
                }}
                size="small"
                type="submit"
                endIcon={saveLoader && <CircularProgress size={20} />}
                disabled={saveLoader}
              >
                Create
              </Button>
            </Grid>
            <Grid item xs={2}>
              <Button
                sx={{
                  "&.MuiButton-root": {
                    color: theme.palette.neutral[0],
                    width: "100%",
                    borderRadius: theme.borderRadius[10],
                    backgroundColor: theme.palette.primary.main,
                    textTransform: "none",
                    "&:hover": {
                      backgroundColor: theme.palette.primary.hover,
                    },
                  },
                }}
                size="small"
                type="button"
                disabled={saveLoader}
                onClick={() => setCreateToggle((prev) => !prev)}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </StyledBox>
  );
}

export default CreateSubject;
