import React, { useEffect, useState, lazy, Suspense } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import journalAdminServices from "../../../services/journal/admin";
import { useNavigate } from "react-router-dom";
import {
  CustomAccordion,
  CustomDialog,
  CustomReactSelect,
} from "../../../components";
import { debounce } from "lodash";
import {
  IssueListDisplay,
  CreateArchive,
} from "../../../components/adminDashboard/archive";
import archiveAdminServices from "../../../services/archive/admin";

// const IssueListDisplay = lazy(() =>
//   import("../../../components/adminDashboard/archive/IssueListDisplay")
// );

const WarningDescText = styled(Typography)(({ theme }) => ({
  color: theme.palette.neutral[90],
  "& strong": {
    color: theme.palette.danger.main,
  },
}));

const ArchiveList = () => {
  const theme = useTheme();

  const [openCreateArchive, setOpenCreateArchive] = useState(false);
  const [deleteArchive, setDeleteArchive] = useState({
    id: null,
    warningOpen: false,
    loading: false,
  });

  const [selectedJournal, setJournalDetails] = useState("");

  const [archiveList, setArchiveList] = useState({
    data: [],
    loading: false,
    filter: {
      searchKey: "",
      sortBy: "",
      sortDirection: "",
      page: 0,
      rowsPerPage: 10,
    },
    refresh: false,
  });

  const [currentArchSelected, setCurrentArchSelected] = useState(null);

  const handleChangePage = (event, newPage) => {
    setArchiveList((prev) => ({
      ...prev,
      filter: {
        ...prev.filter,
        page: newPage,
      },
    }));
  };

  const handleChangeRowsPerPage = (event) => {
    setArchiveList((prev) => ({
      ...prev,
      filter: {
        ...prev.filter,
        rowsPerPage: event.target.value,
        page: 0,
      },
    }));
  };

  useEffect(() => {
    const fetchAllArchives = async () => {
      if (selectedJournal?._id) {
        setArchiveList((prev) => ({
          ...prev,
          loading: true,
        }));

        const reqAllArchive = {
          journal: selectedJournal?._id,
          sortBy: archiveList.filter.sortBy,
          sortDirection: archiveList.filter.sortDirection,
          page: archiveList.filter.page + 1,
          limit: archiveList.filter.rowsPerPage,
        };

        const resAllArchive = await archiveAdminServices.getArchiveList(
          reqAllArchive
        );

        if (resAllArchive?.data?.docs?.length > 0) {
          setArchiveList((prev) => ({
            ...prev,
            data: resAllArchive.data.docs,
            loading: false,
          }));
        } else {
          setArchiveList((prev) => ({
            ...prev,
            data: [],
            loading: false,
          }));
        }
      }
    };
    fetchAllArchives();
  }, [
    selectedJournal._id,
    archiveList.filter.sortBy,
    archiveList.filter.sortDirection,
    archiveList.filter.page,
    archiveList.filter.rowsPerPage,
    archiveList.refresh,
  ]);

  const loadJournalOptions = debounce((value, cb) => {
    journalAdminServices
      .getJournalList({
        searchKey: value ? value : "",
        sortBy: "",
        sortDirection: "",
        page: "",
        limit: "",
      })
      .then((res) => cb(res?.data?.docs));
  }, 500);

  const onClickDeleteArchive = async () => {
    if (!deleteArchive?.id) {
      return;
    }
    setDeleteArchive((prev) => ({
      ...prev,
      loading: true,
    }));

    const deleteRes = await archiveAdminServices.deleteArchive({
      archiveId: deleteArchive?.id,
    });

    if (deleteRes?.data) {
      setArchiveList((prev) => ({
        ...prev,
        refresh: !prev.refresh,
      }));
      setDeleteArchive((prev) => ({
        ...prev,
        id: null,
        warningOpen: false,
        loading: false,
      }));
    } else {
      setDeleteArchive((prev) => ({
        ...prev,
        loading: false,
      }));
    }
  };

  return (
    <>
      <Box>
        <Grid container alignItems={"end"}>
          <Grid item xs={6}>
            <CustomReactSelect
              async
              value={selectedJournal}
              label={"Choose journal"}
              getOptionLabel={(e) => e.name}
              getOptionValue={(e) => e.name}
              loadOptions={loadJournalOptions}
              placeholder={"journal"}
              onChange={(value) => setJournalDetails(value)}
            />
          </Grid>
          <Grid item xs={6}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <Button
                variant="contained"
                color="secondary"
                sx={{
                  "&.MuiButton-root": {
                    color: theme.palette.neutral[0],
                    textTransform: "none",

                    "&:hover": {
                      backgroundColor: theme.palette.secondary.hover,
                    },
                  },
                }}
                size="small"
                onClick={() => setOpenCreateArchive(true)}
                disabled={!selectedJournal?._id}
              >
                Create Archive
              </Button>
            </Box>
          </Grid>
        </Grid>
        <Box sx={{ marginTop: "20px" }}>
          {archiveList?.data?.length > 0 ? (
            archiveList?.data?.map((archive) => (
              <CustomAccordion
                key={archive?._id}
                title={archive.name}
                titleButtons={[
                  {
                    name: "Edit",
                    element: () => (
                      <IconButton
                        size="small"
                        onClick={(e) => {
                          e.stopPropagation();
                          setCurrentArchSelected(archive);
                          setOpenCreateArchive(true);
                        }}
                      >
                        <i className="ri-edit-2-fill"></i>
                      </IconButton>
                    ),
                  },
                  {
                    name: "Delete",
                    element: () => (
                      <IconButton
                        size="small"
                        onClick={(e) => {
                          e.stopPropagation();
                          setDeleteArchive((prev) => ({
                            ...prev,
                            id: archive?._id,
                            warningOpen: true,
                          }));
                        }}
                      >
                        <i className="ri-delete-bin-2-fill"></i>
                      </IconButton>
                    ),
                  },
                ]}
                titleButtonContainerSx={{
                  marginRight: "10px",
                }}
                accordionProp={{
                  sx: {
                    "& .MuiAccordionSummary-root": {
                      boxShadow: theme.boxShadow.shadowOne,
                    },
                    "& .MuiAccordionDetails-root": {
                      background: theme.palette.neutral[10],
                    },
                  },
                  TransitionProps: { unmountOnExit: true },
                }}
              >
                <IssueListDisplay
                  archiveDetails={archive}
                  journalDetails={selectedJournal}
                />
              </CustomAccordion>
            ))
          ) : selectedJournal?._id ? (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "200px",
              }}
            >
              <Typography
                variant="caption"
                gutterBottom
                sx={{
                  textAlign: "center",
                  color: theme.palette.neutral[50],
                }}
              >
                No Archive available
              </Typography>
            </Box>
          ) : (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "200px",
              }}
            >
              <Typography
                variant="caption"
                gutterBottom
                sx={{
                  textAlign: "center",
                  color: theme.palette.neutral[50],
                }}
              >
                Please select a journal
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
      <CreateArchive
        open={openCreateArchive}
        setOpenCreateArchive={setOpenCreateArchive}
        archiveDetails={currentArchSelected}
        setArchiveDetails={setCurrentArchSelected}
        journalDetails={selectedJournal}
        setArchiveList={setArchiveList}
      />
      <CustomDialog
        open={deleteArchive.warningOpen}
        title={"Are you sure ?"}
        agreeButtonText={"Delete"}
        agreeButtonProp={{
          variant: "contained",
          color: "secondary",
          type: "button",
          endIcon: deleteArchive.loading && <CircularProgress size={20} />,
          disabled: deleteArchive.loading,
          onClick: () => onClickDeleteArchive(),
          size: "small",
          sx: {
            "&.MuiButton-root": {
              color: theme.palette.neutral[0],
              borderRadius: theme.borderRadius[10],
              textTransform: "none",
              "&:hover": {
                backgroundColor: theme.palette.secondary.hover,
              },
            },
          },
        }}
        cancelButtonText={"Cancel"}
        cancelButtonProp={{
          variant: "contained",
          color: "primary",
          type: "button",
          disabled: deleteArchive.loading,
          onClick: () =>
            setDeleteArchive((prev) => ({
              ...prev,
              id: null,
              warningOpen: false,
              loading: false,
            })),
          size: "small",
          sx: {
            "&.MuiButton-root": {
              color: theme.palette.neutral[0],
              // width: "100%",
              borderRadius: theme.borderRadius[10],
              textTransform: "none",
              "&:hover": {
                backgroundColor: theme.palette.primary.hover,
              },
            },
          },
        }}
      >
        <WarningDescText>
          Do you really want to delete this Archive? This will{" "}
          <strong>
            <em>
              delete all records ( issues and articles ) related to this archive
            </em>
          </strong>{" "}
          and process cannot be undone.
        </WarningDescText>
      </CustomDialog>
    </>
  );
};

export default ArchiveList;
