import axiosInstance from "../../axiosInstance";

const getArticleSubmissionById = async ({ articleSubmissionId }) => {
  try {
    const res = await axiosInstance.get(
      `/api/articleSubmission?articleSubmissionId=${articleSubmissionId}`
    );

    if (res.status === 200) {
      return res.data;
    } else {
    }
  } catch (error) {}
};

export default getArticleSubmissionById;
