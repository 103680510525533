import getEditorList from "./getEditorList";
import createEditor from "./createEditor";
import getEditorById from "./getEditorById";
import deleteEditor from "./deleteEditor";

const editorAdminServices = {
  getEditorList,
  createEditor,
  getEditorById,
  deleteEditor,
};

export default editorAdminServices;
