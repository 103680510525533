import axiosInstance from "../../axiosInstance";

/**
 * Get Subject By ID
 * @param {*} subjectId
 * @returns
 */
const getJournalById = async ({ subjectId }) => {
  try {
    const res = await axiosInstance.get(`/api/subject?subjectId=${subjectId}`);

    if (res.status === 200) {
      return res.data;
    } else {
    }
  } catch (error) {}
};

export default getJournalById;
