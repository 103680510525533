import {
  Box,
  Divider,
  Grid,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import PaypalCheckout from "../../../components/website/paymentPage/PaypalCheckout";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";

const StyledBox = styled(Grid)(({ theme }) => ({
  width: "100%",
  height: "100%",
  borderRadius: theme.borderRadius[10],
  padding: "25px",
  boxShadow: theme.boxShadow.shadowThree,
  background: theme.palette.neutral[0],
}));

const Payment = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        padding: "20px 40px",
        height: "100%",
        // textAlign: "justify",
        background: theme.palette.primary.litSurface,
      }}
    >
      <Typography
        variant="h5"
        gutterBottom
        sx={{
          fontWeight: 600,
          marginBottom: "20px",
          "& span": {
            color: theme.palette.info.main,
          },
        }}
      >
        Payment
      </Typography>
      <Grid container spacing={2}>
        <Grid
          item
          container
          xs={12}
          sm={6}
          md={6}
          lg={6}
          // sx={{
          //   height: "100%",
          // }}
        >
          <StyledBox>
            <Typography
              variant="h6"
              gutterBottom
              sx={{
                fontWeight: 600,
              }}
            >
              Online payment
            </Typography>

            <Typography
              variant="body2"
              gutterBottom
              sx={{
                marginBottom: "20px",
              }}
            >
              Pay online in USD (PayPal, Credit Card, Debit Card)
            </Typography>
            <Grid item container xs={12} sm={12} md={12} lg={12} spacing={3}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <PayPalScriptProvider
                  options={{
                    clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID,
                    currency: "USD",
                    intent: "capture",
                  }}
                >
                  <PaypalCheckout />
                </PayPalScriptProvider>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Typography variant="body1" gutterBottom>
                  Instructions
                </Typography>
                <ol>
                  <li>
                    <Typography variant="body2" gutterBottom>
                      Please enter amount (Eg- 50 or 75.00 or 100 as required).
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="body2" gutterBottom>
                      Click confirm amount.
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="body2" gutterBottom>
                      You can check out with PayPal (Includes Credit Card or
                      debit Card Options) or go back to change the amount.
                    </Typography>
                  </li>
                </ol>
              </Grid>
            </Grid>
          </StyledBox>
        </Grid>
        <Grid item container xs={12} sm={6} md={6} lg={6}>
          <StyledBox>
            <Typography
              variant="h6"
              gutterBottom
              sx={{
                fontWeight: 600,
              }}
            >
              UPI Details
            </Typography>
            <Divider
              variant="fullWidth"
              sx={{ borderColor: theme.palette.secondary.main }}
            />
            <Typography variant="body2" gutterBottom sx={{ marginTop: "10px" }}>
              <strong>UPI :</strong> 6000605461@hdfcbank
            </Typography>

            <Typography
              variant="h6"
              gutterBottom
              sx={{
                fontWeight: 600,
                marginTop: "20px",
              }}
            >
              Bank details
            </Typography>
            <Divider
              variant="fullWidth"
              sx={{ borderColor: theme.palette.secondary.main }}
            />
            <Typography variant="body2" gutterBottom sx={{ marginTop: "10px" }}>
              <strong>Name of Account :</strong> IFRD INTERNATIONAL LLP
            </Typography>
            <Typography variant="body2" gutterBottom>
              <strong>Account No :</strong> 50200096223307
            </Typography>
            <Typography variant="body2" gutterBottom>
              <strong>Name of Bank :</strong> HDFC Bank
            </Typography>
            <Typography variant="body2" gutterBottom>
              <strong>Branch :</strong> Lanka
            </Typography>
            <Typography variant="body2" gutterBottom>
              <strong>Branch Code :</strong> 8531
            </Typography>
            <Typography variant="body2" gutterBottom>
              <strong>IFSC Code :</strong> HDFC0008531
            </Typography>
            <Typography variant="body2" gutterBottom>
              <strong>MICR Code :</strong> 782240004
            </Typography>
            <Typography variant="body2" gutterBottom>
              <strong>SWIFT Code :</strong> HDFCINBBCAL
            </Typography>
            <Typography variant="body2" gutterBottom>
              <strong>IBAN No :</strong> HDFCINBBCAL
            </Typography>
            <Typography variant="body2" gutterBottom>
              <strong>Bank Address :</strong> Ground Floor, Shillong Road,
              Opposite M Bazar, Ward No 1, Lanka, Dist - Hojai, Assam, 782446
            </Typography>
          </StyledBox>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Payment;
