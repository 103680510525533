import axiosInstance from "../../axiosInstance";

/**
 * Get Archive and issue list
 * @param {*} sortBy
 * @param {*} sortDirection
 * @param {*} page
 * @param {*} limit
 * @returns
 */
const getArchiveIssueList = async ({ journal = "" }) => {
  try {
    const res = await axiosInstance.get(
      `/api/archive/websiteArchiveIssueList?journal=${journal}`
    );

    if (res.status === 200) {
      return res.data;
    } else {
    }
  } catch (error) {}
};

export default getArchiveIssueList;
