import axiosInstance from "../../axiosInstance";

const getEditorById = async ({ editorId }) => {
  try {
    const res = await axiosInstance.get(`/api/editor?editorId=${editorId}`);

    if (res.status === 200) {
      return res.data;
    } else {
    }
  } catch (error) {}
};

export default getEditorById;
