import axiosInstance from "../../axiosInstance";

const deleteJournal = async ({ journalId }) => {
  try {
    const res = await axiosInstance.delete(
      `/api/journal/delete?journalId=${journalId}`
    );

    if (res.status === 200) {
      return res.data;
    } else {
    }
  } catch (error) {}
};

export default deleteJournal;
