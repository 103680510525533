import escapeHtml from "escape-html";
import { Text } from "slate";

const serializeNode = (node) => {
  if (Text.isText(node)) {
    let string = escapeHtml(node.text);
    if (node.bold) {
      string = `<strong>${string}</strong>`;
    }
    if (node.italic) {
      string = `<em>${string}</em>`;
    }
    if (node.underline) {
      string = `<u>${string}</u>`;
    }
    if (node.code) {
      string = `<code>${string}</code>`;
    }
    if (node.strikethrough) {
      string = `<s>${string}</s>`;
    }
    if (node.superscript) {
      string = `<sup>${string}</sup>`;
    }
    if (node.subscript) {
      string = `<sub>${string}</sub>`;
    }
    return string;
  }

  const children = node.children.map((n) => serializeNode(n)).join("");

  let attributes = "";

  if (
    node.align ||
    (node.elAttributes && Object.keys(node.elAttributes).length > 0)
  ) {
    let cloneAttributes = { ...node.elAttributes };

    let extractNodeStyle =
      cloneAttributes?.style &&
      Object.keys(cloneAttributes.style) &&
      convertJsxStyleToHtml(cloneAttributes.style);

    let styleAttribute =
      node.align && extractNodeStyle
        ? `${extractNodeStyle} text-align:${node.align};`
        : extractNodeStyle
        ? `${extractNodeStyle}`
        : node.align
        ? `text-align:${node.align};`
        : "";

    if (node.type === "image") {
      styleAttribute = styleAttribute
        ? `${styleAttribute} max-height: 100%`
        : `max-height: 100%`;
    }

    delete cloneAttributes.style;

    const htmlAttributes = convertJsxAttributesToHtml(cloneAttributes);

    attributes = styleAttribute
      ? `${htmlAttributes} style="${styleAttribute}"`
      : `${htmlAttributes}`;
  }

  switch (node.type) {
    case "quote":
      return `<blockquote><p>${children}</p></blockquote>`;
    case "paragraph":
      return `<p ${attributes ? `${attributes}` : ""}>${children}</p>`;
    case "heading-one":
      return `<h1 ${attributes ? `${attributes}` : ""}>${children}</h1>`;
    case "heading-two":
      return `<h2 ${attributes ? `${attributes}` : ""}> ${children}</h2>`;
    case "heading-three":
      return `<h3 ${attributes ? `${attributes}` : ""}>${children}</h3>`;
    case "heading-four":
      return `<h4 ${attributes ? `${attributes}` : ""}>${children}</h4>`;
    case "heading-five":
      return `<h5 ${attributes ? `${attributes}` : ""}>${children}</h5>`;
    case "heading-six":
      return `<h6 ${attributes ? `${attributes}` : ""}>${children}</h6>`;
    case "bulleted-list":
      return `<ul ${attributes ? `${attributes}` : ""}>${children}</ul>`;
    case "list-item":
      return `<li ${attributes ? `${attributes}` : ""}>${children}</li>`;
    case "numbered-list":
      return `<ol ${attributes ? `${attributes}` : ""}>${children}</ol>`;
    case "table":
      return `<table ${attributes ? `${attributes}` : ""}>${children}</table>`;
    case "tbody":
      return `<tbody ${attributes ? `${attributes}` : ""}>${children}</tbody>`;
    case "table-row":
      return `<tr ${attributes ? `${attributes}` : ""}>${children}</tr>`;
    case "table-cell":
      return `<td ${attributes ? `${attributes}` : ""}>${children}</td>`;
    case "image":
      return `<div ${attributes ? `${attributes}` : ""}>
      <img src="${escapeHtml(
        node.url
      )}" alt="" style="max-width: 50%;">${children}</img></div>`;
    case "link":
      return `<a href="${escapeHtml(node.url)}" ${
        attributes ? `${attributes}` : ""
      }>${children}</a>`;
    default:
      return children;
  }
};

/**
 * Slate to html
 * @param {*} slateJson - slate node json object
 * @returns
 */
const serializeSlateToHtml = (slateJson) => {
  return slateJson.reduce((accumulator, currentNode) => {
    accumulator = `${accumulator}${serializeNode(currentNode)}`;
    return accumulator;
  }, "");
};

export default serializeSlateToHtml;

/**
 * Converts JSX attributes from an object to HTML attributes.
 *
 * @param {object} jsxAttributes - The JSX attribute object.
 * @returns {string} The HTML attribute string.
 */
function convertJsxAttributesToHtml(jsxAttributes) {
  // Initialize an empty string to store the HTML attributes.
  let htmlAttributes = "";

  // Loop through each property in the JSX attribute object.
  for (const attribute in jsxAttributes) {
    // Check if the property is a valid attribute.
    if (Object.prototype.hasOwnProperty.call(jsxAttributes, attribute)) {
      // Convert the attribute name to lowercase.
      const attributeName = attribute.toLowerCase();

      // Check if the attribute value is a boolean.
      if (typeof jsxAttributes[attribute] === "boolean") {
        // If the value is true, only append the attribute name.
        if (jsxAttributes[attribute]) {
          htmlAttributes += ` ${attributeName}`;
        }
      } else {
        // If the value is not a boolean, append the attribute name and its value.
        htmlAttributes += ` ${attributeName}="${jsxAttributes[attribute]}"`;
      }
    }
  }

  // Return the HTML attribute string.
  return htmlAttributes.trim();
}

/**
 * Converts JSX inline styles to HTML inline styles.
 *
 * @param {object} jsxStyle - The JSX inline style object.
 * @returns {string} The HTML inline style string.
 */
function convertJsxStyleToHtml(jsxStyle) {
  // Initialize an empty string to store the HTML inline style.
  let htmlStyle = "";

  // Loop through each property in the JSX inline style object.
  for (const property in jsxStyle) {
    // Check if the property is a valid CSS property.
    if (Object.prototype.hasOwnProperty.call(jsxStyle, property)) {
      // Convert the property name to kebab case (e.g., backgroundColor -> background-color).
      const kebabCaseProperty = property
        .replace(/([A-Z])/g, "-$1")
        .toLowerCase();

      // Append the property and its value to the HTML inline style string.
      htmlStyle += `${kebabCaseProperty}: ${jsxStyle[property]}; `;
    }
  }

  // Return the HTML inline style string.
  return htmlStyle.trim();
}
