import * as React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { IconButton, ListSubheader, styled } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CustomMenuItem from "./CustomMenuItem";

const CustomListSubheader = styled(ListSubheader)(({ theme }) => ({
  backgroundColor: theme.palette.primary.surface,
}));

const CustomIconMenu = ({ icon, options }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls={open ? "icon-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        {icon ? icon : <MoreVertIcon />}
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {options?.map((option, optionIndex) => (
          <>
            {option.categoryName && (
              <CustomListSubheader>{option.categoryName}</CustomListSubheader>
            )}
            {!option.categoryName && (
              <MenuItem
                key={`${optionIndex}_option_${option?.name}`}
                onClick={() => {
                  option?.handleOption();
                  handleClose();
                }}
                sx={{
                  ...(option?.isNested && {
                    paddingLeft: "30px",
                  }),
                }}
              >
                {option?.name}
              </MenuItem>
            )}
          </>
        ))}
      </Menu>
    </div>
  );
};

export default CustomIconMenu;
