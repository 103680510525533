import React, { useCallback, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  useTheme,
  Box,
  Grid,
  FormControlLabel,
  Checkbox,
  Button,
  Typography,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  styled,
  IconButton,
} from "@mui/material";
import { useSearchParams } from "react-router-dom";
import {
  CustomReactSelect,
  CustomTextField,
  Loader,
} from "../../../../components";
import { MuiTelInput } from "mui-tel-input";
import { submitArticleTypeOptions } from "../../../../helpers/constant";
import articleSubmissionAdminServices from "../../../../services/articleSubmission/admin";
import { toast } from "react-toastify";
import { useDropzone } from "react-dropzone";
import { saveAs } from "file-saver";

const StyledDropZoneContainer = styled(Box)(({ theme }) => ({
  border: `1px dashed ${theme.palette.neutral[70]}`,
  background: theme.palette.neutral[10],
  width: "100%",
  height: "100%",
  borderRadius: theme.borderRadius[3],
  cursor: "pointer",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
  flexDirection: "column",
  padding: "10px",
  "& p": {
    fontSize: "12px",
  },
}));

const ArticleSubmissionAddEditForm = ({ defaultFormValues }) => {
  const theme = useTheme();
  const [searchParams] = useSearchParams();
  const [saveLoader, setSaveLoader] = useState(false);
  const [authSign, setAuthSign] = useState({
    uploadLoading: false,
    file: null,
  });

  const [deleteFiles, setDeleteFiles] = useState([]);

  // const [images, setImages] = useState({
  //   inserted: [],
  //   deleted: [],
  // });

  const articleSubmissionValidationSchema = yup.object().shape({
    acknowledgement: yup.object().shape({
      status: yup.boolean(),
      comment: yup.string(),
    }),
    plagiarismsCheck: yup.object().shape({
      status: yup.boolean(),
      comment: yup.string(),
    }),
    editorAssigned: yup.object().shape({
      status: yup.boolean(),
      comment: yup.string(),
    }),
    reviewerAssigned: yup.object().shape({
      status: yup.boolean(),
      comment: yup.string(),
    }),
    approvedRejected: yup.object().shape({
      status: yup.string(),
      comment: yup.string(),
    }),
    payment: yup.object().shape({
      status: yup.boolean(),
      comment: yup.string(),
    }),
    published: yup.object().shape({
      status: yup.boolean(),
      comment: yup.string(),
    }),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
  } = useForm({
    defaultValues: defaultFormValues,
    resolver: yupResolver(articleSubmissionValidationSchema),
  });

  const onSubmit = async (data, event) => {
    event.preventDefault();
    setSaveLoader(true);

    const saveArticleFormData = new FormData();

    Object.entries(data).forEach(([key, value]) => {
      if (key === "journal") {
        saveArticleFormData.append(key, value._id);
      } else if (key === "authorSign") {
        if (authSign.file) {
          saveArticleFormData.append(key, authSign.file);
        } else {
          saveArticleFormData.append(key, value);
        }
      } else if (typeof value === "object") {
        saveArticleFormData.append(key, JSON.stringify(value));
      } else {
        saveArticleFormData.append(key, value);
      }
    });

    if (deleteFiles?.length > 0) {
      saveArticleFormData.append("deleteFiles", JSON.stringify(deleteFiles));
    }

    // const reqSaveArticle = {
    //   ...data,
    //   journal: data.journal._id,
    //   articleSubmissionId: searchParams.get("articleSubmissionId") || "",
    // };
    const resSaveArticle =
      await articleSubmissionAdminServices.createArticleSubmission(
        saveArticleFormData
      );
    if (resSaveArticle) {
      toast.success("Article submission save successful");
      setSaveLoader(false);
    } else {
      setSaveLoader(false);
    }
  };

  const onDrop = useCallback(async (acceptedFiles) => {
    if (acceptedFiles?.length > 0) {
      setAuthSign((prev) => ({
        ...prev,
        file: acceptedFiles[0],
      }));
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: { "image/*": [".jpg", ".jpeg", ".png"] },
    multiple: false,
  });

  const handleDeleteJournalCoverImage = () => {
    if (authSign.file) {
      setAuthSign((prev) => ({ ...prev, file: null }));
      return;
    }

    const currentAuthSignImgUrl = getValues("authorSign");

    if (currentAuthSignImgUrl) {
      setDeleteFiles((prev) => [...prev, currentAuthSignImgUrl]);
      setValue("authorSign", "");
    }
  };

  return (
    <Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Controller
              name="trackingId"
              control={control}
              render={({ field }) => (
                <CustomTextField
                  label="Reference Id"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  disabled
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Controller
              name="authorName"
              control={control}
              render={({ field }) => (
                <CustomTextField
                  label="Author Name"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  helperText={errors?.authorName?.message}
                  error={errors?.authorName?.message}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Controller
              name="phone"
              control={control}
              render={({ field }) => (
                <MuiTelInput
                  label="Phone Number with country Code *"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  helperText={errors?.phone?.message}
                  error={errors?.phone?.message}
                  defaultCountry="IN"
                  sx={{
                    "& label": {
                      "&.Mui-focused": {
                        color: theme.palette.neutral[90],
                      },
                    },
                    "& .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: theme.palette.neutral[50],
                      },
                  }}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <CustomTextField
                  label="Email *"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  helperText={errors?.email?.message}
                  error={errors?.email?.message}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Controller
              name="journal"
              control={control}
              render={({ field }) => (
                <CustomReactSelect
                  label={"Choose Journal *"}
                  getOptionLabel={(e) => e.name}
                  getOptionValue={(e) => e._id}
                  options={[defaultFormValues.journal]}
                  placeholder={"- - -"}
                  helperText={errors?.journal?._id?.message}
                  error={errors?.journal?._id?.message}
                  isDisabled
                  styles={{
                    menu: (base) => ({
                      ...base,
                      zIndex: 5,
                    }),
                  }}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Controller
              name="articleType"
              control={control}
              render={({ field }) => (
                <CustomReactSelect
                  {...field}
                  label={"Choose Article Type *"}
                  getOptionLabel={(e) => e.name}
                  getOptionValue={(e) => e.name}
                  options={submitArticleTypeOptions}
                  value={[{ name: field.value }]}
                  placeholder={"- - -"}
                  helperText={errors?.articleType?.name?.message}
                  error={errors?.articleType?.name?.message}
                  isDisabled
                  styles={{
                    menu: (base) => ({
                      ...base,
                      zIndex: 5,
                    }),
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Controller
              name="manuscriptTitle"
              control={control}
              render={({ field }) => (
                <CustomTextField
                  label="Manuscript Title *"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  helperText={errors?.manuscriptTitle?.message}
                  error={errors?.manuscriptTitle?.message}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item container xs={12} sm={6} md={3} lg={3}>
            <Button
              sx={{
                "&.MuiButton-root": {
                  color: theme.palette.neutral[90],
                  borderRadius: theme.borderRadius[10],
                  backgroundColor: theme.palette.primary.main,
                  textTransform: "none",
                  "&:hover": {
                    backgroundColor: theme.palette.primary.hover,
                  },
                },
              }}
              size="small"
              type="button"
              fullWidth
              onClick={async () => {
                saveAs(
                  `${process.env.REACT_APP_FILE_BASE_URL}${defaultFormValues?.manuscriptFileUrl}`,
                  `${defaultFormValues?.manuscriptFileUrl?.split("/")[1]}`
                );
              }}
            >
              Download Manuscript File
            </Button>
          </Grid>
          <Grid item container xs={12} sm={6} md={3} lg={3}>
            <Button
              sx={{
                "&.MuiButton-root": {
                  color: theme.palette.neutral[90],
                  borderRadius: theme.borderRadius[10],
                  backgroundColor: theme.palette.primary.main,
                  textTransform: "none",
                  "&:hover": {
                    backgroundColor: theme.palette.primary.hover,
                  },
                },
              }}
              size="small"
              type="button"
              fullWidth
              onClick={async () => {
                let resDownload =
                  await articleSubmissionAdminServices.downloadArticleSubmissionCopyright(
                    {
                      articleSubmissionId: defaultFormValues?._id,
                    }
                  );
                let pdfBlob = new Blob(
                  [new Uint8Array(resDownload?.data?.data).buffer],
                  {
                    type: "application/pdf",
                  }
                );

                saveAs(
                  pdfBlob,
                  `${defaultFormValues.trackingId} author's copyright form.pdf`
                );
              }}
            >
              Download copyright form
            </Button>
          </Grid>
          <Grid item xs={12}>
            {authSign.uploadLoading ? (
              <StyledDropZoneContainer>
                <Loader />
              </StyledDropZoneContainer>
            ) : authSign.file || getValues("authorSign") ? (
              <StyledDropZoneContainer>
                <Box sx={{ position: "relative" }}>
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => handleDeleteJournalCoverImage()}
                    edge="end"
                    sx={{
                      position: "absolute",
                      top: -15,
                      right: -5,
                      zIndex: 1,
                      background: theme.palette.neutral[0],
                      color: theme.palette.secondary.main,
                    }}
                  >
                    <i class="ri-delete-bin-2-fill ri-sm"></i>
                  </IconButton>
                  {getValues("authorSign") &&
                  !["jpg", "jpeg", "png"].includes(
                    getValues("authorSign").split(".")[
                      getValues("authorSign").split(".").length - 1
                    ]
                  ) ? (
                    <Box
                      sx={{
                        padding: "20px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                        background: theme.palette.neutral[0],
                        borderRadius: theme.borderRadius[10],
                      }}
                    >
                      <Typography>{getValues("authorSign")}</Typography>
                      <Typography
                        variant="caption"
                        sx={{
                          marginTop: "10px",
                          textAlign: "center",
                          "& span": {
                            color: theme.palette.danger.main,
                          },
                        }}
                      >
                        <strong>
                          <span>Incorrect file format</span> Accepts only{" "}
                          <span>.png, .jpeg, .jpg</span> image type
                        </strong>
                      </Typography>
                    </Box>
                  ) : (
                    <img
                      src={
                        authSign.file
                          ? URL.createObjectURL(authSign.file)
                          : `${process.env.REACT_APP_FILE_BASE_URL}${getValues(
                              "authorSign"
                            )}`
                      }
                      alt="journal-logo"
                      style={{
                        maxWidth: "170px",
                      }}
                    />
                  )}
                  <Typography>
                    <strong>Author's signature</strong>
                  </Typography>
                </Box>
              </StyledDropZoneContainer>
            ) : (
              <StyledDropZoneContainer {...getRootProps()}>
                <input {...getInputProps()} />
                {isDragActive ? (
                  <Box width={"100%"}>
                    <p>Drop the files here ...</p>
                  </Box>
                ) : (
                  <Box>
                    <h5>Upload journal cover Image</h5>
                    <p>
                      Drag 'n' drop some files here, or click to select files
                    </p>
                  </Box>
                )}
              </StyledDropZoneContainer>
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Controller
              name="acknowledgement.status"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={<Checkbox />}
                  label="Acknowledgement"
                  checked={field.value}
                  {...field}
                />
              )}
            />
            <Controller
              name="acknowledgement.comment"
              control={control}
              render={({ field }) => (
                <CustomTextField
                  label="Acknowledgement comment"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  multiline
                  rows={5}
                  helperText={errors?.name?.message}
                  error={errors?.name?.message}
                  {...field}
                />
              )}
            />
          </Grid>
          {/* <Grid item xs={12} sm={12} md={6} lg={6}>
          </Grid> */}
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Controller
              name="plagiarismsCheck.status"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={<Checkbox />}
                  label="Plagiarisms Check"
                  checked={field.value}
                  {...field}
                />
              )}
            />
            <Controller
              name="plagiarismsCheck.comment"
              control={control}
              render={({ field }) => (
                <CustomTextField
                  label="Plagiarisms Check comment"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  multiline
                  rows={5}
                  helperText={errors?.name?.message}
                  error={errors?.name?.message}
                  {...field}
                />
              )}
            />
          </Grid>
          {/* <Grid item xs={12} sm={12} md={6} lg={6}>
          </Grid> */}
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Controller
              name="editorAssigned.status"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={<Checkbox />}
                  label="Editor Assigned"
                  checked={field.value}
                  {...field}
                />
              )}
            />
            <Controller
              name="editorAssigned.comment"
              control={control}
              render={({ field }) => (
                <CustomTextField
                  label="Editor Assigned comment"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  multiline
                  rows={5}
                  helperText={errors?.name?.message}
                  error={errors?.name?.message}
                  {...field}
                />
              )}
            />
          </Grid>
          {/* <Grid item xs={12} sm={12} md={6} lg={6}>
          </Grid> */}
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Controller
              name="reviewerAssigned.status"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={<Checkbox />}
                  label="Reviewer Assigned"
                  checked={field.value}
                  {...field}
                />
              )}
            />
            <Controller
              name="reviewerAssigned.comment"
              control={control}
              render={({ field }) => (
                <CustomTextField
                  label="Reviewer Assigned comment"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  multiline
                  rows={5}
                  helperText={errors?.name?.message}
                  error={errors?.name?.message}
                  {...field}
                />
              )}
            />
          </Grid>
          {/* <Grid item xs={12} sm={12} md={6} lg={6}>
          </Grid> */}
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Controller
              name="approvedRejected.status"
              control={control}
              render={({ field }) => (
                <RadioGroup row {...field}>
                  <FormControlLabel
                    value="APPROVE"
                    control={<Radio />}
                    label="Approve"
                  />
                  <FormControlLabel
                    value="REJECT"
                    control={<Radio />}
                    label="Reject"
                  />
                </RadioGroup>
                // <FormControlLabel
                //   control={<Checkbox />}
                //   label="Approved/Rejected"
                //   {...field}
                // />
              )}
            />
            <Controller
              name="approvedRejected.comment"
              control={control}
              render={({ field }) => (
                <CustomTextField
                  label="Approved/Rejected Comment"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  multiline
                  rows={5}
                  helperText={errors?.name?.message}
                  error={errors?.name?.message}
                  {...field}
                />
              )}
            />
          </Grid>
          {/* <Grid item xs={12} sm={12} md={6} lg={6}>
          </Grid> */}
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Controller
              name="payment.status"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={<Checkbox />}
                  label="Payment"
                  checked={field.value}
                  {...field}
                />
              )}
            />
            <Controller
              name="payment.comment"
              control={control}
              render={({ field }) => (
                <CustomTextField
                  label="Payment comment"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  multiline
                  rows={5}
                  helperText={errors?.name?.message}
                  error={errors?.name?.message}
                  {...field}
                />
              )}
            />
          </Grid>
          {/* <Grid item xs={12} sm={12} md={6} lg={6}>
          </Grid> */}
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Controller
              name="published.status"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={<Checkbox />}
                  label="Published"
                  checked={field.value}
                  {...field}
                />
              )}
            />
            <Controller
              name="published.comment"
              control={control}
              render={({ field }) => (
                <CustomTextField
                  label="Published comment"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  multiline
                  rows={5}
                  helperText={errors?.name?.message}
                  error={errors?.name?.message}
                  {...field}
                />
              )}
            />
          </Grid>
          {/* <Grid item xs={12} sm={12} md={6} lg={6}>
          </Grid> */}
        </Grid>
        <Grid item container xs={12}>
          <Grid item xs={12} md={3} lg={3}>
            <Button
              sx={{
                "&.MuiButton-root": {
                  marginTop: "20px",
                  color: theme.palette.neutral[90],
                  borderRadius: theme.borderRadius[10],
                  backgroundColor: theme.palette.primary.main,
                  textTransform: "none",
                  "&:hover": {
                    backgroundColor: theme.palette.primary.hover,
                  },
                },
              }}
              size="small"
              type="submit"
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default ArticleSubmissionAddEditForm;
