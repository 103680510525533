import createIssue from "./createIssue";
import getIssueList from "./getIssueList";
import getIssueById from "./getIssueById";
import deleteIssue from "./deleteIssue";

const issueAdminServices = {
  createIssue,
  getIssueList,
  getIssueById,
  deleteIssue,
};

export default issueAdminServices;
