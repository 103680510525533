import axiosInstance from "../../axiosInstance";

const deleteArticle = async ({ articleId }) => {
  try {
    const res = await axiosInstance.delete(
      `/api/article/delete?articleId=${articleId}`
    );

    if (res.status === 200) {
      return res.data;
    } else {
    }
  } catch (error) {}
};

export default deleteArticle;
