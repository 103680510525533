import React, { useEffect, useState } from "react";
import EditorAddEditForm from "./EditorAddEditForm";
import { useSearchParams } from "react-router-dom";
import issueAdminServices from "../../../../services/issue/admin";
import editorAdminServices from "../../../../services/editor/admin";
import { Box } from "@mui/material";
import { Loader } from "../../../../components";
import deserializeHtmlToSlate from "../../../../components/SlateEditor/deserializeHtmlToSlate";
import journalAdminServices from "../../../../services/journal/admin";
import { editorOptions } from "../../../../helpers/constant";

const AddEditEditor = () => {
  const [searchParams] = useSearchParams();

  const [journalOptions, setJournalOptions] = useState([]);

  const [editorDetails, setEditorDetails] = useState({
    data: {
      name: "",
      description: "",
      phone: "",
      email: "",
      designation: "",
      journal: "",
    },
    loading: false,
    fetchFailed: false,
  });

  useEffect(() => {
    const fetchJournal = async () => {
      const resJournal = await journalAdminServices.getJournalList({
        searchKey: "",
        sortBy: "",
        sortDirection: "",
        page: "",
        limit: "",
      });

      if (resJournal) {
        setJournalOptions(resJournal?.data?.docs || []);
      }
    };
    fetchJournal();
  }, [searchParams]);

  useEffect(() => {
    const fetchEditor = async () => {
      if (searchParams.get("editorId")) {
        setEditorDetails((prev) => ({
          ...prev,
          loading: true,
          fetchFailed: false,
        }));
        const resEditor = await editorAdminServices.getEditorById({
          editorId: searchParams.get("editorId"),
        });

        if (resEditor) {
          setEditorDetails((prev) => ({
            ...prev,
            data: {
              ...resEditor.data,
              designation: editorOptions.find(
                (editor) => editor.value === resEditor.data.designation
              ),
              editorImg: resEditor.data.editorImg,
            },
            loading: false,
            fetchFailed: false,
          }));
        } else {
          setEditorDetails((prev) => ({
            ...prev,
            loading: false,
            fetchFailed: true,
          }));
        }
      }
    };
    fetchEditor();
  }, [searchParams]);

  return editorDetails?.loading ? (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: "100vh",
      }}
    >
      <Loader />
    </Box>
  ) : (
    <EditorAddEditForm
      defaultFormValues={editorDetails.data}
      journalOptions={journalOptions}
      designationOptions={editorOptions}
    />
  );
};

export default AddEditEditor;
