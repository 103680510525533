import axiosInstance from "../../axiosInstance";

const trackArticleSubmission = async ({ trackingId }) => {
  try {
    const res = await axiosInstance.get(
      `/api/articleSubmission/websiteTrackArticleSubmission?trackingId=${trackingId}`
    );
    if (res.status === 200) {
      return res.data;
    } else {
    }
  } catch (error) {}
};

export default trackArticleSubmission;
