import React, { useState } from "react";
import {
  Box,
  Button,
  Divider,
  IconButton,
  styled,
  useTheme,
} from "@mui/material";
import FormatBoldIcon from "@mui/icons-material/FormatBold";
import FormatItalicIcon from "@mui/icons-material/FormatItalic";
import StrikethroughSIcon from "@mui/icons-material/StrikethroughS";
import AddLinkIcon from "@mui/icons-material/AddLink";
import CustomMenu from "../../CustomMenu";
import InsertLink from "./InsertLink";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import FormatColorFillIcon from "@mui/icons-material/FormatColorFill";
import FormatUnderlinedIcon from "@mui/icons-material/FormatUnderlined";
import HighlightIcon from "@mui/icons-material/Highlight";
import SubscriptIcon from "@mui/icons-material/Subscript";
import SuperscriptIcon from "@mui/icons-material/Superscript";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import FormatAlignJustifyIcon from "@mui/icons-material/FormatAlignJustify";
import FormatAlignRightIcon from "@mui/icons-material/FormatAlignRight";
import FormatAlignCenterIcon from "@mui/icons-material/FormatAlignCenter";
import UndoIcon from "@mui/icons-material/Undo";
import RedoIcon from "@mui/icons-material/Redo";
import FormatSizeIcon from "@mui/icons-material/FormatSize";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import articleAdminServices from "../../../services/article/admin";

const CustomIconButton = styled(IconButton)(({ theme, isActive }) => ({
  margin: "0 5px",
  backgroundColor: isActive
    ? theme.palette.secondary.surface
    : theme.palette.neutral[0],
  "&.Mui-disabled": {
    color: theme.palette.neutral[20],
  },
}));

const CustomDivider = styled(Divider)(({ theme, isActive }) => ({
  mx: 0.5,
}));

const ToolBar = ({ editor, setInsertedFiles }) => {
  const theme = useTheme();
  const [openInsertModal, setOpenInsertModal] = useState({
    link: false,
    image: false,
    table: false,
  });

  if (!editor) {
    return null;
  }

  const historyButton = [
    {
      tag: "undo",
      component: () => (
        <CustomIconButton
          size="medium"
          onClick={() => editor.chain().focus().undo().run()}
          disabled={!editor.can().undo()}
        >
          <UndoIcon />
        </CustomIconButton>
      ),
    },
    {
      tag: "redo",
      component: () => (
        <CustomIconButton
          size="medium"
          onClick={() => editor.chain().focus().redo().run()}
          disabled={!editor.can().redo()}
        >
          <RedoIcon />
        </CustomIconButton>
      ),
    },
  ];

  const textFormatButton = [
    {
      tag: "bold",
      component: () => (
        <CustomIconButton
          size="medium"
          onClick={() => editor.chain().focus().toggleBold().run()}
          isActive={editor.isActive("bold")}
        >
          <FormatBoldIcon />
        </CustomIconButton>
      ),
    },
    {
      tag: "italic",
      component: () => (
        <CustomIconButton
          size="medium"
          onClick={() => editor.chain().focus().toggleItalic().run()}
          isActive={editor.isActive("italic")}
        >
          <FormatItalicIcon />
        </CustomIconButton>
      ),
    },
    {
      tag: "underline",
      component: () => (
        <CustomIconButton
          size="medium"
          onClick={() => editor.chain().focus().toggleUnderline().run()}
          isActive={editor.isActive("underline")}
        >
          <FormatUnderlinedIcon />
        </CustomIconButton>
      ),
    },
    {
      tag: "strike",
      component: () => (
        <CustomIconButton
          size="medium"
          onClick={() => editor.chain().focus().toggleStrike().run()}
          isActive={editor.isActive("toggleStrike")}
        >
          <StrikethroughSIcon />
        </CustomIconButton>
      ),
    },
    {
      tag: "highlight",
      component: () => (
        <CustomIconButton
          size="medium"
          onClick={() =>
            editor.chain().focus().toggleHighlight({ color: "#ffcc00" }).run()
          }
          isActive={editor.isActive("toggleStrike")}
        >
          <FormatColorFillIcon />
        </CustomIconButton>
      ),
    },
    {
      tag: "subscript",
      component: () => (
        <CustomIconButton
          size="medium"
          onClick={() => editor.chain().focus().toggleSubscript().run()}
          isActive={editor.isActive("subscript")}
        >
          <SubscriptIcon />
        </CustomIconButton>
      ),
    },
    {
      tag: "superscript",
      component: () => (
        <CustomIconButton
          size="medium"
          onClick={() => editor.chain().focus().toggleSuperscript().run()}
          isActive={editor.isActive("superscript")}
        >
          <SuperscriptIcon />
        </CustomIconButton>
      ),
    },

    // {
    //   tag: "clear_marks",
    //   component: () => (
    //     <CustomIconButton
    //       size="medium"
    //       onClick={() => editor.chain().focus().unsetAllMarks().run()}
    //       // isActive={editor.isActive("code")}
    //     >
    //       <DeleteIcon />
    //     </CustomIconButton>
    //   ),
    // },
    // {
    //   tag: "clear_nodes",
    //   component: () => (
    //     <CustomIconButton
    //       size="medium"
    //       onClick={() => editor.chain().focus().clearNodes().run()}
    //       // isActive={editor.isActive("code")}
    //     >
    //       <DeleteIcon />
    //     </CustomIconButton>
    //   ),
    // },
  ];

  const lineFormatButton = [
    {
      tag: "text_align_left",
      component: () => (
        <CustomIconButton
          size="medium"
          onClick={() => editor.chain().focus().setTextAlign("left").run()}
          isActive={editor.isActive({ textAlign: "left" })}
        >
          <FormatAlignLeftIcon />
        </CustomIconButton>
      ),
    },
    {
      tag: "text_align_center",
      component: () => (
        <CustomIconButton
          size="medium"
          onClick={() => editor.chain().focus().setTextAlign("center").run()}
          isActive={editor.isActive({ textAlign: "center" })}
        >
          <FormatAlignCenterIcon />
        </CustomIconButton>
      ),
    },
    {
      tag: "text_align_right",
      component: () => (
        <CustomIconButton
          size="medium"
          onClick={() => editor.chain().focus().setTextAlign("right").run()}
          isActive={editor.isActive({ textAlign: "right" })}
        >
          <FormatAlignRightIcon />
        </CustomIconButton>
      ),
    },
    {
      tag: "text_align_justify",
      component: () => (
        <CustomIconButton
          size="medium"
          onClick={() => editor.chain().focus().setTextAlign("justify").run()}
          isActive={editor.isActive({ textAlign: "justify" })}
        >
          <FormatAlignJustifyIcon />
        </CustomIconButton>
      ),
    },
    {
      tag: "bullet_list",
      component: () => (
        <CustomIconButton
          size="medium"
          onClick={() => editor.chain().focus().toggleBulletList().run()}
          isActive={editor.isActive("bulletList")}
        >
          <FormatListBulletedIcon />
        </CustomIconButton>
      ),
    },
    {
      tag: "number_list",
      component: () => (
        <CustomIconButton
          size="medium"
          onClick={() => editor.chain().focus().toggleOrderedList().run()}
          isActive={editor.isActive("orderedList")}
        >
          <FormatListNumberedIcon />
        </CustomIconButton>
      ),
    },
  ];

  const insertFormatButton = [
    {
      tag: "link",
      component: () => (
        <CustomIconButton
          size="medium"
          onClick={() =>
            setOpenInsertModal((prev) => ({
              ...prev,
              link: true,
            }))
          }
          isActive={editor.isActive("bold")}
        >
          <AddLinkIcon />
        </CustomIconButton>
      ),
    },
    {
      tag: "image",
      component: () => (
        <CustomIconButton
          size="medium"
          component="label"
          // onClick={() =>
          //   setOpenInsertModal((prev) => ({
          //     ...prev,
          //     link: true,
          //   }))
          // }
          // isActive={editor.isActive("bold")}
        >
          <AddPhotoAlternateIcon />
          <input
            hidden
            type="file"
            accept="image/png, image/jpeg, image/jpg"
            onChange={async (e) => {
              let file = e.target.files[0];

              const articleImageFormaData = new FormData();
              articleImageFormaData.append("articleImage", file);

              const uploadUrl = await articleAdminServices.uploadArticleImage(
                articleImageFormaData
              );

              if (uploadUrl?.url) {
                editor.commands.setImage({
                  src: `${process.env.REACT_APP_FILE_BASE_URL}${uploadUrl?.url}`,
                  alt: "A boring example image",
                  title: "An example",
                });
                setInsertedFiles((prev) => [...prev, uploadUrl.url]);
              }
            }}
          />
        </CustomIconButton>
      ),
    },
  ];

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        border: `1px solid ${theme.palette.neutral[50]}`,
        borderRadius: "10px",
        padding: "5px 0",
        flexWrap: "wrap",
        // justifyContent: "center",
      }}
    >
      {historyButton.map((historyFormat) => historyFormat.component())}

      <CustomDivider variant="middle" orientation="vertical" flexItem />

      <CustomMenu
        menuButtonText={
          editor.isActive("heading", { level: 1 })
            ? "Heading 1"
            : editor.isActive("heading", { level: 2 })
            ? "Heading 2"
            : editor.isActive("heading", { level: 3 })
            ? "Heading 3"
            : editor.isActive("heading", { level: 4 })
            ? "Heading 4"
            : editor.isActive("heading", { level: 5 })
            ? "Heading 5"
            : editor.isActive("heading", { level: 6 })
            ? "Heading 6"
            : "Paragraph"
        }
        buttonProps={{
          variant: "contained",
          sx: {
            "&.MuiButton-root": {
              marginRight: "5px",
              color: theme.palette.neutral[90],
              borderRadius: theme.borderRadius[10],
              backgroundColor: theme.palette.neutral[0],
              textTransform: "none",
              boxShadow: "none",
              "&:hover": {
                backgroundColor: theme.palette.neutral[10],
              },
            },
          },
          startIcon: <FormatSizeIcon />,
          endIcon: <KeyboardArrowDownIcon />,
        }}
        options={[
          {
            name: "Heading 1",
            onSelect: () =>
              editor.chain().focus().toggleHeading({ level: 1 }).run(),
            selected: editor.isActive("heading", { level: 1 }),
          },
          {
            name: "Heading 2",
            onSelect: () =>
              editor.chain().focus().toggleHeading({ level: 2 }).run(),
            selected: editor.isActive("heading", { level: 2 }),
          },
          {
            name: "Heading 3",
            onSelect: () =>
              editor.chain().focus().toggleHeading({ level: 3 }).run(),
            selected: editor.isActive("heading", { level: 3 }),
          },
          {
            name: "Heading 4",
            onSelect: () =>
              editor.chain().focus().toggleHeading({ level: 4 }).run(),
            selected: editor.isActive("heading", { level: 4 }),
          },
          {
            name: "Heading 5",
            onSelect: () =>
              editor.chain().focus().toggleHeading({ level: 5 }).run(),
            selected: editor.isActive("heading", { level: 5 }),
          },
          {
            name: "Heading 6",
            onSelect: () =>
              editor.chain().focus().toggleHeading({ level: 6 }).run(),
            selected: editor.isActive("heading", { level: 6 }),
          },
          {
            name: "Paragraph",
            onSelect: () => editor.chain().focus().setParagraph().run(),
            selected: editor.isActive("paragraph"),
          },
        ]}
      />

      <CustomDivider variant="middle" orientation="vertical" flexItem />

      {textFormatButton.map((textFormat) => textFormat.component())}

      <CustomDivider variant="middle" orientation="vertical" flexItem />

      {lineFormatButton.map((lineFormat) => lineFormat.component())}

      <CustomDivider variant="middle" orientation="vertical" flexItem />

      {insertFormatButton.map((insertFormat) => insertFormat.component())}

      {openInsertModal.link && (
        <InsertLink
          editor={editor}
          open={openInsertModal.link}
          setOpen={setOpenInsertModal}
        />
      )}
    </Box>
  );
};

export default ToolBar;
