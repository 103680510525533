import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { ReactComponent as Graduation } from "../../../assets/images/Graduation.svg";
import { ReactComponent as Growth } from "../../../assets/images/Growth.svg";
import { ReactComponent as Exams } from "../../../assets/images/Exams.svg";
import { ReactComponent as Fee } from "../../../assets/images/Fee.svg";
import { studentMembershipAdvantagePoints } from "../../../helpers/constant";

const studentMembership = () => {
  return (
    <Box
      sx={{
        padding: "20px 40px",
        height: "100%",

        // background: theme.palette.primary.litSurface,
      }}
    >
      <Box sx={{ marginBottom: "30px" }}>
        <Grid container justifyContent={"center"} alignItems={"center"}>
          <Grid item sx={12} sm={12} md={4} lg={4}>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Graduation style={{ maxWidth: "350px" }} />
            </Box>
          </Grid>
          <Grid item sx={12} sm={12} md={8} lg={8}>
            <Typography
              variant="h5"
              gutterBottom
              sx={{
                fontWeight: 600,
                marginBottom: "20px",
              }}
            >
              Student Insider Memberhip (SMIFRD)
            </Typography>
            <Typography variant="body1" gutterBottom>
              Embark on an inspiring journey towards academic excellence with
              the Student Insider Membership by IFR Discovery. Our membership is
              not just about research; it's about unlocking your potential and
              making your mark in the academic world. It's a chance to transform
              your ideas into impactful research articles, leaving your legacy
              in the scholarly community. Join us and let your passion for
              knowledge drive you forward. With our Student Insider Membership,
              you're not just a student; you're a future researcher, a
              trailblazer, and a change-maker. Start your journey today with our
              Student Insider Membership and let your brilliance shine.
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ marginBottom: "30px" }}>
        <Grid container justifyContent={"center"} alignItems={"center"}>
          <Grid item sx={12} sm={12} md={8} lg={8}>
            <Typography
              variant="h5"
              gutterBottom
              sx={{
                fontWeight: 600,
                marginBottom: "20px",
              }}
            >
              Exclusive Advantages
            </Typography>
            <ol>
              {studentMembershipAdvantagePoints.map((point) => (
                <li>{point.text}</li>
              ))}
            </ol>
          </Grid>
          <Grid item sx={12} sm={12} md={4} lg={4}>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Growth style={{ maxWidth: "350px" }} />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ marginBottom: "30px" }}>
        <Grid container justifyContent={"center"} alignItems={"center"}>
          <Grid item sx={12} sm={12} md={4} lg={4}>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Exams style={{ maxWidth: "350px" }} />
            </Box>
          </Grid>
          <Grid item sx={12} sm={12} md={8} lg={8}>
            <Typography
              variant="h5"
              gutterBottom
              sx={{
                fontWeight: 600,
                marginBottom: "20px",
              }}
            >
              Eligibility Criteria
            </Typography>
            <ol>
              <li>
                Any student currently pursuing or who has completed a
                professional academic degree, including Bachelor's and Master's
                degrees or PhD Degree in any academic discipline, is eligible.
              </li>
              <li>.No past research or Teaching experience required.</li>
            </ol>
          </Grid>
        </Grid>
      </Box>
      <Box>
        <Grid container justifyContent={"center"} alignItems={"center"}>
          <Grid item sx={12} sm={12} md={8} lg={8}>
            <Typography
              variant="h5"
              gutterBottom
              sx={{
                fontWeight: 600,
                marginBottom: "20px",
              }}
            >
              Fees
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              sx={{ marginBottom: "20px" }}
            >
              Our Student Insider Membership is offered at a nominal fee of
              5000INR or 80USD
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              sx={{ marginBottom: "20px" }}
            >
              Designed to be a student-friendly opportunity, it ensures that
              aspiring scholars can access a wealth of academic and research
              opportunities without incurring excessive costs. The transparent
              fee structure offers excellent value for the comprehensive
              benefits provided, allowing students to unlock their full
              potential without straining their finances. Join us today and
              invest in your academic future.
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              sx={{ marginBottom: "20px" }}
            >
              To join our Student Insider Membership, visit our 'Apply for
              Membership' page and complete the online form by providing the
              necessary information. Once the form has been submitted, our team
              will review your submitted form and get in touch with your
              communication email address if you are suitable or not for the
              membership and the further process.
            </Typography>
            <Typography variant="body1" gutterBottom>
              If you do not get any response within 24 hours from the time of
              online form submission, you can also notify to our official email
              address{" "}
              <a href={`mailto:${process.env.REACT_APP_IFRD_EMAIL}`}>
                {process.env.REACT_APP_IFRD_EMAIL}
              </a>
            </Typography>
          </Grid>
          <Grid item sx={12} sm={12} md={4} lg={4}>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Fee style={{ maxWidth: "350px" }} />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default studentMembership;
