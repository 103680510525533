import axiosInstance from "../../axiosInstance";

const deleteSubject = async ({ subjectId }) => {
  try {
    const res = await axiosInstance.delete(
      `/api/subject/delete?subjectId=${subjectId}`
    );

    if (res.status === 200) {
      return res.data;
    } else {
    }
  } catch (error) {}
};

export default deleteSubject;
