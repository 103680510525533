import {
  Box,
  Button,
  Divider,
  Grid,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import React from "react";
import OpenAccessLogoBlack from "../../../assets/images/OpenAccessLogoBlack.png";
import { useNavigate } from "react-router-dom";

const StyledSideBox = styled(Box, {
  shouldForwardProp: (prop) => ["needTopMargin"].indexOf(prop) === -1,
})(({ theme, needTopMargin = false }) => ({
  borderRadius: theme.borderRadius[10],
  boxShadow: theme.boxShadow.shadowTwo,
  border: `1px solid ${theme.palette.secondary.surface}`,
  padding: "10px",
  background: theme.palette.neutral[0],
  marginBottom: "15px",
}));

const StyledBadgeBox = styled(Box, {
  shouldForwardProp: (prop) => ["needTopMargin"].indexOf(prop) === -1,
})(({ theme, needTopMargin = false }) => ({
  padding: "5px 10px",
  borderRadius: "30px",
  background: theme.palette.neutral[10],
  marginRight: "10px",
}));

const JournalCard = ({ articleDetails, journalDetails }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  return (
    <StyledSideBox>
      <Box
        sx={{
          display: "flex",
        }}
      >
        {articleDetails.type && (
          <>
            <Typography
              variant="caption"
              gutterBottom
              sx={{
                wordWrap: "break-word",
              }}
            >
              {articleDetails.type}
            </Typography>
            <Divider
              orientation="vertical"
              flexItem
              sx={{ margin: "0 10px" }}
            />
          </>
        )}
        <Typography
          variant="caption"
          gutterBottom
          sx={{
            wordWrap: "break-word",
          }}
        >
          {`DOI: ${articleDetails.doi}`}
        </Typography>
        {journalDetails?.abbrKeyTitle && (
          <>
            <Divider
              orientation="vertical"
              flexItem
              sx={{ margin: "0 10px" }}
            />
            <Typography
              variant="caption"
              gutterBottom
              sx={{
                wordWrap: "break-word",
              }}
            >
              {`${journalDetails?.abbrKeyTitle}`}
            </Typography>
          </>
        )}
      </Box>
      <Box>
        <Typography
          variant="h5"
          sx={{
            fontWeight: 600,
            wordWrap: "break-word",
            cursor: "pointer",
            color: theme.palette.info.main,
          }}
          onClick={() => {
            navigate(`/article/${articleDetails._id}`);
          }}
        >
          {`${articleDetails.name}`}
        </Typography>
      </Box>
      <Box>
        <Typography
          variant="caption"
          gutterBottom
          sx={{
            wordWrap: "break-word",
          }}
        >
          {`${articleDetails.authors?.reduce((accumulator, authorObj) => {
            let name = `${authorObj.firstName}${
              authorObj.middleName ? ` ${authorObj.middleName}` : ""
            }${authorObj.lastName ? ` ${authorObj.lastName}` : ""}`;
            accumulator = accumulator ? `${accumulator}, ${name}` : name;
            return accumulator;
          }, "")}`}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          marginTop: "10px",
        }}
      >
        {articleDetails.startPage && articleDetails.endPage && (
          <StyledBadgeBox>
            <Typography
              variant="caption"
              gutterBottom
              sx={{
                wordWrap: "break-word",
              }}
            >
              {`Pages: ${articleDetails.startPage}-${articleDetails.endPage}`}
            </Typography>
          </StyledBadgeBox>
        )}
        {(articleDetails?.downloads > 0 ||
          articleDetails?.initialDownloadCount > 0) && (
          <StyledBadgeBox>
            <Typography
              variant="caption"
              gutterBottom
              sx={{
                wordWrap: "break-word",
              }}
            >
              {`Downloads: ${
                articleDetails?.downloads + articleDetails?.initialDownloadCount
              }`}
            </Typography>
          </StyledBadgeBox>
        )}
        {(articleDetails?.views > 0 ||
          articleDetails?.initialViewCount > 0) && (
          <StyledBadgeBox>
            <Typography
              variant="caption"
              gutterBottom
              sx={{
                wordWrap: "break-word",
              }}
            >
              {`Views: ${
                articleDetails?.views + articleDetails?.initialViewCount
              }`}
            </Typography>
          </StyledBadgeBox>
        )}
      </Box>
    </StyledSideBox>
  );
};

export default JournalCard;
