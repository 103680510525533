import { styled, TextareaAutosize } from "@mui/material";

const CustomTextArea = styled(TextareaAutosize)(({ theme }) => ({
  "& label.Mui-focused": {
    color: theme.palette.neutral[90],
  },
  "& .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: theme.palette.neutral[50],
  },
}));

export default CustomTextArea;
