import React from "react";
import { useTheme } from "@emotion/react";
import { Box, Button, Grid, Typography, styled } from "@mui/material";
import {
  aboutMembershipPoints,
  aboutMembershipSectionLinks,
} from "../../../helpers/constant";
import { ReactComponent as TeamWork1 } from "../../../assets/images/TeamWork1.svg";
import { useNavigate } from "react-router-dom";

const StyledBox = styled(Grid)(({ theme }) => ({
  width: "100%",
  height: "100%",
  // background: theme.palette.primary.main,
  borderRadius: theme.borderRadius[10],
  padding: "25px",
  boxShadow: theme.boxShadow.shadowThree,
  marginBottom: "10px",
  textAlign: "center",
}));

const AboutMembership = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        padding: "20px",
        height: "100%",

        // background: theme.palette.primary.litSurface,
      }}
    >
      <Typography
        variant="h5"
        gutterBottom
        sx={{
          fontWeight: 600,
          textAlign: "center",
          marginBottom: "20px",
        }}
      >
        About Membership
      </Typography>
      <Typography variant="body1" gutterBottom sx={{ textAlign: "center" }}>
        At IFR Discovery Publisher, a division of International IFRD LLP, we are
        committed to fostering a vibrant community of scholars, researchers, and
        contributors. Our membership program is designed to provide authors and
        researchers with valuable benefits and opportunities to enhance their
        academic and professional journey.
      </Typography>
      <Grid container justifyContent={"center"} alignItems={"center"}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <TeamWork1 style={{ maxWidth: "350px" }} />
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Typography
            variant="body1"
            gutterBottom
            sx={{
              marginBottom: "20px",
            }}
          >
            As a member of IFR Discovery Publisher, you can enjoy several
            exclusive privileges, including:
          </Typography>
          <ul>
            {aboutMembershipPoints.map((point) => (
              <li>{point.text}</li>
            ))}
          </ul>
        </Grid>
      </Grid>
      <Typography
        variant="body1"
        gutterBottom
        sx={{
          marginBottom: "20px",
          textAlign: "center",
        }}
      >
        Our motto, "Discover, Publish, Thrive," encapsulates our dedication to
        empowering academics to explore, share, and excel. Join our growing
        community of researchers and authors and unlock a world of opportunities
        for your scholarly contributions. Whether you are an emerging researcher
        or an established academic, IFR Discovery Publisher's membership program
        is tailored to meet your needs and facilitate your research
        dissemination.
      </Typography>
      <Typography
        variant="body1"
        gutterBottom
        sx={{
          marginBottom: "40px",
          textAlign: "center",
        }}
      >
        Discover, publish, and thrive with IFR Discovery Publisher's membership.
        Join us in the pursuit of knowledge and innovation.
      </Typography>
      <Typography
        variant="h5"
        gutterBottom
        sx={{
          fontWeight: 600,
          textAlign: "center",
          marginBottom: "20px",
        }}
      >
        Discover Your Membership Tier
      </Typography>
      <Typography
        variant="body1"
        gutterBottom
        sx={{
          marginBottom: "20px",
          textAlign: "center",
        }}
      >
        At IFR Discovery Publisher, we believe in recognizing the unique
        contributions of authors at every stage of their academic journey. Our
        membership program is thoughtfully divided into three distinct tiers,
        each tailored to celebrate your academic experience and contributions in
        research and development.
      </Typography>
      <Typography
        variant="body1"
        gutterBottom
        sx={{
          marginBottom: "50px",
          textAlign: "center",
        }}
      >
        Explore our membership tiers and find the one that best aligns with your
        scholarly path.
      </Typography>
      <Grid container spacing={2}>
        {aboutMembershipSectionLinks.map((section) => (
          <Grid item xs={12} sm={6} md={3} lg={3}>
            <StyledBox>
              <Typography
                variant="body1"
                gutterBottom
                sx={{
                  fontWeight: "600",
                  marginBottom: "20px",
                  textAlign: "center",
                }}
              >
                {section.name}
              </Typography>
              <Button
                sx={{
                  "&.MuiButton-root": {
                    color: theme.palette.neutral[90],
                    backgroundColor: theme.palette.primary.main,
                    textTransform: "none",
                    width: "100%",
                    borderRadius: "30px",
                    padding: "8px 20px",
                    "&:hover": {
                      backgroundColor: theme.palette.primary.hover,
                    },
                  },
                }}
                size="small"
                onClick={() => navigate(section.link)}
              >
                View Details
              </Button>
            </StyledBox>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default AboutMembership;
