import axiosInstance from "../../axiosInstance";

/**
 * Get Journal list for website
 * @param {*} subjectId
 * @param {*} sortBy
 * @param {*} sortDirection
 * @param {*} page
 * @param {*} limit
 * @returns
 */
const getJournalListWebsite = async ({
  subjectId = "",
  sortBy = "",
  sortDirection = "",
  page = 1,
  limit = "",
}) => {
  try {
    const res = await axiosInstance.get(
      `/api/journal/websiteJournalList?sortBy=${sortBy}&sortDirection=${sortDirection}&page=${page}&limit=${limit}&subjectId=${subjectId}`
    );

    if (res.status === 200) {
      return res.data;
    } else {
    }
  } catch (error) {}
};

export default getJournalListWebsite;
