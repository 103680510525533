import {
  Box,
  Button,
  CircularProgress,
  Grow,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  styled,
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import React, { useEffect, useState } from "react";
import subjectAdminServices from "../../../services/subject/admin";
import { subjectListTableColumn } from "../../../helpers/constant";
import { Navigate, useNavigate } from "react-router-dom";
import { CreateSubject } from "../../../components/adminDashboard/subject";
import { useTheme } from "@emotion/react";
import { CustomDialog, CustomIconMenu, Loader } from "../../../components";
import moment from "moment";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.surface,
    color: theme.palette.neutral[90],
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const StyledTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.info.main,
  cursor: "pointer",
}));

const WarningDescText = styled(Typography)(({ theme }) => ({
  color: theme.palette.neutral[90],
}));

const Subject = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const [createToggle, setCreateToggle] = useState();

  const [deleteSubject, setDeleteSubject] = useState({
    id: null,
    warningOpen: false,
    loading: false,
  });

  const [subjectList, setSubjectList] = useState({
    data: [],
    loading: false,
    refresh: false,
    filter: {
      sortBy: "",
      sortDirection: "",
      page: 0,
      rowsPerPage: 10,
      totalDocs: 0,
    },
  });

  const handleChangePage = (event, newPage) => {
    setSubjectList((prev) => ({
      ...prev,
      filter: {
        ...prev.filter,
        page: newPage,
      },
    }));
  };

  const handleChangeRowsPerPage = (event) => {
    setSubjectList((prev) => ({
      ...prev,
      filter: {
        ...prev.filter,
        rowsPerPage: event.target.value,
        page: 0,
      },
    }));
  };

  useEffect(() => {
    const fetchAllSubjects = async () => {
      setSubjectList((prev) => ({
        ...prev,
        loading: true,
      }));

      const reqAllSubjects = {
        sortBy: subjectList.filter.sortBy,
        sortDirection: subjectList.filter.sortDirection,
        page: subjectList.filter.page + 1,
        limit: subjectList.filter.rowsPerPage,
      };

      const resAllSubjects = await subjectAdminServices.getSubjectList(
        reqAllSubjects
      );

      if (resAllSubjects?.data?.docs?.length > 0) {
        setSubjectList((prev) => ({
          ...prev,
          data: resAllSubjects.data.docs,
          loading: false,
          filter: {
            ...prev.filter,
            totalDocs: resAllSubjects.data.totalDocs,
          },
        }));
      } else {
        setSubjectList((prev) => ({
          ...prev,
          data: [],
          loading: false,
        }));
      }
    };
    fetchAllSubjects();
  }, [
    subjectList.filter.page,
    subjectList.filter.rowsPerPage,
    subjectList.refresh,
    subjectList.filter.sortBy,
    subjectList.filter.sortDirection,
  ]);

  const rowActionOptions = (rowData) => [
    {
      name: "Delete",
      handleOption: () => {
        setDeleteSubject((prev) => ({
          ...prev,
          id: rowData._id,
          warningOpen: true,
        }));
      },
    },
  ];

  const onClickDeleteSubject = async () => {
    if (!deleteSubject?.id) {
      return;
    }
    setDeleteSubject((prev) => ({
      ...prev,
      loading: true,
    }));

    const deleteRes = await subjectAdminServices.deleteSubject({
      subjectId: deleteSubject?.id,
    });

    if (deleteRes?.data) {
      setSubjectList((prev) => ({
        ...prev,
        refresh: !prev.refresh,
      }));
      setDeleteSubject((prev) => ({
        ...prev,
        id: null,
        warningOpen: false,
        loading: false,
      }));
    } else {
      setDeleteSubject((prev) => ({
        ...prev,
        loading: false,
      }));
    }
  };

  return (
    <Box>
      {createToggle ? (
        <Grow in={createToggle}>
          <Box>
            <CreateSubject
              setCreateToggle={setCreateToggle}
              setSubjectList={setSubjectList}
            />
          </Box>
        </Grow>
      ) : (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            marginBottom: "10px",
          }}
        >
          <Button
            sx={{
              "&.MuiButton-root": {
                color: theme.palette.neutral[0],
                backgroundColor: theme.palette.secondary.main,
                textTransform: "none",

                "&:hover": {
                  backgroundColor: theme.palette.secondary.hover,
                },
              },
            }}
            size="small"
            onClick={() => setCreateToggle((prev) => !prev)}
          >
            Create Subject
          </Button>
        </Box>
      )}
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              {subjectListTableColumn?.map(
                (subjectListColumn, subjectListColumnIndex) => (
                  <StyledTableCell
                    size="small"
                    key={`${subjectListColumnIndex}_${subjectListColumn}`}
                  >
                    {subjectListColumn}
                  </StyledTableCell>
                )
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {subjectList?.loading ? (
              <StyledTableRow
                key={`subject_not_found`}
                sx={{ height: 30 * subjectList?.filter?.rowsPerPage }}
              >
                <StyledTableCell
                  scope="row"
                  colSpan={4}
                  sx={{ textAlign: "center" }}
                >
                  <Loader />
                </StyledTableCell>
              </StyledTableRow>
            ) : subjectList?.data?.length > 0 ? (
              subjectList?.data?.map((subjectRow) => (
                <StyledTableRow key={`${subjectRow._id}${subjectRow.name}`}>
                  <StyledTableCell size="small" component="th" scope="row">
                    <StyledTitle
                      onClick={() =>
                        navigate(`/admin/subject/edit/${subjectRow._id}`)
                      }
                    >
                      {subjectRow.name}
                    </StyledTitle>
                  </StyledTableCell>
                  <StyledTableCell size="small">
                    {subjectRow?.updatedBy}
                  </StyledTableCell>
                  <StyledTableCell size="small">
                    {moment(subjectRow?.updatedAt).format("DD-MM-YYYY")}
                  </StyledTableCell>
                  <StyledTableCell size="small">
                    <CustomIconMenu options={rowActionOptions(subjectRow)} />
                  </StyledTableCell>
                </StyledTableRow>
              ))
            ) : (
              <StyledTableRow key={`subject_not_found`}>
                <StyledTableCell
                  size="small"
                  scope="row"
                  colSpan={4}
                  sx={{ textAlign: "center" }}
                >
                  No available data
                </StyledTableCell>
              </StyledTableRow>
            )}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 100]}
          component="div"
          count={subjectList?.filter?.totalDocs}
          rowsPerPage={subjectList?.filter?.rowsPerPage}
          page={subjectList?.filter?.page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          showFirstButton
          showLastButton
          sx={{
            "& .MuiToolbar-root": {
              minHeight: "48px",
            },
          }}
        />
      </TableContainer>
      <CustomDialog
        open={deleteSubject.warningOpen}
        title={"Are you sure ?"}
        agreeButtonText={"Delete"}
        agreeButtonProp={{
          variant: "contained",
          color: "secondary",
          type: "button",
          endIcon: deleteSubject.loading && <CircularProgress size={20} />,
          disabled: deleteSubject.loading,
          onClick: () => onClickDeleteSubject(),
          size: "small",
          sx: {
            "&.MuiButton-root": {
              color: theme.palette.neutral[0],
              // width: "100%",
              borderRadius: theme.borderRadius[10],
              // backgroundColor: theme.palette.secondary.main,
              textTransform: "none",
              "&:hover": {
                backgroundColor: theme.palette.secondary.hover,
              },
            },
          },
        }}
        cancelButtonText={"Cancel"}
        cancelButtonProp={{
          variant: "contained",
          color: "primary",
          type: "button",
          disabled: deleteSubject.loading,
          onClick: () =>
            setDeleteSubject((prev) => ({
              ...prev,
              id: null,
              warningOpen: false,
              loading: false,
            })),
          size: "small",
          sx: {
            "&.MuiButton-root": {
              color: theme.palette.neutral[0],
              // width: "100%",
              borderRadius: theme.borderRadius[10],
              // backgroundColor: theme.palette.primary.main,
              textTransform: "none",
              "&:hover": {
                backgroundColor: theme.palette.primary.hover,
              },
            },
          },
        }}
      >
        <WarningDescText>
          Do you really want to delete this record? This process cannot be
          undone.
        </WarningDescText>
      </CustomDialog>
    </Box>
  );
};

export default Subject;
