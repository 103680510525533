import React, { useState } from "react";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";

import {
  Box,
  Button,
  CircularProgress,
  Grid,
  useTheme,
  Divider,
  Typography,
  IconButton,
} from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import { CustomTextField } from "../../../../components";

const AuthorAndAffiliation = ({ handleTabChange }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [saveLoader, setSaveLoader] = useState(false);

  const {
    control,
    // handleSubmit,
    formState: { errors },
    getValues,
  } = useFormContext();

  const authorFieldArray = useFieldArray({ control, name: "authors" });
  // const onSubmit = async (data, event) => {
  //   event.preventDefault();
  //   setSaveLoader(true);

  //   const reqSaveArticle = {
  //     ...data,
  //     issue: data.issue._id,
  //     journal: data.issue.journal._id,
  //     archive: data.issue.archive._id,
  //     articleId: searchParams.get("articleId") || "",
  //     type: data.type.name,
  //   };

  //   const articleFormData = new FormData();

  //   Object.entries(reqSaveArticle).forEach((formValue) => {
  //     articleFormData.append(formValue[0], formValue[1]);
  //   });

  //   const resSaveArticle = await articleAdminServices.createArticle(
  //     articleFormData
  //   );

  //   if (resSaveArticle) {
  //     toast.success("Article save successful");
  //     setSaveLoader(false);
  //     localStorage.removeItem("insertedArticleImages");
  //     // setImages((prev) => ({
  //     //   ...prev,
  //     //   inserted: [],
  //     // }));
  //     // navigate(`/admin/article?issueId=${issueOptions[0]?._id}`);
  //   } else {
  //     setSaveLoader(false);
  //   }
  // };

  return (
    <Box>
      <Grid container spacing={2}>
        {authorFieldArray?.fields?.map((authorField, authorFieldIndex) => (
          <Grid
            item
            container
            xs={12}
            spacing={2}
            alignItems={"end"}
            key={authorField.id}
          >
            <Grid item xs={12}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 600,
                  }}
                >
                  Author {authorFieldIndex + 1}
                </Typography>

                {authorFieldIndex > 0 && (
                  <IconButton
                    onClick={() => authorFieldArray.remove(authorFieldIndex)}
                    edge="end"
                    sx={{
                      background: theme.palette.neutral[0],
                      color: theme.palette.secondary.main,
                    }}
                  >
                    <i class="ri-delete-bin-2-fill ri-sm"></i>
                  </IconButton>
                )}
              </Box>
              <Divider variant="fullWidth" />
            </Grid>
            <Grid item xs={3}>
              <Controller
                name={`authors.${authorFieldIndex}.firstName`}
                control={control}
                render={({ field }) => (
                  <CustomTextField
                    label="First name *"
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    helperText={
                      errors?.authors?.[authorFieldIndex]?.firstName?.message
                    }
                    error={
                      errors?.authors?.[authorFieldIndex]?.firstName?.message
                    }
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={3}>
              <Controller
                name={`authors.${authorFieldIndex}.middleName`}
                control={control}
                render={({ field }) => (
                  <CustomTextField
                    label="Middle name"
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    helperText={
                      errors?.authors?.[authorFieldIndex]?.middleName?.message
                    }
                    error={
                      errors?.authors?.[authorFieldIndex]?.middleName?.message
                    }
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={3}>
              <Controller
                name={`authors.${authorFieldIndex}.lastName`}
                control={control}
                render={({ field }) => (
                  <CustomTextField
                    label="Last name"
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    helperText={
                      errors?.authors?.[authorFieldIndex]?.lastName?.message
                    }
                    error={
                      errors?.authors?.[authorFieldIndex]?.lastName?.message
                    }
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={3}>
              <Controller
                name={`authors.${authorFieldIndex}.email`}
                control={control}
                render={({ field }) => (
                  <CustomTextField
                    label="Email"
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    helperText={
                      errors?.authors?.[authorFieldIndex]?.email?.message
                    }
                    error={errors?.authors?.[authorFieldIndex]?.email?.message}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name={`authors.${authorFieldIndex}.affiliation`}
                control={control}
                render={({ field }) => (
                  <CustomTextField
                    label="Affiliation"
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    multiline
                    rows={5}
                    helperText={
                      errors?.authors?.[authorFieldIndex]?.affiliation?.message
                    }
                    error={
                      errors?.authors?.[authorFieldIndex]?.affiliation?.message
                    }
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}></Grid>
          </Grid>
        ))}
        <Grid item xs={12} md={3} lg={3}>
          <Button
            variant="contained"
            color="primary"
            sx={{
              "&.MuiButton-root": {
                color: theme.palette.neutral[90],
                borderRadius: theme.borderRadius[10],
                textTransform: "none",
                "&:hover": {
                  backgroundColor: theme.palette.primary.hover,
                },
              },
            }}
            size="small"
            type="button"
            disabled={saveLoader}
            onClick={() =>
              authorFieldArray.append({
                firstName: "",
                middleName: "",
                lastName: "",
                email: "",
                affiliation: "",
              })
            }
          >
            Add Author
          </Button>
        </Grid>
        <Grid item container xs={12} justifyContent={"flex-end"}>
          <Grid item xs={12} md={3} lg={3}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                sx={{
                  "&.MuiButton-root": {
                    marginTop: "20px",
                    marginRight: "10px",
                    color: theme.palette.neutral[90],
                    borderRadius: theme.borderRadius[10],
                    textTransform: "none",
                    "&:hover": {
                      backgroundColor: theme.palette.primary.hover,
                    },
                  },
                }}
                size="small"
                type="button"
                disabled={saveLoader}
                endIcon={saveLoader && <CircularProgress size={20} />}
                onClick={() => handleTabChange("basicDetails")}
              >
                Pervious
              </Button>
              <Button
                variant="contained"
                color="secondary"
                sx={{
                  "&.MuiButton-root": {
                    marginTop: "20px",
                    color: theme.palette.neutral[0],
                    borderRadius: theme.borderRadius[10],
                    textTransform: "none",
                    "&:hover": {
                      backgroundColor: theme.palette.secondary.hover,
                    },
                  },
                }}
                size="small"
                type="button"
                disabled={saveLoader}
                endIcon={saveLoader && <CircularProgress size={20} />}
                onClick={() => handleTabChange("abstract")}
              >
                Next
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AuthorAndAffiliation;
