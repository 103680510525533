import { Box, styled, Typography } from "@mui/material";
import React from "react";

const StyledBox = styled(Box)(({ theme }) => ({
  //   borderBottom: `1px solid ${theme.palette.neutral[50]}`,
  marginBottom: "20px",
}));

const ContactUs = () => {
  return (
    <Box
      sx={{
        padding: "20px 40px",
        height: "100%",
        // textAlign: "center",
      }}
    >
      <Box>
        <Typography
          variant="h5"
          gutterBottom
          sx={{
            marginBottom: "30px",
          }}
        >
          Contact Us
        </Typography>
        <StyledBox>
          <Typography variant="body1" gutterBottom>
            <strong>Publisher:</strong>
          </Typography>
          <Typography variant="body2" gutterBottom>
            INTERNATIONAL FEDERATION OF RESEARCH AND DISCOVERY (IFR Discovery)
          </Typography>
          <Typography variant="body2" gutterBottom>
            Office Address: Building no 7, Borda Street, Dahiat Al-Rasheed,
            Amman, Jordan, 11831
          </Typography>
          <Typography variant="body2" gutterBottom>
            Email:{" "}
            <a href={`mailto:${process.env.REACT_APP_IFRD_EMAIL}`}>
              {process.env.REACT_APP_IFRD_EMAIL}
            </a>
          </Typography>
        </StyledBox>
        <StyledBox>
          <Typography variant="body1" gutterBottom>
            <strong>Main Organization:</strong>
          </Typography>
          <Typography variant="body2" gutterBottom>
            IFRD INTERNATIONAL LLP
          </Typography>
          <Typography variant="body2" gutterBottom>
            Office Address: Sing Gaon, Udali, Lanka, Hojai, Nagaon-782446,
            Assam, India
          </Typography>
          <Typography variant="body2" gutterBottom>
            Email:{" "}
            <a href={`mailto:corporate@ifrdiscovery.org`}>
              corporate@ifrdiscovery.org
            </a>
          </Typography>
        </StyledBox>
        <StyledBox>
          <Typography variant="body1" gutterBottom>
            <strong>Contact Person 1:</strong>
          </Typography>
          <Typography variant="body2" gutterBottom>
            MAZID ALAM BARBHUYAN
          </Typography>
          <Typography variant="body2" gutterBottom>
            Address: Director / Publication Manager, IFRD INTERNATIONAL LLP,
            Udali, Lanka, Hojai, Assam, India, 782446
          </Typography>
          <Typography variant="body2" gutterBottom>
            Email:{" "}
            <a href={`mailto:Mazid@ifrdiscovery.org`}>Mazid@ifrdiscovery.org</a>
          </Typography>
        </StyledBox>
        <StyledBox>
          <Typography variant="body1" gutterBottom>
            <strong>Contact Person 2:</strong>
          </Typography>
          <Typography variant="body2" gutterBottom>
            ARIF UDDIN
          </Typography>
          <Typography variant="body2" gutterBottom>
            Address: Director, IFRD INTERNATIONAL LLP, Dakhin Udali, Lanka,
            Hojai, Assam, India, 782446
          </Typography>
          <Typography variant="body2" gutterBottom>
            Email:{" "}
            <a href={`mailto:Arif@ifrdiscovery.org`}>Arif@ifrdiscovery.org</a>
          </Typography>
        </StyledBox>
        <StyledBox>
          <Typography variant="body1" gutterBottom>
            <strong>Sales and Publication:</strong>
          </Typography>
          <Typography variant="body2" gutterBottom>
            Email:{" "}
            <a href={`mailto:support@ifrdiscovery.org`}>
              support@ifrdiscovery.org
            </a>
          </Typography>
          <Typography variant="body2" gutterBottom>
            Phone / WhatsApp: +91 6900487948
          </Typography>
        </StyledBox>
      </Box>
    </Box>
  );
};

export default ContactUs;
