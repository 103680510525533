import { Box, Button, Grid, Typography, styled, useTheme } from "@mui/material";
import React from "react";
import OpenAccessLogoBlack from "../../../assets/images/OpenAccessLogoBlack.png";
import { useNavigate } from "react-router-dom";

const StyledSideBox = styled(Box, {
  shouldForwardProp: (prop) => ["needTopMargin"].indexOf(prop) === -1,
})(({ theme, needTopMargin = false }) => ({
  borderRadius: theme.borderRadius[10],
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  boxShadow: theme.boxShadow.shadowTwo,
  // border: `1px solid ${theme.palette.secondary.surface}`,
  padding: "10px",
  background: theme.palette.neutral[10],
  height: "100%",
}));

const JournalCard = ({ journalDetails }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  return (
    <StyledSideBox>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Box
            sx={{
              maxWidth: "100%",
              borderRadius: theme.borderRadius[10],
              overflow: "hidden",
              display: "flex",
            }}
          >
            <img
              src={`${process.env.REACT_APP_FILE_BASE_URL}${journalDetails?.coverImgUrl}`}
              alt={`${journalDetails?.name}`}
              style={{
                width: "100%",
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              // padding: "10px 0",
              height: "100%",
            }}
          >
            <Box>
              <Typography
                variant="caption"
                gutterBottom
                sx={{
                  fontWeight: 600,
                  wordWrap: "break-word",
                }}
              >
                {journalDetails?.name}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                // justifyContent: "space-between",
                // height: "100%",
              }}
            >
              <img
                src={OpenAccessLogoBlack}
                alt="open access"
                style={{
                  width: "110px",
                }}
              />
              <Button
                sx={{
                  "&.MuiButton-root": {
                    color: theme.palette.neutral[90],
                    backgroundColor: theme.palette.primary.main,
                    textTransform: "none",
                    borderRadius: "30px",
                    // padding: "10px 20px",
                    // width: "100%",
                    marginTop: "10px",
                    "&:hover": {
                      backgroundColor: theme.palette.primary.hover,
                    },
                  },
                }}
                size="small"
                onClick={() =>
                  window.open(
                    `/journal/${journalDetails?.journalCustomId}`,
                    "_blank"
                  )
                }
              >
                View Journal
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </StyledSideBox>
  );
};

export default JournalCard;
