import React, { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import { publishWithIFRDiscoveryCards } from "../../../../helpers/constant";
import PublishWithCardInputFields from "./PublishWithCardInputFields";
import CustomTextField from "../../../CustomTextField";
import CustomReactSelect from "../../../CustomReactSelect";
import journalWebsiteServices from "../../../../services/journal/website";
import { useNavigate } from "react-router-dom";

const PublishWithCardSection = () => {
  const navigate = useNavigate();
  const [journalOptions, setJournalOptions] = useState([]);
  const [inputValues, setInputValues] = useState({
    journal: null,
    manuscriptId: "",
    subject: "",
  });
  const [errors, setErrors] = useState({
    journal: "",
    manuscriptId: "",
    subjects: "",
  });

  useEffect(() => {
    const fetchJournalOptions = async () => {
      const resJournalOptions =
        await journalWebsiteServices.getJournalListWebsite({
          subjectId: "",
          sortBy: "",
          sortDirection: "",
          page: "",
          limit: "",
        });

      setJournalOptions(resJournalOptions?.data?.docs);
    };
    fetchJournalOptions();
  }, []);

  return (
    <>
      {/* {publishWithIFRDiscoveryCards.map(
        (publishWithCard, publishWithCardIndex) => ( */}
      <Grid item xs={12} sm={6} md={6}>
        <PublishWithCardInputFields
          title={"Open Access Journal"}
          buttonText={"Submit Article"}
          buttonProps={{
            onClick: () => {
              if (!inputValues.journal?._id) {
                setErrors((prev) => ({
                  ...prev,
                  journal: "Journal is required",
                }));
                return;
              }
              navigate(`/article/submit?journalId=${inputValues.journal?._id}`);
            },
          }}
        >
          <CustomReactSelect
            // label={"Choose Journal"}
            value={inputValues?.journal}
            getOptionLabel={(e) => e.name}
            getOptionValue={(e) => e._id}
            options={journalOptions}
            onChange={(value) => {
              if (value) {
                setErrors((prev) => ({ ...prev, journal: "" }));
              }
              setInputValues((prev) => ({ ...prev, journal: value }));
            }}
            placeholder={"Choose Journal"}
            helperText={errors?.journal}
            error={errors?.journal}
            formControlProps={{
              sx: {
                width: "100%",
                margin: "20px 0 10px",
              },
            }}
            styles={{
              menu: (base) => ({
                ...base,
                zIndex: 5,
              }),
              control: (base) => ({
                ...base,
                borderRadius: "30px",
              }),
            }}
          />
        </PublishWithCardInputFields>
      </Grid>
      <Grid item xs={12} sm={6} md={6}>
        <PublishWithCardInputFields
          title={"Track Manuscript"}
          buttonText={"Get Details"}
          inputProps={{
            placeholder: "Type Manuscript No",
          }}
          buttonProps={{
            onClick: () => {
              if (!inputValues.manuscriptId) {
                setErrors((prev) => ({
                  ...prev,
                  manuscriptId: "Manuscript No. is required",
                }));
                return;
              }
              navigate(`/article/track?trackingId=${inputValues.manuscriptId}`);
            },
          }}
        >
          <CustomTextField
            fullWidth
            size="small"
            placeholder="Type Manuscript No"
            value={inputValues.manuscriptId}
            onChange={(e) => {
              if (e.target.value) {
                setErrors((prev) => ({ ...prev, manuscriptId: "" }));
              }
              setInputValues((prev) => ({
                ...prev,
                manuscriptId: e.target.value,
              }));
            }}
            error={errors?.manuscriptId}
            helperText={errors?.manuscriptId}
            sx={{
              "& .MuiInputBase-root": {
                borderRadius: "30px",
              },
              margin: "20px 0 10px",
            }}
          />
        </PublishWithCardInputFields>
      </Grid>
      {/* <Grid item xs={12} sm={12} md={4}>
        <PublishWithCardInputFields
          title={"Browse Subjects"}
          buttonText={"Submit"}
        >
          <CustomTextField
            fullWidth
            size="small"
            placeholder="Choose Subjects"
            // value={inputValues.manuscriptId}
            // onChange={(e) => {
            //   if (e.target.value) {
            //     setErrors((prev) => ({ ...prev, journal: "" }));
            //   }
            //   setInputValues((prev) => ({
            //     ...prev,
            //     manuscriptId: e.target.value,
            //   }));
            // }}
            sx={{
              "& .MuiInputBase-root": {
                borderRadius: "30px",
              },
              margin: "20px 0 10px",
            }}
          />
        </PublishWithCardInputFields>
      </Grid> */}
      {/* )
      )} */}
    </>
  );
};

export default PublishWithCardSection;
