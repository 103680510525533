import React, { useEffect, useState } from "react";
import ArticleSubmissionAddEditForm from "./ArticleSubmissionAddEditForm";
import { useSearchParams } from "react-router-dom";
import { Box } from "@mui/material";
import { Loader } from "../../../../components";
import articleSubmissionAdminServices from "../../../../services/articleSubmission/admin";

const AdminAddEditArticleSubmission = () => {
  const [searchParams] = useSearchParams();

  const [articleSubmissionDetails, setArticleSubmissionDetails] = useState({
    data: {
      authorName: "",
      phone: "",
      email: "",
      journal: "",
      articleType: "",
      manuscriptTitle: "",
      acknowledgement: {
        status: false,
        comment: "",
      },
      plagiarismsCheck: {
        status: false,
        comment: "",
      },
      editorAssigned: {
        status: false,
        comment: "",
      },
      reviewerAssigned: {
        status: false,
        comment: "",
      },
      approvedRejected: {
        status: false,
        comment: "",
      },
      payment: {
        status: false,
        comment: "",
      },
      published: {
        status: false,
        comment: "",
      },
    },
    loading: false,
    fetchFailed: false,
  });

  useEffect(() => {
    const fetchArticle = async () => {
      if (searchParams.get("articleSubmissionId")) {
        setArticleSubmissionDetails((prev) => ({
          ...prev,
          loading: true,
          fetchFailed: false,
        }));
        const resArticleSubmission =
          await articleSubmissionAdminServices.getArticleSubmissionById({
            articleSubmissionId: searchParams.get("articleSubmissionId"),
          });

        if (resArticleSubmission) {
          setArticleSubmissionDetails((prev) => ({
            ...prev,
            data: resArticleSubmission.data,
            loading: false,
            fetchFailed: false,
          }));
        } else {
          setArticleSubmissionDetails((prev) => ({
            ...prev,
            loading: false,
            fetchFailed: true,
          }));
        }
      }
    };
    fetchArticle();
  }, [searchParams]);

  return articleSubmissionDetails?.loading ? (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: "100vh",
      }}
    >
      <Loader />
    </Box>
  ) : (
    <Box>
      <ArticleSubmissionAddEditForm
        defaultFormValues={articleSubmissionDetails.data}
      />
    </Box>
  );
};

export default AdminAddEditArticleSubmission;
