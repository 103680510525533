import axiosInstance from "../../axiosInstance";

/**
 * Get article list
 * @param {*} page
 * @param {*} limit
 * @returns
 */
const getArticleListWebsite = async ({ issue = "", page = 1, limit = "" }) => {
  try {
    const res = await axiosInstance.get(
      `/api/article/websiteArticleList?issue=${issue}&page=${page}&limit=${limit}`
    );

    if (res.status === 200) {
      return res.data;
    } else {
    }
  } catch (error) {}
};

export default getArticleListWebsite;
