import axiosInstance from "../../axiosInstance";

const deleteEditor = async ({ editorId }) => {
  try {
    const res = await axiosInstance.delete(
      `/api/editor/delete?editorId=${editorId}`
    );

    if (res.status === 200) {
      return res.data;
    } else {
    }
  } catch (error) {}
};

export default deleteEditor;
