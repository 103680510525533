import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Button from "@mui/material/Button";
import { Box, Typography, styled, useTheme } from "@mui/material";

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  "& .MuiAccordionSummary-root": {
    "& .MuiAccordionSummary-content": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
  },
  //   color: theme.palette.info.main,
  //   cursor: "pointer",
}));

export default function CustomAccordion({
  title,
  titleButtonContainerSx,
  titleButtons,
  accordionProp,
  children,
}) {
  const theme = useTheme();

  return (
    <StyledAccordion {...accordionProp}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <Typography variant="body1">{title}</Typography>
        <Box sx={{ ...titleButtonContainerSx }}>
          {titleButtons?.length > 0 &&
            titleButtons.map((titleBtn, index) =>
              titleBtn.element({
                key: title
                  ? `${title}_${titleBtn?.name}_${index}`
                  : `${titleBtn?.name}_${index}`,
              })
            )}
        </Box>
        {/* {showTitleButton && <Button {...titleButtonProp}>{titleButton}</Button>} */}
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </StyledAccordion>
  );
}
