import axiosInstance from "../../axiosInstance";

const createEditor = async (data) => {
  try {
    const res = await axiosInstance.post(`/api/editor/create`, data);

    if (res.status === 201) {
      return res.data;
    } else {
    }
  } catch (error) {}
};

export default createEditor;
