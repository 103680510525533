import React from "react";
import { Box, Grid, Typography, useTheme } from "@mui/material";
import { ReactComponent as Professor2 } from "../../../assets/images/Professor2.svg";
import { ReactComponent as Success } from "../../../assets/images/Success.svg";
import { ReactComponent as Exams } from "../../../assets/images/Exams.svg";
import { ReactComponent as Fee } from "../../../assets/images/Fee.svg";
import {
  luminaryMembershipAdvantagePoints,
  luminaryMembershipEligibleCriteriaPoints,
} from "../../../helpers/constant";

const LuminaryMembership = () => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        padding: "20px 40px",
        height: "100%",

        // background: theme.palette.primary.litSurface,
      }}
    >
      <Box sx={{ marginBottom: "30px" }}>
        <Grid container justifyContent={"center"} alignItems={"center"}>
          <Grid item sx={12} sm={12} md={4} lg={4}>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Professor2 style={{ maxWidth: "350px" }} />
            </Box>
          </Grid>
          <Grid item sx={12} sm={12} md={8} lg={8}>
            <Typography
              variant="h5"
              gutterBottom
              sx={{
                fontWeight: 600,
                marginBottom: "20px",
              }}
            >
              Luminary Membership (LMIFRD)
            </Typography>
            <Typography variant="body1" gutterBottom>
              Embark on a journey of elevated possibilities with IFR Discovery's
              Luminary Membership. Here, your research enjoys a distinct edge,
              benefiting from expedited peer review and priority publication.
              Join a dynamic community of visionaries shaping the future of
              academia, where your contributions resonate beyond scholarly
              boundaries. As a Luminary Member, your work becomes a powerful
              catalyst for progress, leaving an enduring mark on the landscape
              of knowledge. Embrace this elevated path - become a Luminary
              Member and let your research journey inspire greatness in every
              academic endeavor.
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ marginBottom: "30px" }}>
        <Grid container justifyContent={"center"} alignItems={"center"}>
          <Grid item sx={12} sm={12} md={8} lg={8}>
            <Typography
              variant="h5"
              gutterBottom
              sx={{
                fontWeight: 600,
                marginBottom: "20px",
              }}
            >
              Exclusive Advantages
            </Typography>
            <ol>
              {luminaryMembershipAdvantagePoints.map((point) => (
                <li>{point.text}</li>
              ))}
            </ol>
          </Grid>
          <Grid item sx={12} sm={12} md={4} lg={4}>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Success style={{ maxWidth: "350px" }} />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ marginBottom: "30px" }}>
        <Grid container justifyContent={"center"} alignItems={"center"}>
          <Grid item sx={12} sm={12} md={4} lg={4}>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Exams style={{ maxWidth: "350px" }} />
            </Box>
          </Grid>
          <Grid item sx={12} sm={12} md={8} lg={8}>
            <Typography
              variant="h5"
              gutterBottom
              sx={{
                fontWeight: 600,
                marginBottom: "20px",
              }}
            >
              Eligibility Criteria
            </Typography>
            <ol>
              {luminaryMembershipEligibleCriteriaPoints?.map((point) => (
                <li>{point.text}</li>
              ))}
            </ol>
            <Typography
              variant="body1"
              gutterBottom
              sx={{
                marginBottom: "20px",
                "& span": {
                  color: theme.palette.danger.main,
                },
              }}
            >
              <em>
                <span>
                  <strong>Note:</strong>
                </span>{" "}
                If you meet any One of the four specified eligibility conditions
                outlined above, you are eligible to Apply for Membership.
              </em>
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box>
        <Grid container justifyContent={"center"} alignItems={"center"}>
          <Grid item sx={12} sm={12} md={8} lg={8}>
            <Typography
              variant="h5"
              gutterBottom
              sx={{
                fontWeight: 600,
                marginBottom: "20px",
              }}
            >
              Fees
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              sx={{ marginBottom: "20px" }}
            >
              Our Privileged Membership is offered at a nominal fee of 10,000INR
              or 170USD.
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              sx={{ marginBottom: "20px" }}
            >
              Crafted to be an accessible gateway to exclusive benefits, our
              Luminary Membership fee is designed with your convenience in mind.
              We've structured our fees to provide exceptional value, ensuring
              that you can harness a wealth of opportunities without bearing a
              hefty financial burden. With complete transparency, our fee
              structure offers comprehensive advantages that empower you to
              reach your highest potential without stretching your budget.
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              sx={{ marginBottom: "20px" }}
            >
              To join our ‘Privileged Membership’, visit our 'Apply for
              Membership' page and complete the online form by providing the
              necessary information. Once the form has been submitted, our team
              will review your submitted form and get in touch with your
              communication email address if you are suitable or not for the
              membership and the further process.
            </Typography>
            <Typography variant="body1" gutterBottom>
              If you do not get any response within 24 hours from the time of
              online form submission, you can notify to our Quick Action Team's
              official email address{" "}
              <a href={`mailto:${process.env.REACT_APP_IFRD_EMAIL}`}>
                {process.env.REACT_APP_IFRD_EMAIL}
              </a>
            </Typography>
          </Grid>
          <Grid item sx={12} sm={12} md={4} lg={4}>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Fee style={{ maxWidth: "350px" }} />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default LuminaryMembership;
