import axiosInstance from "../../axiosInstance";

/**
 * Get Journal list
 * @param {*} searchKey
 * @param {*} sortBy
 * @param {*} sortDirection
 * @param {*} page
 * @param {*} limit
 * @returns
 */
const getJournalList = async ({
  searchKey,
  sortBy,
  sortDirection,
  page,
  limit,
}) => {
  try {
    const res = await axiosInstance.get(
      `/api/journal/list?searchKey=${searchKey}&sortBy=${sortBy}&sortDirection=${sortDirection}&page=${page}&limit=${limit}`
    );

    if (res.status === 200) {
      return res.data;
    } else {
    }
  } catch (error) {}
};

export default getJournalList;
