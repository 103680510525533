import {
  Editor,
  Point,
  Element as SlateElement,
  Range,
  Transforms,
} from "slate";

const withTables = (editor) => {
  const { deleteBackward, deleteForward, insertBreak } = editor;

  // editor.deleteBackward = (unit) => {
  //   const { selection } = editor;

  //   if (selection && Range.isCollapsed(selection)) {
  //     const [cell] = Editor.nodes(editor, {
  //       match: (n) =>
  //         !Editor.isEditor(n) &&
  //         SlateElement.isElement(n) &&
  //         n.type === "table-cell",
  //     });

  //     if (cell) {
  //       const [, cellPath] = cell;
  //       const start = Editor.start(editor, cellPath);

  //       if (Point.equals(selection.anchor, start)) {
  //         return;
  //       }
  //     }
  //   }

  //   deleteBackward(unit);
  // };

  // editor.deleteForward = (unit) => {
  //   const { selection } = editor;

  //   if (selection && Range.isCollapsed(selection)) {
  //     const [cell] = Editor.nodes(editor, {
  //       match: (n) =>
  //         !Editor.isEditor(n) &&
  //         SlateElement.isElement(n) &&
  //         n.type === "table-cell",
  //     });

  //     if (cell) {
  //       const [, cellPath] = cell;
  //       const end = Editor.end(editor, cellPath);

  //       if (Point.equals(selection.anchor, end)) {
  //         return;
  //       }
  //     }
  //   }

  //   deleteForward(unit);
  // };

  editor.deleteBackward = (unit) => {
    const { selection } = editor;
    if (selection && Range.isCollapsed(selection)) {
      const [cell] = Editor.nodes(editor, {
        match: (n) =>
          !Editor.isEditor(n) &&
          SlateElement.isElement(n) &&
          n.type === "table-cell",
      });

      if (cell) {
        const [, cellPath] = cell;
        const start = Editor.start(editor, cellPath);

        if (Point.equals(selection.anchor, start)) {
          // Remove the content of the table cell element
          Transforms.select(editor, cellPath);
          editor.deleteFragment();

          // Remove the empty table cell element
          const [cellNode] = Editor.node(editor, cellPath);
          if (
            cellNode &&
            SlateElement.isElement(cellNode) &&
            cellNode.type === "table-cell" &&
            cellNode.children.length === 0
          ) {
            Editor.removeNode(editor, cellPath);
          }

          return;
        }
      }
    }

    deleteBackward(unit);
  };

  editor.deleteForward = (unit) => {
    const { selection } = editor;
    if (selection && Range.isCollapsed(selection)) {
      const [cell] = Editor.nodes(editor, {
        match: (n) =>
          !Editor.isEditor(n) &&
          SlateElement.isElement(n) &&
          n.type === "table-cell",
      });

      if (cell) {
        const [, cellPath] = cell;
        const end = Editor.end(editor, cellPath);

        if (Point.equals(selection.anchor, end)) {
          // Remove the content of the table cell element
          Transforms.select(editor, cellPath);
          editor.deleteFragment();

          // Remove the empty table cell element
          const [cellNode] = Editor.node(editor, cellPath);
          if (
            cellNode &&
            SlateElement.isElement(cellNode) &&
            cellNode.type === "table-cell" &&
            cellNode.children.length === 0
          ) {
            Editor.removeNode(editor, cellPath);
          }

          return;
        }
      }
    }

    deleteForward(unit);
  };

  editor.insertBreak = () => {
    const { selection } = editor;

    if (selection) {
      const [table] = Editor.nodes(editor, {
        match: (n) =>
          !Editor.isEditor(n) &&
          SlateElement.isElement(n) &&
          n.type === "table",
      });

      if (table) {
        return;
      }
    }

    insertBreak();
  };

  return editor;
};

export default withTables;
