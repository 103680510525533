import axiosInstance from "../../axiosInstance";

const createSubject = async (data) => {
  try {
    const res = await axiosInstance.post(`/api/subject/create`, data);

    if (res.status === 201) {
      return res.data;
    } else {
    }
  } catch (error) {}
};

export default createSubject;
