import getArticleListWebsite from "./getArticleListWebsite";
import getArticleByIdWebsite from "./getArticleByIdWebsite";
import downloadArticleXML from "./downloadArticleXML";

const articleWebsiteServices = {
  getArticleListWebsite,
  getArticleByIdWebsite,
  downloadArticleXML,
};

export default articleWebsiteServices;
