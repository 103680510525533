import React, { useCallback, useEffect, useState } from "react";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  CustomReactSelect,
  CustomTextField,
  Loader,
} from "../../../../components";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import { useDropzone } from "react-dropzone";
import journalAdminServices from "../../../../services/journal/admin";
import { toast } from "react-toastify";
import ReactSelect from "react-select";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import documentServices from "../../../../services/document";

const StyledDropZoneContainer = styled(Box)(({ theme }) => ({
  border: `1px dashed ${theme.palette.neutral[70]}`,
  background: theme.palette.neutral[10],
  width: "100%",
  height: "100%",
  borderRadius: theme.borderRadius[3],
  cursor: "pointer",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
  flexDirection: "column",
  padding: "10px",
  "& p": {
    fontSize: "12px",
  },
}));

const StyledScopeContainer = styled(Box)(({ theme }) => ({
  border: `1px dashed ${theme.palette.neutral[70]}`,
  background: theme.palette.neutral[0],
  width: "100%",
  height: "100%",
  borderRadius: theme.borderRadius[3],
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
  flexDirection: "column",
  padding: "10px",
}));

const AddEditJournal = ({ defaultFormValues, subjectOptions }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [saveLoader, setSaveLoader] = useState(false);
  const [journalCoverUploadLoading, setJournalCoverUploadLoading] =
    useState(false);

  const [files, setFiles] = useState({
    journalCover: null,
  });

  const [deleteFiles, setDeleteFiles] = useState([]);

  const journalValidationSchema = yup.object().shape({
    name: yup.string().required("name is required"),
    abstractDescription: yup.string(),
    subject: yup
      .array()
      .required("subject is required")
      .min(1, "Must have at least one subject")
      .of(
        yup.object().shape({
          name: yup.string().required("subject is required"),
        })
      ),
    coverImgUrl: yup.mixed().required("cover image is required"),
    abbrKeyTitle: yup.string().required("abbr. key title is required"),
    openAccessYear: yup.string(),
    issnOnline: yup.string(),
    issnOnlineLink: yup.string(),
    issnPrint: yup.string(),
    issnPrintLink: yup.string(),
    frequency: yup.string(),
    journalLanguage: yup.string(),
    origin: yup.string(),
    journalDescription: yup.string(),
    organizationDescription: yup.string(),
    aimsDescription: yup.string(),
    scopes: yup.array().of(
      yup.object().shape({
        name: yup.string().required("img url is required"),
      })
    ),
    indexing: yup.array().of(
      yup.object().shape({
        name: yup.string(),
        imgUrl: yup.mixed().required("img url is required"),
        redirectUrl: yup.string().required("img url is required"),
      })
    ),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
  } = useForm({
    defaultValues: defaultFormValues,
    resolver: yupResolver(journalValidationSchema),
  });

  const scopeFieldArray = useFieldArray({ control, name: "scopes" });
  const indexingFieldArray = useFieldArray({ control, name: "indexing" });

  const onSubmit = async (data) => {
    setSaveLoader(true);

    // save journal request
    const reqSaveJournal = {
      ...data,
      subject: data.subject.map((subject) => subject._id),
      journalId: searchParams.get("journalId") || "",
    };

    // delete journal related files if deleted
    if (deleteFiles.length > 0) {
      await documentServices.deleteDocument({
        deleteFilePaths: deleteFiles,
      });
    }

    // upload journal related files if newly added
    let uploadFiles = Object.entries(files);

    if (uploadFiles.some((file) => file[1])) {
      const formData = new FormData();

      uploadFiles.forEach((fileData) => {
        if (fileData[1]?.name) {
          formData.append(fileData[0], fileData[1]);
        }
      });

      // upload journal files api
      const uploadJournalCover =
        await journalAdminServices.journalFiles.uploadJournalCoverImage(
          formData
        );

      if (uploadJournalCover) {
        // map uploaded files url to journal request
        uploadJournalCover?.urls?.forEach((resFile) => {
          if (resFile.indexName === "journalCover") {
            reqSaveJournal["coverImgUrl"] = resFile.url;
            return;
          }

          reqSaveJournal.indexing[resFile.indexName].imgUrl = resFile.url;
        });
      }
    }

    const resSaveJournal = await journalAdminServices.createJournal(
      reqSaveJournal
    );
    if (resSaveJournal) {
      toast.success("Journal save successful");
      setSaveLoader(false);
      navigate("/admin/journal");
    } else {
      setSaveLoader(false);
    }
  };

  const onDrop = useCallback(async (acceptedFiles) => {
    if (acceptedFiles?.length > 0) {
      setJournalCoverUploadLoading(true);
      setFiles((prev) => ({
        ...prev,
        journalCover: acceptedFiles[0],
      }));
      setJournalCoverUploadLoading(false);
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: { "image/*": [".jpg", ".jpeg", ".png"] },
    multiple: false,
  });

  const handleDeleteJournalCoverImage = () => {
    if (files?.journalCover) {
      let cloneFiles = { ...files };
      cloneFiles.journalCover = null;
      setFiles(cloneFiles);
      return;
    }

    const currentCoverImgUrl = getValues("coverImgUrl");

    if (currentCoverImgUrl) {
      setDeleteFiles((prev) => [...prev, currentCoverImgUrl]);
      setValue("coverImgUrl", "");
    }
  };

  const handleDeleteIndexImage = (jArrayIndex, isImageDeleted = true) => {
    if (files?.[jArrayIndex]) {
      let cloneFiles = { ...files };
      delete cloneFiles[jArrayIndex];
      setFiles(cloneFiles);
      return;
    }

    const currentImgUrl = getValues(`indexing.${jArrayIndex}.imgUrl`);

    if (currentImgUrl) {
      setDeleteFiles((prev) => [...prev, currentImgUrl]);
    }

    if (isImageDeleted) {
      setValue(`indexing.${jArrayIndex}.imgUrl`, "");
    }
  };

  return (
    <Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item container xs={12} md={8} lg={8} spacing={2}>
            <Grid item xs={12}>
              <Controller
                name="name"
                control={control}
                render={({ field }) => (
                  <CustomTextField
                    label="Name *"
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    helperText={errors?.name?.message}
                    error={errors?.name?.message}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="abstractDescription"
                control={control}
                render={({ field }) => (
                  <CustomTextField
                    label="Abstract Description"
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    multiline
                    minRows={6}
                    maxRows={6}
                    helperText={errors?.abstractDescription?.message}
                    error={errors?.abstractDescription?.message}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="subject"
                control={control}
                render={({ field }) => (
                  <CustomReactSelect
                    label={"Subject *"}
                    getOptionLabel={(e) => e.name}
                    getOptionValue={(e) => e.name}
                    options={subjectOptions}
                    isMulti
                    placeholder={"Select a subject"}
                    styles={{
                      menu: (base) => ({
                        ...base,
                        zIndex: 99,
                      }),
                    }}
                    helperText={errors?.subject?.message}
                    error={errors?.subject?.message}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <Controller
                name="abbrKeyTitle"
                control={control}
                render={({ field }) => (
                  <CustomTextField
                    label="Abbreviated Key Title *"
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    helperText={errors?.abbrKeyTitle?.message}
                    error={errors?.abbrKeyTitle?.message}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <Controller
                name="openAccessYear"
                control={control}
                render={({ field }) => (
                  <CustomTextField
                    label="Open Access Year"
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    helperText={errors?.openAccessYear?.message}
                    error={errors?.openAccessYear?.message}
                    {...field}
                  />
                )}
              />
            </Grid>
          </Grid>

          <Grid item xs={12} md={4} lg={4}>
            {journalCoverUploadLoading ? (
              <StyledDropZoneContainer>
                <Loader />
              </StyledDropZoneContainer>
            ) : files.journalCover || getValues("coverImgUrl") ? (
              <StyledDropZoneContainer>
                <Box sx={{ position: "relative" }}>
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => handleDeleteJournalCoverImage()}
                    edge="end"
                    sx={{
                      position: "absolute",
                      top: -15,
                      right: -5,
                      zIndex: 1,
                      background: theme.palette.neutral[0],
                      color: theme.palette.secondary.main,
                    }}
                  >
                    <i class="ri-delete-bin-2-fill ri-sm"></i>
                  </IconButton>
                  <img
                    src={
                      files.journalCover
                        ? URL.createObjectURL(files.journalCover)
                        : `${process.env.REACT_APP_FILE_BASE_URL}${getValues(
                            "coverImgUrl"
                          )}`
                    }
                    alt="journal-logo"
                    style={{
                      maxWidth: "170px",
                    }}
                  />
                </Box>
              </StyledDropZoneContainer>
            ) : (
              <StyledDropZoneContainer {...getRootProps()}>
                <input {...getInputProps()} />
                {isDragActive ? (
                  <Box width={"100%"}>
                    <p>Drop the files here ...</p>
                  </Box>
                ) : (
                  <Box>
                    <h5>Upload journal cover Image</h5>
                    <p>
                      Drag 'n' drop some files here, or click to select files
                    </p>
                  </Box>
                )}
              </StyledDropZoneContainer>
            )}
          </Grid>

          <Grid item xs={12} md={4} lg={4}>
            <Controller
              name="issnOnline"
              control={control}
              render={({ field }) => (
                <CustomTextField
                  label="ISSN Online"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  helperText={errors?.issnOnline?.message}
                  error={errors?.issnOnline?.message}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={8} lg={8}>
            <Controller
              name="issnOnlineLink"
              control={control}
              render={({ field }) => (
                <CustomTextField
                  label="ISSN Online Url"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  helperText={errors?.issnOnlineLink?.message}
                  error={errors?.issnOnlineLink?.message}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <Controller
              name="issnPrint"
              control={control}
              render={({ field }) => (
                <CustomTextField
                  label="ISSN Print"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  helperText={errors?.issnPrint?.message}
                  error={errors?.issnPrint?.message}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={8} lg={8}>
            <Controller
              name="issnPrintLink"
              control={control}
              render={({ field }) => (
                <CustomTextField
                  label="ISSN Print Url"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  helperText={errors?.issnPrintLink?.message}
                  error={errors?.issnPrintLink?.message}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <Controller
              name="doi"
              control={control}
              render={({ field }) => (
                <CustomTextField
                  label="Doi"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  helperText={errors?.doi?.message}
                  error={errors?.doi?.message}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <Controller
              name="frequency"
              control={control}
              render={({ field }) => (
                <CustomTextField
                  label="Frequency"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  helperText={errors?.frequency?.message}
                  error={errors?.frequency?.message}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <Controller
              name="journalLanguage"
              control={control}
              render={({ field }) => (
                <CustomTextField
                  label="Language"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  helperText={errors?.journalLanguage?.message}
                  error={errors?.journalLanguage?.message}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <Controller
              name="origin"
              control={control}
              render={({ field }) => (
                <CustomTextField
                  label="Origin"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  helperText={errors?.origin?.message}
                  error={errors?.origin?.message}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <Controller
              name="journalDescription"
              control={control}
              render={({ field }) => (
                <CustomTextField
                  label="Journal Description"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  multiline
                  minRows={6}
                  helperText={errors?.journalDescription?.message}
                  error={errors?.journalDescription?.message}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <Controller
              name="organizationDescription"
              control={control}
              render={({ field }) => (
                <CustomTextField
                  label="Organization Description"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  multiline
                  minRows={6}
                  helperText={errors?.organizationDescription?.message}
                  error={errors?.organizationDescription?.message}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <Controller
              name="aimsDescription"
              control={control}
              render={({ field }) => (
                <CustomTextField
                  label="AIMS Description"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  multiline
                  minRows={6}
                  helperText={errors?.aimsDescription?.message}
                  error={errors?.aimsDescription?.message}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <Box>
              <Typography variant="p" gutterBottom>
                Scopes
              </Typography>
              <StyledScopeContainer>
                <Grid item container xs={12} md={12} lg={12} spacing={2}>
                  {scopeFieldArray?.fields?.length > 0 &&
                    scopeFieldArray?.fields?.map((scope, scopeIndex) => (
                      <Grid item xs={12} md={3} lg={3} key={scope.id}>
                        <Controller
                          name={`scopes.${scopeIndex}.name`}
                          control={control}
                          render={({ field }) => (
                            <CustomTextField
                              label=""
                              variant="outlined"
                              size="small"
                              type="text"
                              fullWidth
                              helperText={errors?.email?.message}
                              error={errors?.email?.message}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={() =>
                                        scopeFieldArray.remove(scopeIndex)
                                      }
                                      edge="end"
                                    >
                                      <i class="ri-delete-bin-2-fill"></i>
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                              {...field}
                            />
                          )}
                        />
                      </Grid>
                    ))}
                  <Grid item xs={12} md={3} lg={3}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        height: "100%",
                      }}
                    >
                      <Button
                        sx={{
                          "&.MuiButton-root": {
                            color: theme.palette.neutral[0],
                            width: "100%",

                            borderRadius: theme.borderRadius[10],
                            backgroundColor: theme.palette.secondary.main,
                            textTransform: "none",
                            "&:hover": {
                              backgroundColor: theme.palette.secondary.hover,
                            },
                          },
                        }}
                        size="small"
                        type="button"
                        onClick={() => scopeFieldArray.append({ name: "" })}
                      >
                        Add Scopes
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </StyledScopeContainer>
            </Box>
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <Box>
              <Typography variant="p" gutterBottom>
                Indexing
              </Typography>
              <StyledScopeContainer>
                <Grid item container xs={12} md={12} lg={12} spacing={2}>
                  {indexingFieldArray?.fields?.length > 0 &&
                    indexingFieldArray?.fields?.map((jIndex, jArrayIndex) => (
                      <Grid item xs={12} md={3} lg={3} key={jArrayIndex}>
                        <Box
                          sx={{ padding: "20px", position: "relative" }}
                          component={Paper}
                        >
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => {
                              handleDeleteIndexImage(jArrayIndex, false);
                              indexingFieldArray.remove(jArrayIndex);
                            }}
                            edge="end"
                            sx={{
                              position: "absolute",
                              top: -15,
                              right: -5,
                            }}
                          >
                            <i class="ri-delete-bin-2-fill"></i>
                          </IconButton>
                          <Controller
                            name={`indexing.${jArrayIndex}.name`}
                            control={control}
                            render={({ field }) => (
                              <CustomTextField
                                label="Name"
                                variant="outlined"
                                size="small"
                                type="text"
                                fullWidth
                                helperText={
                                  errors?.indexing?.[jArrayIndex]?.["name"]
                                    ?.message
                                }
                                error={
                                  errors?.indexing?.[jArrayIndex]?.["name"]
                                    ?.message
                                }
                                sx={{
                                  marginBottom: "10px",
                                }}
                                {...field}
                              />
                            )}
                          />
                          {files?.[jArrayIndex] ||
                          getValues(`indexing.${jArrayIndex}.imgUrl`) ? (
                            <Box sx={{ position: "relative" }}>
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={() =>
                                  handleDeleteIndexImage(jArrayIndex)
                                }
                                edge="end"
                                sx={{
                                  position: "absolute",
                                  top: -15,
                                  right: -5,
                                  zIndex: 1,
                                  background: theme.palette.neutral[0],
                                  color: theme.palette.secondary.main,
                                }}
                              >
                                <i class="ri-delete-bin-2-fill ri-sm"></i>
                              </IconButton>
                              <img
                                src={
                                  files?.[jArrayIndex]
                                    ? URL.createObjectURL(files?.[jArrayIndex])
                                    : `${
                                        process.env.REACT_APP_FILE_BASE_URL
                                      }${getValues(
                                        `indexing.${jArrayIndex}.imgUrl`
                                      )}`
                                }
                                alt="index-logo"
                                style={{
                                  maxWidth: "170px",
                                }}
                              />
                            </Box>
                          ) : (
                            <Button
                              sx={{
                                "&.MuiButton-root": {
                                  color: theme.palette.neutral[0],
                                  width: "100%",
                                  borderRadius: theme.borderRadius[10],
                                  backgroundColor: theme.palette.secondary.main,
                                  textTransform: "none",
                                  "&:hover": {
                                    backgroundColor:
                                      theme.palette.secondary.hover,
                                  },
                                },
                              }}
                              size="small"
                              type="button"
                              component="label"
                            >
                              select Image
                              <input
                                hidden
                                type="file"
                                accept="image/jpg, image/jpeg, image/png"
                                onChange={(e) => {
                                  setFiles((prev) => ({
                                    ...prev,
                                    [jArrayIndex]: e.target.files[0],
                                  }));
                                }}
                              />
                            </Button>
                          )}
                          {/* <Controller
                            name={`indexing.${jArrayIndex}.imgUrl`}
                            control={control}
                            render={({ field }) => (
                              <CustomTextField
                                label="Image URL"
                                variant="outlined"
                                size="small"
                                type="text"
                                fullWidth
                                helperText={
                                  errors?.indexing?.[jArrayIndex]["imgUrl"]
                                    ?.message
                                }
                                error={
                                  errors?.indexing?.[jArrayIndex]["imgUrl"]
                                    ?.message
                                }
                                sx={{
                                  marginBottom: "10px",
                                }}
                                {...field}
                              />
                            )}
                          /> */}
                          <Controller
                            name={`indexing.${jArrayIndex}.redirectUrl`}
                            control={control}
                            render={({ field }) => (
                              <CustomTextField
                                label="Redirect Url"
                                variant="outlined"
                                size="small"
                                type="text"
                                fullWidth
                                helperText={
                                  errors?.indexing?.[jArrayIndex]?.[
                                    "redirectUrl"
                                  ]?.message
                                }
                                error={
                                  errors?.indexing?.[jArrayIndex]?.[
                                    "redirectUrl"
                                  ]?.message
                                }
                                sx={{
                                  marginBottom: "10px",
                                }}
                                {...field}
                              />
                            )}
                          />
                        </Box>
                      </Grid>
                    ))}
                  <Grid item xs={12} md={3} lg={3}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        height: "100%",
                      }}
                    >
                      <Button
                        sx={{
                          "&.MuiButton-root": {
                            color: theme.palette.neutral[0],
                            width: "100%",

                            borderRadius: theme.borderRadius[10],
                            backgroundColor: theme.palette.secondary.main,
                            textTransform: "none",
                            "&:hover": {
                              backgroundColor: theme.palette.secondary.hover,
                            },
                          },
                        }}
                        size="small"
                        type="button"
                        onClick={() =>
                          indexingFieldArray.append({
                            name: "",
                            imgUrl: "",
                            redirectUrl: "",
                          })
                        }
                      >
                        Add Indexing
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </StyledScopeContainer>
            </Box>
          </Grid>
          <Grid item container xs={12}>
            <Grid item xs={12} md={3} lg={3}>
              <Button
                variant="contained"
                color="primary"
                sx={{
                  "&.MuiButton-root": {
                    marginTop: "20px",
                    color: theme.palette.neutral[90],
                    borderRadius: theme.borderRadius[10],
                    // backgroundColor: theme.palette.primary.main,
                    textTransform: "none",
                    "&:hover": {
                      backgroundColor: theme.palette.primary.hover,
                    },
                  },
                }}
                size="small"
                type="submit"
                disabled={saveLoader}
                endIcon={saveLoader && <CircularProgress size={20} />}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default AddEditJournal;
