import createSubject from "./createSubject";
import getSubjectList from "./getSubjectList";
import getSubjectById from "./getSubjectById";
import deleteSubject from "./deleteSubject";

const subjectAdminServices = {
  createSubject,
  getSubjectList,
  getSubjectById,
  deleteSubject,
};

export default subjectAdminServices;
