import createArchive from "./createArchive";
import getArchiveList from "./getArchiveList";
import deleteArchive from "./deleteArchive";

const archiveAdminServices = {
  createArchive,
  getArchiveList,
  deleteArchive,
};

export default archiveAdminServices;
