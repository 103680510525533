import React, { useCallback, useEffect, useState } from "react";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  CustomReactSelect,
  CustomTextField,
  Loader,
  SlateEditor,
  TiptapEditor,
} from "../../../../components";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import { useDropzone } from "react-dropzone";
import editorAdminServices from "../../../../services/editor/admin";
import { toast } from "react-toastify";
import ReactSelect from "react-select";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import documentServices from "../../../../services/document";
import serializeSlateToHtml from "../../../../components/SlateEditor/serializeSlateToHtml";
import { MuiTelInput } from "mui-tel-input";

const StyledDropZoneContainer = styled(Box)(({ theme }) => ({
  border: `1px dashed ${theme.palette.neutral[70]}`,
  background: theme.palette.neutral[10],
  width: "100%",
  height: "100%",
  borderRadius: theme.borderRadius[3],
  cursor: "pointer",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
  flexDirection: "column",
  padding: "10px",
  "& p": {
    fontSize: "12px",
  },
}));

const StyledScopeContainer = styled(Box)(({ theme }) => ({
  border: `1px dashed ${theme.palette.neutral[70]}`,
  background: theme.palette.neutral[0],
  width: "100%",
  height: "100%",
  borderRadius: theme.borderRadius[3],
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
  flexDirection: "column",
  padding: "10px",
}));

const AddEditEditor = ({
  defaultFormValues,
  journalOptions,
  designationOptions,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [saveLoader, setSaveLoader] = useState(false);

  const [editorImage, setEditorImage] = useState({
    file: null,
    url: defaultFormValues.editorImg
      ? `${process.env.REACT_APP_FILE_BASE_URL}${defaultFormValues.editorImg}`
      : "",
    deleted: "",
  });

  const editorValidationSchema = yup.object().shape({
    name: yup.string().required("name is required"),
    description: yup.string().required("description is required"),
    phone: yup.string().required("phone is required"),
    email: yup.string().required("email is required"),
    designation: yup.object().shape({
      value: yup.string().required("designation is required"),
    }),
    journal: yup.object().shape({
      _id: yup.string().required("journal is required"),
    }),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm({
    defaultValues: defaultFormValues,
    resolver: yupResolver(editorValidationSchema),
  });

  const onSubmit = async (data) => {
    setSaveLoader(true);

    if (editorImage.deleted) {
      await documentServices.deleteDocument({
        deleteFilePaths: [editorImage.deleted],
      });
      setEditorImage((prev) => ({
        ...prev,
        deleted: "",
      }));
    }

    const editorFormData = new FormData();

    editorFormData.append("name", data.name);
    editorFormData.append("description", data.description);
    editorFormData.append("phone", data.phone);
    editorFormData.append("email", data.email);
    editorFormData.append("designation", data.designation.value);
    editorFormData.append("journal", data.journal._id);
    editorFormData.append(
      "editorImg",
      editorImage?.file || defaultFormValues.editorImg || ""
    );

    if (searchParams.get("editorId")) {
      editorFormData.append("editorId", searchParams.get("editorId"));
    }

    const resSaveEditor = await editorAdminServices.createEditor(
      editorFormData
    );
    if (resSaveEditor) {
      toast.success("Editor save successful");
      setSaveLoader(false);
      navigate("/admin/editor");
    } else {
      setSaveLoader(false);
    }
  };

  const onDrop = useCallback(async (acceptedFiles) => {
    if (acceptedFiles?.length > 0) {
      setEditorImage((prev) => ({
        ...prev,
        file: acceptedFiles[0],
        url: URL.createObjectURL(acceptedFiles[0]),
      }));
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: { "image/*": [".jpg", ".jpeg", ".png"] },
    multiple: false,
  });
  return (
    <Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item container xs={12} spacing={2} alignItems={"end"}>
            <Grid item xs={12}>
              <Controller
                name="name"
                control={control}
                render={({ field }) => (
                  <CustomTextField
                    label="Name *"
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    helperText={errors?.name?.message}
                    error={errors?.name?.message}
                    {...field}
                  />
                )}
              />
            </Grid>

            <Grid item xs={6}>
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <CustomTextField
                    label="Email *"
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    helperText={errors?.email?.message}
                    error={errors?.email?.message}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="phone"
                control={control}
                render={({ field }) => (
                  <MuiTelInput
                    label="Phone Number with country Code *"
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    helperText={errors?.phone?.message}
                    error={errors?.phone?.message}
                    defaultCountry="IN"
                    sx={{
                      "& label": {
                        "&.Mui-focused": {
                          color: theme.palette.neutral[90],
                        },
                      },
                      "& .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                        {
                          borderColor: theme.palette.neutral[50],
                        },
                    }}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="designation"
                control={control}
                render={({ field }) => (
                  <CustomReactSelect
                    label={"Designation"}
                    getOptionLabel={(e) => e.name}
                    getOptionValue={(e) => e.name}
                    options={designationOptions}
                    placeholder={"Select a designation"}
                    styles={{
                      menu: (base) => ({
                        ...base,
                        zIndex: 10,
                      }),
                    }}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="journal"
                control={control}
                render={({ field }) => (
                  <CustomReactSelect
                    label={"Journal"}
                    getOptionLabel={(e) => e.name}
                    getOptionValue={(e) => e.name}
                    options={journalOptions}
                    placeholder={"Select a journal"}
                    styles={{
                      menu: (base) => ({
                        ...base,
                        zIndex: 10,
                      }),
                    }}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="description"
                control={control}
                render={({ field }) => (
                  <CustomTextField
                    label="Description *"
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    multiline
                    minRows={6}
                    maxRows={6}
                    helperText={errors?.description?.message}
                    error={errors?.description?.message}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              {editorImage.url ? (
                <StyledDropZoneContainer>
                  <Box sx={{ position: "relative" }}>
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() =>
                        setEditorImage((prev) => ({
                          ...prev,
                          file: null,
                          url: "",
                          deleted: editorImage.url.includes(
                            process.env.REACT_APP_FILE_BASE_URL
                          )
                            ? defaultFormValues.editorImg
                            : "",
                        }))
                      }
                      edge="end"
                      sx={{
                        position: "absolute",
                        top: -15,
                        right: -5,
                        zIndex: 1,
                        background: theme.palette.neutral[0],
                        color: theme.palette.secondary.main,
                      }}
                    >
                      <i class="ri-delete-bin-2-fill ri-sm"></i>
                    </IconButton>
                    <img
                      src={`${editorImage.url}`}
                      alt="editor-logo"
                      style={{
                        maxWidth: "170px",
                      }}
                    />
                  </Box>
                </StyledDropZoneContainer>
              ) : (
                <StyledDropZoneContainer {...getRootProps()}>
                  <input {...getInputProps()} />
                  {isDragActive ? (
                    <Box width={"100%"}>
                      <p>Drop the files here ...</p>
                    </Box>
                  ) : (
                    <Box>
                      <h5>Upload journal cover Image</h5>
                      <p>
                        Drag 'n' drop some files here, or click to select files
                      </p>
                    </Box>
                  )}
                </StyledDropZoneContainer>
              )}
            </Grid>
          </Grid>
          <Grid item container xs={12}>
            <Grid item xs={12} md={3} lg={3}>
              <Button
                variant="contained"
                color="primary"
                sx={{
                  "&.MuiButton-root": {
                    marginTop: "20px",
                    color: theme.palette.neutral[90],
                    borderRadius: theme.borderRadius[10],
                    textTransform: "none",
                    "&:hover": {
                      backgroundColor: theme.palette.primary.hover,
                    },
                  },
                }}
                size="small"
                type="submit"
                disabled={saveLoader}
                endIcon={saveLoader && <CircularProgress size={20} />}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default AddEditEditor;
