import { Box, Button, Grid, Typography, styled, useTheme } from "@mui/material";
import React from "react";

const StyledBox = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "100%",
  // background: theme.palette.primary.main,
  borderRadius: theme.borderRadius[10],
  padding: "25px",
  //   boxShadow: theme.boxShadow.shadowThree,
  marginBottom: "10px",
  textAlign: "center",
  display: "flex",
  justifyContent: "space-between",
}));

function SupportHubCard({
  title,
  image,
  description,
  flexDirection,
  isButton,
  buttonText,
}) {
  const theme = useTheme();
  return (
    <Grid
      container
      item
      xs={12}
      justifyContent={"center"}
      {...(flexDirection && {
        direction: "row-reverse",
      })}
      sx={{
        borderRadius: theme.borderRadius[10],
        // boxShadow: theme.boxShadow.shadowThree,
        // border: `1px solid ${theme.palette.neutral[0]}`,
      }}
    >
      <Grid item xs={12} lg={4}>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              width: "60%",
            }}
          >
            {image}
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} lg={8}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <Typography
            variant="p"
            gutterBottom
            sx={{
              fontWeight: 600,
            }}
          >
            {title}
          </Typography>

          {!isButton ? (
            <Typography variant="p" gutterBottom>
              {description}
            </Typography>
          ) : (
            <Button
              sx={{
                "&.MuiButton-root": {
                  color: theme.palette.neutral[0],
                  backgroundColor: theme.palette.secondary.main,
                  textTransform: "none",
                  borderRadius: "30px",
                  padding: "10px 20px",
                  marginLeft: "10px",
                  "&:hover": {
                    backgroundColor: theme.palette.secondary.hover,
                  },
                },
              }}
              size="small"
              // onClick={() => setCreateToggle((prev) => !prev)}
            >
              {buttonText}
            </Button>
          )}
        </Box>
      </Grid>
    </Grid>
  );
}

export default SupportHubCard;
