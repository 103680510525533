import axiosInstance from "../../axiosInstance";

const getIssueByIdWebsite = async ({ issueId }) => {
  try {
    const res = await axiosInstance.get(
      `/api/issue/websiteIssueView?issueId=${issueId}`
    );

    if (res.status === 200) {
      return res.data;
    } else {
    }
  } catch (error) {}
};

export default getIssueByIdWebsite;
