import React from "react";
import { Box, Typography, useTheme } from "@mui/material";

const DelegateMembership = () => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        // padding: "20px 40px",
        // height: "100%",

        // remove below lines after coming soon over
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100vh",
      }}
    >
      <Typography
        variant="h3"
        gutterBottom
        sx={{
          fontWeight: 600,
          marginTop: "20px",
          textAlign: "center",
          color: theme.palette.secondary.main,
        }}
      >
        Coming Soon!
      </Typography>
    </Box>
  );
};

export default DelegateMembership;
