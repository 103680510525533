import * as React from "react";
import { Box, Toolbar, useTheme } from "@mui/material";
import Header from "./Header";
import Footer from "./Footer";

function LandingPageLayout({ window, children }) {
  const theme = useTheme();
  return (
    <Box>
      <Header />
      <Box
        component="main"
        sx={{
          // p: 3,
          // background: theme.palette.neutral[10],
          width: "100%",
          minHeight: "100vh",
          // overflow: "auto",
          maxWidth: "1600px",
          margin: "0 auto",
        }}
      >
        <Toolbar />
        {children}
      </Box>
      <Footer />
    </Box>
  );
}

export default LandingPageLayout;
