import { styled, TextField } from "@mui/material";

const CustomTextField = styled(TextField)(({ theme }) => ({
  "& label": {
    // color: theme.palette.secondary.main,
    "&.Mui-focused": {
      color: theme.palette.neutral[90],
    },
  },
  "& .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: theme.palette.neutral[50],
  },
}));

export default CustomTextField;
