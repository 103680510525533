import { styled } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";

const CustomDatePicker = styled(DatePicker)(({ theme }) => ({
  "& label": {
    // color: theme.palette.secondary.main,
    "&.Mui-focused": {
      color: theme.palette.neutral[90],
    },
  },
  "& .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: theme.palette.neutral[50],
  },
}));

export default CustomDatePicker;
