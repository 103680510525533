import React, { useEffect, useState } from "react";
import { AdminDashboardLayout } from "../containers";
import { Outlet, Navigate } from "react-router-dom";
import userServices from "../services/user";
import { Loader } from "../components";
import { Box } from "@mui/material";
import { useTheme } from "@emotion/react";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserById, resetUser } from "../redux/slices/user";

function AuthRoute() {
  const { user, userError } = useSelector((state) => state.userReducer);
  const theme = useTheme();
  const dispatch = useDispatch();
  const [userLoading, setUserLoading] = useState(true);

  useEffect(() => {
    setUserLoading(true);
    const userId = localStorage.getItem("userId");
    dispatch(fetchUserById({ userId }));
    setUserLoading(false);
  }, [dispatch]);

  return userLoading ? (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: "100vh",
      }}
    >
      <Loader />
    </Box>
  ) : !userLoading && !user?._id && userError ? (
    <Navigate to={"/admin/login"} />
  ) : (
    <AdminDashboardLayout>
      <Outlet />
    </AdminDashboardLayout>
  );
}

export default AuthRoute;
