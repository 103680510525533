import axiosInstance from "../../axiosInstance";

/**
 * Get Subject list
 * @param {*} sortBy
 * @param {*} sortDirection
 * @param {*} page
 * @param {*} limit
 * @returns
 */
const getSubjectList = async ({
  sortBy = "",
  sortDirection = "",
  page = 1,
  limit = "",
}) => {
  try {
    const res = await axiosInstance.get(
      `/api/subject/list?sortBy=${sortBy}&sortDirection=${sortDirection}&page=${page}&limit=${limit}`
    );

    if (res.status === 200) {
      return res.data;
    } else {
    }
  } catch (error) {}
};

export default getSubjectList;
