import axiosInstance from "../../axiosInstance";

/**
 * Get all article Submissions
 * @param {*} trackingId
 * @param {*} page
 * @param {*} limit
 * @returns
 */
const getAllArticleSubmission = async ({ trackingId, page, limit }) => {
  try {
    const res = await axiosInstance.get(
      `/api/articleSubmission/list?trackingId=${trackingId}&page=${page}&limit=${limit}`
    );

    if (res.status === 200) {
      return res.data;
    } else {
    }
  } catch (error) {}
};

export default getAllArticleSubmission;
