// src/Tiptap.jsx
import {
  useEditor,
  EditorContent,
  FloatingMenu,
  BubbleMenu,
} from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import ToolBar from "./ToolBarEditor/ToolBar";
import Link from "@tiptap/extension-link";
import Image from "@tiptap/extension-image";
import ImageResize from "tiptap-extension-resize-image";
// import ListItem from "@tiptap/extension-list-item";
// import OrderedList from "@tiptap/extension-ordered-list";
import Highlight from "@tiptap/extension-highlight";
import Underline from "@tiptap/extension-underline";
import Subscript from "@tiptap/extension-subscript";
import Superscript from "@tiptap/extension-superscript";
import TextAlign from "@tiptap/extension-text-align";
import { styled, useTheme } from "@mui/material";

// const TipTapContainer = styled(Box)(({ theme, isActive }) => ({
//   // borderRadius: "10px",
//   // border: `1px solid ${theme.palette.secondary.main}`,
//   // // padding: "10px",
// }));

const CustomImageTag = Image.extend({
  isolating: true,
});

const TipTapEditorContent = styled(EditorContent)(({ theme }) => ({
  marginTop: "10px",
  "& .ProseMirror": {
    border: `1px solid ${theme.palette.neutral[50]}`,
    borderRadius: "10px",
    padding: "5px",
    "&.ProseMirror-focused": {
      border: `1px solid red`,
    },
  },
}));

// define your extension array
const extensions = [
  StarterKit,
  // ImageResize,
  Link.configure({
    autolink: true,
  }),
  CustomImageTag,
  Highlight,
  Underline,
  Subscript,
  Superscript,
  TextAlign.configure({
    types: ["heading", "paragraph"],
    alignments: ["left", "center", "right", "justify"],
  }),

  // configure({
  //   shouldShow: ({ editor, view, state, oldState, from, to }) => {
  //     // only show the bubble menu for images and links
  //     return editor.isActive("image");
  //   },
  // }),
];

const defaultContent = "<p></p>";

const TiptapEditor = ({ value, onChange, setInsertedFiles }) => {
  const theme = useTheme();
  const editor = useEditor({
    extensions,
    content: value ? value : defaultContent,
    editorProps: {
      attributes: {
        style: {
          border: `1px solid ${theme.palette.neutral[70]}`,
        },
      },
    },
    // onTransaction: ({ editor, transaction }) => {
    //   if (editor.isActive("image")) {
    //     console.log("exe");
    //     return;
    //   }
    // },
    onUpdate: ({ editor, transaction }) => {
      // console.log(transaction);
      // console.log(editor.getJSON());
      const html = editor.getHTML();
      // console.log(editor.getAttributes("image"));
      onChange(html);
    },
  });

  return (
    <>
      <ToolBar editor={editor} setInsertedFiles={setInsertedFiles} />
      <TipTapEditorContent editor={editor} />
      <BubbleMenu
        editor={editor}
        shouldShow={({ editor, view, state, oldState, from, to }) => {
          // only show the bubble menu for images and links
          return editor.isActive("image");
        }}
      >
        This is the bubble menu
      </BubbleMenu>
      {/* <FloatingMenu editor={editor}>This is the floating menu</FloatingMenu> */}
    </>
  );
};

export default TiptapEditor;
