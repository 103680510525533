import { Box, Button, Typography, styled, useTheme } from "@mui/material";
import React from "react";

const StyledBox = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "100%",
  // background: theme.palette.primary.main,
  borderRadius: theme.borderRadius[10],
  padding: "25px",
  //   boxShadow: theme.boxShadow.shadowThree,
  marginBottom: "10px",
  textAlign: "center",
  //   display: "flex",
  //   justifyContent: "center",
}));

function PublishWithCardDisplay({ title, image, description }) {
  const theme = useTheme();
  return (
    <StyledBox>
      <Box>{image}</Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography
          variant="p"
          gutterBottom
          sx={{
            fontWeight: 600,
          }}
        >
          {title}
        </Typography>

        <Typography variant="p" gutterBottom>
          {description}
        </Typography>
      </Box>
    </StyledBox>
  );
}

export default PublishWithCardDisplay;
