import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Typography,
  InputAdornment,
  Button,
  useTheme,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  styled,
  StepConnector,
} from "@mui/material";
import { CustomTextField } from "../../../components";
import articleSubmissionWebsiteServices from "../../../services/articleSubmission/website";
import { useSearchParams } from "react-router-dom";
import { articleSubmissionTrackingTags } from "../../../helpers/constant";

const CustomIconBox = styled(Box)(({ theme, ownerState }) => ({
  backgroundColor: ownerState.error
    ? theme.palette.danger.main
    : ownerState.active
    ? theme.palette.primary.main
    : theme.palette.neutral[30],
  zIndex: 1,
  color: "#fff",
  width: 30,
  height: 30,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
}));

const CustomStepConnector = styled(StepConnector)(({ theme }) => ({
  marginLeft: "15px",
}));

function CustomStepIconComponent(props) {
  const { active, completed, error } = props;
  // const icons: { [index: string]: React.ReactElement } = {
  //   1: <SettingsIcon />,
  //   2: <GroupAddIcon />,
  //   3: <VideoLabelIcon />,
  // };

  return (
    <CustomIconBox ownerState={{ active, completed, error }}>
      <i className="ri-footprint-fill"></i>
    </CustomIconBox>
  );
}

const TrackSubmitArticle = () => {
  const theme = useTheme();
  const [searchParams] = useSearchParams();
  const [activeStep, setActiveStep] = useState(0);
  const [trackingInputId, setTrackingInputId] = useState(
    searchParams.get("trackingId") || ""
  );
  const [trackingDetails, setTrackingDetails] = useState({
    data: [],
    originalTrackingId: "",
    loading: false,
    noDataFound: false,
  });

  const fetchTrackingDetails = useCallback(
    async (trackingId) => {
      setTrackingDetails((prev) => ({
        ...prev,
        loading: true,
      }));
      const resTrackingDetails =
        await articleSubmissionWebsiteServices.trackArticleSubmission({
          trackingId: trackingId ? trackingId : searchParams.get("trackingId"),
        });

      if (resTrackingDetails.data) {
        let modifiedData = articleSubmissionTrackingTags?.map(
          (trackingObj) => ({
            name: trackingObj.name,
            ...resTrackingDetails?.data[trackingObj.tag],
          })
        );

        setTrackingDetails((prev) => ({
          ...prev,
          data: modifiedData,
          originalTrackingId: resTrackingDetails?.data?.trackingId,
          loading: false,
        }));
      } else {
        setTrackingDetails((prev) => ({
          ...prev,
          loading: false,
        }));
      }
    },
    [searchParams.get("trackingId")]
  );

  useEffect(() => {
    if (searchParams.get("trackingId")) {
      fetchTrackingDetails();
    }
  }, [fetchTrackingDetails]);

  const handleTrackingIdSubmit = () => {
    fetchTrackingDetails(trackingInputId);
  };

  return (
    <Box
      sx={{
        padding: "20px 5%",
        height: "100%",
      }}
    >
      <Typography
        variant="h5"
        gutterBottom
        sx={{
          fontWeight: 600,
          marginBottom: "30px",
        }}
      >
        Stay on Course: Track Your Manuscript's Journey!
      </Typography>
      <Box sx={{ marginBottom: "10px", maxWidth: 500 }}>
        <CustomTextField
          placeholder="Type your Manuscript number. Example: IFRJMS0005"
          size="small"
          value={trackingInputId}
          onChange={(event) => setTrackingInputId(event.target.value)}
          fullWidth
          InputProps={{
            endAdornment: (
              <InputAdornment position="center">
                <i
                  className="ri-search-2-line ri-lg"
                  style={{ marginLeft: "5px" }}
                ></i>
              </InputAdornment>
            ),
          }}
          sx={{
            "& .MuiInputBase-root": {
              borderRadius: "30px",
              background: theme.palette.neutral[0],
            },
          }}
        />
      </Box>
      <Box sx={{ marginBottom: "30px" }}>
        <Button
          sx={{
            "&.MuiButton-root": {
              color: theme.palette.neutral[90],
              backgroundColor: theme.palette.primary.main,
              textTransform: "none",
              borderRadius: "30px",
              padding: "8px 20px",
              "&:hover": {
                backgroundColor: theme.palette.primary.hover,
              },
            },
          }}
          // size="small"
          onClick={() => handleTrackingIdSubmit()}
        >
          Get Details
        </Button>
      </Box>
      <Box sx={{ height: "100%" }}>
        {trackingDetails?.noDataFound && (
          <Typography
            variant="body1"
            gutterBottom
            sx={{
              fontWeight: 600,
              marginBottom: "30px",
            }}
          >
            {`No footprints of your tracking id.`}
          </Typography>
        )}
        {!trackingDetails?.noDataFound && trackingDetails?.data?.length > 0 && (
          <>
            <Typography
              variant="body1"
              gutterBottom
              sx={{
                fontWeight: 600,
                marginBottom: "30px",
              }}
            >
              {`Hurry!!! We found the footprints of your ${trackingDetails.originalTrackingId}.`}
            </Typography>
            <Box sx={{ maxWidth: 400 }}>
              <Stepper
                orientation="vertical"
                connector={<CustomStepConnector />}
              >
                {trackingDetails.data.map((step, index) => (
                  <Step key={`${step.name}${index}`} active={step.status}>
                    <StepLabel
                      StepIconComponent={CustomStepIconComponent}
                      StepIconProps={{
                        error: step.status === "REJECT" ? true : false,
                      }}
                    >
                      {step.name}
                    </StepLabel>
                    <StepContent
                      sx={{
                        marginLeft: "15px",
                        ...(step.status === "REJECT" && {
                          color: theme.palette.danger.main,
                        }),
                      }}
                    >
                      <Typography>{step.comment}</Typography>
                    </StepContent>
                  </Step>
                ))}
              </Stepper>
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};

export default TrackSubmitArticle;
