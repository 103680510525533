import React, { useEffect } from "react";
import { Box, Grid, styled, Typography, useTheme } from "@mui/material";
import { ReactComponent as Guidelines } from "../../../assets/images/Guidelines.svg";
import { ReactComponent as ArticleEditor } from "../../../assets/images/ArticleEditor.svg";
import {
  authGuidelinesArticlePoints,
  editorialGuidelinesPoints,
  luminaryMembershipAdvantagePoints,
  luminaryMembershipEligibleCriteriaPoints,
} from "../../../helpers/constant";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchJournalById } from "../../../redux/slices/webJournal";

const MainManuscriptOl = styled("ol")(({ theme }) => ({
  counterReset: "manuscript",

  "& > li": {
    counterIncrement: "manuscript",
  },
}));

const OrganizationOl = styled("ol")(({ theme }) => ({
  counterReset: "organization",
  listStyle: "none",
  margin: "0",
  paddingLeft: "23px",

  "& > li": {
    marginBottom: "20px",
    display: "table",
    counterIncrement: "organization",
    listStyleType: "none",

    "&:before": {
      display: "table-cell",
      content: 'counters(manuscript, ".") ". " counters(organization, ".")',
      paddingRight: "0.6em",
      width: "40px",
    },
  },
}));

const EditorGuidelines = () => {
  const dispatch = useDispatch();
  const { journalId } = useParams();

  useEffect(() => {
    if (journalId) {
      dispatch(fetchJournalById({ journalCustomId: journalId }));
    }
  }, [journalId, dispatch]);

  return (
    <Box
      sx={{
        padding: "20px 40px",
        height: "100%",
        wordBreak: "break-word",
        // background: theme.palette.primary.litSurface,
      }}
    >
      <Box sx={{ marginBottom: "30px" }}>
        <Typography
          variant="h5"
          gutterBottom
          sx={{
            fontWeight: 600,
            marginBottom: "20px",
          }}
        >
          EDITORIAL GUIDELINES
        </Typography>
        <Grid container justifyContent={"center"} alignItems={"center"}>
          <Grid item sx={12} sm={12} md={7} lg={7}>
            <Typography
              //   variant="h5"
              gutterBottom
              sx={{
                fontWeight: 600,
              }}
            >
              <strong>WHAT IS OUR PEER REVIEW MODEL?</strong>
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              sx={{
                marginBottom: "20px",
              }}
            >
              At IFR Discovery, we utilize a Double Blind Peer Review model.
              This process ensures that both the authors and the reviewers
              remain anonymous to each other throughout the review process. This
              confidentiality helps to eliminate potential biases that could
              arise from personal relationships, reputations, or institutional
              affiliations. By focusing solely on the content of the manuscript,
              this model aims to maintain fairness and objectivity in the
              evaluation process. The double-blind system ensures that decisions
              about publication are based entirely on the quality of the
              research, free from external influences.
            </Typography>
          </Grid>
          <Grid item sx={12} sm={12} md={5} lg={5}>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <ArticleEditor style={{ maxWidth: "250px" }} />
            </Box>
          </Grid>
        </Grid>
      </Box>
      {editorialGuidelinesPoints.map((guideline) => (
        <Box sx={{ marginBottom: "30px", textAlign: "justify" }}>
          <Typography
            gutterBottom
            sx={{
              fontWeight: 600,
            }}
          >
            <strong>{guideline.title}</strong>
          </Typography>
          {guideline?.description && (
            <Typography
              variant="body1"
              gutterBottom
              sx={{
                marginBottom: "20px",
              }}
            >
              {guideline.description}
            </Typography>
          )}
          {guideline?.points?.length > 0 && (
            <ul
              style={{
                ...(guideline?.listStyle && {
                  paddingLeft: 0,
                }),
              }}
            >
              {guideline?.points?.map((point) => (
                <li
                  style={{
                    marginBottom: "20px",
                    ...(guideline?.listStyle && {
                      listStyle: "none",
                    }),
                  }}
                >
                  <Typography variant="body1" gutterBottom>
                    {point.title}
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    {point.description}
                  </Typography>
                </li>
              ))}
            </ul>
          )}

          {guideline.subTitle && (
            <Typography gutterBottom>{guideline.subTitle}</Typography>
          )}
          {guideline?.subDescription && (
            <Typography
              variant="body1"
              gutterBottom
              sx={{
                marginBottom: "20px",
              }}
            >
              {guideline.subDescription}
            </Typography>
          )}
          {guideline?.subPoints?.length > 0 && (
            <ul>
              {guideline?.subPoints?.map((subPoint) => (
                <li
                  style={{
                    marginBottom: "20px",
                  }}
                >
                  <Typography variant="body1" gutterBottom>
                    {subPoint.title}
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    {subPoint.description}
                  </Typography>
                </li>
              ))}
            </ul>
          )}
          {guideline.conclusion && (
            <Typography variant="body1" gutterBottom>
              {guideline.conclusion}
            </Typography>
          )}
        </Box>
      ))}
      <Box sx={{ textAlign: "justify" }}>
        <Typography
          gutterBottom
          sx={{
            fontWeight: 600,
          }}
        >
          <strong>KEEP IN TOUCH </strong>
        </Typography>
        <Typography
          variant="body1"
          gutterBottom
          sx={{
            marginBottom: "20px",
          }}
        >
          Maintaining open communication with the editorial office is crucial
          throughout the review process. If you encounter any issues, have
          questions, or need to discuss any aspect of the review, contact the
          editorial team promptly. Effective communication helps ensure a smooth
          and efficient review process and allows for timely resolution of any
          issues that may arise. Your proactive engagement contributes to the
          overall quality and efficiency of the peer review process.
        </Typography>
        <Typography
          variant="body1"
          gutterBottom
          sx={{
            marginBottom: "20px",
          }}
        >
          Thank you for your commitment to upholding the high standards of
          research published in the Journals of IFR Discovery. Your expertise
          and dedication play a vital role in maintaining the quality and
          integrity of our publications. We appreciate your valuable
          contribution to the academic community.
        </Typography>
      </Box>
    </Box>
  );
};

export default EditorGuidelines;
