import axiosInstance from "../../axiosInstance";

/**
 * Update article analysis
 * @param {*} article
 * @param {*} download
 * @param {*} view
 * @returns
 */
const updateArticleAnalysis = async (data) => {
  try {
    const res = await axiosInstance.post(
      `/api/articleAnalysis/websiteUpdateArticleAnalysis`,
      data
    );

    if (res.status === 201) {
      return res.data;
    } else {
    }
  } catch (error) {}
};

export default updateArticleAnalysis;
