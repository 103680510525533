const colorPalette = {
  primary: {
    main: "#06eb83",
    hover: "#05bc69",
    pressed: "#04a55c",
    surface: "#b4fddc",
    litSurface: "#e6fef3",
  },
  secondary: {
    main: "#ff5722",
    hover: "#e64e1f",
    pressed: "#b33d18",
    surface: "#ffc5b3",
  },
  neutral: {
    0: "#ffffff",
    10: "#e7e7e8",
    20: "#d0d0d0",
    30: "#b8b8b9",
    40: "#a1a0a1",
    50: "#89898a",
    60: "#717172",
    70: "#5a595b",
    80: "#424143",
    90: "#2b2a2c",
    100: "#131214",
  },
  danger: {
    main: "#E00000",
    surface: "#FFECEC",
    border: "#FFD7D7",
    hover: "#A20000",
    pressed: "#630000",
  },
  warning: {
    main: "#EA780E",
    surface: "#FFF2E7",
    border: "#FFCA99",
    hover: "#D16603",
    pressed: "#653608",
  },
  success: {
    main: "#008243",
    surface: "#EBF5F0",
    border: "#B3DAC7",
    hover: "#006836",
    pressed: "#005029",
  },
  info: {
    main: "#0B5CD7",
    surface: "#ECF2FC",
    border: "#B6CFF3",
    hover: "#044DBA",
    pressed: "#043C8F",
  },
};

export default colorPalette;
