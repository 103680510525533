import React, { useState } from "react";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";

import {
  Box,
  Button,
  CircularProgress,
  Grid,
  useTheme,
  Divider,
  Typography,
  IconButton,
  styled,
  InputAdornment,
  Paper,
} from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import { CustomTextField, SlateEditor } from "../../../../components";

const ArticleCitation = ({ handleTabChange }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [saveLoader, setSaveLoader] = useState(false);

  const {
    control,
    // handleSubmit,
    formState: { errors },
    getValues,
  } = useFormContext();

  const citeFieldArray = useFieldArray({ control, name: "citeArticle" });

  // const onSubmit = async (data, event) => {
  //   event.preventDefault();
  //   setSaveLoader(true);

  //   const reqSaveArticle = {
  //     ...data,
  //     issue: data.issue._id,
  //     journal: data.issue.journal._id,
  //     archive: data.issue.archive._id,
  //     articleId: searchParams.get("articleId") || "",
  //     type: data.type.name,
  //   };

  //   const articleFormData = new FormData();

  //   Object.entries(reqSaveArticle).forEach((formValue) => {
  //     articleFormData.append(formValue[0], formValue[1]);
  //   });

  //   const resSaveArticle = await articleAdminServices.createArticle(
  //     articleFormData
  //   );

  //   if (resSaveArticle) {
  //     toast.success("Article save successful");
  //     setSaveLoader(false);
  //     localStorage.removeItem("insertedArticleImages");
  //     // setImages((prev) => ({
  //     //   ...prev,
  //     //   inserted: [],
  //     // }));
  //     // navigate(`/admin/article?issueId=${issueOptions[0]?._id}`);
  //   } else {
  //     setSaveLoader(false);
  //   }
  // };

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item container xs={12}>
          <Grid item container spacing={2} xs={12}>
            <Grid item xs={12}>
              <Typography gutterBottom>Cite this article</Typography>
            </Grid>
            {citeFieldArray?.fields?.length > 0 &&
              citeFieldArray?.fields?.map((cite, citeIndex) => (
                <Grid item xs={12} md={6} lg={6} key={cite.id}>
                  <Box
                    sx={{
                      padding: "20px",
                      position: "relative",
                      // background: theme.palette.neutral[10],
                    }}
                    component={Paper}
                  >
                    <Controller
                      name={`citeArticle.${citeIndex}.citeDescription`}
                      control={control}
                      render={({ field }) => (
                        <CustomTextField
                          label={cite.name}
                          variant="outlined"
                          size="small"
                          type="text"
                          fullWidth
                          multiline
                          rows={5}
                          helperText={
                            errors?.citeArticle?.[citeIndex]?.[
                              "citeDescription"
                            ]?.message
                          }
                          error={
                            errors?.citeArticle?.[citeIndex]?.[
                              "citeDescription"
                            ]?.message
                          }
                          sx={{
                            marginBottom: "10px",
                          }}
                          {...field}
                        />
                      )}
                    />
                  </Box>
                </Grid>
              ))}
          </Grid>
        </Grid>

        <Grid item container xs={12} justifyContent={"flex-end"}>
          <Grid item xs={12} md={3} lg={3}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                sx={{
                  "&.MuiButton-root": {
                    marginTop: "20px",
                    marginRight: "10px",
                    color: theme.palette.neutral[90],
                    borderRadius: theme.borderRadius[10],
                    textTransform: "none",
                    "&:hover": {
                      backgroundColor: theme.palette.primary.hover,
                    },
                  },
                }}
                size="small"
                type="button"
                disabled={saveLoader}
                endIcon={saveLoader && <CircularProgress size={20} />}
                onClick={() => handleTabChange("reference")}
              >
                Pervious
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ArticleCitation;
