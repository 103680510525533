import axiosInstance from "../../axiosInstance";

const downloadArticleSubmissionCopyright = async (data) => {
  try {
    const res = await axiosInstance.post(
      `/api/articleSubmission/downloadArticleCopyright`,
      data
    );

    if (res.status === 200) {
      return res.data;
    } else {
    }
  } catch (error) {}
};

export default downloadArticleSubmissionCopyright;
