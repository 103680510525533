import React, { useEffect, useState } from "react";
// import CustomAccordion from "../../CustomAccordion";
import {
  Box,
  Button,
  CircularProgress,
  // Button,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  // TablePagination,
  TableRow,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { useNavigate } from "react-router-dom";
import { issueListTableColumn } from "../../../helpers/constant";
import { CreateIssue } from "./index";
import issueAdminServices from "../../../services/issue/admin";
import moment from "moment";
import CustomIconMenu from "../../CustomIconMenu";
import CustomDialog from "../../CustomDialog";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.surface,
    color: theme.palette.neutral[90],
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const StyledTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.info.main,
  cursor: "pointer",
}));

const WarningDescText = styled(Typography)(({ theme }) => ({
  color: theme.palette.neutral[90],
  "& strong": {
    color: theme.palette.danger.main,
  },
}));

const IssueListDisplay = ({ journalDetails, archiveDetails }) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const [openCreateIssue, setOpenCreateIssue] = useState(false);

  const [deleteIssue, setDeleteIssue] = useState({
    id: null,
    warningOpen: false,
    loading: false,
  });

  const [issueList, setIssueList] = useState({
    data: [],
    loading: false,
    filter: {
      searchKey: "",
      sortBy: "",
      sortDirection: "",
      page: 0,
      rowsPerPage: 10,
    },
    refresh: false,
  });

  const [currentIssueSelected, setCurrentIssueSelected] = useState(null);

  useEffect(() => {
    const fetchAllArchives = async () => {
      if (journalDetails?._id) {
        setIssueList((prev) => ({
          ...prev,
          loading: true,
        }));

        const reqAllArchive = {
          journal: journalDetails?._id,
          archive: archiveDetails?._id,
          sortBy: issueList.filter.sortBy,
          sortDirection: issueList.filter.sortDirection,
          page: issueList.filter.page + 1,
          limit: issueList.filter.rowsPerPage,
        };

        const resAllArchive = await issueAdminServices.getIssueList(
          reqAllArchive
        );

        if (resAllArchive?.data?.docs?.length > 0) {
          setIssueList((prev) => ({
            ...prev,
            data: resAllArchive.data.docs,
            loading: false,
          }));
        } else {
          setIssueList((prev) => ({
            ...prev,
            data: [],
            loading: false,
          }));
        }
      }
    };
    fetchAllArchives();
  }, [
    journalDetails?._id,
    issueList.filter.sortBy,
    issueList.filter.sortDirection,
    issueList.filter.page,
    issueList.filter.rowsPerPage,
    issueList.refresh,
    archiveDetails?._id,
  ]);

  const rowActionOptions = (rowData) => [
    {
      name: "Edit",
      handleOption: () => {
        setCurrentIssueSelected(rowData);
        setOpenCreateIssue(true);
      },
    },
    {
      name: "Delete",
      handleOption: () => {
        setDeleteIssue((prev) => ({
          ...prev,
          id: rowData._id,
          warningOpen: true,
        }));
      },
    },
  ];

  const onClickDeleteIssue = async () => {
    if (!deleteIssue?.id) {
      return;
    }
    setDeleteIssue((prev) => ({
      ...prev,
      loading: true,
    }));

    const deleteRes = await issueAdminServices.deleteIssue({
      issueId: deleteIssue?.id,
    });

    if (deleteRes?.data) {
      setIssueList((prev) => ({
        ...prev,
        refresh: !prev.refresh,
      }));
      setDeleteIssue((prev) => ({
        ...prev,
        id: null,
        warningOpen: false,
        loading: false,
      }));
    } else {
      setDeleteIssue((prev) => ({
        ...prev,
        loading: false,
      }));
    }
  };

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          marginBottom: "10px",
        }}
      >
        <Button
          sx={{
            "&.MuiButton-root": {
              color: theme.palette.neutral[90],
              backgroundColor: theme.palette.primary.main,
              textTransform: "none",

              "&:hover": {
                backgroundColor: theme.palette.primary.hover,
              },
            },
          }}
          size="small"
          onClick={() => setOpenCreateIssue(true)}
        >
          Create Issue
        </Button>
      </Box>
      <TableContainer component={Paper}>
        <Table aria-label="customized table">
          <TableHead>
            <TableRow>
              {issueListTableColumn?.map(
                (journalListColumn, journalListColumnIndex) => (
                  <StyledTableCell
                    size="small"
                    key={`${journalListColumnIndex}_${journalListColumn}`}
                  >
                    {journalListColumn}
                  </StyledTableCell>
                )
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {issueList?.data?.map((issueRow) => (
              <StyledTableRow key={`${issueRow._id}${issueRow.name}`}>
                <StyledTableCell size="small" component="th" scope="row">
                  <StyledTitle
                    onClick={() =>
                      navigate(`/admin/article?issueId=${issueRow._id}`)
                    }
                  >
                    {issueRow.name}
                  </StyledTitle>
                </StyledTableCell>
                <StyledTableCell size="small">
                  {issueRow?.archive?.name}
                </StyledTableCell>
                <StyledTableCell size="small">
                  {issueRow?.journal?.name}
                </StyledTableCell>
                <StyledTableCell size="small">
                  {issueRow?.updatedBy}
                </StyledTableCell>
                <StyledTableCell size="small">
                  {moment(issueRow?.updatedAt).format("DD-MM-YYYY")}
                </StyledTableCell>
                <StyledTableCell size="small">
                  <CustomIconMenu options={rowActionOptions(issueRow)} />
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
        {/* <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={journalList?.data?.length}
          rowsPerPage={journalList?.filter?.rowsPerPage}
          page={journalList?.filter?.page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            "& .MuiToolbar-root": {
              minHeight: "48px",
            },
          }}
        /> */}
      </TableContainer>
      <CreateIssue
        open={openCreateIssue}
        setOpenCreateIssue={setOpenCreateIssue}
        archiveDetails={archiveDetails}
        journalDetails={journalDetails}
        issueDetails={currentIssueSelected}
        setIssueDetails={setCurrentIssueSelected}
        setIssueList={setIssueList}
      />
      <CustomDialog
        open={deleteIssue.warningOpen}
        title={"Are you sure ?"}
        agreeButtonText={"Delete"}
        agreeButtonProp={{
          variant: "contained",
          type: "button",
          color: "secondary",
          endIcon: deleteIssue.loading && <CircularProgress size={20} />,
          disabled: deleteIssue.loading,
          onClick: () => onClickDeleteIssue(),
          size: "small",
          sx: {
            "&.MuiButton-root": {
              color: theme.palette.neutral[0],
              // width: "100%",
              borderRadius: theme.borderRadius[10],
              textTransform: "none",
              "&:hover": {
                backgroundColor: theme.palette.secondary.hover,
              },
            },
          },
        }}
        cancelButtonText={"Cancel"}
        cancelButtonProp={{
          variant: "contained",
          type: "button",
          color: "primary",
          disabled: deleteIssue.loading,
          onClick: () =>
            setDeleteIssue((prev) => ({
              ...prev,
              id: null,
              warningOpen: false,
              loading: false,
            })),
          size: "small",
          sx: {
            "&.MuiButton-root": {
              color: theme.palette.neutral[0],
              // width: "100%",
              borderRadius: theme.borderRadius[10],
              textTransform: "none",
              "&:hover": {
                backgroundColor: theme.palette.primary.hover,
              },
            },
          },
        }}
      >
        <WarningDescText>
          Do you really want to delete this Issue? This will{" "}
          <strong>
            <em>delete all records ( articles ) related to this Issue</em>
          </strong>{" "}
          and process cannot be undone.
        </WarningDescText>
      </CustomDialog>
    </Box>
  );
};

export default IssueListDisplay;
