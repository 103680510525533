import React from "react";
import { useTheme } from "@emotion/react";
import { Box, Button, Grid, Typography, styled } from "@mui/material";
import { FaqPoints, careersPoints, TCPoints } from "../../../helpers/constant";
import * as DOMPurify from "dompurify";
import htmlParser from "html-react-parser";

const StyledBox = styled(Grid)(({ theme }) => ({
  width: "100%",
  height: "100%",
  // background: theme.palette.primary.main,
  borderRadius: theme.borderRadius[10],
  padding: "25px",
  boxShadow: theme.boxShadow.shadowThree,
  marginBottom: "10px",
  textAlign: "center",
}));

const Careers = () => {
  return (
    <Box
      sx={{
        padding: "20px 40px",
        height: "100%",
        textAlign: "justify",
        // background: theme.palette.primary.litSurface,
      }}
    >
      <Typography
        variant="h5"
        gutterBottom
        sx={{
          fontWeight: 600,
          marginBottom: "20px",
        }}
      >
        Careers at IFRD INTERNATIONAL LLP
      </Typography>

      {careersPoints.map((careersPoint) => (
        <Box
          sx={{
            marginBottom: "30px",
          }}
        >
          <Typography
            variant="body1"
            gutterBottom
            sx={{
              fontWeight: 600,
              marginBottom: "10px",
            }}
          >
            {careersPoint.title}
          </Typography>
          {careersPoint.description && (
            <Typography variant="body1" gutterBottom>
              {htmlParser(DOMPurify.sanitize(careersPoint.description))}
            </Typography>
          )}
          {careersPoint?.points?.length > 0 && (
            <ol>
              {careersPoint?.points?.map((cPoint) => (
                <li>
                  {cPoint.textTitle && (
                    <Typography
                      variant="body1"
                      gutterBottom
                      sx={{
                        marginBottom: "10px",
                        fontWeight: 600,
                      }}
                    >
                      {cPoint.textTitle}
                    </Typography>
                  )}
                  <Typography
                    variant="body1"
                    gutterBottom
                    sx={{
                      marginBottom: "20px",
                    }}
                  >
                    {htmlParser(DOMPurify.sanitize(cPoint.text))}
                  </Typography>
                </li>
              ))}
            </ol>
          )}
        </Box>
      ))}
    </Box>
  );
};

export default Careers;
