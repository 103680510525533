import React, { useEffect, useMemo, useState } from "react";
import articleSubmissionAdminServices from "../../../services/articleSubmission/admin";
import {
  Box,
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
  tableCellClasses,
  useTheme,
  TablePagination,
} from "@mui/material";
import { articleSubmissionListTableColumn } from "../../../helpers/constant";
import { CustomIconMenu, CustomTextField, Loader } from "../../../components";
import { saveAs } from "file-saver";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { debounce } from "lodash";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.surface,
    color: theme.palette.neutral[90],
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const StyledTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.info.main,
  cursor: "pointer",
}));

const ArticleSubmissionList = () => {
  const navigate = useNavigate();
  const [articleSubmissionList, setArticleSubmissionList] = useState({
    data: [],
    loading: false,
    filter: {
      trackingId: "",
      sortBy: "",
      sortDirection: "",
      page: 0,
      rowsPerPage: 10,
      totalDocs: 0,
    },
    refresh: false,
  });

  const handleChangePage = (event, newPage) => {
    setArticleSubmissionList((prev) => ({
      ...prev,
      filter: {
        ...prev.filter,
        page: newPage,
      },
    }));
  };

  const handleChangeRowsPerPage = (event) => {
    setArticleSubmissionList((prev) => ({
      ...prev,
      filter: {
        ...prev.filter,
        rowsPerPage: event.target.value,
        page: 0,
      },
    }));
  };

  useEffect(() => {
    const fetchIssue = async () => {
      setArticleSubmissionList((prev) => ({
        ...prev,
        loading: true,
      }));

      const resArticleSubmission =
        await articleSubmissionAdminServices.getAllArticleSubmission({
          trackingId: articleSubmissionList.filter.trackingId,
          page: articleSubmissionList.filter.page + 1,
          limit: articleSubmissionList.filter.rowsPerPage,
        });

      if (resArticleSubmission) {
        setArticleSubmissionList((prev) => ({
          ...prev,
          data: resArticleSubmission.data,
          loading: false,
          filter: {
            ...prev.filter,
            totalDocs: resArticleSubmission.data.totalDocs,
          },
        }));
      } else {
        setArticleSubmissionList((prev) => ({
          ...prev,
          loading: false,
        }));
      }
    };
    fetchIssue();
  }, [
    articleSubmissionList.filter.trackingId,
    articleSubmissionList.filter.page,
    articleSubmissionList.filter.rowsPerPage,
  ]);

  const onSearchChange = useMemo(
    () =>
      debounce((key, value) => {
        setArticleSubmissionList((prev) => ({
          ...prev,
          filter: {
            ...prev.filter,

            [key]: value,
            page: 0,
            rowsPerPage: 10,
          },
        }));
      }, 300),
    []
  );

  return (
    <Box
      sx={{
        maxWidth: "100%",
      }}
    >
      <Box
        sx={{
          marginBottom: "20px",
        }}
      >
        <CustomTextField
          label="Search by Tracking Id"
          variant="outlined"
          size="small"
          type="text"
          onChange={(e) => onSearchChange("trackingId", e.target.value)}
        />
      </Box>
      <TableContainer sx={{ minWidth: 700 }}>
        <Table aria-label="customized table">
          <TableHead>
            <TableRow>
              {articleSubmissionListTableColumn?.map(
                (articleListColumn, articleListColumnIndex) => (
                  <StyledTableCell
                    size="small"
                    key={`${articleListColumnIndex}_${articleListColumn}`}
                  >
                    {articleListColumn}
                  </StyledTableCell>
                )
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {articleSubmissionList?.loading ? (
              <StyledTableRow
                key={`subject_not_found`}
                sx={{ height: 30 * articleSubmissionList?.filter?.rowsPerPage }}
              >
                <StyledTableCell
                  scope="row"
                  colSpan={8}
                  sx={{ textAlign: "center" }}
                >
                  <Loader />
                </StyledTableCell>
              </StyledTableRow>
            ) : articleSubmissionList?.data?.docs?.length > 0 ? (
              articleSubmissionList?.data?.docs?.map((articleSubmissionRow) => (
                <StyledTableRow
                  key={`${articleSubmissionRow._id}${articleSubmissionRow.name}`}
                >
                  <StyledTableCell size="small">
                    <StyledTitle
                      onClick={() =>
                        window.open(
                          `/admin/article-submission/edit?articleSubmissionId=${articleSubmissionRow?._id}`,
                          "_blank"
                        )
                      }
                    >
                      {articleSubmissionRow?.trackingId}
                    </StyledTitle>
                  </StyledTableCell>
                  <StyledTableCell size="small">
                    {articleSubmissionRow?.manuscriptTitle}
                  </StyledTableCell>
                  <StyledTableCell size="small">
                    {articleSubmissionRow?.authorName}
                  </StyledTableCell>
                  <StyledTableCell size="small">
                    {`${articleSubmissionRow?.email}, ${articleSubmissionRow?.phone}`}
                  </StyledTableCell>
                  <StyledTableCell size="small">
                    {articleSubmissionRow?.journal?.name}
                  </StyledTableCell>
                  <StyledTableCell size="small">
                    {articleSubmissionRow?.articleType}
                  </StyledTableCell>
                  {/* <StyledTableCell size="small">
                  {articleSubmissionRow?.updatedBy}
                </StyledTableCell> */}
                  <StyledTableCell size="small">
                    {moment(articleSubmissionRow?.createdAt).format(
                      "DD-MM-YYYY"
                    )}
                  </StyledTableCell>
                  <StyledTableCell size="small">
                    <CustomIconMenu
                      options={[
                        {
                          name: "Download manuscript file",
                          handleOption: async () => {
                            saveAs(
                              `${process.env.REACT_APP_FILE_BASE_URL}${articleSubmissionRow?.manuscriptFileUrl}`,
                              `${
                                articleSubmissionRow?.manuscriptFileUrl?.split(
                                  "/"
                                )[1]
                              }`
                            );
                          },
                        },
                        {
                          name: "Download copyright form",
                          handleOption: async () => {
                            let resDownload =
                              await articleSubmissionAdminServices.downloadArticleSubmissionCopyright(
                                {
                                  articleSubmissionId:
                                    articleSubmissionRow?._id,
                                }
                              );
                            let pdfBlob = new Blob(
                              [new Uint8Array(resDownload?.data?.data).buffer],
                              {
                                type: "application/pdf",
                              }
                            );

                            saveAs(
                              pdfBlob,
                              `${articleSubmissionRow.trackingId} author's copyright form.pdf`
                            );
                          },
                        },
                      ]}
                    />
                  </StyledTableCell>
                </StyledTableRow>
              ))
            ) : (
              <StyledTableRow key={`subject_not_found`}>
                <StyledTableCell
                  size="small"
                  scope="row"
                  colSpan={8}
                  sx={{ textAlign: "center" }}
                >
                  No available data
                </StyledTableCell>
              </StyledTableRow>
            )}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={articleSubmissionList?.filter?.totalDocs}
          rowsPerPage={articleSubmissionList?.filter?.rowsPerPage}
          page={articleSubmissionList?.filter?.page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            "& .MuiToolbar-root": {
              minHeight: "48px",
            },
          }}
        />
      </TableContainer>
    </Box>
  );
};

export default ArticleSubmissionList;
