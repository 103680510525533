import axiosInstance from "../../axiosInstance";

const getArticleById = async ({ articleId }) => {
  try {
    const res = await axiosInstance.get(`/api/article?articleId=${articleId}`);

    if (res.status === 200) {
      return res.data;
    } else {
    }
  } catch (error) {}
};

export default getArticleById;
