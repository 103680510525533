import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { CustomDialog, CustomReactSelect, CustomTextField } from "../../index";
import subjectAdminServices from "../../../services/subject/admin";
import { toast } from "react-toastify";
import archiveAdminService from "../../../services/archive/admin";

const StyledBox = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "100%",
  borderRadius: theme.borderRadius[10],
  padding: "25px",
  boxShadow: theme.boxShadow.shadowThree,
  marginBottom: "10px",
}));

const WarningDescText = styled(Typography)(({ theme }) => ({
  color: theme.palette.neutral[90],
}));

function CreateArchive({
  open,
  setOpenCreateArchive,
  archiveDetails,
  setArchiveDetails,
  journalDetails,
  setArchiveList,
}) {
  const theme = useTheme();
  const [saveLoader, setSaveLoader] = useState(false);
  // const [selectedJournal, setSelectJournal] = useState("");
  const [journalOptions, setJournalOptions] = useState([journalDetails]);

  const archiveValidationSchema = yup.object().shape({
    name: yup.string().required("name is required"),
    journal: yup.mixed().required("journal is required"),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    setError,
    setValue,
    reset,
  } = useForm({
    defaultValues: {
      name: "",
      journal: "",
    },
    resolver: yupResolver(archiveValidationSchema),
  });

  const onSubmit = async ({ name, journal }) => {
    setSaveLoader(true);
    const reqSaveArchive = {
      name: name,
      journal: journal?._id,
      ...(archiveDetails?._id && {
        archiveId: archiveDetails?._id,
      }),
    };
    const resSaveArchive = await archiveAdminService.createArchive(
      reqSaveArchive
    );
    if (resSaveArchive) {
      toast.success("Archive save successful");
      setSaveLoader(false);
      setValue("name", "");
      setArchiveList((prev) => ({ ...prev, refresh: !prev.refresh }));
      setOpenCreateArchive((prev) => !prev);
      setArchiveDetails(null);
    } else {
      setSaveLoader(false);
      setError("name", { type: "custom", message: "Archive already exist" });
    }
  };

  useEffect(() => {
    if (archiveDetails) {
      setValue("name", archiveDetails.name);
    }
  }, [archiveDetails, setValue]);

  useEffect(() => {
    setJournalOptions([journalDetails]);
    setValue("journal", journalDetails);
  }, [journalDetails, setValue]);

  return (
    <CustomDialog
      open={open}
      title={"Create Article"}
      agreeButtonText={"Create"}
      agreeButtonProp={{
        variant: "contained",
        color: "secondary",
        type: "submit",
        endIcon: saveLoader && <CircularProgress size={20} />,
        disabled: saveLoader,
        onClick: handleSubmit(onSubmit),
        size: "small",
        sx: {
          "&.MuiButton-root": {
            color: theme.palette.neutral[0],
            // width: "100%",
            borderRadius: theme.borderRadius[10],
            textTransform: "none",
            "&:hover": {
              backgroundColor: theme.palette.secondary.hover,
            },
          },
        },
      }}
      cancelButtonText={"Cancel"}
      cancelButtonProp={{
        variant: "contained",
        color: "primary",
        type: "button",
        disabled: saveLoader,
        onClick: () => {
          setValue("name", "");
          setOpenCreateArchive((prev) => !prev);
          setArchiveDetails(null);
        },
        size: "small",
        sx: {
          "&.MuiButton-root": {
            color: theme.palette.neutral[0],
            // width: "100%",
            borderRadius: theme.borderRadius[10],
            textTransform: "none",
            "&:hover": {
              backgroundColor: theme.palette.primary.hover,
            },
          },
        },
      }}
    >
      {/* <StyledBox> */}
      <form>
        <Grid container spacing={2} sx={{ marginTop: "10px" }}>
          <Grid item xs={12}>
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <CustomTextField
                  label="Name *"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  helperText={errors?.name?.message}
                  error={errors?.name?.message}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="journal"
              control={control}
              render={({ field }) => (
                <CustomReactSelect
                  // label={"Subject"}
                  getOptionLabel={(e) => e.name}
                  getOptionValue={(e) => e.name}
                  options={journalOptions}
                  placeholder={"Select a journal"}
                  helperText={errors?.journal?.message}
                  error={errors?.journal?.message}
                  {...field}
                />
              )}
            />
          </Grid>
        </Grid>
      </form>
      {/* </StyledBox> */}
    </CustomDialog>
  );
}

export default CreateArchive;
