import getAllArticleSubmission from "./getAllArticleSubmission";
import getArticleSubmissionById from "./getArticleSubmissionById";
import createArticleSubmission from "./createArticleSubmission";
import downloadArticleSubmissionCopyright from "./downloadArticleSubmissionCopyright";

const articleSubmissionAdminServices = {
  getAllArticleSubmission,
  getArticleSubmissionById,
  createArticleSubmission,
  downloadArticleSubmissionCopyright,
};

export default articleSubmissionAdminServices;
