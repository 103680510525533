import { useEffect } from "react";
import { Box, Button, Grid, Typography, styled, useTheme } from "@mui/material";
import { ReactComponent as Fee } from "../../../assets/images/Fee.svg";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchJournalById } from "../../../redux/slices/webJournal";
import { APCPoints, ethicsAndPoliciesPoints } from "../../../helpers/constant";

const StyledBox = styled(Grid)(({ theme }) => ({
  width: "100%",
  height: "100%",
  // background: theme.palette.primary.main,
  borderRadius: theme.borderRadius[10],
  padding: "25px",
  boxShadow: theme.boxShadow.shadowThree,
  marginBottom: "10px",
  textAlign: "center",
}));

const JournalAPC = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { journalId } = useParams();

  const { journalDetails, journalLoading } = useSelector(
    (state) => state.webJournalReducer
  );

  useEffect(() => {
    if (journalId) {
      dispatch(fetchJournalById({ journalCustomId: journalId }));
    }
  }, [journalId, dispatch]);

  return (
    <Box
      sx={{
        padding: "20px 40px",
        height: "100%",
        textAlign: "justify",
        // background: theme.palette.primary.litSurface,
      }}
    >
      <Typography
        variant="h5"
        gutterBottom
        sx={{
          fontWeight: 600,
          marginBottom: "20px",
        }}
      >
        Ethics & Policies
      </Typography>
      <Typography
        variant="body1"
        gutterBottom
        sx={{
          marginBottom: "20px",
        }}
      >
        At the Journals of the IFR Discovery, our mission is to uphold the
        highest standards of research integrity and ethical conduct in all our
        publications. We are proud to follow the guidelines established by the{" "}
        <a href={`https://publicationethics.org/`}>
          Committee on Publication Ethics (COPE)
        </a>
        , ensuring that our processes are transparent, fair, and aligned with
        best practices in scholarly publishing. Below, we outline our policies
        and procedures in detail.
      </Typography>
      <ul
        style={{
          paddingLeft: "0",
        }}
      >
        {ethicsAndPoliciesPoints?.map((epPoint) => (
          <li
            style={{
              marginBottom: `30px`,
              listStyle: "none",
              paddingLeft: "0",
            }}
          >
            <Typography
              variant="body1"
              gutterBottom
              sx={{
                fontWeight: 600,
              }}
            >
              {epPoint.heading}:
            </Typography>
            {epPoint?.points?.length > 0 && (
              <ol>
                {epPoint?.points?.map((point) => (
                  <li
                    style={{
                      marginBottom: "20px",
                    }}
                  >
                    <Typography
                      variant="body1"
                      gutterBottom
                      sx={{
                        fontWeight: 600,
                      }}
                    >
                      {point.title}:
                    </Typography>
                    {point.description && (
                      <Typography variant="body1" gutterBottom>
                        {point.description}
                      </Typography>
                    )}
                    {point?.subPoints?.length > 0 && (
                      <ul>
                        {point?.subPoints?.map((subPoint) => (
                          <li>
                            <Typography variant="body1" gutterBottom>
                              {subPoint.title}:
                            </Typography>
                            {subPoint.description && (
                              <Typography variant="body1" gutterBottom>
                                {subPoint.description}
                              </Typography>
                            )}
                          </li>
                        ))}
                      </ul>
                    )}
                  </li>
                ))}
              </ol>
            )}
          </li>
        ))}
      </ul>
      <Typography variant="body1" gutterBottom>
        By adhering to these comprehensive guidelines, the Journals of the
        International Federation of Research and Discovery (IFR Discovery)
        ensure that our publication practices align with the highest ethical
        standards as set by the{" "}
        <a href={`https://publicationethics.org/`}>
          Committee on Publication Ethics (COPE)
        </a>
        . We are committed to fostering a scholarly environment characterized by
        transparency, fairness, and integrity.
      </Typography>
    </Box>
  );
};

export default JournalAPC;
