import React, { useEffect, useState } from "react";
import { Box, Grid, Typography, useTheme } from "@mui/material";
import editorWebsiteServices from "../../../services/editor/website";
import { useParams } from "react-router-dom";
import { editorTypes } from "../../../helpers/constant";
import EditorCard from "../../../components/website/editorPage/EditorCard";
import { Loader } from "../../../components";
import { useDispatch, useSelector } from "react-redux";
import { fetchJournalById } from "../../../redux/slices/webJournal";

const EditorList = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { journalId } = useParams();

  const { journalDetails, journalLoading } = useSelector(
    (state) => state.webJournalReducer
  );

  const [editorList, setEditorList] = useState({
    loading: false,
    chief: {},
    associate: [],
  });

  useEffect(() => {
    if (journalId && !journalDetails) {
      setEditorList((prev) => ({
        ...prev,
        loading: true,
      }));
      dispatch(fetchJournalById({ journalCustomId: journalId }));
    }
  }, [journalId, dispatch]);

  useEffect(() => {
    const fetchEditorList = async () => {
      setEditorList((prev) => ({
        ...prev,
        loading: true,
      }));
      const resEditorList = await editorWebsiteServices.getEditorListWebsite({
        journal: journalDetails?._id,
      });

      if (resEditorList.data) {
        setEditorList((prev) => ({
          ...prev,
          loading: false,
          chief: resEditorList?.data?.find(
            (editor) => editor.designation === editorTypes.CHIEF_EDITOR.tag
          ),
          associate: resEditorList?.data?.filter(
            (editor) => editor.designation !== editorTypes.CHIEF_EDITOR.tag
          ),
        }));
      } else {
        setEditorList((prev) => ({
          ...prev,
          loading: false,
        }));
      }
    };

    if (journalDetails?._id) {
      fetchEditorList();
    }
  }, [journalDetails?._id]);

  return (
    <Box
      sx={{
        padding: "20px",
        height: "100%",
        background: theme.palette.primary.litSurface,
      }}
    >
      {journalLoading || editorList?.loading ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "80vh",
          }}
        >
          <Loader />
        </Box>
      ) : editorList?.chief?._id || editorList.associate.length > 0 ? (
        <>
          <Grid container alignItems={"center"}>
            <Grid item xs={12} sm={3} md={3}>
              <Box
                sx={{
                  maxWidth: "100%",
                  overflow: "hidden",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <img
                  src={`${process.env.REACT_APP_FILE_BASE_URL}${editorList?.chief?.journal?.coverImgUrl}`}
                  alt="journal cover"
                  style={{
                    maxWidth: "66%",
                    borderRadius: theme.borderRadius[10],
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={9} md={9}>
              <Typography
                variant="body1"
                gutterBottom
                sx={{
                  fontWeight: 600,
                }}
              >
                Publisher: International Federation of Research and Discovery
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  wordWrap: "break-word",
                }}
              >
                Our accomplished board of experts is dedicated to upholding the
                highest standards of research and scholarship, ensuring the
                integrity and excellence of our publications.
              </Typography>
              {editorList?.chief?._id && (
                <Box
                  sx={{
                    borderRadius: theme.borderRadius[10],
                    overflow: "hidden",
                    marginTop: "30px",
                    boxShadow: theme.boxShadow.shadowTwo,
                  }}
                >
                  <Box
                    sx={{
                      padding: "5px 10px",
                      background: theme.palette.primary.main,
                      textAlign: "center",
                    }}
                  >
                    <Typography
                      variant="h5"
                      sx={{
                        wordWrap: "break-word",
                        fontWeight: 400,
                      }}
                    >
                      {editorTypes.CHIEF_EDITOR.name}
                    </Typography>
                  </Box>
                  <Grid
                    container
                    sx={{
                      padding: "20px",
                      background: theme.palette.neutral[0],
                    }}
                  >
                    <Grid xs={12} sm={8} order={{ xs: 2, md: 1 }}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          flexDirection: "column",
                          height: "100%",
                        }}
                      >
                        <Box>
                          <Typography
                            variant="h5"
                            gutterBottom
                            sx={{
                              wordWrap: "break-word",
                              fontWeight: 600,
                            }}
                          >
                            {editorList?.chief?.name}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            margin: "10px 0",
                          }}
                        >
                          <Typography
                            variant="body1"
                            gutterBottom
                            sx={{
                              wordWrap: "break-word",
                              whiteSpace: "pre-wrap",
                            }}
                          >
                            {editorList?.chief?.description}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography
                            variant="body1"
                            gutterBottom
                            sx={{
                              wordWrap: "break-word",
                              fontWeight: 600,
                            }}
                          >
                            {editorList?.chief?.email}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid xs={12} sm={4} order={{ xs: 1, md: 2 }}>
                      <Box
                        sx={{
                          maxWidth: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          position: "relative",
                          width: "100%",
                          height: "100%",
                        }}
                      >
                        <Box
                          sx={{
                            display: "block",
                            justifyContent: "center",
                            background: "#fdfdfd",
                            borderRadius: theme.borderRadius[10],
                            marginBottom: "8px",
                            cursor: "pointer",
                            textAlign: "center",
                            boxShadow: theme.boxShadow.shadowThree,
                          }}
                        >
                          {editorList?.chief?.editorImg ? (
                            <img
                              src={`${process.env.REACT_APP_FILE_BASE_URL}${editorList?.chief?.editorImg}`}
                              alt="editor avatar"
                              style={{
                                maxWidth: "100%",
                                maxHeight: "150px",
                                // background:
                                //   "url(Bildschirmfoto 2022-02-18 um 10.37.png)",
                                borderRadius: theme.borderRadius[10],
                                verticalAlign: "middle",
                              }}
                            />
                          ) : (
                            <i class="ri-user-2-fill ri-10x"></i>
                          )}
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              )}
            </Grid>
          </Grid>
          {editorList?.associate?.length > 0 && (
            <Box
              sx={{
                borderRadius: theme.borderRadius[10],
                overflow: "hidden",
                marginTop: "30px",
                backgroundColor: theme.palette.neutral[10],
              }}
            >
              <Box
                sx={{
                  padding: "5px 10px",
                  background: theme.palette.primary.main,
                  textAlign: "center",
                }}
              >
                <Typography
                  variant="h5"
                  sx={{
                    wordWrap: "break-word",
                    fontWeight: 400,
                  }}
                >
                  {editorTypes.ASSOCIATE_EDITOR.name}
                </Typography>
              </Box>
              <Grid
                container
                spacing={2}
                sx={{
                  padding: "10px",
                }}
              >
                {editorList?.associate?.map((editor) => (
                  <Grid container item xs={12} sm={12} md={6} lg={6}>
                    <EditorCard
                      editorDetails={editor}
                      gridContainerProps={{
                        item: true,
                      }}
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>
          )}
        </>
      ) : (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "80vh",
          }}
        >
          <Typography
            variant="body1"
            sx={{
              wordWrap: "break-word",
            }}
          >
            No Editor Available
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default EditorList;
