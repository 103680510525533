import axiosInstance from "../../axiosInstance";

const deleteIssue = async ({ issueId }) => {
  try {
    const res = await axiosInstance.delete(
      `/api/issue/delete?issueId=${issueId}`
    );

    if (res.status === 200) {
      return res.data;
    } else {
    }
  } catch (error) {}
};

export default deleteIssue;
