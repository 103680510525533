import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import issueAdminServices from "../../../../services/issue/admin";
import articleAdminServices from "../../../../services/article/admin";
import { Box, Button, Tab, Tabs, Typography, useTheme } from "@mui/material";
import { Loader } from "../../../../components";
import deserializeHtmlToSlate from "../../../../components/SlateEditor/deserializeHtmlToSlate";
import moment from "moment";
import {
  articlePublishStatus,
  submitArticleTypeOptions,
} from "../../../../helpers/constant";
import AuthorAndAffiliation from "./AuthorAndAffiliation";
import AbstractAndArticleBody from "./AbstractAndArticleBody";
import ArticleReference from "./ArticleReference";
import ArticleCitation from "./ArticleCitation";
import BasicDetails from "./BasicDetails";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import documentServices from "../../../../services/document";
import serializeSlateToHtml from "../../../../components/SlateEditor/serializeSlateToHtml";
import { toast } from "react-toastify";

const AddEditArticle = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [tabValue, setTabValue] = useState("basicDetails");

  const handleTabChange = (tabNewValue) => {
    setTabValue(tabNewValue);
  };

  const articleValidationSchema = yup.object().shape({
    name: yup.string().required("article name is required"),
    doi: yup.string(),
    issue: yup.object().shape({
      name: yup.string().required("issue is required"),
    }),

    type: yup.object().shape({
      name: yup.string().required("article type is required"),
    }),
    startPage: yup.number(),
    endPage: yup
      .number()
      .nullable()
      .when("startPage", {
        is: (startPageValue) => startPageValue,
        then: (schema) => schema.moreThan(yup.ref("startPage")),
        otherwise: () => yup.string(),
      }),
    publishedDate: yup.date().required("published date is required"),
    initialViewCount: yup.number(),
    initialDownloadCount: yup.number(),
    authors: yup.array().of(
      yup.object().shape({
        firstName: yup.string().required("first name is required"),
        middleName: yup.string(),
        lastName: yup.string(),
        email: yup.string().email(),
        affiliation: yup.string(),
      })
    ),
    articleAbstract: yup.string(),
    keywords: yup.array().of(
      yup.object().shape({
        name: yup.string().required("name is required"),
      })
    ),
    references: yup.array().of(
      yup.object().shape({
        authorNames: yup.array().of(
          yup.object().shape({
            surname: yup.string(),
            shortName: yup.string(),
          })
        ),
        year: yup.string(),
        articleTitle: yup.string(),
        journalName: yup.string(),
        volume: yup.string(),
        issue: yup.string(),
        fPage: yup.string(),
        lPage: yup.string(),
        link: yup.string(),
      })
    ),
  });

  const [issueOptions, setIssueOptions] = useState([]);

  const [articleDetails, setArticleDetails] = useState({
    data: {
      name: "",
      authors: [
        {
          firstName: "",
          middleName: "",
          lastName: "",
          email: "",
          affiliation: "",
        },
      ],
      doi: "",
      issue: "",
      articleBody: [
        {
          type: "paragraph",
          children: [
            {
              text: "",
            },
          ],
        },
      ],
      type: "",
      startPage: "",
      endPage: "",
      publishedDate: moment(new Date()),
      initialViewCount: 0,
      initialDownloadCount: 0,
      articleAbstract: "",
      keywords: [],
      citeArticle: [
        {
          name: "APA",
          citeDescription: "",
        },
        {
          name: "MLA",
          citeDescription: "",
        },
        {
          name: "Chicago",
          citeDescription: "",
        },
        {
          name: "Harvard",
          citeDescription: "",
        },
        {
          name: "Vancouver",
          citeDescription: "",
        },
      ],
      references: [
        // {
        //   authorNames: [
        //     {
        //       surname: "",
        //       shortName: "",
        //     },
        //   ],
        //   year: "",
        //   articleTitle: "",
        //   journalName: "",
        //   volume: "",
        //   issue: "",
        //   fPage: "",
        //   lPage: "",
        //   link: "",
        // },
      ],
      status: "",
    },
    loading: false,
    fetchFailed: false,
  });

  const [uploadAttachmentFiles, setUploadAttachmentFiles] = useState({
    pdf: null,
  });

  const [deleteAttachmentFiles, setDeleteAttachmentFiles] = useState({
    pdf: null,
  });

  const fetchArticle = async () => {
    if (searchParams.get("articleId")) {
      setArticleDetails((prev) => ({
        ...prev,
        loading: true,
        fetchFailed: false,
      }));
      const resArticle = await articleAdminServices.getArticleById({
        articleId: searchParams.get("articleId"),
      });

      return new Promise((resolve, reject) => {
        if (resArticle) {
          setIssueOptions([resArticle.data?.issue]);

          const articleHtmlText = resArticle.data.articleBody;
          const articleDocument = new DOMParser().parseFromString(
            articleHtmlText,
            "text/html"
          );

          let preFillForm = {
            ...resArticle.data,
            authors:
              resArticle.data?.authors?.length > 0
                ? resArticle.data?.authors
                : [
                    {
                      firstName: "",
                      middleName: "",
                      lastName: "",
                      email: "",
                      affiliation: "",
                    },
                  ],
            articleBody: deserializeHtmlToSlate(articleDocument.body),
            publishedDate: moment(resArticle.data.publishedDate),
            type: submitArticleTypeOptions.find(
              (articleTypeOption) =>
                articleTypeOption.name === resArticle.data.type
            ),
            citeArticle:
              resArticle.data?.citeArticle?.length > 0
                ? resArticle.data?.citeArticle
                : [
                    {
                      name: "APA",
                      citeDescription: "",
                    },
                    {
                      name: "MLA",
                      citeDescription: "",
                    },
                    {
                      name: "Chicago",
                      citeDescription: "",
                    },
                    {
                      name: "Harvard",
                      citeDescription: "",
                    },
                    {
                      name: "Vancouver",
                      citeDescription: "",
                    },
                  ],
            references:
              resArticle.data?.references?.length > 0
                ? resArticle.data?.references
                : [],
            keywords:
              resArticle.data?.keywords?.length > 0
                ? resArticle.data?.keywords
                : [],
          };

          if (preFillForm?.pdfUrl) {
            setUploadAttachmentFiles({ pdf: preFillForm?.pdfUrl });
          }

          resolve(preFillForm);

          setArticleDetails((prev) => ({
            ...prev,
            data: preFillForm,
            loading: false,
            fetchFailed: false,
          }));
        } else {
          setArticleDetails((prev) => ({
            ...prev,
            loading: false,
            fetchFailed: true,
          }));
        }
      });
    } else {
      setArticleDetails((prev) => ({
        ...prev,

        loading: true,
      }));
      let addPrefillForm = {
        ...articleDetails.data,
      };
      const resIssue = await issueAdminServices.getIssueById({
        issueId: searchParams.get("issueId"),
      });

      if (resIssue) {
        setIssueOptions([resIssue.data]);
        addPrefillForm.issue = resIssue.data;
        setArticleDetails((prev) => ({
          ...prev,

          loading: false,
        }));
      } else {
        setArticleDetails((prev) => ({
          ...prev,

          loading: false,
        }));
      }

      return new Promise((resolve) => resolve(addPrefillForm));
    }
  };

  const formMethods = useForm({
    defaultValues: () => fetchArticle(),
    resolver: yupResolver(articleValidationSchema),
  });

  // useEffect(() => {
  //   const fetchIssue = async () => {
  //     if (searchParams.get("issueId")) {
  //       setArticleDetails((prev) => ({
  //         ...prev,
  //         loading: true,
  //       }));
  //       const resIssue = await issueAdminServices.getIssueById({
  //         issueId: searchParams.get("issueId"),
  //       });

  //       if (resIssue) {
  //         setIssueOptions([resIssue.data]);
  //         setArticleDetails((prev) => ({
  //           ...prev,
  //           data: {
  //             ...prev.data,
  //             issue: resIssue.data,
  //           },
  //           loading: false,
  //         }));
  //       } else {
  //         setArticleDetails((prev) => ({
  //           ...prev,
  //           loading: false,
  //         }));
  //       }
  //     }
  //   };
  //   fetchIssue();
  // }, [searchParams]);

  useEffect(() => {
    return () => {
      const insertImages =
        JSON.parse(localStorage.getItem("insertedArticleImages")) || [];

      if (insertImages.length > 0) {
        const imagesToDelete = [...new Set([...insertImages])];

        const resDeleteImages = documentServices.deleteDocument({
          deleteFilePaths: imagesToDelete.map((path) =>
            path.replace(`${process.env.REACT_APP_FILE_BASE_URL}`, "")
          ),
        });

        if (resDeleteImages) {
          localStorage.removeItem("insertedArticleImages");
        }
      }
    };
  }, []);

  const onSubmit = async (data, event) => {
    event.preventDefault();

    let statusType = event.target.getAttribute("aria-description");

    // setSaveLoader(true);

    const deleteImages =
      JSON.parse(localStorage.getItem("deletedArticleImages")) || [];

    if (deleteImages.length > 0) {
      const imagesToDelete = [...new Set([...deleteImages])];

      const resDeleteImages = documentServices.deleteDocument({
        deleteFilePaths: imagesToDelete.map((path) =>
          path.replace(`${process.env.REACT_APP_FILE_BASE_URL}`, "")
        ),
      });

      if (resDeleteImages) {
        localStorage.removeItem("deletedArticleImages");
      }
    }

    const reqSaveArticle = {
      ...data,
      issue: data.issue._id,
      journal: data.issue.journal._id,
      archive: data.issue.archive._id,
      articleId: searchParams.get("articleId") || "",
      articleBody: serializeSlateToHtml(data.articleBody),
      type: data.type.name,
      citeArticle: JSON.stringify(data.citeArticle),
      references: JSON.stringify(data.references),
      authors: JSON.stringify(data.authors),
      keywords: JSON.stringify(data.keywords),
      status: statusType,
      deleteDocumentFiles: Object.values(deleteAttachmentFiles)
        .filter(Boolean)
        .join(","),
      ...(uploadAttachmentFiles?.pdf
        ? { pdf: uploadAttachmentFiles.pdf }
        : { pdfUrl: "" }),
    };

    const articleFormData = new FormData();

    Object.entries(reqSaveArticle).forEach((formValue) => {
      articleFormData.append(formValue[0], formValue[1]);
    });

    const resSaveArticle = await articleAdminServices.createArticle(
      articleFormData
    );

    if (resSaveArticle) {
      toast.success("Article save successful");
      // setSaveLoader(false);
      localStorage.removeItem("insertedArticleImages");
      // setImages((prev) => ({
      //   ...prev,
      //   inserted: [],
      // }));
      setArticleDetails((prev) => ({
        ...prev,
        data: {
          ...prev.data,
          status: resSaveArticle?.data?.status,
        },
      }));
      setDeleteAttachmentFiles({ pdf: null });
      navigate(`/admin/article/edit?articleId=${resSaveArticle?.data?._id}`);
    } else {
      // setSaveLoader(false);
    }
  };

  return articleDetails?.loading ? (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: "100vh",
      }}
    >
      <Loader />
    </Box>
  ) : (
    <Box>
      <Tabs
        value={tabValue}
        onChange={(e, tabNewValue) => handleTabChange(tabNewValue)}
        variant="standard"
        sx={{ marginBottom: "30px" }}
      >
        <Tab
          value={"basicDetails"}
          wrapped={false}
          label={"Basic Details"}
          sx={{ borderBottom: 1, borderColor: "divider" }}
        />
        <Tab
          value={"author"}
          wrapped={false}
          label={"Authors & Affiliations"}
          sx={{ borderBottom: 1, borderColor: "divider" }}
        />
        <Tab
          value={"abstract"}
          wrapped={false}
          label={"Abstract & Article Body"}
          sx={{ borderBottom: 1, borderColor: "divider" }}
        />
        <Tab
          value={"reference"}
          wrapped={false}
          label={"References"}
          sx={{ borderBottom: 1, borderColor: "divider" }}
        />
        <Tab
          value={"citation"}
          wrapped={false}
          label={"Citation"}
          sx={{ borderBottom: 1, borderColor: "divider" }}
        />
      </Tabs>
      <FormProvider {...formMethods}>
        <form>
          {tabValue === "basicDetails" && (
            <BasicDetails
              issueOptions={issueOptions}
              handleTabChange={handleTabChange}
              uploadAttachmentFiles={uploadAttachmentFiles}
              setUploadAttachmentFiles={setUploadAttachmentFiles}
              setDeleteAttachmentFiles={setDeleteAttachmentFiles}
            />
          )}
          {tabValue === "author" && (
            <AuthorAndAffiliation handleTabChange={handleTabChange} />
          )}
          {tabValue === "abstract" && (
            <AbstractAndArticleBody handleTabChange={handleTabChange} />
          )}
          {tabValue === "reference" && (
            <ArticleReference handleTabChange={handleTabChange} />
          )}
          {tabValue === "citation" && (
            <ArticleCitation handleTabChange={handleTabChange} />
          )}
          <Typography variant="caption">
            <em>
              Article publish status :
              <strong> {articleDetails.data.status}</strong>
            </em>
          </Typography>
          <Box
            sx={{
              marginTop: "20px",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              sx={{
                "&.MuiButton-root": {
                  marginRight: "10px",
                  color: theme.palette.neutral[90],
                  borderRadius: theme.borderRadius[10],
                  textTransform: "none",
                  "&:hover": {
                    backgroundColor: theme.palette.primary.hover,
                  },
                },
              }}
              size="small"
              type="button"
              aria-description={articlePublishStatus.draft}
              onClick={(e) => formMethods.handleSubmit(onSubmit)(e)}
              // disabled={saveLoader}
              // endIcon={saveLoader && <CircularProgress size={20} />}
            >
              Save as Draft
            </Button>
            <Button
              variant="contained"
              color="secondary"
              sx={{
                "&.MuiButton-root": {
                  color: theme.palette.neutral[0],
                  borderRadius: theme.borderRadius[10],
                  textTransform: "none",
                  "&:hover": {
                    backgroundColor: theme.palette.secondary.hover,
                  },
                },
              }}
              size="small"
              type="button"
              aria-description={articlePublishStatus.published}
              onClick={(e) => formMethods.handleSubmit(onSubmit)(e)}
              // disabled={saveLoader}
              // endIcon={saveLoader && <CircularProgress size={20} />}
            >
              Publish Article
            </Button>
          </Box>
        </form>
      </FormProvider>
    </Box>
  );
};

export default AddEditArticle;
