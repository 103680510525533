import axiosInstance from "../axiosInstance";

const getUser = async ({ userId }) => {
  try {
    const res = await axiosInstance.get(`/api/user?userId=${userId}`);

    if (res.status === 200) {
      return res.data;
    } else {
    }
  } catch (error) {}
};

export default getUser;
