import { Box, Button, Grid, Typography, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import journalWebsiteServices from "../../../services/journal/website";
import { Loader } from "../../../components";
import OpenAccessLogoBlack from "../../../assets/images/OpenAccessLogoBlack.png";
import { useDispatch, useSelector } from "react-redux";
import { fetchJournalById } from "../../../redux/slices/webJournal";

const ViewJournal = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { journalId } = useParams();

  const { journalDetails, journalLoading } = useSelector(
    (state) => state.webJournalReducer
  );

  useEffect(() => {
    if (journalId) {
      dispatch(fetchJournalById({ journalCustomId: journalId }));
    }
  }, [journalId, dispatch]);

  return (
    <Box
      sx={{
        padding: "20px 25px",
        height: "100%",
        background: theme.palette.primary.litSurface,
      }}
    >
      {journalLoading ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "80vh",
          }}
        >
          <Loader />
        </Box>
      ) : (
        <Box>
          <Grid container spacing={2} sx={{ marginBottom: "50px" }}>
            <Grid item xs={12} sm={8} order={{ xs: 2, sm: 1 }}>
              <Typography
                variant="h5"
                gutterBottom
                sx={{
                  fontWeight: 600,
                  marginBottom: "20px",
                }}
              >
                {journalDetails?.name}
              </Typography>
              <Typography
                variant="body2"
                gutterBottom
                sx={{
                  marginBottom: "30px",
                  whiteSpace: "pre-wrap",
                }}
              >
                {journalDetails?.abstractDescription}
              </Typography>
              <Grid item container xs={12}>
                <Grid item xs={12} sm={8}>
                  <Typography variant="body1" gutterBottom>
                    <strong>Abbreviated Key Title : </strong>
                    {`${journalDetails?.abbrKeyTitle}`}
                  </Typography>
                  {journalDetails?.openAccessYear && (
                    <Typography variant="body1" gutterBottom>
                      <strong>Open Access Year : </strong>
                      {`${journalDetails?.openAccessYear}`}
                    </Typography>
                  )}
                  <Typography variant="body1" gutterBottom>
                    <strong>ISSN Online : </strong>
                    {journalDetails?.issnOnlineLink ? (
                      <a
                        href={`${journalDetails?.issnOnlineLink}`}
                        rel="noreferrer"
                        target="_blank"
                        style={{
                          textDecoration: "none",
                        }}
                      >
                        {journalDetails?.issnOnline}
                      </a>
                    ) : (
                      `${journalDetails?.issnOnline}`
                    )}
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    <strong>ISSN Print : </strong>
                    {journalDetails?.issnPrintLink ? (
                      <a
                        href={`${journalDetails?.issnPrintLink}`}
                        rel="noreferrer"
                        target="_blank"
                        style={{
                          textDecoration: "none",
                        }}
                      >
                        {journalDetails?.issnPrint}
                      </a>
                    ) : (
                      `${journalDetails?.issnPrint}`
                    )}
                  </Typography>
                  {journalDetails?.doi && (
                    <Typography variant="body1" gutterBottom>
                      <strong>DOI prefix : </strong>
                      {`${journalDetails?.doi}`}
                    </Typography>
                  )}
                  <Typography variant="body1" gutterBottom>
                    <strong>Frequency : </strong>
                    {`${journalDetails?.frequency}`}
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    <strong>Language : </strong>
                    {`${journalDetails?.journalLanguage}`}
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    <strong>Origin : </strong>
                    {`${journalDetails?.origin}`}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                      height: "100%",
                    }}
                  >
                    <Button
                      fullWidth
                      sx={{
                        "&.MuiButton-root": {
                          color: theme.palette.neutral[90],
                          backgroundColor: theme.palette.primary.main,
                          textTransform: "none",
                          borderRadius: "30px",
                          // padding: "10px 20px",
                          // width: "100%",
                          marginTop: "10px",
                          "&:hover": {
                            backgroundColor: theme.palette.primary.hover,
                          },
                        },
                      }}
                      size="small"
                      onClick={() => navigate(`/archive/${journalId}`)}
                    >
                      Archives
                    </Button>
                    <Button
                      fullWidth
                      sx={{
                        "&.MuiButton-root": {
                          color: theme.palette.neutral[0],
                          backgroundColor: theme.palette.secondary.main,
                          textTransform: "none",
                          borderRadius: "30px",
                          // padding: "10px 20px",
                          // width: "100%",
                          marginTop: "10px",
                          "&:hover": {
                            backgroundColor: theme.palette.secondary.hover,
                          },
                        },
                      }}
                      size="small"
                      onClick={() => navigate(`/article/submit`)}
                    >
                      Submit Article
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={4} order={{ xs: 1, sm: 2 }}>
              <Box
                sx={{
                  maxWidth: "100%",
                  borderRadius: theme.borderRadius[10],
                  overflow: "hidden",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <img
                  src={OpenAccessLogoBlack}
                  alt="open access"
                  style={{
                    width: "120px",
                    marginBottom: "20px",
                  }}
                />
                <img
                  src={`${process.env.REACT_APP_FILE_BASE_URL}${journalDetails?.coverImgUrl}`}
                  alt="journal cover"
                  style={{
                    maxWidth: "55%",
                  }}
                />
              </Box>
            </Grid>
          </Grid>
          <Box sx={{ marginBottom: "50px" }}>
            <Typography
              variant="h5"
              gutterBottom
              sx={{
                fontWeight: 600,
                marginBottom: "20px",
              }}
            >
              About Journal
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              sx={{
                marginBottom: "20px",
                whiteSpace: "pre-wrap",
              }}
            >
              {journalDetails?.journalDescription}
            </Typography>
          </Box>
          <Box sx={{ marginBottom: "50px" }}>
            <Typography
              variant="h5"
              gutterBottom
              sx={{
                fontWeight: 600,
                marginBottom: "20px",
                whiteSpace: "pre-wrap",
              }}
            >
              About Organization
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              sx={{
                marginBottom: "20px",
              }}
            >
              {journalDetails?.organizationDescription}
            </Typography>
          </Box>
          <Box sx={{ marginBottom: "50px" }}>
            <Typography
              variant="h5"
              gutterBottom
              sx={{
                fontWeight: 600,
                marginBottom: "20px",
              }}
            >
              AIMS & Scopes
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              sx={{
                marginBottom: "20px",
              }}
            >
              {journalDetails?.aimsDescription}
            </Typography>
            <Grid container spacing={2}>
              {journalDetails?.scopes?.map((scope) => (
                <Grid item xs={12} sm={4} lg={3}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "baseline",
                    }}
                  >
                    <i class="ri-circle-fill ri-xxs"></i>
                    <Typography
                      variant="body2"
                      gutterBottom
                      sx={{ marginLeft: "5px" }}
                    >
                      {scope?.name}
                    </Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>
          {journalDetails?.indexing?.length > 0 && (
            <Box sx={{ marginBottom: "50px" }}>
              <Typography
                variant="h5"
                gutterBottom
                sx={{
                  fontWeight: 600,
                  marginBottom: "20px",
                }}
              >
                Indexing
              </Typography>
              <Grid container spacing={2}>
                {journalDetails?.indexing?.map((jIndex, index) => (
                  <Grid item xs={4} sm={3} lg={2}>
                    <Box
                      sx={{
                        maxWidth: "100%",
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: theme.palette.neutral[10],
                        borderRadius: "10px",
                      }}
                      onClick={() => {
                        jIndex?.redirectUrl &&
                          window.open(`${jIndex?.redirectUrl}`, "_blank");
                      }}
                    >
                      <img
                        src={`${process.env.REACT_APP_FILE_BASE_URL}${jIndex.imgUrl}`}
                        alt={jIndex?.name ? jIndex.name : `index ${index}`}
                        style={{
                          maxWidth: "100%",
                        }}
                      />
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

export default ViewJournal;
