import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import userServices from "../../services/user";

const initialState = {
  user: null,
  userLoading: true,
  userError: "",
};

export const fetchUserById = createAsyncThunk(
  "user/fetchByIdStatus",
  async (userId, thunkAPI) => {
    const resUser = await userServices.getUser(userId);
    return resUser.user;
  }
);

const userSlice = createSlice({
  name: "user",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserById.pending, (state, action) => {
        state.userLoading = true;
        state.userError = "";
      })
      .addCase(fetchUserById.fulfilled, (state, action) => {
        state.user = action.payload;
        state.userLoading = false;
        state.userError = "";
      })
      .addCase(fetchUserById.rejected, (state, action) => {
        state.userLoading = false;
        state.userError = "user fetching failed";
      });
  },
});

// export const { resetUser } = userSlice.actions;
export default userSlice.reducer;
