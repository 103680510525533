import axiosInstance from "../../axiosInstance";

/**
 * Get Editor list
 * @param {*} journal
 * @returns
 */
const getEditorList = async ({ journal }) => {
  try {
    const res = await axiosInstance.get(
      `/api/editor/websiteEditorList?journal=${journal}`
    );

    if (res.status === 200) {
      return res.data;
    } else {
    }
  } catch (error) {}
};

export default getEditorList;
