/**
 * Checks file type
 * @param {*} file
 * @param {*} allowedTypes
 * @returns
 */
export default function checkFileType(file, allowedTypes) {
  const fileType = file.type;

  if (allowedTypes.includes(fileType)) {
    return true;
  } else {
    return false;
  }
}
