import createJournal from "./createJournal";
import getJournalList from "./getJournalList";
import getJournalById from "./getJournalById";
import journalFiles from "./JournalFiles";
import deleteJournal from "./deleteJournal";

const journalAdminServices = {
  createJournal,
  getJournalList,
  getJournalById,
  journalFiles,
  deleteJournal,
};

export default journalAdminServices;
