import axiosInstance from "../../axiosInstance";

const createArticle = async (data) => {
  try {
    const res = await axiosInstance.post(`/api/article/create`, data);

    if (res.status === 201) {
      return res.data;
    } else {
    }
  } catch (error) {}
};

export default createArticle;
