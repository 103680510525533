import getArticleList from "./getArticleList";
import createArticle from "./createArticle";
import getArticleById from "./getArticleById";
import uploadArticleImage from "./uploadArticleImages";
import deleteArticle from "./deleteArticle";

const articleAdminServices = {
  getArticleList,
  createArticle,
  getArticleById,
  uploadArticleImage,
  deleteArticle,
};

export default articleAdminServices;
