import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  styled,
  tableCellClasses,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  CustomReactSelect,
  CustomIconMenu,
  CustomDialog,
  Loader,
} from "../../../components";
import { useNavigate, useSearchParams } from "react-router-dom";
import issueAdminServices from "../../../services/issue/admin";
import articleAdminServices from "../../../services/article/admin";
import { articleListTableColumn } from "../../../helpers/constant";
import moment from "moment";
import articleWebsiteServices from "../../../services/article/website";
import { saveAs } from "file-saver";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.surface,
    color: theme.palette.neutral[90],
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const StyledTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.info.main,
  cursor: "pointer",
}));

const WarningDescText = styled(Typography)(({ theme }) => ({
  color: theme.palette.neutral[90],
}));

const ArticleList = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [journal, setJournal] = useState();
  const [archive, setArchive] = useState();
  const [issue, setIssue] = useState();

  const [deleteArticle, setDeleteArticle] = useState({
    id: null,
    warningOpen: false,
    loading: false,
  });

  const [articleList, setArticleList] = useState({
    data: [],
    loading: false,
    filter: {
      searchKey: "",
      sortBy: "",
      sortDirection: "",
      page: 0,
      rowsPerPage: 10,
      totalDocs: 0,
    },
    refresh: false,
  });

  const handleChangePage = (event, newPage) => {
    setArticleList((prev) => ({
      ...prev,
      filter: {
        ...prev.filter,
        page: newPage,
      },
    }));
  };

  const handleChangeRowsPerPage = (event) => {
    setArticleList((prev) => ({
      ...prev,
      filter: {
        ...prev.filter,
        rowsPerPage: event.target.value,
        page: 0,
      },
    }));
  };

  useEffect(() => {
    const fetchIssue = async () => {
      if (searchParams.get("issueId")) {
        const resIssue = await issueAdminServices.getIssueById({
          issueId: searchParams.get("issueId"),
        });

        if (resIssue) {
          setJournal(resIssue.data.journal);
          setArchive(resIssue.data.archive);
          setIssue(resIssue.data);
        }
      }
    };
    fetchIssue();
  }, [searchParams]);

  useEffect(() => {
    const fetchArticleList = async () => {
      if (issue?._id) {
        setArticleList((prev) => ({
          ...prev,
          loading: true,
        }));
        const reqArticleList = {
          issue: issue?._id,
          page: articleList.filter.page + 1,
          limit: articleList.filter.rowsPerPage,
        };
        const resArticleList = await articleAdminServices.getArticleList(
          reqArticleList
        );

        if (resArticleList?.data?.docs?.length > 0) {
          setArticleList((prev) => ({
            ...prev,
            data: resArticleList.data.docs,
            loading: false,
            filter: {
              ...prev.filter,
              totalDocs: resArticleList.data.totalDocs,
            },
          }));
        } else {
          setArticleList((prev) => ({
            ...prev,
            data: [],
            loading: false,
          }));
        }
      }
    };
    fetchArticleList();
  }, [
    issue?._id,
    articleList.filter.page,
    articleList.filter.rowsPerPage,
    articleList.refresh,
    articleList.filter.sortBy,
    articleList.filter.sortDirection,
  ]);

  const onClickDeleteArticle = async () => {
    if (!deleteArticle?.id) {
      return;
    }
    setDeleteArticle((prev) => ({
      ...prev,
      loading: true,
    }));

    const deleteRes = await articleAdminServices.deleteArticle({
      articleId: deleteArticle?.id,
    });

    if (deleteRes?.data) {
      setArticleList((prev) => ({
        ...prev,
        refresh: !prev.refresh,
      }));
      setDeleteArticle((prev) => ({
        ...prev,
        id: null,
        warningOpen: false,
        loading: false,
      }));
    } else {
      setDeleteArticle((prev) => ({
        ...prev,
        loading: false,
      }));
    }
  };

  const getXMLData = async (row, xmlType, actionType) => {
    // const curr = "fetch-xml";
    // toast.loading(`fetching xml data...`, {
    //   toastId: curr,
    // });

    const resXML = await articleWebsiteServices.downloadArticleXML({
      articleId: row?._id,
      xmlType,
    });

    if (resXML?.data) {
      // toast.update(curr, {
      //   render: "XML fetch successful.",
      //   type: "success",
      //   autoClose: 5000,
      // });
      let xmlBlob = new Blob([resXML?.data], {
        type: "application/xml",
      });

      if (xmlBlob && actionType === "download") {
        saveAs(xmlBlob, `${row?.name}`);
      } else if (xmlBlob && actionType === "view") {
        let url = URL.createObjectURL(xmlBlob);
        window.open(url);
        URL.revokeObjectURL(url);
      }
    } else {
      // toast.update(curr, {
      //   render: "XML fetch failed.",
      //   type: "error",
      //   autoClose: 5000,
      // });
    }
  };

  const rowActionOptions = (rowData) => [
    {
      name: "Download as Crossref XML",
      handleOption: async () =>
        await getXMLData(rowData, "crossRef", "download"),
    },
    {
      name: "Download as DOAJ XML",
      handleOption: async () => await getXMLData(rowData, "doaj", "download"),
    },
    {
      name: "Delete article",
      handleOption: () => {
        setDeleteArticle((prev) => ({
          ...prev,
          id: rowData._id,
          warningOpen: true,
        }));
      },
    },
  ];

  return (
    <Box>
      <Grid container alignItems={"end"} spacing={2}>
        <Grid item xs={3}>
          <CustomReactSelect
            async
            value={journal}
            label={"Journal"}
            getOptionLabel={(e) => e.name}
            getOptionValue={(e) => e.name}
            // loadOptions={loadJournalOptions}
            isDisabled={searchParams.get("issueId")}
            placeholder={"Choose journal"}
            onChange={(value) => setJournal(value)}
          />
        </Grid>
        <Grid item xs={3}>
          <CustomReactSelect
            async
            value={archive}
            label={"Archive"}
            getOptionLabel={(e) => e.name}
            getOptionValue={(e) => e.name}
            // loadOptions={loadJournalOptions}
            isDisabled={searchParams.get("issueId")}
            placeholder={"Choose archive"}
            onChange={(value) => setArchive(value)}
          />
        </Grid>
        <Grid item xs={3}>
          <CustomReactSelect
            async
            value={issue}
            label={"Issue"}
            getOptionLabel={(e) => e.name}
            getOptionValue={(e) => e.name}
            // loadOptions={loadJournalOptions}
            isDisabled={searchParams.get("issueId")}
            placeholder={"Choose issue"}
            onChange={(value) => setIssue(value)}
          />
        </Grid>
        <Grid item xs={3}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <Button
              sx={{
                "&.MuiButton-root": {
                  color: theme.palette.neutral[0],
                  backgroundColor: theme.palette.secondary.main,
                  textTransform: "none",

                  "&:hover": {
                    backgroundColor: theme.palette.secondary.hover,
                  },
                },
              }}
              size="small"
              onClick={() =>
                navigate(`/admin/article/add?issueId=${issue?._id}`)
              }
            >
              Create Article
            </Button>
          </Box>
        </Grid>
      </Grid>
      <TableContainer component={Paper}>
        <Table aria-label="customized table">
          <TableHead>
            <TableRow>
              {articleListTableColumn?.map(
                (articleListColumn, articleListColumnIndex) => (
                  <StyledTableCell
                    size="small"
                    key={`${articleListColumnIndex}_${articleListColumn}`}
                  >
                    {articleListColumn}
                  </StyledTableCell>
                )
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {articleList?.loading ? (
              <StyledTableRow
                key={`subject_not_found`}
                sx={{ height: 30 * articleList?.filter?.rowsPerPage }}
              >
                <StyledTableCell
                  scope="row"
                  colSpan={7}
                  sx={{ textAlign: "center" }}
                >
                  <Loader />
                </StyledTableCell>
              </StyledTableRow>
            ) : articleList?.data?.length > 0 ? (
              articleList?.data?.map((articleRow) => (
                <StyledTableRow key={`${articleRow._id}${articleRow.name}`}>
                  <StyledTableCell size="small" component="th" scope="row">
                    <StyledTitle
                      onClick={() =>
                        navigate(
                          `/admin/article/edit?articleId=${articleRow._id}`
                        )
                      }
                    >
                      {articleRow.name}
                    </StyledTitle>
                  </StyledTableCell>
                  <StyledTableCell size="small">
                    {articleRow?.issue?.name}
                  </StyledTableCell>
                  <StyledTableCell size="small">
                    {articleRow?.archive?.name}
                  </StyledTableCell>
                  <StyledTableCell size="small">
                    {articleRow?.startPage && articleRow.endPage
                      ? `${articleRow?.startPage}-${articleRow?.endPage}`
                      : "-"}
                  </StyledTableCell>
                  <StyledTableCell size="small">
                    {articleRow?.status}
                  </StyledTableCell>
                  <StyledTableCell size="small">
                    {articleRow?.updatedBy}
                  </StyledTableCell>
                  <StyledTableCell size="small">
                    {moment(articleRow?.updatedAt).format("DD-MM-YYYY")}
                  </StyledTableCell>
                  <StyledTableCell size="small">
                    <CustomIconMenu options={rowActionOptions(articleRow)} />
                  </StyledTableCell>
                </StyledTableRow>
              ))
            ) : (
              <StyledTableRow key={`subject_not_found`}>
                <StyledTableCell
                  size="small"
                  scope="row"
                  colSpan={7}
                  sx={{ textAlign: "center" }}
                >
                  No available data
                </StyledTableCell>
              </StyledTableRow>
            )}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 100]}
          component="div"
          count={articleList?.filter?.totalDocs}
          rowsPerPage={articleList?.filter?.rowsPerPage}
          page={articleList?.filter?.page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          showFirstButton
          showLastButton
          sx={{
            "& .MuiToolbar-root": {
              minHeight: "48px",
            },
          }}
        />
      </TableContainer>
      <CustomDialog
        open={deleteArticle.warningOpen}
        title={"Are you sure ?"}
        agreeButtonText={"Delete"}
        agreeButtonProp={{
          variant: "contained",
          color: "secondary",
          type: "button",
          endIcon: deleteArticle.loading && <CircularProgress size={20} />,
          disabled: deleteArticle.loading,
          onClick: () => onClickDeleteArticle(),
          size: "small",
          sx: {
            "&.MuiButton-root": {
              color: theme.palette.neutral[0],
              // width: "100%",
              borderRadius: theme.borderRadius[10],
              // backgroundColor: theme.palette.secondary.main,
              textTransform: "none",
              "&:hover": {
                backgroundColor: theme.palette.secondary.hover,
              },
            },
          },
        }}
        cancelButtonText={"Cancel"}
        cancelButtonProp={{
          variant: "contained",
          color: "primary",
          type: "button",
          disabled: deleteArticle.loading,
          onClick: () =>
            setDeleteArticle((prev) => ({
              ...prev,
              id: null,
              warningOpen: false,
              loading: false,
            })),
          size: "small",
          sx: {
            "&.MuiButton-root": {
              color: theme.palette.neutral[0],
              // width: "100%",
              borderRadius: theme.borderRadius[10],
              // backgroundColor: theme.palette.primary.main,
              textTransform: "none",
              "&:hover": {
                backgroundColor: theme.palette.primary.hover,
              },
            },
          },
        }}
      >
        <WarningDescText>
          Do you really want to delete this record? This process cannot be
          undone.
        </WarningDescText>
      </CustomDialog>
    </Box>
  );
};

export default ArticleList;
