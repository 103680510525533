import React from "react";
import { Box, Grid, Typography, useTheme } from "@mui/material";

const EditorCard = ({ editorDetails, gridContainerProps }) => {
  const theme = useTheme();
  return (
    <Grid
      container
      sx={{
        padding: "20px",
        background: theme.palette.neutral[0],
        borderRadius: theme.borderRadius[10],
        boxShadow: theme.boxShadow.shadowTwo,
      }}
      {...gridContainerProps}
    >
      <Grid xs={12} sm={8} order={{ xs: 2, md: 1 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <Box>
            <Typography
              variant="h5"
              gutterBottom
              sx={{
                wordWrap: "break-word",
                fontWeight: 600,
              }}
            >
              {editorDetails?.name}
            </Typography>
          </Box>

          <Box
            sx={{
              margin: "10px 0",
            }}
          >
            <Typography
              variant="body"
              gutterBottom
              sx={{
                wordWrap: "break-word",
              }}
            >
              {editorDetails?.description}
            </Typography>
          </Box>
          <Box>
            <Typography
              variant="body"
              gutterBottom
              sx={{
                wordWrap: "break-word",
                fontWeight: 600,
              }}
            >
              {editorDetails?.email}
            </Typography>
          </Box>
        </Box>
      </Grid>
      <Grid xs={12} sm={4} order={{ xs: 1, md: 2 }}>
        <Box
          sx={{
            maxWidth: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
            width: "100%",
            height: "100%",
          }}
        >
          <Box
            sx={{
              display: "block",
              justifyContent: "center",
              background: "#fdfdfd",
              borderRadius: theme.borderRadius[10],
              marginBottom: "8px",
              cursor: "pointer",
              textAlign: "center",
              boxShadow: theme.boxShadow.shadowThree,
            }}
          >
            {editorDetails?.editorImg ? (
              <img
                src={`${process.env.REACT_APP_FILE_BASE_URL}${editorDetails?.editorImg}`}
                alt="editor avatar"
                style={{
                  maxWidth: "100%",
                  maxHeight: "150px",
                  borderRadius: theme.borderRadius[10],
                  verticalAlign: "middle",
                }}
              />
            ) : (
              <i class="ri-user-2-fill ri-10x"></i>
            )}
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default EditorCard;
