import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import isHotkey from "is-hotkey";
import {
  Editable as slateEditable,
  withReact,
  useSlate,
  Slate,
  useSlateStatic,
  ReactEditor,
  useSelected,
  useFocused,
} from "slate-react";
import {
  Editor,
  Transforms,
  createEditor,
  Descendant,
  Element as SlateElement,
  Path,
  Range,
} from "slate";
import { withHistory } from "slate-history";
import isUrl from "is-url";
import imageExtensions from "image-extensions";

// import { Toolbar } from "../components";
import { Box, IconButton, styled } from "@mui/material";
import CustomMenu from "../CustomMenu";
import FormatBoldIcon from "@mui/icons-material/FormatBold";
import FormatItalicIcon from "@mui/icons-material/FormatItalic";
import StrikethroughSIcon from "@mui/icons-material/StrikethroughS";
import AddLinkIcon from "@mui/icons-material/AddLink";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import FormatColorFillIcon from "@mui/icons-material/FormatColorFill";
import FormatUnderlinedIcon from "@mui/icons-material/FormatUnderlined";
import HighlightIcon from "@mui/icons-material/Highlight";
import SubscriptIcon from "@mui/icons-material/Subscript";
import SuperscriptIcon from "@mui/icons-material/Superscript";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import FormatAlignJustifyIcon from "@mui/icons-material/FormatAlignJustify";
import FormatAlignRightIcon from "@mui/icons-material/FormatAlignRight";
import FormatAlignCenterIcon from "@mui/icons-material/FormatAlignCenter";
import UndoIcon from "@mui/icons-material/Undo";
import RedoIcon from "@mui/icons-material/Redo";
import FormatSizeIcon from "@mui/icons-material/FormatSize";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useTheme } from "@emotion/react";
import articleAdminServices from "../../services/article/admin";
import withHtml from "./plugin/withHtml";
import { withDocxDeserializer } from "slate-docx-deserializer";
import { jsx } from "slate-hyperscript";
import withTables from "./plugin/withTable";

const CustomIconButton = styled(IconButton)(({ theme, isActive }) => {
  return {
    margin: "0 5px",
    backgroundColor: !isActive
      ? theme.palette.neutral[0]
      : theme.palette.secondary.surface,
    "&.Mui-disabled": {
      color: theme.palette.neutral[20],
    },
  };
});

const Editable = styled(slateEditable)(({ theme, isActive }) => {
  return {
    marginTop: "10px",
    border: `1px solid ${theme.palette.neutral[50]}`,
    borderRadius: "10px",
    padding: "10px 10px",

    "& > *": {
      margin: 0,
    },
  };
});

const HOTKEYS = {
  "mod+b": "bold",
  "mod+i": "italic",
  "mod+u": "underline",
  "mod+`": "code",
};

const LIST_TYPES = ["numbered-list", "bulleted-list"];
const TEXT_ALIGN_TYPES = ["left", "center", "right", "justify"];
const VOID_TYPES = ["image"];

const RichTextExample = ({
  name,
  value,
  onEditorChange,
  getDeletedImages,
  getInsertedImages,
}) => {
  const theme = useTheme();
  const renderElement = useCallback(
    (props) => <Element getDeletedImages={getDeletedImages} {...props} />,
    []
  );
  const renderLeaf = useCallback((props) => <Leaf {...props} />, []);
  const editor = useMemo(
    () =>
      // withDocxDeserializer(
      withHtml(
        withReact(
          withInlines(
            withImages(
              withTables(withHistory(withReact(createEditor()))),
              getDeletedImages
            )
          )
        )
      ),
    //   jsx
    // ),

    []
  );

  return (
    <Slate
      editor={editor}
      initialValue={value}
      onChange={(value) => {
        const isAstChange = editor.operations.some(
          (op) => "set_selection" !== op.type
        );
        if (isAstChange) {
          // const content = JSON.stringify(value);

          onEditorChange(value);
        }
      }}
      // style={
      //   {
      //     // "&": {
      //     //   border: `1px solid ${theme.palette.neutral[50]}`,
      //     // },
      //     // "*": {
      //     //   margin: 0,
      //     // },
      //   }
      // }
    >
      <Toolbar getInsertedImages={getInsertedImages} />
      <Editable
        renderElement={renderElement}
        renderLeaf={renderLeaf}
        placeholder="Enter some rich text…"
        spellCheck
        autoFocus
        onKeyDown={(event) => {
          for (const hotkey in HOTKEYS) {
            if (isHotkey(hotkey, event)) {
              event.preventDefault();
              const mark = HOTKEYS[hotkey];
              toggleMark(editor, mark);
            }
          }
        }}
      />
    </Slate>
  );
};

/**
 * Block prevention HOC
 */
const withPreventBlockDeletion = (editor) => {
  const { apply } = editor;

  editor.apply = (operation) => {
    if (operation.type === "remove_node") {
      const { node } = operation;
      if (node.type === "image") {
        // Prevent certain element/node to be deleted
        return;
      }
    }

    // Other cases
    apply(operation);
  };

  return editor;
};

const withInlines = (editor) => {
  const { insertData, insertText, isInline, isElementReadOnly, isSelectable } =
    editor;

  editor.isInline = (element) =>
    ["link", "button", "badge"].includes(element.type) || isInline(element);

  editor.isElementReadOnly = (element) =>
    element.type === "badge" || isElementReadOnly(element);

  editor.isSelectable = (element) =>
    element.type !== "badge" && isSelectable(element);

  editor.insertText = (text) => {
    if (text && isUrl(text)) {
      wrapLink(editor, text);
    } else {
      insertText(text);
    }
  };

  editor.insertData = (data) => {
    // const text = data.getData("text/plain");
    const text = data.getData("text/html");

    if (text && isUrl(text)) {
      wrapLink(editor, text);
    } else {
      insertData(data);
    }
  };

  return editor;
};

/**
 * Image HOC
 * @param {*} editor
 * @returns
 */
const withImages = (editor, setDeleteImages = () => {}) => {
  const { insertData, isVoid, apply } = editor;

  editor.isVoid = (element) => {
    return element.type === "image" ? true : isVoid(element);
  };

  editor.insertData = (data) => {
    const text = data.getData("text/plain");
    const { files } = data;

    if (files && files.length > 0) {
      for (const file of files) {
        const reader = new FileReader();
        const [mime] = file.type.split("/");

        if (mime === "image") {
          reader.addEventListener("load", () => {
            const url = reader.result;
            insertImage(editor, url);
          });

          reader.readAsDataURL(file);
        }
      }
    } else if (isImageUrl(text)) {
      insertImage(editor, text);
    } else {
      insertData(data);
    }
  };

  editor.apply = (operation) => {
    if (operation.type === "remove_node") {
      const { node } = operation;
      if (node.type === "image") {
        // Prevent certain element/node to be deleted
        // return;
        setDeleteImages(node.url);
      }
    }

    // Other cases
    apply(operation);
  };

  return editor;
};

const insertImage = (editor, url) => {
  const text = { text: "" };
  const image = { type: "image", url, children: [text] };
  Transforms.insertNodes(editor, image);
  Transforms.insertNodes(editor, {
    type: "paragraph",
    children: [{ text: "" }],
  });
};

/**
 * Toggle block state
 * @param {*} editor
 * @param {*} format
 */
const toggleBlock = (editor, format) => {
  const isActive = isBlockActive(
    editor,
    format,
    TEXT_ALIGN_TYPES.includes(format) ? "align" : "type"
  );
  const isList = LIST_TYPES.includes(format);
  // const isVoidType = VOID_TYPES.includes(format);

  Transforms.unwrapNodes(editor, {
    match: (n) =>
      !Editor.isEditor(n) &&
      SlateElement.isElement(n) &&
      LIST_TYPES.includes(n.type) &&
      !TEXT_ALIGN_TYPES.includes(format),
    split: true,
  });
  let newProperties = {};
  if (TEXT_ALIGN_TYPES.includes(format)) {
    newProperties = {
      align: isActive ? undefined : format,
    };
  } else {
    newProperties = {
      type: isActive ? "paragraph" : isList ? "list-item" : format,
    };
  }
  Transforms.setNodes(editor, newProperties);

  if (!isActive && isList) {
    const block = { type: format, children: [] };
    Transforms.wrapNodes(editor, block);
  }
};

/**
 * Toggle mark state
 * @param {*} editor
 * @param {*} format
 */
const toggleMark = (editor, format) => {
  const isActive = isMarkActive(editor, format);
  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};

/**
 * Check if block is active
 * @param {*} editor
 * @param {*} format
 * @param {*} blockType
 * @returns
 */
const isBlockActive = (editor, format, blockType = "type") => {
  const { selection } = editor;
  if (!selection) return false;

  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: (n) =>
        !Editor.isEditor(n) &&
        SlateElement.isElement(n) &&
        n[blockType] === format,
    })
  );

  return !!match;
};

/**
 * check if mark is active
 * @param {*} editor
 * @param {*} format
 * @returns
 */
const isMarkActive = (editor, format) => {
  const marks = Editor.marks(editor);
  return marks ? marks[format] === true : false;
};

const isLinkActive = (editor) => {
  const [link] = Editor.nodes(editor, {
    match: (n) =>
      !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === "link",
  });
  return !!link;
};

const InsertImageButton = ({ getInsertedImages }) => {
  const editor = useSlateStatic();
  return (
    <CustomIconButton component="label">
      <AddPhotoAlternateIcon />
      <input
        hidden
        type="file"
        accept="image/png, image/jpeg, image/jpg"
        onChange={async (e) => {
          let file = e.target.files[0];

          const articleImageFormaData = new FormData();
          articleImageFormaData.append("articleImage", file);

          const uploadUrl = await articleAdminServices.uploadArticleImage(
            articleImageFormaData
          );

          if (uploadUrl?.url) {
            insertImage(
              editor,
              `${process.env.REACT_APP_FILE_BASE_URL}${uploadUrl?.url}`
            );
            getInsertedImages(
              `${process.env.REACT_APP_FILE_BASE_URL}${uploadUrl?.url}`
            );
          }
        }}
      />
    </CustomIconButton>
  );
};

const isImageUrl = (url) => {
  if (!url) return false;
  if (!isUrl(url)) return false;
  const ext = new URL(url).pathname.split(".").pop();
  return imageExtensions.includes(ext);
};

const insertLink = (editor, url) => {
  if (editor.selection) {
    wrapLink(editor, url);
  }
};

const wrapLink = (editor, url) => {
  if (!url) return;

  if (isLinkActive(editor)) {
    Transforms.unwrapNodes(editor, {
      match: (n) =>
        !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === "link",
    });
  }

  const { selection } = editor;
  // const link = createLinkNode(url, "New Link");
  const isCollapsed = selection && Range.isCollapsed(selection);
  const link = {
    type: "link",
    url,
    children: isCollapsed ? [{ text: url }] : [],
  };

  if (isCollapsed) {
    Transforms.insertNodes(editor, link);
  } else {
    Transforms.wrapNodes(editor, link, { split: true });
    Transforms.collapse(editor, { edge: "end" });
  }
};

/**
 * Slate render element
 * @param {*} attributes
 * @param {*} children
 * @param {*} element
 * @returns
 */
const Element = ({ attributes, children, element, getDeletedImages }) => {
  let style = {};
  if (element.elAttributes?.style) {
    style = { textAlign: element.align, ...element.elAttributes.style };
  } else {
    style = { textAlign: element.align };
  }
  const elAttributes = { ...element.elAttributes };
  delete elAttributes.style;
  switch (element.type) {
    case "block-quote":
      return (
        <blockquote style={style} {...attributes} {...elAttributes}>
          {children}
        </blockquote>
      );
    case "bulleted-list":
      return (
        <ul style={style} {...attributes} {...elAttributes}>
          {children}
        </ul>
      );
    case "heading-one":
      return (
        <h1 style={style} {...attributes} {...elAttributes}>
          {children}
        </h1>
      );
    case "heading-two":
      return (
        <h2 style={style} {...attributes} {...elAttributes}>
          {children}
        </h2>
      );
    case "heading-three":
      return (
        <h3 style={style} {...attributes} {...elAttributes}>
          {children}
        </h3>
      );
    case "heading-four":
      return (
        <h4 style={style} {...attributes} {...elAttributes}>
          {children}
        </h4>
      );
    case "heading-five":
      return (
        <h5 style={style} {...attributes} {...elAttributes}>
          {children}
        </h5>
      );
    case "heading-six":
      return (
        <h6 style={style} {...attributes} {...elAttributes}>
          {children}
        </h6>
      );
    case "list-item":
      return (
        <li style={style} {...attributes} {...elAttributes}>
          {children}
        </li>
      );
    case "numbered-list":
      return (
        <ol style={style} {...attributes} {...elAttributes}>
          {children}
        </ol>
      );

    case "image":
      return (
        <Image
          style={style}
          attributes={attributes}
          children={children}
          element={element}
          getDeletedImages={getDeletedImages}
        />
      );
    case "link":
      return (
        <Link attributes={attributes} children={children} element={element} />
      );

    case "table":
      return (
        <table>
          <tbody style={style} {...attributes} {...elAttributes}>
            {children}
          </tbody>
        </table>
      );
    case "table-row":
      return (
        <tr style={style} {...attributes} {...elAttributes}>
          {children}
        </tr>
      );
    case "table-cell":
      return (
        <td style={style} {...attributes} {...elAttributes}>
          {children}
        </td>
      );
    default:
      return (
        <p style={style} {...attributes} {...elAttributes}>
          {children}
        </p>
      );
  }
};

const Leaf = ({ attributes, children, leaf }) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }

  if (leaf.code) {
    children = <code>{children}</code>;
  }

  if (leaf.italic) {
    children = <em>{children}</em>;
  }

  if (leaf.underline) {
    children = <u>{children}</u>;
  }

  if (leaf.strikethrough) {
    children = <s>{children}</s>;
  }
  if (leaf.superscript) {
    children = <sup>{children}</sup>;
  }
  if (leaf.subscript) {
    children = <sub>{children}</sub>;
  }

  return <span {...attributes}>{children}</span>;
};

const BlockButton = ({ format, icon }) => {
  const editor = useSlate();
  return (
    <CustomIconButton
      isActive={isBlockActive(
        editor,
        format,
        TEXT_ALIGN_TYPES.includes(format) ? "align" : "type"
      )}
      onMouseDown={(event) => {
        event.preventDefault();
        toggleBlock(editor, format);
      }}
    >
      {icon}
    </CustomIconButton>
  );
};

const MarkButton = ({ format, icon }) => {
  const editor = useSlate();
  return (
    <CustomIconButton
      isActive={isMarkActive(editor, format)}
      onMouseDown={(event) => {
        event.preventDefault();
        toggleMark(editor, format);
      }}
    >
      {icon}
    </CustomIconButton>
  );
};

const Image = ({ attributes, style, children, element, getDeletedImages }) => {
  const editor = useSlateStatic();
  const path = ReactEditor.findPath(editor, element);

  const selected = useSelected();
  const focused = useFocused();

  return (
    <div
      {...attributes}
      style={{
        display: "flex",
        justifyContent: style?.textAlign || "left",
      }}
    >
      {children}
      <div
        contentEditable={false}
        style={{
          position: "relative",
        }}
      >
        <img
          style={{
            display: "block",
            maxWidth: "100%",
            maxHeight: "20em",
            boxShadow: selected && focused ? "0 0 0 3px #B4D5FF" : "none",
          }}
          src={element.url}
          alt=""
        />
        <div
          onClick={() => {
            getDeletedImages(element.url);
            Transforms.removeNodes(editor, { at: path });
          }}
          style={{
            display: selected && focused ? "inline" : "none",
            position: "absolute",
            top: "0.5em",
            left: "0.5em",
            background: "#ffc5b3",
            padding: "10px 12px",
            borderRadius: "50%",
            cursor: "pointer",
          }}
        >
          <i class="ri-delete-bin-2-fill ri-lg"></i>
        </div>
      </div>
    </div>
  );
};

const Link = ({ attributes, element, children }) => {
  const editor = useSlateStatic();
  const selected = useSelected();
  const focused = useFocused();

  return (
    <a {...attributes} href={element.url}>
      {children}
    </a>
  );
  // return (
  //   <div className="element-link">
  //     <a {...attributes} href={element.href}>
  //       {children}
  //     </a>
  //     {selected && focused && (
  //       <div className="popup" contentEditable={false}>
  //         <a href={element.href} rel="noreferrer" target="_blank">
  //           {element.href}
  //         </a>
  //         <button
  //           onClick={() =>
  //             Transforms.unwrapNodes(editor, {
  //               match: (n) =>
  //                 !Editor.isEditor(n) &&
  //                 Element.isElement(n) &&
  //                 n.type === "link",
  //             })
  //           }
  //         >
  //           {/* <FontAwesomeIcon icon={faUnlink} /> */}
  //         </button>
  //       </div>
  //     )}
  //   </div>
  // );
};

const Toolbar = ({ getInsertedImages }) => {
  const editor = useSlate();
  // const editorStatic = useSlateStatic();
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        border: `1px solid ${theme.palette.neutral[50]}`,
        borderRadius: "10px",
        padding: "5px 0",
        flexWrap: "wrap",
        position: "sticky",
        top: "65px",
        background: `${theme.palette.neutral[0]}`,
        zIndex: 1,
      }}
    >
      <CustomMenu
        menuButtonText={
          isBlockActive(
            editor,
            "heading-one",
            TEXT_ALIGN_TYPES.includes("heading-one") ? "align" : "type"
          )
            ? "Heading 1"
            : isBlockActive(
                editor,
                "heading-two",
                TEXT_ALIGN_TYPES.includes("heading-two") ? "align" : "type"
              )
            ? "Heading 2"
            : isBlockActive(
                editor,
                "heading-three",
                TEXT_ALIGN_TYPES.includes("heading-two") ? "align" : "type"
              )
            ? "Heading 3"
            : isBlockActive(
                editor,
                "heading-four",
                TEXT_ALIGN_TYPES.includes("heading-two") ? "align" : "type"
              )
            ? "Heading 4"
            : isBlockActive(
                editor,
                "heading-five",
                TEXT_ALIGN_TYPES.includes("heading-two") ? "align" : "type"
              )
            ? "Heading 5"
            : isBlockActive(
                editor,
                "heading-six",
                TEXT_ALIGN_TYPES.includes("heading-two") ? "align" : "type"
              )
            ? "Heading 6"
            : "Paragraph"
        }
        buttonProps={{
          variant: "contained",
          sx: {
            "&.MuiButton-root": {
              marginRight: "5px",
              color: theme.palette.neutral[90],
              borderRadius: theme.borderRadius[10],
              backgroundColor: theme.palette.neutral[0],
              textTransform: "none",
              boxShadow: "none",
              "&:hover": {
                backgroundColor: theme.palette.neutral[10],
              },
            },
          },
          startIcon: <FormatSizeIcon />,
          endIcon: <KeyboardArrowDownIcon />,
        }}
        options={[
          {
            name: "Heading 1",
            format: "heading-one",
            onSelect: () => toggleBlock(editor, "heading-one"),
          },
          {
            name: "Heading 2",
            format: "heading-two",
            onSelect: () => toggleBlock(editor, "heading-two"),
          },
          {
            name: "Heading 3",
            format: "heading-three",
            onSelect: () => toggleBlock(editor, "heading-three"),
          },
          {
            name: "Heading 4",
            format: "heading-four",
            onSelect: () => toggleBlock(editor, "heading-four"),
          },
          {
            name: "Heading 5",
            format: "heading-five",
            onSelect: () => toggleBlock(editor, "heading-five"),
          },
          {
            name: "Heading 6",
            format: "heading-six",
            onSelect: () => toggleBlock(editor, "heading-six"),
          },
        ]}
      />
      <MarkButton editor={editor} format="bold" icon={<FormatBoldIcon />} />
      <MarkButton editor={editor} format="italic" icon={<FormatItalicIcon />} />
      <MarkButton
        editor={editor}
        format="underline"
        icon={<FormatUnderlinedIcon />}
      />
      <MarkButton
        editor={editor}
        format="superscript"
        icon={<SuperscriptIcon />}
      />
      <MarkButton editor={editor} format="subscript" icon={<SubscriptIcon />} />
      {/* <MarkButton editor={editor} format="code" icon="code" /> */}
      {/* <BlockButton editor={editor} format="heading-one" icon="looks_one" />
  <BlockButton editor={editor} format="heading-two" icon="looks_two" /> */}
      {/* <BlockButton editor={editor} format="block-quote" icon="format_quote" /> */}
      <BlockButton
        editor={editor}
        format="numbered-list"
        icon={<FormatListNumberedIcon />}
      />
      <BlockButton
        editor={editor}
        format="bulleted-list"
        icon={<FormatListBulletedIcon />}
      />
      <BlockButton
        editor={editor}
        format="left"
        icon={<FormatAlignLeftIcon />}
      />
      <BlockButton
        editor={editor}
        format="center"
        icon={<FormatAlignCenterIcon />}
      />
      <BlockButton
        editor={editor}
        format="right"
        icon={<FormatAlignRightIcon />}
      />
      <BlockButton
        editor={editor}
        format="justify"
        icon={<FormatAlignJustifyIcon />}
      />
      <InsertImageButton getInsertedImages={getInsertedImages} />
      <CustomIconButton
        onClick={() => {
          const url = prompt("Enter a URL"); // For simplicity
          insertLink(editor, url);
        }}
      >
        <AddLinkIcon />
      </CustomIconButton>
    </Box>
  );
};

export default RichTextExample;
