import React from "react";
import { LandingPageLayout } from "../containers";
import { Outlet } from "react-router-dom";
import { Maintenance } from "../pages/website";

function LayoutRoute() {
  return (
    <LandingPageLayout>
      <Outlet />
    </LandingPageLayout>
  );
}

export default LayoutRoute;
