import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import journalWebsiteServices from "../../services/journal/website";

const initialState = {
  journalDetails: null,
  journalLoading: true,
  journalError: "",
};

export const fetchJournalById = createAsyncThunk(
  "journal/fetchById",
  async (journalCustomId, thunkAPI) => {
    const resJournal = await journalWebsiteServices.getJournalViewWebsite(
      journalCustomId
    );
    return resJournal?.data;
  }
);

const webJournalSlice = createSlice({
  name: "webJournal",
  initialState,
  reducers: {
    setReduxJournalDetails: (state, action) => {
      state.journalDetails = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchJournalById.pending, (state, action) => {
        state.journalLoading = true;
        state.journalError = "";
      })
      .addCase(fetchJournalById.fulfilled, (state, action) => {
        state.journalDetails = action.payload;
        state.journalLoading = false;
        state.journalError = "";
      })
      .addCase(fetchJournalById.rejected, (state, action) => {
        state.journalLoading = false;
        state.journalError = "user fetching failed";
      });
  },
});

export const { setReduxJournalDetails } = webJournalSlice.actions;
export default webJournalSlice.reducer;
