import React, { useEffect } from "react";

const Home = () => {
  // useEffect(() => {
  //   localStorage.setItem("maintenance-banner", false);
  // }, []);
  return <div>Home</div>;
};

export default Home;
