import React, { useCallback, useEffect, useState } from "react";
import {
  useForm,
  Controller,
  useFieldArray,
  useFormContext,
} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  CustomDatePicker,
  CustomFileUploadButton,
  CustomReactSelect,
  CustomTextField,
  Loader,
  SlateEditor,
  TiptapEditor,
} from "../../../../components";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import { useDropzone } from "react-dropzone";
import articleAdminServices from "../../../../services/article/admin";
import { toast } from "react-toastify";
import ReactSelect from "react-select";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import documentServices from "../../../../services/document";
import serializeSlateToHtml from "../../../../components/SlateEditor/serializeSlateToHtml";
import { submitArticleTypeOptions } from "../../../../helpers/constant";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

const BasicDetails = ({
  issueOptions,
  handleTabChange,
  uploadAttachmentFiles,
  setUploadAttachmentFiles,
  setDeleteAttachmentFiles,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [saveLoader, setSaveLoader] = useState(false);

  // const articleValidationSchema = yup.object().shape({
  //   name: yup.string().required("name is required"),
  //   doi: yup.string().required("doi is required"),
  //   issue: yup.object().shape({
  //     name: yup.string().required("issue is required"),
  //     // name: yup.string(),
  //   }),

  //   type: yup.object().shape({
  //     name: yup.string().required("article type is required"),
  //   }),
  //   startPage: yup.number(),
  //   endPage: yup.number().when("startPage", {
  //     is: (startPageValue) => startPageValue,
  //     then: (schema) =>
  //       schema.moreThan(yup.ref("startPage")).required("end page is required"),
  //     otherwise: () => yup.string(),
  //   }),
  //   publishedDate: yup.date().required("published date is required"),
  //   initialViewCount: yup.number(),
  //   initialDownloadCount: yup.number(),
  // });

  const {
    control,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useFormContext();

  const onSubmit = async (data, event) => {
    event.preventDefault();
    setSaveLoader(true);

    const reqSaveArticle = {
      ...data,
      issue: data.issue._id,
      journal: data.issue.journal._id,
      archive: data.issue.archive._id,
      articleId: searchParams.get("articleId") || "",
      type: data.type.name,
    };

    const articleFormData = new FormData();

    Object.entries(reqSaveArticle).forEach((formValue) => {
      articleFormData.append(formValue[0], formValue[1]);
    });

    const resSaveArticle = await articleAdminServices.createArticle(
      articleFormData
    );

    if (resSaveArticle) {
      toast.success("Article save successful");
      setSaveLoader(false);
      localStorage.removeItem("insertedArticleImages");
      // setImages((prev) => ({
      //   ...prev,
      //   inserted: [],
      // }));
      navigate(`/admin/article?issueId=${issueOptions[0]?._id}`);
    } else {
      setSaveLoader(false);
    }
  };

  return (
    <Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item container xs={12} spacing={2} alignItems={"end"}>
            <Grid item xs={12}>
              <Controller
                name="name"
                control={control}
                render={({ field }) => (
                  <CustomTextField
                    label="Article Name *"
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    helperText={errors?.name?.message}
                    error={errors?.name?.message}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <Controller
                name="doi"
                control={control}
                render={({ field }) => (
                  <CustomTextField
                    label="Doi"
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    helperText={errors?.doi?.message}
                    error={errors?.doi?.message}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <Controller
                name="issue"
                control={control}
                render={({ field }) => (
                  <CustomReactSelect
                    label={"Issue *"}
                    getOptionLabel={(e) => e.name}
                    getOptionValue={(e) => e.name}
                    options={issueOptions}
                    placeholder={"Select a issue"}
                    helperText={errors?.issue?.message}
                    error={errors?.issue?.message}
                    styles={{
                      menu: (base) => ({
                        ...base,
                        zIndex: 10,
                      }),
                    }}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <Controller
                name="type"
                control={control}
                render={({ field }) => (
                  <CustomReactSelect
                    label={"Article type *"}
                    getOptionLabel={(e) => e.name}
                    getOptionValue={(e) => e.name}
                    options={submitArticleTypeOptions}
                    placeholder={"Select a article type"}
                    helperText={errors?.type?.message}
                    error={errors?.type?.message}
                    styles={{
                      menu: (base) => ({
                        ...base,
                        zIndex: 10,
                      }),
                    }}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <Controller
                name="publishedDate"
                control={control}
                render={({ field }) => (
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <CustomDatePicker
                      label="Published date *"
                      format="DD/MM/YYYY"
                      slotProps={{
                        textField: {
                          size: "small",
                          fullWidth: true,
                          helperText: errors?.publishedDate?.message,
                          error: errors?.publishedDate?.message,
                        },
                      }}
                      {...field}
                    />
                  </LocalizationProvider>
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <Controller
                name="startPage"
                control={control}
                render={({ field }) => (
                  <CustomTextField
                    label="Start page *"
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    helperText={errors?.startPage?.message}
                    error={errors?.startPage?.message}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <Controller
                name="endPage"
                control={control}
                render={({ field }) => (
                  <CustomTextField
                    label="End page"
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    helperText={errors?.endPage?.message}
                    error={errors?.endPage?.message}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <Controller
                name="initialViewCount"
                control={control}
                render={({ field }) => (
                  <CustomTextField
                    label="Initial view count"
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    helperText={errors?.initialViewCount?.message}
                    error={errors?.initialViewCount?.message}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <Controller
                name="initialDownloadCount"
                control={control}
                render={({ field }) => (
                  <CustomTextField
                    label="Initial download count"
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    helperText={errors?.initialDownloadCount?.message}
                    error={errors?.initialDownloadCount?.message}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <CustomFileUploadButton
                fileTitleText={"Article PDF File"}
                fileName={
                  uploadAttachmentFiles.pdf
                    ? uploadAttachmentFiles.pdf?.name ||
                      uploadAttachmentFiles.pdf?.split("/")[
                        uploadAttachmentFiles.pdf?.split("/").length - 1
                      ]
                    : null
                }
                buttonText={"Upload PDF File"}
                buttonProps={{
                  component: "label",
                  variant: "contained",
                  color: "primary",
                  fullWidth: true,
                  sx: {
                    "&.MuiButton-root": {
                      marginTop: "20px",
                      color: theme.palette.neutral[90],
                      borderRadius: theme.borderRadius[10],
                      textTransform: "none",
                      "&:hover": {
                        backgroundColor: theme.palette.primary.hover,
                      },
                    },
                  },
                  size: "small",
                  type: "button",
                  disabled: saveLoader,
                  endIcon: saveLoader && <CircularProgress size={20} />,
                }}
                inputProps={{
                  accept: "application/pdf",
                  onChange: (e) => {
                    setUploadAttachmentFiles((prev) => ({
                      ...prev,
                      pdf: e.target.files[0],
                    }));
                  },
                }}
                deleteButtonProps={{
                  onClick: () => {
                    if (uploadAttachmentFiles.pdf?.name) {
                      setUploadAttachmentFiles((prev) => ({
                        ...prev,
                        pdf: null,
                      }));
                    } else if (uploadAttachmentFiles.pdf) {
                      setUploadAttachmentFiles((prev) => ({
                        ...prev,
                        pdf: null,
                      }));
                      setDeleteAttachmentFiles((prev) => ({
                        ...prev,
                        pdf: uploadAttachmentFiles.pdf,
                      }));
                    }
                  },
                }}
              />
            </Grid>
          </Grid>

          <Grid item container xs={12} justifyContent={"flex-end"}>
            <Grid item xs={12} md={3} lg={3}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  variant="contained"
                  color="secondary"
                  sx={{
                    "&.MuiButton-root": {
                      marginTop: "20px",
                      color: theme.palette.neutral[0],
                      borderRadius: theme.borderRadius[10],
                      textTransform: "none",
                      "&:hover": {
                        backgroundColor: theme.palette.secondary.hover,
                      },
                    },
                  }}
                  size="small"
                  disabled={saveLoader}
                  endIcon={saveLoader && <CircularProgress size={20} />}
                  onClick={() => handleTabChange("author")}
                >
                  Next
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default BasicDetails;
