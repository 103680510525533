import { toast } from "react-toastify";
import axiosInstance from "../axiosInstance";

const login = async ({ email, password }) => {
  try {
    const res = await axiosInstance.post("/api/auth/login", {
      email,
      password,
    });

    if (res.status === 201) {
      return res.data;
    }
  } catch (error) {
    toast.error(error?.response?.data?.message);
  }
};

export default login;
