import axiosInstance from "../../axiosInstance";

const getArticleByIdWebsite = async ({ articleId }) => {
  try {
    const res = await axiosInstance.get(
      `/api/article/websiteArticleView?articleId=${articleId}`
    );

    if (res.status === 200) {
      return res.data;
    } else {
    }
  } catch (error) {}
};

export default getArticleByIdWebsite;
