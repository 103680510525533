import axiosInstance from "../../axiosInstance";

/**
 * Get Journal By ID
 * @param {*} journalId
 * @returns
 */
const getJournalById = async ({ journalId }) => {
  try {
    const res = await axiosInstance.get(`/api/journal?journalId=${journalId}`);

    if (res.status === 200) {
      return res.data;
    } else {
    }
  } catch (error) {}
};

export default getJournalById;
