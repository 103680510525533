import axiosInstance from "../../axiosInstance";

/**
 * Download article as XML
 * @param {*} articleId
 * @param {*} xmlType
 * @returns
 */
const downloadArticleXML = async ({ articleId = "", xmlType }) => {
  try {
    const res = await axiosInstance.get(
      `/api/article/websiteDownloadArticleXml?articleId=${articleId}&xmlType=${xmlType}`
    );

    if (res.status === 200) {
      return res.data;
    } else {
    }
  } catch (error) {}
};

export default downloadArticleXML;
