import React, { useState } from "react";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";

import {
  Box,
  Button,
  CircularProgress,
  Grid,
  useTheme,
  Divider,
  Typography,
  IconButton,
  styled,
  InputAdornment,
} from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import { CustomTextField, SlateEditor } from "../../../../components";

const StyledScopeContainer = styled(Box)(({ theme }) => ({
  border: `1px dashed ${theme.palette.neutral[70]}`,
  background: theme.palette.neutral[0],
  width: "100%",
  height: "100%",
  borderRadius: theme.borderRadius[3],
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
  flexDirection: "column",
  padding: "10px",
}));

const AbstractAndArticleBody = ({ handleTabChange }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [saveLoader, setSaveLoader] = useState(false);

  const {
    control,
    // handleSubmit,
    formState: { errors },
    getValues,
  } = useFormContext();

  const keywordFieldArray = useFieldArray({ control, name: "keywords" });

  // const onSubmit = async (data, event) => {
  //   event.preventDefault();
  //   setSaveLoader(true);

  //   const reqSaveArticle = {
  //     ...data,
  //     issue: data.issue._id,
  //     journal: data.issue.journal._id,
  //     archive: data.issue.archive._id,
  //     articleId: searchParams.get("articleId") || "",
  //     type: data.type.name,
  //   };

  //   const articleFormData = new FormData();

  //   Object.entries(reqSaveArticle).forEach((formValue) => {
  //     articleFormData.append(formValue[0], formValue[1]);
  //   });

  //   const resSaveArticle = await articleAdminServices.createArticle(
  //     articleFormData
  //   );

  //   if (resSaveArticle) {
  //     toast.success("Article save successful");
  //     setSaveLoader(false);
  //     localStorage.removeItem("insertedArticleImages");
  //     // setImages((prev) => ({
  //     //   ...prev,
  //     //   inserted: [],
  //     // }));
  //     // navigate(`/admin/article?issueId=${issueOptions[0]?._id}`);
  //   } else {
  //     setSaveLoader(false);
  //   }
  // };
  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Controller
            name={`articleAbstract`}
            control={control}
            render={({ field }) => (
              <CustomTextField
                label="Abstract"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                multiline
                rows={5}
                helperText={errors?.articleAbstract?.message}
                error={errors?.articleAbstract?.message}
                {...field}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <Box>
            <Typography variant="p" gutterBottom>
              Keywords
            </Typography>
            <StyledScopeContainer>
              <Grid item container xs={12} md={12} lg={12} spacing={2}>
                {keywordFieldArray?.fields?.length > 0 &&
                  keywordFieldArray?.fields?.map((keyword, keywordIndex) => (
                    <Grid item xs={12} md={3} lg={3} key={keyword.id}>
                      <Controller
                        name={`keywords.${keywordIndex}.name`}
                        control={control}
                        render={({ field }) => (
                          <CustomTextField
                            label=""
                            variant="outlined"
                            size="small"
                            type="text"
                            fullWidth
                            helperText={
                              errors?.keywords?.[keywordIndex]?.name?.message
                            }
                            error={
                              errors?.keywords?.[keywordIndex]?.name?.message
                            }
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() =>
                                      keywordFieldArray.remove(keywordIndex)
                                    }
                                    edge="end"
                                  >
                                    <i class="ri-delete-bin-2-fill"></i>
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                            {...field}
                          />
                        )}
                      />
                    </Grid>
                  ))}
                <Grid item xs={12} md={3} lg={3}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <Button
                      sx={{
                        "&.MuiButton-root": {
                          color: theme.palette.neutral[0],
                          width: "100%",

                          borderRadius: theme.borderRadius[10],
                          backgroundColor: theme.palette.secondary.main,
                          textTransform: "none",
                          "&:hover": {
                            backgroundColor: theme.palette.secondary.hover,
                          },
                        },
                      }}
                      size="small"
                      type="button"
                      onClick={() => keywordFieldArray.append({ name: "" })}
                    >
                      Add Keywords
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </StyledScopeContainer>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="articleBody"
            control={control}
            render={({ field }) => (
              <SlateEditor
                onEditorChange={field.onChange}
                getInsertedImages={(currentImgUrl) => {
                  let getInsertImages =
                    JSON.parse(localStorage.getItem("insertedArticleImages")) ||
                    [];
                  getInsertImages.push(currentImgUrl);
                  localStorage.setItem(
                    "insertedArticleImages",
                    JSON.stringify(getInsertImages)
                  );
                  // setImages((prev) => {
                  //   if (prev.inserted.includes(currentImgUrl)) {
                  //     return;
                  //   }
                  //   return {
                  //     ...prev,
                  //     inserted: [...prev.inserted, currentImgUrl],
                  //   };
                  // });
                }}
                getDeletedImages={(currentImgUrl) => {
                  let localSDeletedImages =
                    JSON.parse(localStorage.getItem("deletedArticleImages")) ||
                    [];

                  localSDeletedImages.push(currentImgUrl);

                  localStorage.setItem(
                    "deletedArticleImages",
                    JSON.stringify(localSDeletedImages)
                  );
                  // setImages((prev) => {
                  //   if (prev.deleted.includes(currentImgUrl)) {
                  //     return;
                  //   }
                  //   const filterInsertedUrl = prev.inserted?.filter(
                  //     (insertUrl) => insertUrl !== currentImgUrl
                  //   );
                  //   return {
                  //     ...prev,
                  //     inserted: [...filterInsertedUrl],
                  //     deleted: [...prev.deleted, currentImgUrl],
                  //   };
                  // });
                }}
                {...field}
              />
            )}
          />
        </Grid>

        <Grid item container xs={12} justifyContent={"flex-end"}>
          <Grid item xs={12} md={3} lg={3}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                sx={{
                  "&.MuiButton-root": {
                    marginTop: "20px",
                    marginRight: "10px",
                    color: theme.palette.neutral[90],
                    borderRadius: theme.borderRadius[10],
                    textTransform: "none",
                    "&:hover": {
                      backgroundColor: theme.palette.primary.hover,
                    },
                  },
                }}
                size="small"
                type="button"
                disabled={saveLoader}
                endIcon={saveLoader && <CircularProgress size={20} />}
                onClick={() => handleTabChange("author")}
              >
                Pervious
              </Button>
              <Button
                variant="contained"
                color="secondary"
                sx={{
                  "&.MuiButton-root": {
                    marginTop: "20px",
                    color: theme.palette.neutral[0],
                    borderRadius: theme.borderRadius[10],
                    textTransform: "none",
                    "&:hover": {
                      backgroundColor: theme.palette.secondary.hover,
                    },
                  },
                }}
                size="small"
                type="button"
                disabled={saveLoader}
                endIcon={saveLoader && <CircularProgress size={20} />}
                onClick={() => handleTabChange("reference")}
              >
                Next
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AbstractAndArticleBody;
