import styled from "@emotion/styled";
import {
  Box,
  Button,
  Divider,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import subjectWebsiteServices from "../../../services/subject/website";
import { JournalCard } from "../../../components/website/journalPage";
import journalWebsiteServices from "../../../services/journal/website";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import CustomTextField from "../../../components/CustomTextField";
import { Loader } from "../../../components";
import { useNavigate } from "react-router-dom";

const StyledSideBox = styled(Box)(({ theme }) => ({
  borderRadius: theme.borderRadius[10],
  background: theme.palette.neutral[0],
  boxShadow: theme.boxShadow.shadowTwo,
  padding: "20px",
  marginBottom: "1rem",
}));

const JournalList = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [subjectList, setSubjectList] = useState({ docs: [], loading: false });
  const [selectedSubject, setSelectedSubject] = useState("");
  const [journalList, setJournalList] = useState({
    docs: [],
    page: 0,
    limit: "",
    loading: false,
  });
  const [trackingIdInput, setTrackingIdInput] = useState("");

  useEffect(() => {
    const fetchAllSubjectsWebsite = async () => {
      setSubjectList((prev) => ({
        ...prev,
        loading: true,
      }));
      const resAllSubjectsWebsite =
        await subjectWebsiteServices.getSubjectListWebsite({
          sortBy: "",
          sortDirection: "",
          page: "",
          limit: "",
        });

      if (resAllSubjectsWebsite?.data?.docs?.length) {
        setSubjectList((prev) => ({
          ...prev,
          loading: false,
          docs: [
            ...resAllSubjectsWebsite?.data?.docs,
            {
              name: "View all subjects",
              _id: "",
            },
          ],
        }));
      } else {
        setSubjectList((prev) => ({
          ...prev,
          loading: false,
          docs: [],
        }));
      }
    };
    fetchAllSubjectsWebsite();
  }, []);

  useEffect(() => {
    const fetchAllJournalsWebsite = async () => {
      setJournalList((prev) => ({
        ...prev,
        loading: true,
      }));
      const resAllJournalsWebsite =
        await journalWebsiteServices.getJournalListWebsite({
          subjectId: selectedSubject ? selectedSubject : "",
          sortBy: "",
          sortDirection: "",
          page: journalList.page + 1,
          limit: journalList.limit,
        });

      if (resAllJournalsWebsite?.data?.docs?.length) {
        setJournalList((prev) => ({
          ...prev,
          loading: false,
          docs: resAllJournalsWebsite?.data?.docs,
        }));
      } else {
        setJournalList((prev) => ({
          ...prev,
          loading: false,
          docs: [],
        }));
      }
    };
    fetchAllJournalsWebsite();
  }, [journalList.page, journalList.limit, selectedSubject]);

  return (
    <Box
      sx={{
        padding: "20px",
        height: "100%",
        background: theme.palette.primary.litSurface,
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={3} order={{ xs: 2, sm: 1 }}>
          {/* <StyledSideBox>
            <Typography
              variant="p"
              gutterBottom
              sx={{
                fontWeight: 600,
                textAlign: "center",
                // width: "100%",
                padding: "10px",
                marginBottom: "30px",
              }}
            >
              Recently Joined
            </Typography>
          </StyledSideBox> */}
          <StyledSideBox>
            <Box
              sx={{
                marginBottom: "5px",
                display: "flex",
              }}
            >
              <FilterAltIcon
                sx={{ color: theme.palette.primary.main, marginRight: "5px" }}
              />
              <Typography
                variant="p"
                gutterBottom
                sx={{
                  fontWeight: 600,
                  textAlign: "center",
                }}
              >
                Browse by Subjects
              </Typography>
            </Box>
            <Divider
              variant="fullWidth"
              sx={{ borderColor: theme.palette.secondary.main }}
            />
            {subjectList?.loading ? (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "200px",
                }}
              >
                <Loader />
              </Box>
            ) : subjectList?.docs?.length > 0 ? (
              <Box
                sx={{
                  marginTop: "20px",
                  maxHeight: "300px",
                  overflowY: "auto",
                }}
              >
                {subjectList?.docs?.map((subject) => (
                  <Box
                    key={`${subject._id}_${subject.name}`}
                    sx={{
                      marginBottom: "5px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      variant="body2"
                      gutterBottom
                      sx={{
                        color:
                          subject?._id === selectedSubject
                            ? theme.palette.secondary.main
                            : theme.palette.neutral[80],
                        fontSize: "0.813rem",

                        "&:hover": {
                          cursor: "pointer",
                          textDecoration: "underline",
                        },
                      }}
                      onClick={() => setSelectedSubject(subject?._id)}
                    >
                      {subject?.name}
                    </Typography>
                  </Box>
                ))}
              </Box>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "200px",
                }}
              >
                <Typography
                  variant="caption"
                  gutterBottom
                  sx={{
                    textAlign: "center",
                    color: theme.palette.neutral[50],
                  }}
                >
                  No subjects available
                </Typography>
              </Box>
            )}
          </StyledSideBox>
          <StyledSideBox>
            <Box
              sx={{
                marginBottom: "5px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <i
                className="ri-road-map-fill ri-lg"
                style={{
                  color: theme.palette.primary.main,
                  marginRight: "5px",
                }}
              ></i>
              <Typography
                variant="p"
                gutterBottom
                sx={{
                  fontWeight: 600,
                  textAlign: "center",
                }}
              >
                Track Your Manuscript
              </Typography>
            </Box>
            <Divider
              variant="fullWidth"
              sx={{ borderColor: theme.palette.secondary.main }}
            />
            <Box
              sx={{
                marginTop: "15px",
              }}
            >
              <CustomTextField
                fullWidth
                label="Enter your Manuscript ID"
                placeholder="Example: IFRJMS201"
                size="small"
                sx={{
                  "& .MuiInputBase-root": {
                    borderRadius: "30px",
                  },
                  margin: "10px 0",
                }}
                onChange={(e) => setTrackingIdInput(e.target.value)}
              />
              <Button
                sx={{
                  "&.MuiButton-root": {
                    color: theme.palette.neutral[90],
                    backgroundColor: theme.palette.primary.main,
                    textTransform: "none",
                    borderRadius: "30px",
                    padding: "5px 20px",
                    // width: "100%",
                    "&:hover": {
                      backgroundColor: theme.palette.primary.hover,
                    },
                  },
                }}
                size="small"
                disabled={!trackingIdInput}
                onClick={() =>
                  navigate(`/article/track?trackingId=${trackingIdInput}`)
                }
              >
                Get Details
              </Button>
            </Box>
          </StyledSideBox>
        </Grid>
        <Grid item xs={12} sm={9} order={{ xs: 1, sm: 2 }}>
          <Box
            sx={{
              borderRadius: theme.borderRadius[10],
              padding: "20px",
              boxShadow: theme.boxShadow.shadowTwo,
              marginBottom: "1rem",
              background: theme.palette.neutral[0],
            }}
          >
            <Box
              sx={{
                marginBottom: "5px",
                display: "flex",
              }}
            >
              <FilterAltIcon
                sx={{ color: theme.palette.primary.main, marginRight: "5px" }}
              />
              <Typography
                variant="p"
                gutterBottom
                sx={{
                  fontWeight: 600,
                }}
              >
                Browse By Subjects
              </Typography>
            </Box>
            <Divider
              variant="fullWidth"
              sx={{ borderColor: theme.palette.secondary.main }}
            />
            <Grid item container xs={12} spacing={2} marginTop={"5px"}>
              {subjectList?.loading ? (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "150px",
                    width: "100%",
                  }}
                >
                  <Loader />
                </Box>
              ) : subjectList?.docs?.length > 0 ? (
                subjectList?.docs?.map((subject) => (
                  <Grid
                    key={`${subject._id}_${subject.name}`}
                    item
                    xs={6}
                    sm={4}
                    lg={3}
                  >
                    <Typography
                      variant="body2"
                      gutterBottom
                      sx={{
                        width: "fit-content",
                        color:
                          subject?._id === selectedSubject
                            ? theme.palette.secondary.main
                            : theme.palette.neutral[80],
                        fontSize: "0.813rem",
                        "&:hover": {
                          cursor: "pointer",
                          textDecoration: "underline",
                        },
                      }}
                      onClick={() => setSelectedSubject(subject?._id)}
                    >
                      {subject?.name}
                    </Typography>
                  </Grid>
                ))
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "150px",
                    width: "100%",
                  }}
                >
                  <Typography
                    variant="caption"
                    gutterBottom
                    sx={{
                      textAlign: "center",
                      color: theme.palette.neutral[50],
                    }}
                  >
                    No subjects available
                  </Typography>
                </Box>
              )}
            </Grid>
          </Box>

          <Box
            sx={{
              borderRadius: theme.borderRadius[10],
              padding: "20px",
              boxShadow: theme.boxShadow.shadowTwo,
              marginBottom: "30px",
              background: theme.palette.neutral[0],
            }}
          >
            <Box
              sx={{
                marginBottom: "5px",
                display: "flex",
              }}
            >
              <i
                className="ri-booklet-fill"
                style={{
                  color: theme.palette.primary.main,
                  marginRight: "5px",
                }}
              ></i>
              <Typography
                variant="p"
                gutterBottom
                sx={{
                  fontWeight: 600,
                }}
              >
                Journals
              </Typography>
            </Box>
            <Divider
              variant="fullWidth"
              sx={{ borderColor: theme.palette.secondary.main }}
            />
            <Grid item container xs={12} spacing={2} sx={{ marginTop: "10px" }}>
              {journalList?.loading ? (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "300px",
                    width: "100%",
                  }}
                >
                  <Loader />
                </Box>
              ) : journalList?.docs?.length > 0 ? (
                journalList?.docs?.map((journal) => (
                  <Grid key={`${journal._id}`} item xs={12} sm={6} lg={4}>
                    <JournalCard journalDetails={journal} />
                  </Grid>
                ))
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "300px",
                    width: "100%",
                  }}
                >
                  <Typography
                    variant="caption"
                    gutterBottom
                    sx={{
                      textAlign: "center",
                      color: theme.palette.neutral[50],
                    }}
                  >
                    No Journals available
                  </Typography>
                </Box>
              )}
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default JournalList;
