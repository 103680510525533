import axiosInstance from "../../axiosInstance";

/**
 * Get Journal View for website
 * @param {*} journalId
 * @param {*} journalCustomId
 * @returns
 */
const getJournalViewWebsite = async ({
  journalId = "",
  journalCustomId = "",
}) => {
  try {
    const res = await axiosInstance.get(
      `/api/journal/websiteJournalView?journalId=${journalId}&journalCustomId=${journalCustomId}`
    );

    if (res.status === 200) {
      return res.data;
    } else {
    }
  } catch (error) {}
};

export default getJournalViewWebsite;
