import React from "react";
import { useTheme } from "@emotion/react";
import { Box, Button, Grid, Typography, styled } from "@mui/material";
import {
  FaqPoints,
  refundPolicyPoints,
  TCPoints,
} from "../../../helpers/constant";
import * as DOMPurify from "dompurify";
import htmlParser from "html-react-parser";

const StyledBox = styled(Grid)(({ theme }) => ({
  width: "100%",
  height: "100%",
  // background: theme.palette.primary.main,
  borderRadius: theme.borderRadius[10],
  padding: "25px",
  boxShadow: theme.boxShadow.shadowThree,
  marginBottom: "10px",
  textAlign: "center",
}));

const RefundPolicy = () => {
  return (
    <Box
      sx={{
        padding: "20px 40px",
        height: "100%",
        textAlign: "justify",
        // background: theme.palette.primary.litSurface,
      }}
    >
      <Typography
        variant="h5"
        gutterBottom
        sx={{
          fontWeight: 600,
          marginBottom: "20px",
        }}
      >
        REFUND POLICY
      </Typography>

      {refundPolicyPoints.map((refundPoint) => (
        <Box
          sx={{
            marginBottom: "30px",
          }}
        >
          <Typography
            variant="body1"
            gutterBottom
            sx={{
              fontWeight: 600,
              marginBottom: "10px",
            }}
          >
            {refundPoint.title}
          </Typography>
          <Typography variant="body1" gutterBottom>
            {refundPoint.description}
          </Typography>
          {refundPoint?.points?.length > 0 && (
            <ul>
              {refundPoint?.points?.map((rPoint) => (
                <li>
                  <Typography
                    variant="body1"
                    gutterBottom
                    sx={{
                      marginBottom: "20px",
                    }}
                  >
                    {htmlParser(DOMPurify.sanitize(rPoint.text))}
                  </Typography>
                </li>
              ))}
            </ul>
          )}
        </Box>
      ))}
    </Box>
  );
};

export default RefundPolicy;
