import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useMediaQuery, useTheme } from "@mui/material";

export default function CustomDialog({
  open,
  handleClose,
  title,
  dialogProp,
  agreeButtonProp,
  cancelButtonProp,
  agreeButtonText,
  cancelButtonText,
  children,
}) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Dialog
      // fullScreen={fullScreen}
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
      {...dialogProp}
    >
      <DialogTitle id="responsive-dialog-title">{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button {...cancelButtonProp}>{cancelButtonText}</Button>
        <Button {...agreeButtonProp}>{agreeButtonText}</Button>
      </DialogActions>
    </Dialog>
  );
}
