import React from "react";
import { Box, Button, Grid, Typography, styled, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";

const StyledArchiveBox = styled(Box)(({ theme }) => ({
  boxShadow: theme.boxShadow.shadowTwo,
  borderRadius: theme.borderRadius[10],
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  overflow: "hidden",
}));

const StyledTitleBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.litSurface,
  padding: "10px 20px",
}));

const ArchiveCard = ({ archiveName, issueList }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  return (
    <StyledArchiveBox>
      <StyledTitleBox>
        <Typography
          variant="body1"
          sx={{
            fontWeight: 600,
          }}
        >
          {archiveName}
        </Typography>
      </StyledTitleBox>
      <Box
        sx={{
          padding: "20px",
        }}
      >
        {issueList.length > 0 ? (
          <Grid container spacing={2}>
            {issueList?.map((issue, index) => (
              <Grid
                item
                xs={12}
                sm={4}
                md={4}
                lg={2}
                key={`${archiveName}_${issue.name}_${index}`}
              >
                <Button
                  fullWidth
                  sx={{
                    "&.MuiButton-root": {
                      color: theme.palette.neutral[90],
                      backgroundColor: theme.palette.primary.main,
                      textTransform: "none",
                      borderRadius: "30px",
                      // padding: "10px 20px",
                      // width: "100%",
                      marginTop: "10px",
                      "&:hover": {
                        backgroundColor: theme.palette.primary.hover,
                      },
                    },
                  }}
                  size="small"
                  onClick={() => navigate(`/issue/${issue?._id}`)}
                >
                  {issue.name}
                </Button>
              </Grid>
            ))}
          </Grid>
        ) : (
          <Typography variant="body1">No Issue Available</Typography>
        )}
      </Box>
    </StyledArchiveBox>
  );
};

export default ArchiveCard;
