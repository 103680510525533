import React from "react";
import { useTheme } from "@emotion/react";
import { Box, Button, Grid, Typography, styled } from "@mui/material";
import { TCPoints } from "../../../helpers/constant";
import * as DOMPurify from "dompurify";
import htmlParser from "html-react-parser";

const StyledBox = styled(Grid)(({ theme }) => ({
  width: "100%",
  height: "100%",
  // background: theme.palette.primary.main,
  borderRadius: theme.borderRadius[10],
  padding: "25px",
  boxShadow: theme.boxShadow.shadowThree,
  marginBottom: "10px",
  textAlign: "center",
}));

const TermAndCond = () => {
  return (
    <Box
      sx={{
        padding: "20px 40px",
        height: "100%",
        textAlign: "justify",
        // background: theme.palette.primary.litSurface,
      }}
    >
      <Typography
        variant="h5"
        gutterBottom
        sx={{
          fontWeight: 600,
          marginBottom: "20px",
        }}
      >
        TERMS AND CONDITIONS
      </Typography>

      {TCPoints.map((tcPoint) => (
        <>
          <Typography
            variant="body1"
            gutterBottom
            sx={{
              fontWeight: 600,
              marginBottom: "10px",
            }}
          >
            {tcPoint.title}
          </Typography>
          {tcPoint.descriptions.map((description) => (
            <Typography
              variant="body1"
              gutterBottom
              sx={{
                marginBottom: "20px",
              }}
            >
              {htmlParser(DOMPurify.sanitize(description.text))}
            </Typography>
          ))}
          {tcPoint?.points?.length > 0 && (
            <ul>
              {tcPoint?.points?.map((point) => (
                <li>
                  <Typography
                    variant="body1"
                    gutterBottom
                    sx={{
                      marginBottom: "20px",
                    }}
                  >
                    {point.text}
                  </Typography>
                </li>
              ))}
            </ul>
          )}
          {tcPoint?.conclusion?.length > 0 &&
            tcPoint?.conclusion?.map((conclusion) => (
              <Typography
                variant="body1"
                gutterBottom
                sx={{
                  marginBottom: "20px",
                }}
              >
                {conclusion.text}
              </Typography>
            ))}
        </>
      ))}
    </Box>
  );
};

export default TermAndCond;
