import axiosInstance from "../../axiosInstance";

/**
 * Get Subject list
 * @param {*} sortBy
 * @param {*} sortDirection
 * @param {*} page
 * @param {*} limit
 * @returns
 */
const getIssueList = async ({
  journal = "",
  archive = "",
  sortBy = "",
  sortDirection = "",
  page = 1,
  limit = "",
}) => {
  try {
    const res = await axiosInstance.get(
      `/api/issue/list?sortBy=${sortBy}&sortDirection=${sortDirection}&page=${page}&limit=${limit}&journal=${journal}&archive=${archive}`
    );

    if (res.status === 200) {
      return res.data;
    } else {
    }
  } catch (error) {}
};

export default getIssueList;
