import React, { useState } from "react";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";

import {
  Box,
  Button,
  CircularProgress,
  Grid,
  useTheme,
  Divider,
  Typography,
  IconButton,
  styled,
  InputAdornment,
  Paper,
} from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import { CustomTextField, SlateEditor } from "../../../../components";

const ArticleReference = ({ handleTabChange }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [saveLoader, setSaveLoader] = useState(false);

  const {
    control,
    // handleSubmit,
    formState: { errors },
    getValues,
  } = useFormContext();

  const referenceFieldArray = useFieldArray({ control, name: "references" });
  // const onSubmit = async (data, event) => {
  //   event.preventDefault();
  //   setSaveLoader(true);

  //   const reqSaveArticle = {
  //     ...data,
  //     issue: data.issue._id,
  //     journal: data.issue.journal._id,
  //     archive: data.issue.archive._id,
  //     articleId: searchParams.get("articleId") || "",
  //     type: data.type.name,
  //   };

  //   const articleFormData = new FormData();

  //   Object.entries(reqSaveArticle).forEach((formValue) => {
  //     articleFormData.append(formValue[0], formValue[1]);
  //   });

  //   const resSaveArticle = await articleAdminServices.createArticle(
  //     articleFormData
  //   );

  //   if (resSaveArticle) {
  //     toast.success("Article save successful");
  //     setSaveLoader(false);
  //     localStorage.removeItem("insertedArticleImages");
  //     // setImages((prev) => ({
  //     //   ...prev,
  //     //   inserted: [],
  //     // }));
  //     // navigate(`/admin/article?issueId=${issueOptions[0]?._id}`);
  //   } else {
  //     setSaveLoader(false);
  //   }
  // };

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item container xs={12}>
          <Grid item container spacing={2} xs={12}>
            {referenceFieldArray?.fields?.length > 0 &&
              referenceFieldArray?.fields?.map((rField, rFieldIndex) => (
                <Grid
                  item
                  container
                  xs={12}
                  key={rField.id}
                  spacing={2}
                  sx={{
                    marginBottom: "20px",
                  }}
                >
                  <Grid item xs={12}>
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography
                        gutterBottom
                        sx={{
                          fontWeight: 600,
                        }}
                      >
                        Reference {rFieldIndex + 1}
                      </Typography>

                      <IconButton
                        onClick={() => referenceFieldArray.remove(rFieldIndex)}
                        edge="end"
                        sx={{
                          background: theme.palette.neutral[0],
                          color: theme.palette.secondary.main,
                        }}
                      >
                        <i class="ri-delete-bin-2-fill ri-sm"></i>
                      </IconButton>
                    </Box>
                    <Divider variant="fullWidth" />
                  </Grid>
                  <AuthorNameField rFieldIndex={rFieldIndex} />
                  <Grid item xs={12} sm={3}>
                    <Controller
                      name={`references.${rFieldIndex}.year`}
                      control={control}
                      render={({ field }) => (
                        <CustomTextField
                          label="Year"
                          variant="outlined"
                          size="small"
                          type="text"
                          fullWidth
                          helperText={
                            errors?.references?.[rFieldIndex]?.["year"]?.message
                          }
                          error={
                            errors?.references?.[rFieldIndex]?.["year"]?.message
                          }
                          {...field}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} sm={9}>
                    <Controller
                      name={`references.${rFieldIndex}.articleTitle`}
                      control={control}
                      render={({ field }) => (
                        <CustomTextField
                          label="Article title"
                          variant="outlined"
                          size="small"
                          type="text"
                          fullWidth
                          helperText={
                            errors?.references?.[rFieldIndex]?.["articleTitle"]
                              ?.message
                          }
                          error={
                            errors?.references?.[rFieldIndex]?.["articleTitle"]
                              ?.message
                          }
                          {...field}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Controller
                      name={`references.${rFieldIndex}.journalName`}
                      control={control}
                      render={({ field }) => (
                        <CustomTextField
                          label="Journal name"
                          variant="outlined"
                          size="small"
                          type="text"
                          fullWidth
                          helperText={
                            errors?.references?.[rFieldIndex]?.["journalName"]
                              ?.message
                          }
                          error={
                            errors?.references?.[rFieldIndex]?.["journalName"]
                              ?.message
                          }
                          {...field}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <Controller
                      name={`references.${rFieldIndex}.volume`}
                      control={control}
                      render={({ field }) => (
                        <CustomTextField
                          label="Volume"
                          variant="outlined"
                          size="small"
                          type="text"
                          fullWidth
                          helperText={
                            errors?.references?.[rFieldIndex]?.["volume"]
                              ?.message
                          }
                          error={
                            errors?.references?.[rFieldIndex]?.["volume"]
                              ?.message
                          }
                          {...field}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <Controller
                      name={`references.${rFieldIndex}.issue`}
                      control={control}
                      render={({ field }) => (
                        <CustomTextField
                          label="Issue"
                          variant="outlined"
                          size="small"
                          type="text"
                          fullWidth
                          helperText={
                            errors?.references?.[rFieldIndex]?.["issue"]
                              ?.message
                          }
                          error={
                            errors?.references?.[rFieldIndex]?.["issue"]
                              ?.message
                          }
                          {...field}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <Controller
                      name={`references.${rFieldIndex}.fPage`}
                      control={control}
                      render={({ field }) => (
                        <CustomTextField
                          label="First Page"
                          variant="outlined"
                          size="small"
                          type="text"
                          fullWidth
                          helperText={
                            errors?.references?.[rFieldIndex]?.["fPage"]
                              ?.message
                          }
                          error={
                            errors?.references?.[rFieldIndex]?.["fPage"]
                              ?.message
                          }
                          {...field}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <Controller
                      name={`references.${rFieldIndex}.lPage`}
                      control={control}
                      render={({ field }) => (
                        <CustomTextField
                          label="Last Page"
                          variant="outlined"
                          size="small"
                          type="text"
                          fullWidth
                          helperText={
                            errors?.references?.[rFieldIndex]?.["lPage"]
                              ?.message
                          }
                          error={
                            errors?.references?.[rFieldIndex]?.["lPage"]
                              ?.message
                          }
                          {...field}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Controller
                      name={`references.${rFieldIndex}.link`}
                      control={control}
                      render={({ field }) => (
                        <CustomTextField
                          label="Link"
                          variant="outlined"
                          size="small"
                          type="text"
                          fullWidth
                          helperText={
                            errors?.references?.[rFieldIndex]?.["link"]?.message
                          }
                          error={
                            errors?.references?.[rFieldIndex]?.["link"]?.message
                          }
                          {...field}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              ))}
            <Grid item xs={6}>
              <Button
                variant="contained"
                color="primary"
                sx={{
                  "&.MuiButton-root": {
                    color: theme.palette.neutral[90],
                    borderRadius: theme.borderRadius[10],
                    textTransform: "none",
                    "&:hover": {
                      backgroundColor: theme.palette.primary.hover,
                    },
                  },
                }}
                size="small"
                fullWidth
                // disabled={saveLoader}
                onClick={() =>
                  referenceFieldArray.append({
                    authorNames: [
                      {
                        surname: "",
                        shortName: "",
                      },
                    ],
                    year: "",
                    articleTitle: "",
                    journalName: "",
                    volume: "",
                    issue: "",
                    fPage: "",
                    lPage: "",
                    link: "",
                  })
                }
              >
                Add Reference
              </Button>
            </Grid>
          </Grid>
        </Grid>

        <Grid item container xs={12} justifyContent={"flex-end"}>
          <Grid item xs={12} md={3} lg={3}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                sx={{
                  "&.MuiButton-root": {
                    marginTop: "20px",
                    marginRight: "10px",
                    color: theme.palette.neutral[90],
                    borderRadius: theme.borderRadius[10],
                    textTransform: "none",
                    "&:hover": {
                      backgroundColor: theme.palette.primary.hover,
                    },
                  },
                }}
                size="small"
                type="button"
                disabled={saveLoader}
                endIcon={saveLoader && <CircularProgress size={20} />}
                onClick={() => handleTabChange("abstract")}
              >
                Pervious
              </Button>
              <Button
                variant="contained"
                color="secondary"
                sx={{
                  "&.MuiButton-root": {
                    marginTop: "20px",
                    color: theme.palette.neutral[0],
                    borderRadius: theme.borderRadius[10],
                    textTransform: "none",
                    "&:hover": {
                      backgroundColor: theme.palette.secondary.hover,
                    },
                  },
                }}
                size="small"
                type="button"
                disabled={saveLoader}
                endIcon={saveLoader && <CircularProgress size={20} />}
                onClick={() => handleTabChange("citation")}
              >
                Next
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

const AuthorNameField = ({ rFieldIndex }) => {
  const theme = useTheme();
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const rAuthorFieldArray = useFieldArray({
    control,
    name: `references.${rFieldIndex}.authorNames`,
  });

  return (
    <Grid item container xs={12} spacing={2} alignItems={"center"}>
      {rAuthorFieldArray?.fields?.length > 0 &&
        rAuthorFieldArray?.fields?.map((rAuthor, rAuthorIndex) => (
          <Grid
            item
            container
            xs={12}
            sm={12}
            md={6}
            lg={6}
            spacing={2}
            key={rAuthor.id}
            alignItems={"end"}
          >
            <Grid item xs={12} sm={6}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "baseline",
                  justifyContent: "flex-start",
                  marginBottom: "10px",
                }}
              >
                <Typography variant="body2" gutterBottom>
                  Author {rAuthorIndex + 1}
                </Typography>
                {rAuthorIndex > 0 && (
                  <IconButton
                    onClick={() => rAuthorFieldArray.remove(rAuthorIndex)}
                    edge="end"
                    sx={{
                      background: theme.palette.neutral[0],
                      color: theme.palette.secondary.main,
                    }}
                  >
                    <i class="ri-delete-bin-2-fill ri-sm"></i>
                  </IconButton>
                )}
              </Box>
              <Controller
                name={`references.${rFieldIndex}.authorNames.${rAuthorIndex}.surname`}
                control={control}
                render={({ field }) => (
                  <CustomTextField
                    label="Surname"
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    helperText={
                      errors?.references?.[rFieldIndex]?.authorNames?.[
                        rAuthorIndex
                      ]?.["surname"]?.message
                    }
                    error={
                      errors?.references?.[rFieldIndex]?.authorNames?.[
                        rAuthorIndex
                      ]?.["surname"]?.message
                    }
                    sx={{
                      marginBottom: "10px",
                    }}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name={`references.${rFieldIndex}.authorNames.${rAuthorIndex}.shortName`}
                control={control}
                render={({ field }) => (
                  <CustomTextField
                    label="Short G-name. M-Name."
                    variant="outlined"
                    size="small"
                    type="text"
                    fullWidth
                    helperText={
                      errors?.references?.[rFieldIndex]?.authorNames?.[
                        rAuthorIndex
                      ]?.["shortName"]?.message
                    }
                    error={
                      errors?.references?.[rFieldIndex]?.authorNames?.[
                        rAuthorIndex
                      ]?.["shortName"]?.message
                    }
                    sx={{
                      marginBottom: "10px",
                    }}
                    {...field}
                  />
                )}
              />
            </Grid>
          </Grid>
        ))}
      <Grid item xs={12} sm={6} md={3} lg={3}>
        <Box
          sx={{
            height: "80%",
          }}
        >
          <Button
            variant="contained"
            color="primary"
            sx={{
              "&.MuiButton-root": {
                color: theme.palette.neutral[90],
                borderRadius: theme.borderRadius[10],
                textTransform: "none",
                "&:hover": {
                  backgroundColor: theme.palette.primary.hover,
                },
              },
            }}
            size="small"
            fullWidth
            // disabled={saveLoader}
            onClick={() =>
              rAuthorFieldArray.append({
                surname: "",
                shortName: "",
              })
            }
          >
            Add Author
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default ArticleReference;
