import { Box, useTheme, FormHelperText, FormControl } from "@mui/material";
import React, { forwardRef } from "react";
import ReactSelect from "react-select";
import AsyncReactSelect from "react-select/async";

const CustomReactSelect = forwardRef(
  ({ label, async, helperText, error, formControlProps, ...props }, ref) => {
    const theme = useTheme();
    return (
      <FormControl sx={{ width: "100%" }} {...formControlProps}>
        {label && (
          <label style={{ color: theme.palette.neutral[90] }}>{label}</label>
        )}
        {async ? (
          <AsyncReactSelect ref={ref} {...props} />
        ) : (
          <ReactSelect ref={ref} {...props} />
        )}
        {(error || helperText) && (
          <FormHelperText error={error}>{helperText}</FormHelperText>
        )}
      </FormControl>
    );
  }
);

export default CustomReactSelect;
