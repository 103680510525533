import { createTheme } from "@mui/material/styles";
import colorPalette from "./colorPalette";
import borderRadius from "./borderRadius";
import boxShadow from "./boxShadow";

const theme = createTheme({
  palette: colorPalette,
  borderRadius: borderRadius,
  boxShadow: boxShadow,
});

export default theme;
